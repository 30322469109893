import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { inputCheckToNumber, strategyDeactivate } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import InputItem from '@shared/UI/InputItem/InputItem';
import {
  getOptions,
  updateDeactivateOptions,
  updateOptions,
} from '@widgets/Redundancy/RedundancyRequests/RedundancyRequests';
import './RedundancySettings.scss';
import { useTranslation } from 'react-i18next';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';
import Modal from '@shared/UI/Modal/Modal';
import InputMeasureable from '@shared/UI/InputMeasureable/InputMeasureable';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";

const RedundancySettings = ({ closeMenu }) => {
  const { t } = useTranslation();
  const [options, setOptions] = React.useState(null);
  const [deactivOptions, setDeactivOptions] = React.useState(null);
  const [numFail, setNumFail] = React.useState(null);
  const [numTimeout, setNumTimeout] = React.useState(null);
  const [showError, setShowError] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');

  const getSettingsData = () => {
    getOptions(setOptions, setDeactivOptions, setShowError, setMessageError);
  };

  const methods = useForm();
  const { handleSubmit, register, watch, setValue } = methods;
  const numberFailed = watch('number_of_ping_request');
  //   console.log('numberFailed', numberFailed);
  const numberTimeout = watch('ping_timeout');
  //   console.log('numberTimeout', numberTimeout);
  const cpuCheck = watch('cpu_condition.enabled');

  React.useEffect(() => {
    getSettingsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (options) {
      //   console.log('options', options);
      setValue(`checkUpdate`, Object.hasOwn(options, 'update_schema_by_time'));
      setNumFail(options.number_of_ping_request);
      setNumTimeout(options.ping_timeout);
    }
    if (deactivOptions) {
      setValue(`cpu_condition.enabled`, deactivOptions.cpu_condition.enabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, deactivOptions]);

  React.useEffect(() => {
    numberFailed && setNumFail(numberFailed ? numberFailed : options.number_of_ping_request);
    numberTimeout && setNumTimeout(numberTimeout ? numberTimeout : options.ping_timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberFailed, numberTimeout]);

  const onSubmitReserve = (data) => {
    // console.log('data', data);
    const optionsData = {
      number_of_ping_request: +data.number_of_ping_request,
      ping_timeout: +data.ping_timeout,
    };
    if (data.checkUpdate) {
      optionsData.update_schema_by_time = {};
      optionsData.update_schema_by_time.period = +data.update_schema_by_time.period;
    }
    if (data.checkUpdate && data?.updateTime === 'B') {
      optionsData.update_schema_by_time.start_timepoint = {};
      optionsData.update_schema_by_time.start_timepoint.hours =
        +data.update_schema_by_time.start_timepoint.hours;
      optionsData.update_schema_by_time.start_timepoint.minutes =
        +data.update_schema_by_time.start_timepoint.minutes;
    }
    // console.log('optionsData', optionsData);
    const deactivateData = {
      cpu_condition: {
        ...data.cpu_condition,
        duration: +data.cpu_condition.duration,
        max_value: +data.cpu_condition.max_value,
      },
      strategy: data.strategy,
      streams_condition_enabled: data.streams_condition_enabled,
      web_dav_condition_enabled: data.web_dav_condition_enabled,
    };
    // console.log('deactivateData', deactivateData);

    updateOptions(optionsData, setShowError, setMessageError, closeMenu).then(() =>
      updateDeactivateOptions(deactivateData, setShowError, setMessageError, closeMenu),
    );
  };

  return (
    <ModalPortal>
      <Modal title={t('redundancy_settings')} onClose={closeMenu} showLoader={!deactivOptions}>
        {deactivOptions && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitReserve)}>
              <div className="peerList__item mb10">
                <input
                  id={'checkUpdate'}
                  defaultChecked={Object.hasOwn(options, 'update_schema_by_time')}
                  {...register(`checkUpdate`)}
                  onChange={(e) => {
                    // console.log(e.target.checked);
                    register(`checkUpdate`).onChange(e);
                  }}
                  className="peerList-check"
                  type="checkbox"
                />
                <label className="peerList-label" htmlFor={'checkUpdate'}>
                  {t('update_schemas_on_redundant_servers')}
                </label>
              </div>

              <div className="condition-box mb10">
                <div className="condition-box__item input-wrapper--small-gap">
                  <input
                    disabled={!watch('checkUpdate')}
                    id={'updateTime'}
                    defaultChecked={
                      options?.update_schema_by_time
                        ? !Object.hasOwn(options?.update_schema_by_time, 'start_timepoint')
                        : true
                    }
                    value="A"
                    className="peerList-check"
                    type="radio"
                    {...register('updateTime')}
                  />
                  <label
                    htmlFor={'updateTime'}
                    className="input-wrapper__label"
                    disabled={!watch('checkUpdate')}
                  >
                    {t('update_schemas_after_every')}
                  </label>
                  <InputMeasureable
                    disabled={!watch('checkUpdate')}
                    inputName={'update_schema_by_time.period'}
                    defaultValue={
                      options?.update_schema_by_time?.period
                        ? options?.update_schema_by_time.period
                        : 24
                    }
                    inputMeasure={t('hours')}
                    onKeyPress={inputCheckToNumber}
                    min={0}
                    max={24}
                    wrapperClassName="w-95"
                    inputClassName="w-40"
                  />
                  <p className="input-wrapper__label" disabled={!watch('checkUpdate')}>
                    {t('after_the_service_start')}
                  </p>
                </div>
                <div className="condition-box__item input-wrapper--small-gap">
                  <input
                    defaultChecked={
                      options?.update_schema_by_time
                        ? Object.hasOwn(options?.update_schema_by_time, 'start_timepoint')
                        : false
                    }
                    value="B"
                    disabled={!watch('checkUpdate')}
                    id={'updateTimeFrom'}
                    className="peerList-check"
                    type="radio"
                    {...register('updateTime')}
                  />
                  <label
                    htmlFor={'updateTimeFrom'}
                    className="input-wrapper__label"
                    disabled={!watch('checkUpdate')}
                  >
                    {t('update_schemas_after_every')}
                  </label>
                  <InputMeasureable
                    disabled={!watch('checkUpdate')}
                    inputName={'update_schema_by_time.period'}
                    defaultValue={
                      options?.update_schema_by_time?.period
                        ? options?.update_schema_by_time.period
                        : 24
                    }
                    inputMeasure={t('hours')}
                    onKeyPress={inputCheckToNumber}
                    min={0}
                    max={24}
                    wrapperClassName="w-95"
                    inputClassName="w-40"
                  />
                  <p className="input-wrapper__label" disabled={!watch('checkUpdate')}>
                    {t('starting_from')}
                  </p>
                  <InputMeasureable
                    disabled={!watch('checkUpdate')}
                    inputName={'update_schema_by_time.start_timepoint.hours'}
                    defaultValue={
                      options?.update_schema_by_time?.start_timepoint?.hours
                        ? options?.update_schema_by_time?.start_timepoint?.hours
                        : 0
                    }
                    inputMeasure={t('hours')}
                    onKeyPress={inputCheckToNumber}
                    min={0}
                    max={23}
                    wrapperClassName="w-95"
                    inputClassName="w-40"
                  />

                  <InputMeasureable
                    disabled={!watch('checkUpdate')}
                    inputName={'update_schema_by_time.start_timepoint.minutes'}
                    defaultValue={
                      options?.update_schema_by_time?.start_timepoint?.minutes
                        ? options?.update_schema_by_time?.start_timepoint?.minutes
                        : 0
                    }
                    inputMeasure={t('minutes')}
                    onKeyPress={inputCheckToNumber}
                    min={0}
                    max={59}
                    wrapperClassName="w-120"
                    inputClassName="w-40"
                  />
                </div>
              </div>

              <div className="divider-thin" />

              <div className="customList__item m10">
                <label className="addServer-item__label" htmlFor="number_of_ping_request">
                  {t('number_of_failed_requests')}
                </label>
                <InputItem
                  classes={'num ta-e'}
                  value={options.number_of_ping_request}
                  name={'number_of_ping_request'}
                  type={'number'}
                  minValue={0}
                  maxValue={10}
                  onKeyPress={inputCheckToNumber}
                />
              </div>
              <div className="customList__item m10">
                <label className="addServer-item__label" htmlFor="ping_timeout">
                  {t('requests_timeout')}
                </label>

                <InputMeasureable
                  inputName={'ping_timeout'}
                  defaultValue={options.ping_timeout}
                  inputMeasure={t('milliseconds')}
                  onKeyPress={inputCheckToNumber}
                  min={0}
                  max={1800000}
                  wrapperClassName="w-200"
                  inputClassName="w-95"
                />
              </div>

              <div className="customList__item m10">
                <label className="addServer-item__label">{t('failover_timeout')}</label>
                <div className="input--measureable w-200" disabled>
                  <input
                    className={'input--measureable__input ta-e'}
                    type="text"
                    disabled
                    value={numFail * numTimeout}
                  />
                  <div className="input--measureable__measure">{t('milliseconds')}</div>
                </div>
              </div>

              <div className="divider-thin" />

              {/* DEACTIVATE */}
              <div className="deactivateBlock">
                <div className="customList__item">
                  <label className="addServer-item__label  ml-10 mt-10">
                    {t('deactivate_server_in_case_of')}:
                  </label>
                </div>
                <div className="deactivation-border m10">
                  <div className="customList__item mb10">
                    <label className="addServer-item__label">
                      {t('Deactivation_settings_are_applied')}
                    </label>
                  </div>
                  <div className="customList__item m10">
                    <label className="addServer-item__label">{t('deactivate_strategy')}:</label>
                    <select
                      {...register('strategy')}
                      defaultValue={deactivOptions.strategy}
                      className="source-input__param w-fc"
                    >
                      {strategyDeactivate(t).map((elem, index) => (
                        <option key={elem.name + index} value={elem.value}>
                          {elem.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="peerList__item m10">
                    <input
                      id={'noStreams'}
                      defaultChecked={deactivOptions.streams_condition_enabled}
                      {...register('streams_condition_enabled')}
                      className="peerList-check"
                      type="checkbox"
                    />
                    <label className="peerList-label" htmlFor={'noStreams'}>
                      {t('no_input_streams_detected')}
                    </label>
                  </div>
                  <div className="peerList__item m10">
                    <input
                      id={'webDav'}
                      defaultChecked={deactivOptions.web_dav_condition_enabled}
                      {...register('web_dav_condition_enabled')}
                      className="peerList-check"
                      type="checkbox"
                    />
                    <label className="peerList-label" htmlFor={'webDav'}>
                      {t('failure_of_publishing_segments')}
                    </label>
                  </div>
                  <div className="peerList__item m10">
                    <input
                      id={'cpuUsage'}
                      defaultChecked={deactivOptions.cpu_condition.enabled}
                      {...register('cpu_condition.enabled')}
                      className="peerList-check"
                      type="checkbox"
                    />
                    <label className="peerList-label" htmlFor={'cpuUsage'}>
                      {t('cpu_usage')}
                    </label>
                  </div>
                  <div className="ml-10">
                    <div className="customList__item m10">
                      <label className="input-wrapper__label" disabled={!cpuCheck} htmlFor="source">
                        {t('max_load_value')}
                      </label>
                      <InputMeasureable
                        disabled={!cpuCheck}
                        inputName={'cpu_condition.max_value'}
                        defaultValue={deactivOptions.cpu_condition.max_value}
                        inputMeasure={'%'}
                        onKeyPress={inputCheckToNumber}
                        min={1}
                        max={99}
                        wrapperClassName="w-200"
                        inputClassName="w-95"
                      />
                    </div>
                    <div className="customList__item m10">
                      <label className="input-wrapper__label" disabled={!cpuCheck} htmlFor="source">
                        {t('during')}
                      </label>

                      <InputMeasureable
                        disabled={!cpuCheck}
                        inputName={'cpu_condition.duration'}
                        defaultValue={deactivOptions.cpu_condition.duration}
                        inputMeasure={t('seconds')}
                        onKeyPress={inputCheckToNumber}
                        min={0}
                        max={1800000}
                        wrapperClassName="w-200"
                        inputClassName="w-95"
                      />
                    </div>
                  </div>
                </div>
                {showError && <div className="login-error">{messageError}</div>}
                <div className="redundancy__button-apply">
                  <ButtonBase color={'green'} type={'submit'}>{t('apply')}</ButtonBase>
                </div>
              </div>
            </form>
          </FormProvider>
        )}
      </Modal>
    </ModalPortal>
  );
};

export default RedundancySettings;
