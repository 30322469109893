import $api from './apiAxios';

export default class Server {
  static async getData() {
    let data;
    try {
      data = await $api.get('/server/config');
      if (data.isAxiosError) {
        throw new Error(data?.response);
      }
      return data;
    } catch (error) {
      return Promise.reject(data);
    }
  }

  static async updateSettings(dataObj) {
    const data = await $api.put(`/server/config`, dataObj);
    if (data.isAxiosError) {
      throw new Error(data?.response?.data ? data?.response?.data[0].message : data.message);
    }
    return data;
  }
}
