import React from 'react';

const ErrorsAlert = ({ arrMessages }) => {
  // console.log('arrMessages', arrMessages);
  return (
    <div className="errorAlert">
      {arrMessages.map((elem) => (
        <div className="errorAlert__item" key={elem.name}>
          {elem.message}
        </div>
      ))}
    </div>
  );
};

export default ErrorsAlert;
