import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import './InputItem.scss';

const InputItem = ({
  value,
  classes,
  disabled,
  name,
  refs,
  type,
  maxValue,
  minValue,
  onKeyPress,
}) => {
  const {
    register,
    setValue,
    // formState: { errors },
  } = useFormContext();

  return (
    <input
      ref={refs}
      className={classNames('item-input', classes ? classes : '')}
      {...register(`${name}`)}
      onChange={(e) => {
        const updateValue = e.target.value;
        register(`${name}`).onChange(e);
        setValue(`${name}`, +updateValue);
      }}
      type={type ? type : 'text'}
      disabled={disabled}
      defaultValue={value}
      min={minValue}
      max={maxValue}
      onKeyPress={onKeyPress}
      id={name}
      data-testid={'input-item'}
    />
  );
};

export default InputItem;
