import React from 'react';
import s from './ErrorAlert.module.scss';
import { ReactComponent as CloseBtn } from '@images/close-lite.svg';
import ForbiddenRow from '@shared/UI/Warnings/ForbiddenRow/ForbiddenRow';

const ErrorAlert = ({ message, status, onClose }) =>
  status === 403 ? (
    <ForbiddenRow />
  ) : (
    <div className={s['error-alert']}>
      <CloseBtn className={s['error-alert__close-btn']} onClick={onClose} />
      <pre className={s['error-alert__text']}>{message}</pre>
    </div>
  );

export default ErrorAlert;
