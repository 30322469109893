import React from 'react';
import './MyTable.scss';

const MyTable = ({ items, bodyItems, empty, emptyText, setBodyItems }) => {
  const [mainCheck, setMainCheck] = React.useState(false);
  // console.log('bodyItems', bodyItems);

  React.useEffect(() => {
    if (bodyItems) {
      if (bodyItems.length !== 0) {
        const bodyItemsLength = bodyItems.length;
        const checkedItems = bodyItems.filter((el) => el.checked).length;
        if (bodyItemsLength === checkedItems) {
          setMainCheck(true);
        } else {
          setMainCheck(false);
        }
      }
    }
  }, [bodyItems]);

  const setCheckbox = (name) => {
    const idxItem = bodyItems.findIndex((item) => item.name === name);
    const selectItem = bodyItems.find((item) => item.name === name);
    const updateSelectItem = { ...selectItem, checked: !selectItem.checked };
    const updateBodyItems = [
      ...bodyItems.slice(0, idxItem),
      updateSelectItem,
      ...bodyItems.slice(idxItem + 1),
    ];
    setBodyItems(updateBodyItems);
  };

  const setAllChecked = () => {
    const updateBodyItems = bodyItems.map((item) => ({
      ...item,
      checked: !mainCheck,
    }));
    setMainCheck(!mainCheck);
    setBodyItems(updateBodyItems);
  };

  return (
    <div className="tab">
      <div className="tab-content">
        <div className="tab-content-head">
          {items.map((elem) =>
            elem.name === 'checkbox' ? (
              <div className="tab-check" key={elem.name}>
                {empty ? (
                  <input type="checkbox" checked={false} disabled />
                ) : (
                  <input
                    checked={mainCheck}
                    className="checkbox"
                    type="checkbox"
                    onChange={setAllChecked}
                  />
                )}
              </div>
            ) : elem.name === '#' || elem.name === '№' ? (
              <div className="tab-num" key={elem.name}>
                {elem.name}
              </div>
            ) : (
              <div className="tab-text" key={elem.name}>
                {elem.name}
              </div>
            ),
          )}
        </div>
        {empty ? (
          <div className="tab-content-body">
            <div className="tab-check"></div>
            <div className="tab-text"></div>
            <div className="tab-text">{emptyText}</div>
          </div>
        ) : (
          bodyItems?.map((item, i) => (
            <div className="tab-content-body" key={'body-item ' + i}>
              <div className="tab-check">
                <input
                  className="checkbox"
                  checked={item?.checked}
                  type="checkbox"
                  onChange={() => {
                    setCheckbox(item.name);
                  }}
                />
              </div>
              <div className="tab-num">{i + 1}</div>
              <div className="tab-text" key={'tab-text' + i}>
                {item.name}
                {item.subname ? <span>&nbsp;({item.subname})</span> : null}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MyTable;
