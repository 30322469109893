import React, { useState } from 'react';
import OutputTabs from '@widgets/TaskConfigurator/OutputSection/OutputTabs/OutputTabs';
import OutputParameters from '@widgets/TaskConfigurator/OutputSection/OutputParameters/OutputParameters';
import ExtensionNode from '@widgets/TaskConfigurator/OutputSection/ExtensionNode/ExtensionNode';
import ExtensionAddButtons from '@widgets/TaskConfigurator/OutputSection/ExtensionAddButtons/ExtensionAddButtons';

const DVBTables = ({ dvbTables }) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      {dvbTables?.parameters && <OutputParameters parameters={dvbTables?.parameters} />}

      <OutputTabs
        className="pt-6"
        tabNames={dvbTables?.extensions}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        showCloseButton={true}
      >
        <ExtensionAddButtons
          className={'button-wrapper output-tabs__button-add'}
          extension={dvbTables}
          dependencies={[dvbTables]}
          actionAfterAdd={() =>
            setActiveTab(dvbTables && dvbTables?.extensions ? dvbTables?.extensions?.length : 0)
          }
        />
      </OutputTabs>

      {dvbTables?.extensions && dvbTables?.extensions[activeTab] && (
        <>
          {dvbTables?.extensions[activeTab]?.parameters && (
            <OutputParameters parameters={dvbTables?.extensions[activeTab]?.parameters} />
          )}
          <ExtensionAddButtons
            className={'button-wrapper'}
            extension={dvbTables?.extensions[activeTab]}
            dependencies={[dvbTables?.extensions[activeTab], activeTab]}
          />
          {dvbTables?.extensions[activeTab]?.extensions && (
            <div className="node-children">
              {dvbTables?.extensions[activeTab]?.extensions?.map((extensionNode) => (
                <ExtensionNode key={extensionNode.id} node={extensionNode} />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DVBTables;
