import React from 'react';
import { apiRestartServer } from '@widgets/Server/ServerInfo/api/apiServerInfo';
import { apiUpdateTasks } from '@widgets/Redundancy/api/apiRedundancyService';

const useServerInfoData = () => {
  // State
  const [showNetworkInterfaces, setShowNetworkInterfaces] = React.useState();

  // TASKS ACTIVE
  const [showMiniLoader, setShowMiniLoader] = React.useState(true);

  const host = window.location.host;
  const ws = React.useRef(null);
  const [wsData, setWsData] = React.useState(null);
  // console.log('wsData', wsData);
  // console.log('ws', ws);

  React.useEffect(() => {
    return () => {
      // console.log('CLOSED SOCKET SERVER INFO');
      ws.current.cwFinal = true;
      ws.current.close();
    };
  }, []);

  React.useEffect(() => {
    showMiniLoader && setShowMiniLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsData]);

  React.useEffect(() => {
    // console.log('ServerInfoWS', ws.current);
    // readyState===0 - CONNECTING
    // readyState===1 - OPEN
    // readyState===2 - CLOSING
    // readyState===3 - CLOSED
    if (ws.current === null || ws.current.readyState === 2 || ws.current.readyState === 3) {
      startWS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws.current]);

  const startWS = () => {
    ws.current = new WebSocket(`ws://${host}/api`);
    ws.current.onopen = () => {
      ws.current.send('/api');
      // console.log('Соединение открыто');
    };
    ws.current.onclose = () => {
      if (!ws.current.cwFinal) {
        setTimeout(startWS, 3000);
      }
      // console.log('Соединение закрыто');
    };
    ws.current.onerror = (event) => {
      console.dir(event);
    };
    gettingData();
  };

  const gettingData = () => {
    ws.current.onmessage = (e) => {
      if (e.data === 'OK') {
        return;
      }
      const message = JSON.parse(e.data);
      setWsData(message?.data?.node ? message.data.node : []);
    };
  };

  // Перезапуск сервера
  const ServerRestart = () => {
    setShowMiniLoader(true);
    apiRestartServer()
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.dir(err);
      });
  };

  // Обновление тасок
  const updateTasks = () => {
    // console.log('url', actions.get('restart')?.url);
    apiUpdateTasks()
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.dir(err);
      });
  };

  let time = wsData?.duration.time;
  let systemInfo = wsData?.system_info;
  /*  let cpu = wsData?.cpu;
  let gpu = wsData?.gpu;
  let ram = wsData?.ram; */

  return {
    ServerRestart,
    updateTasks,
    wsData,
    time,
    systemInfo,
    showMiniLoader,
    showNetworkInterfaces,
    setShowNetworkInterfaces,
    /*   cpu,
    gpu,
    ram, */
  };
};

export default useServerInfoData;
