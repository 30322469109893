import React from 'react';
import './ErrorRow.scss';

const ErrorRow = ({ message, setShowError }) => {
  const closeError = () => {
    setShowError(false);
  };
  return (
    <div className="errorRow">
      <div className="errorRow-close" onClick={closeError}>
        ×
      </div>
      <p>{message}</p>
    </div>
  );
};

export default ErrorRow;
