import React from 'react';
import ComponentView from '@components/ViewSourceLink/ComponentView';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: '' };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, message: error.toString() };
  }
  /*componentDidCatch(error, errorInfo) {
      //console.log(error);
      //console.log(errorInfo);
    }*/
  render() {
    if (this.state.hasError) {
      return <ComponentView error={this.state.message} />;
    }
    return this.props.children;
  }
}
