import React from 'react';
import classNames from 'classnames';
import RoundBadge from '@shared/UI/RoundBadge/RoundBadge';
import s from './TransformTabs.module.scss';
import TabItem from '@shared/UI/Tabs/TabItem/TabItem';
import { useDispatch, useSelector } from 'react-redux';
import { createTaskActions } from '@store/CreateTask';
import paletteClasses from '../../../../Shared/styles/paletteClasses.module.scss';

const TransformTabs = ({ tabsList }) => {
  const dispatch = useDispatch();
  const activeTransformTab = useSelector((state) => state.createTask.activeTransformTab);
  //табы были реализованы с activeTransformTab который хранится в стейте `tab-${elem.id}-${idx}-${index}`..необходим рефакторинг
  // console.log(tabsList)
  return (
    <ul className={s['transform-tabs']} data-testid="transform-tabs">
      {tabsList?.map((elem, idx) =>
        elem?.programs?.map((program, index) => {
          const tab = `tab-${elem.id}-${idx}-${index}`;

          return (
            program?.checked && (
              <TabItem
                onClick={() => dispatch(createTaskActions.setActiveTransformTab(tab))}
                active={activeTransformTab === tab}
                key={`tabs-${program.program}-index-${elem.id}_${idx}_${index}`}
                data-number={tab}
                tabText={`Prog#${program.program}`}
              >
                <RoundBadge
                  classNameBadge={classNames(
                    paletteClasses[`color-${elem.sectionNumber}`],
                    s['transform-tabs__round-badge'],
                  )}
                >
                  {elem.sectionNumber}
                </RoundBadge>
              </TabItem>
            )
          );
        }),
      )}
    </ul>
  );
};

export default TransformTabs;
