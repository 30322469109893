import React from 'react';
import s from './AddInputModal.module.scss';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';
import Modal from '@shared/UI/Modal/Modal';
import { TextField } from '@mui/material';
import ButtonBase from '@shared/UI/Buttons/ButtonBase/ButtonBase';
import { DEFAULT_TEXT } from '@shared/Lib/Utils/Constants/Constants';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { apiAddSection } from '@services/CreateTaskService';
import Loader from '@shared/UI/Loaders/Loader/Loader';

const AddInputModal = ({ setShowModal, draft, setTreeData, setSectionsData, templateData }) => {
  const methods = useForm();
  const { register, getValues, setValue, watch, resetField } = methods;

  const sourceTypeField = 'source-type';
  const inputProtocolField = 'input-protocol';
  const transportTypeField = 'transport-type';

  const [sourceTypeMap, setSourceTypeMap] = useState();
  useEffect(() => {
    if (templateData) {
      const rootKey = Object.keys(templateData)?.[0];
      const newSourceTypeMap = new Map(Object.entries(templateData?.[rootKey]));
      setSourceTypeMap(newSourceTypeMap);
      const selectedValue = [...newSourceTypeMap?.keys()]?.[2];
      selectedValue && setValue(sourceTypeField, selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData]);

  const [inputProtocolMap, setInputProtocolMap] = useState([]);
  useEffect(() => {
    if (watch(sourceTypeField)) {
      resetField(inputProtocolField);
      resetField(transportTypeField);

      if (sourceTypeMap) {
        const newInputProtocolMap = sourceTypeMap.has(getValues(sourceTypeField))
          ? sourceTypeMap.get(getValues(sourceTypeField))
          : [];

        setInputProtocolMap(newInputProtocolMap);
        const selectedValue = Object.keys(newInputProtocolMap?.[0])?.[0];
        selectedValue && setValue(inputProtocolField, selectedValue);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(sourceTypeField)]);

  const [transportTypeMap, setTransportTypeMap] = useState([]);
  useEffect(() => {
    if (watch(inputProtocolField)) {
      if (inputProtocolMap) {
        const newTransportTypeItem = inputProtocolMap.find(
          (item) => Object.keys(item)?.[0] === watch(inputProtocolField),
        );
        const newTransportTypeMap = newTransportTypeItem?.[watch(inputProtocolField)];
        newTransportTypeMap && setTransportTypeMap(newTransportTypeMap);
        const selectedValue = newTransportTypeMap?.[0];
        selectedValue && setValue(transportTypeField, selectedValue);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(inputProtocolField)]);

  const handleClickAddItem = () => {
    apiAddSection(watch(inputProtocolField), watch(transportTypeField)).then((res) => {
      const newSection = res?.data?.data?.section;

      setSectionsData((sectionsData) => {
        const oldSection = sectionsData.find((item) => item?.id === newSection?.id);
        if (oldSection) {
          return sectionsData.map((item) => (item?.id === newSection?.id ? newSection : item));
        } else {
          return [...sectionsData, newSection];
        }
      });

      setTreeData((treeData) => {
        const oldChild = treeData?.children?.find((item) => item?.id === newSection?.id);
        if (oldChild) {
          return {
            ...treeData,
            children: treeData?.children.map((item) =>
              item?.id === newSection?.id ? { id: newSection?.id, name: newSection?.name } : item,
            ),
          };
        } else {
          return {
            ...treeData,
            children: [...treeData.children, { id: newSection?.id, name: newSection?.name }],
          };
        }
      });
    });
    setShowModal(false);
  };

  return (
    <div>
      <ModalPortal>
        <Modal
          className={s['add-input-modal']}
          title={'Add input'}
          onClose={() => setShowModal(false)}
        >
          <div className={s['add-input-modal__container']}>
            <TextField required label="Name" defaultValue={DEFAULT_TEXT} size="small" />

            {!draft ? (
              <Loader className={'m-a'} />
            ) : (
              <>
                {sourceTypeMap && (
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    label="Source type"
                    SelectProps={{
                      native: true,
                    }}
                    {...register(sourceTypeField)}
                    size="small"
                  >
                    {[...sourceTypeMap.keys()].map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </TextField>
                )}

                {inputProtocolMap.length !== 0 && (
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    label="Input protocol/Source"
                    SelectProps={{
                      native: true,
                    }}
                    {...register(inputProtocolField)}
                    size="small"
                  >
                    {inputProtocolMap?.map((item, index) => (
                      <option key={index} value={Object.keys(item)}>
                        {Object.keys(item)}
                      </option>
                    ))}
                  </TextField>
                )}

                {transportTypeMap.length !== 0 && (
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    label="IP Transport Type"
                    SelectProps={{
                      native: true,
                    }}
                    {...register(transportTypeField)}
                    size="small"
                  >
                    {transportTypeMap?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </TextField>
                )}
              </>
            )}

            <div className={'m-a'}>
              <ButtonBase onClick={() => handleClickAddItem()} color={'green'}>
                Add
              </ButtonBase>
            </div>
          </div>
        </Modal>
      </ModalPortal>
    </div>
  );
};

export default AddInputModal;
