import React from 'react';
import SvgPic from '@shared/UI/SvgPic/SvgPic';

import './ButtonAction.scss';

const ButtonAction = ({ showFunc, label, img, classes, disabled = false }) => {
  return (
    <button type="button" className="button-action" onClick={showFunc} disabled={disabled}>
      <SvgPic img={img} classes={classes} />
      {label &&
        <div className="button-action__text">{label}</div>
      }
    </button>
  );
};

export default ButtonAction;
