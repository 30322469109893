import React from 'react';
import s from './SettingsSource.module.scss';
import { useTranslation } from 'react-i18next';
import InputParameters from '@widgets/TaskConfigurator/InputSection/InputParameters/InputParameters';
import Collapse from '@shared/UI/Collapse/Collapse';
import { generatePinName } from '@shared/Lib/Utils/TaskConfigurator/generalFunctions';
import { SOURCE } from '@shared/Lib/Utils/Constants/Constants';
import { FormProvider, useForm } from 'react-hook-form';
import { apiSendParams, getTransformConnectorList } from '@services/CreateTaskService';
import { updatePinsOnly } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import ButtonBase from '@shared/UI/Buttons/ButtonBase/ButtonBase';
import { useEffect } from 'react';
import { apiGetAllOutputsListForEdit } from '@pages/TaskConfigurator/api/apiTaskConfigurator';
import classNames from 'classnames';
import TabsBase from '@shared/UI/Tabs/TabsBase/TabsBase';
import { SERVICES } from '@shared/Lib/Utils/Constants/SourceTemplate';

const SettingsSource = ({
  selectItem,
  setSelectItem,
  isShowMiniLoader,
  setIsShowMiniLoader,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const methods = useForm();
  const { handleSubmit } = methods;

  const handleClickSendParameters = async (data) => {
    // используем guid т к необходим autocomplete для Multicast Group or Unicast Address и Port
    const finishData = Object.keys(data).reduce((obj, key) => {
      if (isNaN(key)) {
        let newKey = selectItem?.filters
          ?.map((filter) => filter?.parameters)
          ?.flat()
          .find((parameter) => parameter?.guid === key)?.id;

        return Object.assign(obj, { [newKey]: data[key] });
      } else {
        return Object.assign(obj, { [key]: data[key] });
      }
    }, {});

    let newSelectItem = {
      ...selectItem,
      filters: selectItem?.filters?.map((filter) => {
        return {
          ...filter,
          parameters: filter?.parameters?.map((parameter) => {
            return {
              ...parameter,
              value: finishData?.[parameter?.id] ? finishData?.[parameter?.id] : parameter.value,
            };
          }),
        };
      }),
    };

    setIsShowMiniLoader(true);
    try {
      await apiSendParams(finishData);
      await updatePinsOnly();
      await getTransformConnectorList().then((res) => {
        const selectSection = res?.data?.data?.find((item) => item.section_id === selectItem?.id);
        newSelectItem = {
          ...newSelectItem,
          groups: selectSection?.groups,
        };
      });
      setActiveTab(SERVICES);
    } catch (error) {
    } finally {
      setSelectItem(newSelectItem);
      setIsShowMiniLoader(false);
    }
  };

  useEffect(() => {
    if (selectItem?.filters) {
      //  вызываем запрос параметров "Settings" т к необходимо обновить ID параметров для Source секции после reset
      //  https://redmine.elecard.net.ru/issues/11711

      setIsShowMiniLoader(true);
      apiGetAllOutputsListForEdit()
        .then((res) => {
          let newSectionData = res?.data?.data?.find((source) => {
            return source?.section?.id === selectItem?.id;
          })?.section;
          setSelectItem({ ...selectItem, filters: newSectionData?.filters });
        })
        .finally(() => {
          setIsShowMiniLoader(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s['settings-source']}>
      <h2 className={s['settings-source__title']}>
        {t('source')} - {selectItem?.name}
      </h2>
      <FormProvider {...methods}>
        <TabsBase
          activeTab={selectItem?.filters?.[0]?.name}
          tabNames={selectItem?.filters?.map((filter) => filter?.name)}
          tabPanelComponents={selectItem?.filters?.map((filter, index) => (
            <div
              className={s['settings-source__tab-components']}
              key={'settings-source__tab-components_' + index}
            >
              {/*когда будет готово надо будет переделать отображение анонсов  https://redmine.elecard.net.ru/issues/11841 */}

              {filter?.parameters && (
                <InputParameters
                  parameters={filter?.parameters}
                  isUseGuid={true}
                  disabled={isShowMiniLoader}
                />
              )}

              {filter?.pins && (
                <Collapse
                  text={'Pins'}
                  classNameControl={classNames(s['settings-source__collapse'])}
                  classNameButton={'svg-button_dark'}
                >
                  {filter?.pins?.map((pin, index) => (
                    <React.Fragment key={pin.name + 'pin' + index}>
                      <Collapse
                        text={generatePinName(pin, SOURCE)}
                        classNameControl={classNames(s['settings-source__collapse'])}
                        classNameButton={'svg-button_dark'}
                        classNameCollapse={classNames(s['settings-source__collapse_inner-padding'])}
                      >
                        <div className={s['settings-source__collapse_inner-padding']}>
                          {pin?.parameters && (
                            <InputParameters
                              parameters={pin?.parameters}
                              disabled={isShowMiniLoader}
                            />
                          )}
                        </div>
                      </Collapse>
                    </React.Fragment>
                  ))}
                </Collapse>
              )}
            </div>
          ))}
        />

        <div className={s['settings-source__button-add']}>
          <ButtonBase
            color={'green'}
            onClick={handleSubmit(handleClickSendParameters)}
            disabled={isShowMiniLoader}
          >
            {t('apply')}
          </ButtonBase>
        </div>
      </FormProvider>
    </div>
  );
};

export default SettingsSource;
