import { gpuInfoActionTypes } from './actions';

const initialState = {
  checkedIds: [0],
};

export const gpuInfo = (state = initialState, action) => {
  switch (action.type) {
    case gpuInfoActionTypes.SET_NEW_ARRAY:
      return { ...state, checkedIds: action.payload };

    case gpuInfoActionTypes.SET_CHECKED_ID:
      const { id, checked } = action.payload;

      if (checked) {
        return {
          ...state,
          checkedIds: [...state.checkedIds, +id].sort(),
        };
      } else {
        return {
          ...state,
          checkedIds: state.checkedIds.filter((item) => item !== +id),
        };
      }

    default:
      return state;
  }
};
