import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import GroupPermissions from '@services/GroupSettings';
import Security from '@services/SecuritySettings';
import { serviceAccessName, serviceRightsName } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import './ServiceRights.scss';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";

const ServiceRights = ({
  selectGroup,
  getSecureData,
  selectOwnerId,
  currentGroup,
  blockAction,
}) => {
  const { t } = useTranslation();
  const [serviceRights, setServiceRights] = React.useState([]);
  const [allApplyAccess, setAllApplyAccess] = React.useState('1');
  // console.log('serviceRights', serviceRights);

  React.useEffect(() => {
    setServiceRights(currentGroup?.access_tables[0]?.actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectGroup]);

  const { register } = useFormContext();

  const handleChange = (updateItem, value) => {
    // console.log('updateItem', updateItem);
    // console.log('value', value);
    const itemIdx = serviceRights.findIndex((elem) => elem.id === updateItem);
    // console.log('itemIdx', itemIdx);

    const updateRights =
      updateItem === 'all'
        ? serviceRights.map((elem) => ({ access: value, id: elem.id }))
        : [
            ...serviceRights.slice(0, itemIdx),
            { access: value, id: updateItem },
            ...serviceRights.slice(itemIdx + 1),
          ];
    // console.log(updateRights);
    setServiceRights(updateRights);

    if (updateItem === 'all') {
      const updateDataItemAll = updateRights.map((elem) => ({
        group_id: +selectGroup,
        owner_id: +selectOwnerId,
        action: +elem.id,
        access: +value,
      }));
      // console.log('updateDataItemAll', updateDataItemAll);
      Security.updatePermission(updateDataItemAll).then((response) => {
        // console.log(response);
        getSecureData();
        GroupPermissions.applyUpdate().then((response) => {
          // console.log('apply', response);
        });
      });
    } else {
      const updateDataItem = [
        { group_id: +selectGroup, owner_id: +selectOwnerId, action: +updateItem, access: +value },
      ];
      // console.log('updateDataItem', updateDataItem);
      Security.updatePermission(updateDataItem).then((response) => {
        // console.log(response);
        getSecureData();
        GroupPermissions.applyUpdate().then((response) => {
          // console.log('apply', response);
        });
      });
    }
  };

  return (
    <div className="service-rights">
      <div className="permissions-item-show">
        <div className="permissions-item-header hla">
          <div className="severity-task-header__item">{t('action')}</div>
          <div className="severity-task-header__item">{t('access')}</div>
        </div>
        <div className="service-rights-data hla">
          {serviceRights.map((item) => (
            <div className="service-rights-data__item" key={item.id} name={item.id}>
              <div className="service-rights-data__item-name">{serviceRightsName[item.id]}</div>
              <select
                disabled={blockAction ? true : selectGroup === '0' ? true : false}
                name={item.id}
                className="severity-task-data-list"
                value={item.access}
                {...register(`access-${item.id}`)}
                onChange={(e) => {
                  register(`access-${item.id}`).onChange(e);
                  handleChange(item.id, e.target.value);
                }}
              >
                {serviceAccessName}
              </select>
            </div>
          ))}
        </div>
        <div className="service-rights-data">
          <div className="service-rights-data-all">
            <div className="severity-events-data__name">{t('common_access')}</div>
            <div className="service-rights-data-all-btns">
              <select
                defaultValue={allApplyAccess}
                disabled={blockAction ? true : selectGroup === '0' ? true : false}
                className="severity-task-data-list severity-task-data-list-short"
                {...register(`accessAll`)}
                onChange={(e) => {
                  register(`accessAll`).onChange(e);
                  setAllApplyAccess(e.target.value);
                }}
              >
                {serviceAccessName}
              </select>
              <ButtonBase
                color={'green'}
                disabled={blockAction ? true : selectGroup === '0' ? true : false}
                onClick={() => handleChange('all', allApplyAccess)}
              >
                {t('apply_for_all')}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceRights;
