import React from 'react';
import Draggable from 'react-draggable';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import s from './DraggableModal.module.scss';
import classNames from 'classnames';

const DraggableModal = ({ children, className, showLoader, setShowModal, title }) => {
  const setSelectedModalUp = (e) => {
    let allModals = document.querySelectorAll('[data-draggable-modal ]');
    allModals.forEach((element) => (element.style.zIndex = '5'));

    let currentModal = e.target.closest('[data-draggable-modal ]');
    currentModal.style.zIndex = '6';
    e.stopPropagation();
  };

  return (
    <div onMouseDown={(e) => setSelectedModalUp(e)}>
      <Draggable>
        <div className={classNames(s['draggable-modal'], className)} data-draggable-modal>
          <div className={s['draggable-modal__header']}>
            <div>{title}</div>
            <div className={s['draggable-modal__close']} onClick={setShowModal}>
              ×
            </div>
          </div>
          {showLoader && <Loader className={s['draggable-modal__loader']} />}
          {children}
        </div>
      </Draggable>
    </div>
  );
};

export default DraggableModal;
