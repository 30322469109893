import React from 'react';
import s from './SelectSource.module.scss';
import { useTranslation } from 'react-i18next';
import Collapse from '@shared/UI/Collapse/Collapse';
import classNames from 'classnames';
import ButtonBase from '@shared/UI/Buttons/ButtonBase/ButtonBase';
import Loader from '@shared/UI/Loaders/Loader/Loader';

const SelectSource = ({
  setIsShowSelectSource,
  templateData,
  addSection,
  selectItem,
  setSelectItem,
  isLoading,
}) => {
  const { t } = useTranslation();

  const handlerClick = (transportTypeItem, inputProtocolKey) => {
    setSelectItem({ category: inputProtocolKey, name: transportTypeItem });
  };

  const deleteSelectSource = () => {
    setIsShowSelectSource(false);
    setSelectItem(null);
  };

  return (
    <div className={s['select-source']}>
      <div className="po-r">
        <h2 className={s['select-source__title']}>{t('select_source')}</h2>
        <div className={s['select-source__button-close']} onClick={deleteSelectSource}>
          ×
        </div>
      </div>

      <div>
        {isLoading ? (
          <Loader className={'m0'} />
        ) : (
          templateData &&
          Object.entries(templateData)?.map(([, sourceTypeValue], index) => (
            <React.Fragment key={'select-source__root_' + index}>
              {sourceTypeValue &&
                Object.entries(sourceTypeValue)?.map(([sourceTypeKey, value], index) => {
                  return (
                    <ul key={'source-item_' + sourceTypeKey + '_' + index}>
                      <Collapse
                        isShowFirst={sourceTypeKey === 'Network'}
                        text={sourceTypeKey}
                        classNameText={classNames(s['select-source__collapse-title'], 'fw-500')}
                        classNameButton={'svg-button_dark'}
                      >
                        {value &&
                          Object.entries(value)?.map(([, inputProtocolValue], index) => {
                            return (
                              <React.Fragment key={'select-source__wrapper_' + index}>
                                {inputProtocolValue &&
                                  Object.entries(inputProtocolValue)?.map(
                                    ([inputProtocolKey, transportTypeValue], index) => {
                                      return (
                                        <React.Fragment key={'select-source__children_' + index}>
                                          {transportTypeValue &&
                                            transportTypeValue?.map((transportTypeItem, index) => (
                                              <li
                                                className={classNames(
                                                  s['select-source__collapse-item'],
                                                  selectItem?.name === transportTypeItem &&
                                                    s['select-source__collapse-item_active'],
                                                )}
                                                key={'select-source__children__item_' + index}
                                                onClick={() =>
                                                  handlerClick(transportTypeItem, inputProtocolKey)
                                                }
                                                onDoubleClick={() => {
                                                  addSection();
                                                }}
                                              >
                                                {transportTypeItem}
                                              </li>
                                            ))}
                                        </React.Fragment>
                                      );
                                    },
                                  )}
                              </React.Fragment>
                            );
                          })}
                      </Collapse>
                    </ul>
                  );
                })}
            </React.Fragment>
          ))
        )}
      </div>

      <div className={s['select-source__button-add']}>
        <ButtonBase color={'green'} onClick={() => addSection()} disabled={!selectItem}>
          {t('add')}
        </ButtonBase>
      </div>
    </div>
  );
};

export default SelectSource;
