import React from 'react';
import s from './TextFieldWrapper.module.scss';
import classNames from 'classnames';

const TextFieldWrapper = ({ children, label, dataType }) => {
  return (
    <div className={classNames(s['sections-filters-params__item'])}>
      <p className={s['params__item-name']}>{label}</p>
      <div className={s['params__output-value']} data-type={dataType}>
        {children}
      </div>
    </div>
  );
};

export default TextFieldWrapper;
