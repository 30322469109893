import $api from './apiAxios';

export default class Security {
  static async getData() {
    return $api.get('/user-permissions?type=all');
  }
  static async getPermissions(id) {
    return $api.get(`/user-permissions?type=access_tables&id=${id}`);
  }

  static async registration(user, password) {
    return $api.post('./register', { user, password });
  }
  static async updatePermission(data) {
    return $api.post(`/user-permissions?command=update&type=access_tables`, data);
  }
}
