import Modal from '@shared/UI/Modal/Modal';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import {
  findAndNumberDuplicates,
  hideScroll,
  inputCheckToNumber,
  showScroll,
} from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import {
  getConsoleById,
  getSchemaReservingData,
  setConsoleById,
  setSchemaReservingData,
} from './api/apiTaskSettings';
import InputMeasureable from '@shared/UI/InputMeasureable/InputMeasureable';
import SelectBasic from '@shared/UI/SelectBasic/SelectBasic';
import './TaskSettingsModal.scss';
import { Link } from 'react-router-dom';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";

const TaskSettingsModal = ({
  setShowSettings,
  index,
  setIsSettingsDisabled,
  setShowSuccessModal,
  setShowError,
  setMessageError,
  isSourceRedundancy,
  storage_id,
  schema,
}) => {
  const [consoleData, setConsoleData] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [primarySource, setPrimarySource] = useState([]);

  const { t } = useTranslation();
  const methods = useForm();

  const { handleSubmit, control } = methods;
  const { dirtyFields } = useFormState({
    control,
  });

  const onSubmit = (data) => {
    // console.log('data', data);
    setIsSettingsDisabled(true);
    Promise.all([sendPrimarySourceData(data), sendConsoleData(data)])
      .then(() => {
        setShowSuccessModal(true);
      })
      .catch((error) => {
        console.dir(error);
      })
      .finally(() => {
        setIsSettingsDisabled(false);
      });
    setShowSettings(false);
  };

  const sendConsoleData = (data) => {
    return setConsoleById(index, {
      ...consoleData,
      start_delay: dirtyFields['start-delay'] ? +data['start-delay'] : consoleData.start_delay,
      enviroment: {
        ...consoleData.enviroment,
        current: dirtyFields['enviroment'] ? data['enviroment'] : consoleData.enviroment.current,
      },
    });
  };

  const sendPrimarySourceData = (data) => {
    let currentPrimarySource = data['primary_source'];
    // console.log('currentPrimarySource', currentPrimarySource);
    // console.log('primarySource', primarySource);
    let orders = primarySource?.map((item) => item?.order);
    let minOrder = orders?.length !== 0 && Math.min(...orders);

    //меняем местами только order, в массиве сотрировку делать нельзя - для совместимости с бэком
    let orderCurrentSource = primarySource?.find(
      (item) => item?.displayedValue === currentPrimarySource,
    )?.order;
    let newPrimarySource = primarySource;
    if (orderCurrentSource !== minOrder) {
      let swapItem = primarySource?.find((item) => item?.order === minOrder);
      newPrimarySource = primarySource?.map((item) => {
        if (item?.displayedValue === currentPrimarySource) {
          return { ...item, order: minOrder };
        }
        if (item?.displayedValue === swapItem?.displayedValue) {
          return { ...item, order: orderCurrentSource };
        }
        return { ...item };
      });
    }

    let sendedParameters = newPrimarySource?.map((item) => {
      return {
        order: item?.order,
        source: item?.source
          ?.toString()
          .replace(/^\w.*@/g, '')
          .replace(/:\w*/g, ''),
      };
    });
    // console.log('sendedParameters', sendedParameters);

    return setSchemaReservingData(storage_id, schema, sendedParameters);
  };

  useEffect(() => {
    hideScroll();

    return () => {
      showScroll();
    };
  }, []);

  useEffect(() => {
    setShowLoader(true);

    Promise.all([getConsoleData(), getPrimarySourceData()])
      .catch((error) => {
        setMessageError(error.message);
        setShowError(true);
        setConsoleData(null);
        setShowSettings(false);
      })
      .finally(() => {
        setShowLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const getConsoleData = async () => {
    const res = await getConsoleById(index);
    let newData = res?.data?.data;
    newData && setConsoleData(newData);

    return res;
  };

  const getPrimarySourceData = async () => {
    const res = await getSchemaReservingData(storage_id, schema);
    let data = res?.data?.data ? res?.data?.data : [];
    let numberedDuplicates = findAndNumberDuplicates(data?.map((item) => item?.source));
    let duplicates = {};

    let primarySourceDisplayed = data?.map((item) => {
      if (numberedDuplicates[item?.source]) {
        if (duplicates[item?.source] === undefined) {
          duplicates[item?.source] = 1;
        } else {
          duplicates[item?.source]++;
        }
      }

      let displayedValue = duplicates[item?.source]
        ? item?.source + ` (${duplicates[item?.source]})`
        : item?.source;

      return { ...item, displayedValue: displayedValue };
    });

    setPrimarySource(primarySourceDisplayed);

    return res;
  };

  return (
    <ModalPortal>
      <Modal title={t('task_settings')} onClose={() => setShowSettings(false)} showLoader={showLoader}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <span className="task-settings__subtitle">{t('start_delay_subtitle')}</span>
            <InputMeasureable
              inputName={'start-delay'}
              labelName={t('start_delay')}
              defaultValue={consoleData?.start_delay}
              inputMeasure={t('seconds')}
              onKeyPress={inputCheckToNumber}
              min={0}
              max={3600}
              wrapperClassName="input-wrapper--full-width m10"
            />

            <div className="divider-thin" />

            <span className="task-settings__subtitle">{t('enviroment_subtitle')}</span>
            <div>
              <SelectBasic
                inputName={'enviroment'}
                labelName={t('enviroment')}
                defaultValue={consoleData?.enviroment?.current}
                valid_options={consoleData?.enviroment?.modes_list}
                wrapperClassName="input-wrapper--full-width m10"
                inputClassName="task-settings__select"
              />

              <div className="tooltipText m10">
                <span>{t('enviroment_tooltip')}</span>

                <Link to="/server/config/thresholds" target="_blank">
                  <span className="breadcrumbs-link">{t('enviroment_tooltip_link')}</span>
                </Link>
              </div>
            </div>

            {isSourceRedundancy && primarySource && (
              <div>
                <div className="divider-thin" />
                <SelectBasic
                  inputName={'primary_source'}
                  labelName={t('primary_source')}
                  defaultValue={
                    Array.isArray(primarySource) &&
                    [...primarySource]?.sort((a, b) => a?.order - b?.order)?.[0]?.displayedValue
                  }
                  valid_options={primarySource?.map((item) => item?.displayedValue)}
                  wrapperClassName="input-wrapper--full-width m10"
                  inputClassName="task-settings__select w-fc maw-350"
                />
              </div>
            )}

            <div className="task-settings__button-apply">
              <ButtonBase type={'submit'} color={'green'}>
                {t('apply')}
              </ButtonBase>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </ModalPortal>
  );
};

export default TaskSettingsModal;
