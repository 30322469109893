import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";

const SnmpCommunity = ({
  activeSNMPItem,
  dataCommunity,
  activeCommunitiesNumber,
  onChangeActiveCommunity,
  onRemoveCommunity,
  onRemoveHost,
  openNewCommunity,
  toggleOpenHost,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames('snmp-block-community', activeSNMPItem === 0 ? 'snmp-block-show' : '')}>
      <div className="snmp-block-community__name">
        <div className="snmp-block-community__name-title">{t('communities')}</div>
        <div className="snmp-block-community__name-data">
          {dataCommunity
            ? dataCommunity.map((item, i) => (
                <div className="community-name-block" key={item.name} hostchild={i}>
                  <div
                    className={classNames(
                      'community-name',
                      activeCommunitiesNumber === i ? 'community-name-active' : '',
                    )}
                    onClick={() => onChangeActiveCommunity(i)}>
                    {item.name}
                  </div>
                  <div className="community-close" onClick={(e) => onRemoveCommunity(e, i)}>
                    &#215;
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <div className="snmp-block-community__host">
        <div className="snmp-block-community__host-title">{t('host')}</div>
        <div className="snmp-block-community__host-data">
          {dataCommunity
            ? dataCommunity.map((item, i) =>
                item.destinations ? (
                  <div
                    className={classNames(
                      `community__host-data-block host-block-${i}`,
                      activeCommunitiesNumber === i ? 'communities-show' : '',
                    )}
                    key={item.destinations + i}>
                    {item.destinations.map((item, i) => (
                      <div
                        className={classNames('community__host-data-block-item', `host-item-${i}`)}
                        key={item.host + i}>
                        <div className="community-host-text">{item.host + ':' + item.port}</div>
                        <p className="community-close" onClick={onRemoveHost}>
                          &#215;
                        </p>
                      </div>
                    ))}
                  </div>
                ) : null,
              )
            : null}
        </div>
      </div>
      <div className="snmp-block-community__add">
        <ButtonBase color={'green'} onClick={openNewCommunity}>
          {t('add')}
        </ButtonBase>

        <ButtonBase color={'green'} onClick={toggleOpenHost} disabled={dataCommunity.length === 0}>
          {t('add')}
        </ButtonBase>
      </div>
    </div>
  );
};

export default SnmpCommunity;
