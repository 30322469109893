import { createTaskActionTypes } from './actions';

const initialState = {
  errorRequest: null,
  errorCreateTaskId: [],
  openTransformSection: false,
  isDeleteSections: false,
  activeSourceBlock: 0,
  inputsNumberBlock: 0,
  outputsNumberBlock: 100,
  activeTransformTab: 'tab-1-0-0',
  activeOutTab: 'Services',
  activeInputTab: 'Settings',
  arraySources: [],
  arrayOutputs: [],
  transformApplyData: null,
  transformRenderPins: null,
};

export const createTask = (state = initialState, action) => {
  switch (action.type) {
    case createTaskActionTypes.SET_IS_DELETE_SECTIONS:
      return {
        ...state,
        isDeleteSections: action.payload,
      };

    case createTaskActionTypes.SET_ADD_SOURCE:
      return {
        ...state,
        arraySources: [
          ...state.arraySources,
          {
            id: null,
            selectSource: null,
            sectionNumber: action.payload,
            programs: [],
          },
        ],
      };

    case createTaskActionTypes.SET_CLONE_SOURCE_BLOCK:
      return {
        ...state,
        arraySources: [...state.arraySources, action.payload],
      };

    case createTaskActionTypes.SET_ERROR_FROM_REQUEST:
      return {
        ...state,
        errorRequest: action.payload,
      };

    case createTaskActionTypes.SET_CLEAR_ERROR_REQUEST: {
      return {
        ...state,
        errorRequest: null,
      };
    }

    case createTaskActionTypes.SET_DELETE_SOURCE:
      const newArraySourceDelete = [
        ...state.arraySources.slice(0, action.payload),
        ...state.arraySources.slice(action.payload + 1),
      ];
      // console.log('newArraySourceDelete', newArraySourceDelete);
      return {
        ...state,
        arraySources: newArraySourceDelete,
      };

    // SOURCE BLOCKS //
    case createTaskActionTypes.SET_ADD_SOURCE_BLOCK:
      // console.log('action', action);
      const selectSource = action.selectSource;
      const number = action.number;
      const sourceFilters = action.sourceFilters;

      // console.log('index', number);
      const currentSource = state.arraySources.find((item) => item.sectionNumber === number);
      // console.log('currentSource', currentSource);
      const currentSourceIndex = state.arraySources.indexOf(
        state.arraySources.find((item) => item.sectionNumber === number),
      );
      // console.log(currentSourceIndex);
      currentSource.selectSource = selectSource;
      currentSource.sourceFilters = sourceFilters;

      const newArraySource = [
        ...state.arraySources.slice(0, currentSourceIndex),
        currentSource,
        ...state.arraySources.slice(currentSourceIndex + 1),
      ];

      return {
        ...state,
        arraySources: newArraySource,
      };

    case createTaskActionTypes.SET_UPDATE_SOURCE_BLOCK: {
      // console.log('action', action);
      const sourceNumber = action.number;
      const sectionId = action.id;
      const sourceFilters = action.sourceFilters;

      const currentSourceItem = state.arraySources.find(
        (item) => item.sectionNumber === sourceNumber,
      );
      if (currentSourceItem) {
        // console.log('currentSourceItem', currentSourceItem);
        const currentSourceIndexItem = state.arraySources.indexOf(
          state.arraySources.find((item) => item.sectionNumber === sourceNumber),
        );
        // console.log(currentSourceIndexItem);
        // currentSourceItem.parameters = sourceParameters;
        currentSourceItem.id = sectionId;
        // currentSourceItem.sourceId = sourceId;
        currentSourceItem.sourceFilters = sourceFilters;

        const updateArraySource = [
          ...state.arraySources.slice(0, currentSourceIndexItem),
          currentSourceItem,
          ...state.arraySources.slice(currentSourceIndexItem + 1),
        ];

        return {
          ...state,
          arraySources: updateArraySource,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case createTaskActionTypes.SET_DELETE_SOURCE_BLOCK:
      const newSource = state.arraySources.filter((item) => item.id !== action.payload);
      return {
        ...state,
        arraySources: newSource,
      };

    /////////////////////////////////////////////////////////////

    case createTaskActionTypes.SET_ADD_PROGRAMS_SOURCE_ITEMS: {
      const idx = action.id;
      const newProgs = action.programs;

      const isReserve = newProgs?.some((elem) =>
        elem.pins.some((pin) => !pin.can_show_next_section),
      );

      const newArraySourceItems = state.arraySources.map((item) =>
        item.id === idx
          ? {
              ...item,
              programs: newProgs,
              isReserve: isReserve,
            }
          : item,
      );

      return {
        ...state,
        arraySources: newArraySourceItems,
      };
    }

    ///////////////////////////////////////////////////////////////////

    // UPDATE

    case createTaskActionTypes.SET_UPDATE_ALL_PROGRAMS_SOURCE_ITEMS: {
      const updatePrograms = action?.programs;
      const updateArrSourceItems = state.arraySources.map((item) => ({
        ...item,
        programs: updatePrograms.find((elem) => elem.id === item.id)?.programs,
        isReserve: updatePrograms
          .find((elem) => elem.id === item.id)
          .programs?.some((elem) => elem.pins.some((pin) => !pin.can_show_next_section)),
      }));

      return {
        ...state,
        arraySources: updateArrSourceItems,
      };
    }

    case createTaskActionTypes.SET_UPDATE_TRANSFORM_PROGRAM: {
      const transformData = action.pinsData;
      const updateProg = action.programNum;
      const updateSelectSection = action.sectionId;
      const curTransformSectionIdx = state.arraySources.findIndex(
        (item) => item.id === updateSelectSection,
      );
      const curTransformSection = state.arraySources?.[curTransformSectionIdx];

      const curTransformProgramIdx = curTransformSection?.programs.findIndex(
        (item) => item.program === updateProg,
      );
      const curTransformProgram = curTransformSection?.programs?.[curTransformProgramIdx];
      const curUpdateProgramPins = curTransformProgram?.pins?.map((item) => ({
        ...item,
        section_id: transformData
          ? transformData.find((elem) => elem.owner_connector_index === item.index)?.section_id
          : item.section_id,
        next_section: transformData
          ? transformData.find((elem) => elem.owner_connector_index === item.index)?.name
          : null,
        renderPins: transformData
          ? transformData.find((elem) => elem.owner_connector_index === item.index)?.pins
          : null,
      }));

      const updateCurTransformProg = { ...curTransformProgram, pins: curUpdateProgramPins };

      const updateAllProgsInCurSectionTransform = curUpdateProgramPins
        ? curTransformProgramIdx !== -1
          ? [
              ...curTransformSection?.programs.slice(0, curTransformProgramIdx),
              updateCurTransformProg,
              ...curTransformSection?.programs.slice(curTransformProgramIdx + 1),
            ]
          : curTransformSection?.programs
        : [];

      const updateCurSection = {
        ...curTransformSection,
        programs: updateAllProgsInCurSectionTransform,
      };
      const updateAllSectionsInTransform = [
        ...state.arraySources.slice(0, curTransformSectionIdx),
        updateCurSection,
        ...state.arraySources.slice(curTransformSectionIdx + 1),
      ];
      // console.log('updateAllSectionsInTransform', updateAllSectionsInTransform);

      return {
        ...state,
        arraySources: updateAllSectionsInTransform,
      };
    }

    case createTaskActionTypes.SET_ENCODER_DATA_TRANSFORM: {
      const indexEncoder = action.index;
      const is_usedEncoder = action.isUsed;
      // console.log('indexEncoder', indexEncoder);
      // console.log('is_usedEncoder', is_usedEncoder);
      const updateArraySourcesInTransform = state.arraySources.map((section) => ({
        ...section,
        programs: section.programs.map((program) => ({
          ...program,
          pins: program.pins?.map((pin) => ({
            ...pin,
            renderPins: pin.renderPins?.map((rePin) => ({
              ...rePin,
              is_used: rePin.index === indexEncoder ? is_usedEncoder : rePin.is_used,
            })),
          })),
        })),
      }));
      return {
        ...state,
        arraySources: updateArraySourcesInTransform,
      };
    }

    case createTaskActionTypes.SET_TRANSFORM_APPLY:
      return {
        ...state,
        transformApplyData: action.payload,
      };

    case createTaskActionTypes.SET_UPDATE_RENDER_PINS:
      // console.log('payload', action.payload);
      return {
        ...state,
        transformRenderPins: action.payload,
      };

    ////////////////////////////////////////////////////////////////

    case createTaskActionTypes.SET_UPDATE_PROGRAMS_SOURCE_ITEMS: {
      const sourceId = action.id;
      const newPrograms = action.programs;
      const newArraySources = state.arraySources.map((source) => ({
        ...source,
        programs: source.id === sourceId ? newPrograms : source.programs,
      }));

      return {
        ...state,
        arraySources: newArraySources,
      };
    }

    ///////////////////////////////////////////////////////////////////////////////////////

    case createTaskActionTypes.SET_SOURCE_LIST_TO_PIN: {
      const { sectionNumber, programNum, newPinList } = action.payload;
      let newArraySources = state.arraySources.map((source) => ({
        ...source,
        programs: source?.programs?.map((program) => ({
          ...program,
          pins:
            source.sectionNumber === sectionNumber && program.program === programNum
              ? program?.pins?.map((pin) => {
                  return pin?.index === newPinList?.index
                    ? { ...pin, connectorList: newPinList.connectorList }
                    : pin;
                })
              : program.pins,
        })),
      }));

      return {
        ...state,
        arraySources: newArraySources,
      };
    }

    case createTaskActionTypes.SET_DELETE_PROGRAMS_SOURCE_ITEMS:
      const idSection = action.payload;
      // console.log('idSection', idSection);
      const currentSectionBlock = state.arraySources.find((item) => item.id === idSection);
      // console.log('currentSectionBlock', currentSectionBlock);

      const currentSectionBlockIndex = state.arraySources.indexOf(
        state.arraySources.find((item) => item.id === idSection),
      );
      currentSectionBlock.programsSourceItems = [];

      const newArraySectionItems = [
        ...state.arraySources.slice(0, currentSectionBlockIndex),
        currentSectionBlock,
        ...state.arraySources.slice(currentSectionBlockIndex + 1),
      ];

      return {
        ...state,
        arraySources: newArraySectionItems,
      };

    case createTaskActionTypes.SET_DELETE_ALL_SOURCE_PROGRAMS:
      const sectionsWithoutProgs = state.arraySources.map((item) => ({
        ...item,
        programs: [],
      }));
      return {
        ...state,
        arraySources: sectionsWithoutProgs,
      };

    case createTaskActionTypes.SET_ACTIVE_SOURCE_BLOCK:
      return {
        ...state,
        activeSourceBlock: action.payload,
      };

    case createTaskActionTypes.SET_ACTIVE_TRANSFORM_TAB: {
      return {
        ...state,
        activeTransformTab: action.payload,
      };
    }

    case createTaskActionTypes.SET_INPUTS_NUMBER_BLOCK:
      return {
        ...state,
        inputsNumberBlock: action.payload,
      };

    case createTaskActionTypes.SET_OUTPUTS_NUMBER_BLOCK:
      return {
        ...state,
        outputsNumberBlock: action.payload,
      };

    case createTaskActionTypes.SET_MODAL_OPEN_TRANSFORM_SECTION:
      return {
        ...state,
        openTransformSection: action.payload,
      };

    case createTaskActionTypes.SET_ADD_OUTPUT:
      return {
        ...state,
        arrayOutputs: [...state.arrayOutputs, action.payload],
      };

    case createTaskActionTypes.SET_EXCHANGE_OUTPUT:
      return {
        ...state,
        arrayOutputs: action.payload,
      };

    case createTaskActionTypes.SET_UPDATE_SECTION:
      const addMuxProgs = action.addMuxProgs;
      // console.log('addMuxProgs', addMuxProgs);
      const addPins = action.addPins;
      // console.log('addPins', addPins);
      const arrChangedParams = action.arrChangedParams;
      // console.log('arrChangedParams', arrChangedParams);

      const sectionUpdateId =
        addMuxProgs[0].section_id || addPins[0].section_id || arrChangedParams[0].section_id;
      // console.log('sectionUpdateId', sectionUpdateId);

      const filterUpdateId =
        addMuxProgs[0].filter_id || addPins[0].filter_id || arrChangedParams[0].filter_id;
      // console.log('filterUpdateId', filterUpdateId);

      const sectionForUpdate = state.arrayOutputs.find((elem) => elem.id === sectionUpdateId);
      // console.log('sectionForUpdate', sectionForUpdate);

      const filterForUpdate = sectionForUpdate.filters.find((item) => item.id === filterUpdateId);
      // console.log('filterForUpdate', filterForUpdate);

      const sectionForUpdateIdx = state.arrayOutputs.findIndex(
        (elem) => elem.id === sectionUpdateId,
      );
      // console.log('sectionForUpdateIdx', sectionForUpdateIdx);

      const filterIdx = sectionForUpdate.filters.findIndex((item) => item.id === filterUpdateId);
      // console.log('filterIdx', filterIdx);

      // addMuxProgs
      const updateMuxProgs = filterForUpdate.mux_programs.concat(
        addMuxProgs[0].mux_programs.map((elem) => elem),
      );
      // console.log('updateMuxProgs', updateMuxProgs);
      // addPins
      const updatePins = filterForUpdate.pins.concat(addPins[0].pins.map((elem) => elem));
      // console.log('updatePins', updatePins);

      // UpdateSection

      const updateCurrentFilter = {
        ...filterForUpdate,
        mux_programs: updateMuxProgs,
        pins: updatePins,
      };
      // console.log('updateCurrentFilter', updateCurrentFilter);

      const updateSections = [
        ...state.arrayOutputs.slice(0, sectionForUpdateIdx),
        {
          ...sectionForUpdate,
          filters: [
            ...sectionForUpdate.filters.slice(0, filterIdx),
            updateCurrentFilter,
            ...sectionForUpdate.filters.slice(filterIdx + 1),
          ],
        },
        ...state.arrayOutputs.slice(sectionForUpdateIdx + 1),
      ];
      // console.log('updateSections', updateSections);
      if (updateSections) {
        return {
          ...state,
          arrayOutputs: updateSections,
        };
      }
      break;

    case createTaskActionTypes.SET_UPDATE_OUTPUT:
      const dataAdd = action.addData;
      const dataDelete = action.deleteData;
      // console.log('dataAdd', dataAdd);
      // console.log('dataDelete', dataDelete);
      if (dataAdd && !dataDelete) {
        return {
          ...state,
          arrayOutputs: [...state.arrayOutputs, dataAdd[0]],
        };
      }
      if (!dataAdd && dataDelete) {
        return {
          ...state,
          arrayOutputs: state.arrayOutputs.filter((item) =>
            dataDelete.some((id) => item.id !== id),
          ),
        };
      }
      if (dataAdd && dataDelete) {
        return {
          ...state,
          arrayOutputs: [...state.arrayOutputs, dataAdd[0]].filter(
            (item) => !dataDelete.some((id) => item.id === id),
          ),
        };
      }
      break;

    case createTaskActionTypes.SET_DELETE_OUTPUT:
      const newArrayOutputDelete = [
        ...state.arrayOutputs.slice(0, action.payload),
        ...state.arrayOutputs.slice(action.payload + 1),
      ];
      return {
        ...state,
        arrayOutputs: newArrayOutputDelete,
      };

    case createTaskActionTypes.SET_CLEAN_OUTPUT:
      return {
        ...state,
        arrayOutputs: [],
        transformApplyData: null,
      };

    case createTaskActionTypes.SET_SELECTED_STREAMS_NUMBER:
      const newNumber = action.payload;
      const idOutputs = action.id;

      const currentOutputItem = state.arrayOutputs.find((item) => item.id === idOutputs);
      const currentOutputItemIndex = state.arrayOutputs.indexOf(
        state.arrayOutputs.find((item) => item.id === idOutputs),
      );

      currentOutputItem.selectedStreamsNumber = newNumber;

      const newArrOutput = [
        ...state.arrayOutputs.slice(0, currentOutputItemIndex),
        currentOutputItem,
        ...state.arrayOutputs.slice(currentOutputItemIndex + 1),
      ];

      return {
        ...state,
        arrayOutputs: newArrOutput,
      };

    case createTaskActionTypes.SET_ADD_OUTPUT_BLOCK:
      const indexBlock = action.payload.props.number;

      const currentOutput = state.arrayOutputs.find((item) => item.id === indexBlock);

      const currentOutputIndex = state.arrayOutputs.indexOf(
        state.arrayOutputs.find((item) => item.id === indexBlock),
      );

      currentOutput.outputBlock = action.payload;

      const newArrayOutput = [
        ...state.arrayOutputs.slice(0, currentOutputIndex),
        currentOutput,
        ...state.arrayOutputs.slice(currentOutputIndex + 1),
      ];

      return {
        ...state,
        arrayOutputs: newArrayOutput,
      };

    case createTaskActionTypes.SET_DELETE_OUTPUT_BLOCK:
      const newOutput = state.arrayOutputs.filter((item) => item.id !== action.payload);
      return {
        ...state,
        arrayOutputs: newOutput,
      };

    case createTaskActionTypes.SET_ADD_OUTPUT_SECTION:
      return {
        ...state,
        arrayOutputBlock: [...state.arrayOutputBlock, action.payload],
      };

    case createTaskActionTypes.SET_DELETE_OUTPUT_SECTION:
      const newArrayOutputBlock = [
        ...state.arrayOutputBlock.slice(0, action.payload),
        ...state.arrayOutputBlock.slice(action.payload + 1),
      ];
      return {
        ...state,
        arrayOutputBlock: newArrayOutputBlock,
      };

    case createTaskActionTypes.SET_ADD_OUTPUT_ADDRESS:
      const outputData = action.payload;
      const idOut = action.number;
      const currentOutputAddress = state.arrayOutputs.find((item) => item.id === idOut);
      const currentOutputIdx = state.arrayOutputs.indexOf(
        state.arrayOutputs.find((item) => item.id === idOut),
      );

      currentOutputAddress.outputAddress = outputData;

      const newArrayOutputAddress = [
        ...state.arrayOutputs.slice(0, currentOutputIdx),
        currentOutputAddress,
        ...state.arrayOutputs.slice(currentOutputIdx + 1),
      ];

      return {
        ...state,
        arrayOutputs: newArrayOutputAddress,
      };

    case createTaskActionTypes.SET_ADD_AVAILABLE_ITEMS:
      const availableItems = action.payload;
      const idAvailable = availableItems[0]['output-id'];
      const currentAvailablePrograms = state.arrayOutputs.find((item) => item.id === +idAvailable);
      const currentAvailableProgramsIdx = state.arrayOutputs.indexOf(
        state.arrayOutputs.find((item) => item.id === +idAvailable),
      );
      currentAvailablePrograms.programsAvailableItems = availableItems;

      const newArrayAvailablePrograms = [
        ...state.arrayOutputs.slice(0, currentAvailableProgramsIdx),
        currentAvailablePrograms,
        ...state.arrayOutputs.slice(currentAvailableProgramsIdx + 1),
      ];

      return {
        ...state,
        arrayOutputs: newArrayAvailablePrograms,
      };

    case createTaskActionTypes.SET_ADD_UNIQE_STREAMS:
      const uniqeStreams = action.payload;
      const uniqeId = action.number;
      const currentUniqeSource = state.arrayOutputs.find((item) => item.id === +uniqeId);
      const currentUniqeProgramsIdx = state.arrayOutputs.indexOf(
        state.arrayOutputs.find((item) => item.id === +uniqeId),
      );

      currentUniqeSource.uniqeStreamsAfterAvailable = uniqeStreams;

      const newArrayUniqePrograms = [
        ...state.arrayOutputs.slice(0, currentUniqeProgramsIdx),
        currentUniqeSource,
        ...state.arrayOutputs.slice(currentUniqeProgramsIdx + 1),
      ];

      return {
        ...state,
        arrayOutputs: newArrayUniqePrograms,
      };

    case createTaskActionTypes.SET_ADD_OUTPUT_PROGRAMS_ITEMS:
      const outputItems = action.payload;
      // console.log(outputItems);
      const idOutput = action.number;
      // console.log(idOutput);
      const currentOutputPrograms = state.arrayOutputs.find((item) => item.id === +idOutput);
      // console.log(currentOutputPrograms);
      const currentOutputProgramsIdx = state.arrayOutputs.indexOf(
        state.arrayOutputs.find((item) => item.id === +idOutput),
      );
      // console.log(currentOutputProgramsIdx);
      currentOutputPrograms.programsOutputItems = outputItems;

      const newArrayOutputPrograms = [
        ...state.arrayOutputs.slice(0, currentOutputProgramsIdx),
        currentOutputPrograms,
        ...state.arrayOutputs.slice(currentOutputProgramsIdx + 1),
      ];

      return {
        ...state,
        arrayOutputs: newArrayOutputPrograms,
      };

    case createTaskActionTypes.SET_ADD_COMPONENT_OUTPUT_ADDRESS:
      // console.log('action', action);
      const componentOutputAddress = action.payload;
      // console.log('componentOutputAddress', componentOutputAddress);
      const componentIdOutputAddress = action.number;
      // console.log('componentIdOutputAddress', componentIdOutputAddress);
      const currentOutputsItem = state.arrayOutputs.find(
        (item) => item.id === +componentIdOutputAddress,
      );
      // console.log('currentOutputsItem', currentOutputsItem);

      currentOutputsItem.componentOutputAddress = componentOutputAddress;
      // console.log('currentOutputsItem', currentOutputsItem);

      const currentComponentOutputIdx = state.arrayOutputs.indexOf(
        state.arrayOutputs.find((item) => item.id === componentIdOutputAddress),
      );
      const newArrayComponentOutputAddress = [
        ...state.arrayOutputs.slice(0, currentComponentOutputIdx),
        currentOutputsItem,
        ...state.arrayOutputs.slice(currentComponentOutputIdx + 1),
      ];

      return {
        ...state,
        arrayOutputs: newArrayComponentOutputAddress,
      };

    case createTaskActionTypes.SET_ADD_COMPONENT_OUTPUT_ITEMS:
      const componentOutputItems = action.payload;
      // console.log(componentOutputItems);
      const componentIdOutput = componentOutputItems.props.number;
      // console.log(componentIdOutput);
      const currentComponentOutputPrograms = state.arrayOutputs.find(
        (item) => item.id === +componentIdOutput,
      );
      // console.log(currentComponentOutputPrograms);
      const currentComponentOutputProgramsIdx = state.arrayOutputs.indexOf(
        state.arrayOutputs.find((item) => item.id === +componentIdOutput),
      );
      // console.log(currentComponentOutputProgramsIdx);

      currentComponentOutputPrograms.componentOutputItems = componentOutputItems;

      const newComponentyOutputPrograms = [
        ...state.arrayOutputs.slice(0, currentComponentOutputProgramsIdx),
        currentComponentOutputPrograms,
        ...state.arrayOutputs.slice(currentComponentOutputProgramsIdx + 1),
      ];
      // console.log(newComponentyOutputPrograms);
      return {
        ...state,
        arrayOutputs: newComponentyOutputPrograms,
      };

    case createTaskActionTypes.SET_ADD_ID_WITH_ERROR:
      return {
        ...state,
        errorCreateTaskId: [...state.errorCreateTaskId, action.payload],
      };

    case createTaskActionTypes.SET_DELETE_ID_WITH_ERROR:
      const newErrorTaskId = state.errorCreateTaskId.filter((item) => item !== action.payload);
      return {
        ...state,
        errorCreateTaskId: newErrorTaskId,
      };

    case createTaskActionTypes.SET_OUT_TABS:
      return {
        ...state,
        activeOutTab: action.payload,
      };
    case createTaskActionTypes.SET_INPUT_TABS:
      return {
        ...state,
        activeInputTab: action.payload,
      };

    case createTaskActionTypes.SET_CLEAR_CREATE_COMPONENT:
      return initialState;

    default:
      return state;
  }
};
