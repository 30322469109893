import React from 'react';
import OutputParametersTaskParams from '@widgets/Task/OutputParametersTaskParams/OutputParametersTaskParams';
import s from './ExtensionNodeTaskParams.module.scss';
import Collapse from '@shared/UI/Collapse/Collapse';
import classNames from 'classnames';

const ExtensionNodeTaskParams = ({ node }) => {
  const CollapsesOpened = ['MuxPrograms', 'MuxProgram', 'DVB Tables', 'Entry'];

  return (
    <div className={classNames(s['extension-wrapper'], 'pt-6')}>
      <Collapse
        isShowFirst={CollapsesOpened.includes(node?.name)}
        text={node?.name}
        isFullWidth={true}
      >
        <OutputParametersTaskParams filter={node} />
        {node?.extensions &&
          node?.extensions.map((extensionNode) => (
            <div key={extensionNode.id} className={s['extension-node']}>
              <ExtensionNodeTaskParams node={extensionNode} />
            </div>
          ))}
      </Collapse>
    </div>
  );
};

export default ExtensionNodeTaskParams;
