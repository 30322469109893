import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import Flags from '../Flags/Flags';
// import { useTranslation } from 'react-i18next';
import UserMenu from './UserMenu/UserMenu';
import RootAddress from '@services/linksApp';
import { rightsPermissions } from '@store/UserRights';
import { authSettingsActions } from '@store/Authorization';
import sprite from '@shared/Images/sprite.svg';
import Flags from './Flags/Flags';
import { useTranslation } from 'react-i18next';
import './HeaderMenu.scss';
import DocsMenu from './DocsMenu/DocsMenu';

const HeaderMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOur = process.env.REACT_APP_COMPANY_NAME === 'Elecard';

  // console.log('hideButtons', hideButtons);
  // const path = window.location.pathname;
  const location = useLocation();
  const path = location.pathname;
  const hasPermissions = useSelector((state) => state.userRights.havePermissions);
  // const multyLang = process.env.REACT_APP_MULTY_LANGUAGES === 'true';
  // const isAuthorized = rootData._links && 'cw:server' in rootData._links;
  // console.log('multyLang', multyLang);
  React.useEffect(() => {
    RootAddress.startApi().then((result) => {
      const hasPermissions = result?.data?.node?.features.split(',').includes('UserRights');
      // console.log('hasPermissions', hasPermissions);
      dispatch(rightsPermissions.setPermissionsIsExist(hasPermissions));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // console.log('auth', doesHttpOnlyCookieExist('CW_SESSION_ID'));
    RootAddress.startApi().then((result) => {
      // console.dir(result);
      if (result?.data) {
        if (!result?.data?._links.hasOwnProperty('cw:server')) {
          localStorage.removeItem('authSettings');
          const updateAuth = {
            auth: false,
            group_id: null,
            group_name: 'guest',
            id: null,
            name: 'Guest',
          };
          dispatch(authSettingsActions.setAuthSettingsLogout(updateAuth));
        }
        // setRootData(result.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  // console.log('path', path);
  return (
    <>
      {isOur && <DocsMenu />}
      <Link to="/server-list">
        <div className="user user-box">
          <svg className="server-img">
            <use href={sprite + '#servers'}></use>
          </svg>
          <p className="docs-text">{t('all_servers')}</p>
        </div>
      </Link>
      {hasPermissions && path !== '/login' && <UserMenu />}
      {JSON.parse(process.env.REACT_APP_MULTY_LANGUAGES) && <Flags />}
    </>
  );
};

export default HeaderMenu;
