import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { inputCheckToNumber } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';

const General = ({ activeSettingsNavItem, dataState }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  // console.log('errors', errors);

  return (
    <div
      className={classNames(
        'settings-form-general',
        activeSettingsNavItem === 0 ? 'setting-show' : '',
      )}
    >
      <h2 className="settings-form-title">{t('general')}</h2>
      <div>{errors.name && <div className="settings-form__errors">{errors.name.message}</div>}</div>
      <div className="settings-form__item">
        <label className="settings-form__item-label" htmlFor="name">
          {t('server_name')}
        </label>
        <input
          className="settings-form__input"
          defaultValue={dataState.dispatcher.name}
          {...register('name')}
          placeholder="Enter service name..."
          id="name"
          name="name"
          type="text"
        />
      </div>
      <div>
        {errors.multicastAddress && (
          <div className="settings-form__errors">{errors.multicastAddress.message}</div>
        )}
      </div>
      <div className="settings-form__item">
        <label className="settings-form__item-label" htmlFor="multicastAddress">
          {t('multicast_address')}
        </label>

        <input
          className="settings-form__input"
          defaultValue={dataState.announces[0].multicast_addr}
          {...register('multicastAddress')}
          placeholder={'xxx.xxx.xxx.xxx'}
          maxLength="15"
          id="multicastAddress"
          type="text"
        />
      </div>

      <div className="settings-form__item">
        {errors.startDelay && (
          <div className="settings-form__errors">{errors.startDelay.message}</div>
        )}
        <label className="settings-form__item-label" htmlFor="startDelay">
          {t('service_start_delay')} ({t('s')})
        </label>
        <input
          className="settings-form__input num"
          defaultValue={dataState.options.StartDelay ? dataState.options.StartDelay : '0'}
          {...register('startDelay')}
          id="startDelay"
          type="number"
          min={0}
          max={3600}
          onKeyPress={inputCheckToNumber}
        />
      </div>
    </div>
  );
};

export default General;
