import React, { useEffect, useState } from 'react';
import OutputTabs from '@widgets/TaskConfigurator/OutputSection/OutputTabs/OutputTabs';
import OutputParameters from '@widgets/TaskConfigurator/OutputSection/OutputParameters/OutputParameters';
import ExtensionNode from '@widgets/TaskConfigurator/OutputSection/ExtensionNode/ExtensionNode';
import ExtensionAddButtons from '@widgets/TaskConfigurator/OutputSection/ExtensionAddButtons/ExtensionAddButtons';
import OutputFiltersItem from '@widgets/TaskConfigurator/OutputSection/OutputFiltersItem/OutputFiltersItem';
import { apiChangeMuxProgram } from '@widgets/TaskConfigurator/OutputSection/api/apiExtensions';
import { useDispatch } from 'react-redux';
import { apiGetAllOutputsList } from '@services/CreateTaskService';
import { createTaskActions } from '@store/CreateTask';
import TextFieldWrapper from '@shared/UI/TextFieldWrapper/TextFieldWrapper';
import { generatePinName } from '@shared/Lib/Utils/TaskConfigurator/generalFunctions';

const MuxPrograms = ({ muxPrograms, multiplexer_id }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [programNumber, setProgramNumber] = useState();

  useEffect(() => {
    setProgramNumber({
      name: 'Program Number',
      value: muxPrograms?.extensions?.[activeTab]?.id,
      valid_options: muxPrograms?.extensions?.map((exension, index) => {
        return { id: exension.id, value: exension.name };
      }),
    });
  }, [muxPrograms, activeTab]);

  const handleChangeProgram = (e, pin_id) => {
    const { selectedIndex } = e.target;
    apiChangeMuxProgram(multiplexer_id, pin_id, programNumber.valid_options[selectedIndex].id).then(
      (res) => {
        apiGetAllOutputsList().then((items) => {
          const outputData = items?.data?.data;
          const arrSections = outputData ? outputData.map((elem) => elem.section) : [];
          dispatch(createTaskActions.setExchangeOutput(arrSections));
        });
      },
    );
  };

  return (
    <>
      <OutputTabs
        tabNames={muxPrograms?.extensions}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        showCloseButton={true}
      >
        <ExtensionAddButtons
          className={'output-tabs__button-add'}
          extension={muxPrograms}
          dependencies={[muxPrograms]}
          actionAfterAdd={() =>
            setActiveTab(
              muxPrograms && muxPrograms?.extensions ? muxPrograms?.extensions?.length : 0,
            )
          }
        />
      </OutputTabs>

      {muxPrograms?.extensions && muxPrograms?.extensions[activeTab] && (
        <>
          {muxPrograms?.extensions[activeTab]?.parameters && (
            <OutputParameters parameters={muxPrograms?.extensions[activeTab]?.parameters} />
          )}
          {muxPrograms?.extensions[activeTab]?.pins &&
            muxPrograms?.extensions[activeTab]?.pins.map((pin) => (
              <div className="output-filters-progs" key={`Pin-${pin.id}`}>
                <OutputFiltersItem item={{ ...pin, name: generatePinName(pin) }}>
                  {pin.parameters && <OutputParameters parameters={pin.parameters} />}
                  {programNumber && (
                    <TextFieldWrapper key={programNumber.id} label={programNumber.name}>
                      <select
                        onChange={(e) => handleChangeProgram(e, pin.id)}
                        value={programNumber.value}
                        className="params__item-output params__output-value--select"
                      >
                        {programNumber?.valid_options?.map((elem) => (
                          <option key={elem.id} value={elem.id}>
                            {elem.value.toString()}
                          </option>
                        ))}
                      </select>
                    </TextFieldWrapper>
                  )}
                </OutputFiltersItem>
              </div>
            ))}
          <ExtensionAddButtons
            className={'button-wrapper'}
            extension={muxPrograms?.extensions[activeTab]}
            dependencies={[muxPrograms?.extensions[activeTab]?.extensions, activeTab]}
          />
          {muxPrograms?.extensions[activeTab]?.extensions &&
            muxPrograms?.extensions[activeTab]?.extensions.map((item) => (
              <ExtensionNode key={item.id} node={item} />
            ))}
        </>
      )}
    </>
  );
};

export default MuxPrograms;
