import React, { useState, useRef, useEffect } from 'react';

const useNetworkStatisticData = () => {
  const [interfacesInfo, setInterfacesInfo] = useState();
  const [showLoader, setShowLoader] = useState();

  const host = window.location.host;
  const ws = useRef(null);

  useEffect(() => {
    return () => {
      // console.log('CLOSED SOCKET NETWORKSTAT');
      ws.current.cwFinal = true;
      ws.current.close();
    };
  }, []);

  React.useEffect(() => {
    // console.log('CPUCoreInfoWS', ws.current);
    // readyState===0 - CONNECTING
    // readyState===1 - OPEN
    // readyState===2 - CLOSING
    // readyState===3 - CLOSED
    setShowLoader(true);
    if (ws.current === null || ws.current.readyState === 2 || ws.current.readyState === 3) {
      startWS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws.current]);

  const startWS = () => {
    ws.current = new WebSocket(`ws://${host}/api/system-info?network`);
    ws.current.onopen = () => {
      ws.current.send('/api/system-info?network');
      // console.log('Соединение открыто');
    };
    ws.current.onclose = () => {
      if (!ws.current.cwFinal) {
        setTimeout(startWS, 3000);
      }
      // console.log('Соединение закрыто');
    };
    ws.current.onerror = (event) => {
      console.dir(event);
    };
    gettingData();
  };

  const gettingData = () => {
    ws.current.onmessage = (e) => {
      if (e.data === 'OK') {
        return;
      }
      const message = JSON.parse(e.data);
      setInterfacesInfo(message?.data?.network ? message.data.network : []);
      setShowLoader(false);
    };
  };

  return { interfacesInfo, showLoader };
};

export default useNetworkStatisticData;
