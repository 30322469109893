import { useSelector } from 'react-redux';
import { ROLE_GUEST } from '../Constants/Constants';

const useAuth = () => {
  const { havePermissions } = useSelector((state) => state.userRights);
  const { group_name } = useSelector((state) => state.authSettings);
  const disableButtonForGuest = havePermissions && group_name === ROLE_GUEST;

  return { disableButtonForGuest };
};

export default useAuth;
