import React, { useEffect, useState } from 'react';
import HeaderSaveName from '@widgets/ConfiguratorRemux/HeaderSaveName/HeaderSaveName';
import ResizableBlock from '@widgets/ConfiguratorRemux/ResizableBlock/ResizableBlock';
import s from './ConfiguratorRemux.module.scss';
import classNames from 'classnames';
import TreeViewBase from '@widgets/ConfiguratorRemux/TreeViewBase/TreeViewBase';
import { CW_DRAFT_ID } from '@shared/Lib/Utils/Constants/Constants';
import Cookies from 'js-cookie';
import {
  apiAddDraft,
  apiCompareName,
  apiDeleteDraft,
  apiGetSourceList,
} from '@services/CreateTaskService';
import AddInputModal from '@widgets/ConfiguratorRemux/AddInputModal/AddInputModal';
import SettingsParameters from '@widgets/ConfiguratorRemux/SettingsParameters/SettingsParameters';
import { useTranslation } from 'react-i18next';
import { apiCreateSchema } from '@services/CreateTaskService';
import { useHistory } from 'react-router';

const ConfiguratorRemux = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [treeData, setTreeData] = useState({ id: 'root', name: 'Transports', children: [] });
  const [sectionsData, setSectionsData] = useState([]);
  // console.log('sectionsData', sectionsData);
  const [showModal, setShowModal] = useState();
  const [draft, setDraft] = useState();
  const [templateData, setTemplateData] = useState();
  // console.log('templateData', templateData);
  const [selectedTreeItemId, setSelectedTreeItemId] = useState();
  const [selectedTreeItemFilters, setSelectedTreeItemFilters] = useState([]);
  const [isValid, setIsValid] = useState({ status: true, error: '' });

  useEffect(() => {
    setDraftId();

    return () => removeDraft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDraftId = async () => {
    const draftName = Cookies.get(CW_DRAFT_ID);
    draftName && (await removeDraft());
    await apiAddDraft().then((res) => {
      const draftId = res.data;
      Cookies.set(CW_DRAFT_ID, draftId);
      setDraft(draftId);
    });

    apiGetSourceList().then((res) => {
      setTemplateData(res?.data);
    });
  };

  const removeDraft = async () => {
    await apiDeleteDraft().then(() => {
      Cookies.remove(CW_DRAFT_ID);
      setDraft('');
    });
  };

  const handleClickAddInput = () => {
    setShowModal(true);
  };

  const handleClickAddOutput = () => {
    console.log('handleClickAddOutput');
  };

  useEffect(() => {
    if (!isNaN(selectedTreeItemId)) {
      setSelectedTreeItemFilters((selectedTreeItemFilters) => {
        return sectionsData?.length !== 0
          ? sectionsData.find((item) => item.id === selectedTreeItemId)?.filters
          : [];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTreeItemId]);

  const handleClickButton = async (schemaName) => {
    const isExistName = await apiCompareName(schemaName).then((res) => res.data.name_is_used);

    if (isExistName) {
      // имя задачи существует
      setIsValid({ status: false, error: `"${schemaName}" ${t('task_name_alredy_used')}` });
    } else {
      // имя задачи новое
      apiCreateSchema(schemaName, '')
        .then(() => {
          history.push('/');
        })
        .catch((error) => {
          console.dir(error);
        });
    }
  };

  return (
    <>
      <div>
        <HeaderSaveName
          titleName={t('create_task_remux')}
          inputLabel={t('task_name')}
          inputPlaceHolder={t('enter_task_name')}
          handleClickButton={handleClickButton}
          isValidOutside={isValid}
          className={'m15 mb-10'}
        />
        <div className={classNames(s['configurator-remux__container'], 'm15 mt-10')}>
          <ResizableBlock
            title={'Inputs'}
            classNameResizableBlock={s['configurator-remux__item']}
            handleClick={() => handleClickAddInput()}
          >
            <TreeViewBase treeData={treeData} setSelectedTreeItemId={setSelectedTreeItemId} />
          </ResizableBlock>
          <ResizableBlock
            title={'Outputs'}
            classNameResizableBlock={s['configurator-remux__item']}
            handleClick={() => handleClickAddOutput()}
          >
            Outputs Tree
          </ResizableBlock>
        </div>
        {!isNaN(selectedTreeItemId) && (
          <ResizableBlock
            title={'Settings'}
            classNameResizableBlock={s['configurator-remux__item']}
            handleClick={() => handleClickAddOutput()}
          >
            {selectedTreeItemFilters?.length !== 0 &&
              selectedTreeItemFilters?.map((filter, index) => (
                <div key={index + '_filter_' + filter?.id}>
                  <p>{filter?.name}</p>
                  <SettingsParameters parameters={filter?.parameters} />
                </div>
              ))}
          </ResizableBlock>
        )}
      </div>
      {showModal && (
        <AddInputModal
          setShowModal={() => setShowModal(false)}
          draft={draft}
          setTreeData={setTreeData}
          setSectionsData={setSectionsData}
          templateData={templateData}
        />
      )}
    </>
  );
};

export default ConfiguratorRemux;
