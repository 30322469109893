import classNames from 'classnames';
import React from 'react';
import s from './RoundBadge.module.scss';

const RoundBadge = ({ children, classNameBadge }) => {
  return (
    <div className={classNames(s['round-badge'], classNameBadge)} data-testid="round-badge">
      {children}
    </div>
  );
};

export default RoundBadge;
