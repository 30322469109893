import axios from 'axios';
import $api from './apiAxios';
import $apiHome from './apiAxiosHome';
import { formErrorObject } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';

export const apiStartTasks = async (id, name, environment = 'Common') => {
  try {
    const res = await $api.post(
      `encoder/schema/start?storage_id=${id}&schema=${name}&environment=${environment}`,
    );
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiStartTask = async (link) => {
  // console.log('link', link);
  try {
    const res = await $apiHome.post(`${link}/state/job`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiStopTasks = async (id, name) => {
  try {
    const res = await $api.delete(`encoder/schema/stop?storage_id=${id}&schema=${name}`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiStopTask = async (link) => {
  try {
    const res = await $apiHome.delete(`${link}/state/job`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiRestartTasks = async (id) => {
  try {
    // console.log('id', id);
    const res = await $api.put(`encoder/channel/${id}/state`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiPrepareTasks = async (id, name) => {
  try {
    const res = await $api.post(`encoder/schema/prepare?storage_id=${id}&schema=${name}`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiRebuildTasks = async (id) => {
  try {
    const res = await $api.patch(`encoder/channel/${id}/schema/current`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiTaskRebuild = async (id, name) => {
  try {
    const res = await $api.post(`encoder/schema/rebuild?storage_id=${id}&schema=${name}`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiDeleteTasks = async (id, name) => {
  try {
    const res = await $api.delete(`encoder/schema/delete?storage_id=${id}&schema=${name}`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiAllowColorBar = async (id) => {
  try {
    const res = await $api.put(`encoder/channel/${id}/colorbargenerator/auto`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiDenyColorBar = async (id) => {
  try {
    const res = await $api.delete(`encoder/channel/${id}/colorbargenerator/auto`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiRunColorbar = async (id) => {
  try {
    const res = await $api.put(`encoder/channel/${id}/colorbargenerator/manual`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiStopColorbar = async (id) => {
  try {
    const res = await $api.delete(`encoder/channel/${id}/colorbargenerator/manual`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const getTaskParams = async (id, name) => {
  try {
    const res = await $api.get(
      `encoder/schema/parameters?storage_id=${id}&schema=${name}&modifier=special&exInvisible&exReadOnly`,
    );
    if (res.isAxiosError) {
      console.dir(res);
      let newError = formErrorObject(res);
      return Promise.reject(newError);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const getInactiveTask = async () => {
  try {
    const res = await $api.get(`/encoder/schema/list`);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const getActiveTask = async () => {
  try {
    const res = await $api.get(`/encoder`);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiPutParameter = async (elem) => {
  try {
    const params = new URLSearchParams({ value: encodeURIComponent(elem.value) });
    const res = await axios
      .create({
        headers: { 'content-type': 'application/json' },
      })
      .put(`${elem.link}?${decodeURIComponent(decodeURIComponent(params))}`);

    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    return { elem, res };
  } catch (error) {
    console.dir(error);
    return Promise.reject({ elem, error });
  }
};

export const apiUpdateTaskParameters = async (id, name, diffData) => {
  try {
    const res = await $api.post(
      `encoder/schema/parameters?storage_id=${id}&schema=${name}`,
      diffData,
    );
    if (res.isAxiosError) {
      console.dir(res);
      return Promise.reject(res.response.data);
      // throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiResetAst = async (id, name) => {
  try {
    const res = await $api.post(`encoder/schema/reset-ast?storage_id=${id}&schema=${name}`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiResetRefPids = async (id, name) => {
  try {
    const res = await $api.post(
      `encoder/schema/reset-reference-pid?storage_id=${id}&schema=${name}`,
    );
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};
