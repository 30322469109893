import {
  apiHeartBeatRedundancy,
  apiRedundancyGroups,
  apiRedundancyHosts,
  apiRedundancySettings,
  apiRedundancySettingsDeactivate,
  apiStartRedundancy,
  apiStopRedundancy,
  apiUpdateSettings,
  apiUpdateSettingsDeactivate,
  apiAddGroups,
  apiDeleteGroups,
  apiAddHosts,
  apiDeleteHosts,
} from '@widgets/Redundancy/api/apiRedundancyService';

export const getStart = async (setShowError, setMessageError) => {
  await apiStartRedundancy().catch((error) => {
    setMessageError(error.message);
    setShowError(true);
  });
};

export const getGroups = async (setReservServers, setShowError, setMessageError) => {
  await apiRedundancyGroups()
    .then((response) => {
      //console.log('responseGroup', response);
      const items = response?.data?.data?.map((el) => ({
        name: el.name,
        subname: el.connections
          ? el?.connections?.[0]?.port === 1535
            ? el?.connections?.[0]?.interface
            : el?.connections?.[0]?.interface + ':' + el?.connections?.[0]?.port
          : '',
        checked: false,
      }));
      //console.log('items', items);
      setReservServers(items);
    })
    .catch((error) => {
      setMessageError(error.message);
      setShowError(true);
    });
};

export const addGroups = async (
  allData,
  setShowError,
  setMessageError,
  setReservHosts,
  closeMenu,
) => {
  apiAddGroups(allData)
    .then((response) => {
      getGroups(setReservHosts, setShowError, setMessageError);
      closeMenu();
    })
    .catch((error) => {
      setMessageError(error.message);
      setShowError(true);
    });
};

export const deleteGroups = async (allData, setShowError, setMessageError, setReservServers) => {
  await apiDeleteGroups(allData)
    .then((response) => {
      getGroups(setReservServers, setMessageError, setReservServers);
    })
    .catch((error) => {
      setMessageError(error.message);
      setShowError(true);
    });
};

export const getHosts = async (setReservHosts, setShowError, setMessageError, setIsLoad) => {
  await apiRedundancyHosts()
    .then((response) => {
      // console.log('responseHOST', response);
      const items = response?.data?.data?.map((el) => ({
        name: el.name,
        subname: el.connections
          ? el?.connections?.[0]?.port === 1535
            ? el?.connections?.[0]?.interface
            : el?.connections?.[0]?.interface + ':' + el?.connections?.[0]?.port
          : '',
        checked: false,
      }));
      // console.log('items', items);
      setReservHosts(items);
      setIsLoad && setIsLoad(true);
    })
    .catch((error) => {
      setMessageError(error.message);
      setShowError(true);
    });
};

export const addHosts = async (
  allData,
  setShowError,
  setMessageError,
  setReservHosts,
  closeMenu,
) => {
  await apiAddHosts(allData)
    .then((response) => {
      getHosts(setReservHosts, setShowError, setMessageError);
      closeMenu();
    })
    .catch((error) => {
      setMessageError(error.message);
      setShowError(true);
    });
};

export const deleteHosts = async (allData, setShowError, setMessageError, setReservHosts) => {
  await apiDeleteHosts(allData)
    .then((response) => {
      getHosts(setReservHosts, setShowError, setMessageError);
    })
    .catch((error) => {
      setMessageError(error.message);
      setShowError(true);
    });
};

export const stopReserve = (setShowError, setMessageError) => {
  apiStopRedundancy('not-change').catch((error) => {
    setMessageError(error.message);
    setShowError(true);
  });
};

export const applyReserve = async (setShowError, setMessageError) => {
  await apiStopRedundancy('apply').catch((error) => {
    setMessageError(error.message);
    setShowError(true);
  });
};

export const updateReserve = (setShowError, setMessageError) => {
  apiHeartBeatRedundancy().catch((error) => {
    setMessageError(error.message);
    setShowError(true);
  });
};

export const getOptions = async (setOptions, setDeactivOptions, setShowError, setMessageError) => {
  await apiRedundancySettings()
    .then((response) => {
      // console.log('responseOptions', response);
      const items = response.data.data;
      setOptions(items);
      apiRedundancySettingsDeactivate()
        .then((response) => {
          // console.log('responseDeactivate', response);
          const items = response.data.data;
          setDeactivOptions(items);
        })
        .catch((error) => {
          setMessageError(error.message);
          setShowError(true);
        });
    })
    .catch((error) => {
      setMessageError(error.message);
      setShowError(true);
    });
};

export const updateOptions = async (allData, setShowError, setMessageError, closeMenu) => {
  await apiUpdateSettings(allData)
    .then((response) => {
      closeMenu();
    })
    .catch((error) => {
      setMessageError(error.message);
      setShowError(true);
    });
};

export const updateDeactivateOptions = async (
  allData,
  setShowError,
  setMessageError,
  closeMenu,
) => {
  await apiUpdateSettingsDeactivate(allData)
    .then((response) => {
      closeMenu();
    })
    .catch((error) => {
      setMessageError(error.message);
      setShowError(true);
    });
};
