import { SOURCE } from '../Constants/Constants';

export const generatePinName = (pin, type) => {
  if (type === SOURCE) {
    return pin?.name ? pin?.name : '';
  }

  const trackSubtype = pin?.track_subtype ? ` (${pin?.track_subtype})` : '';
  const trackName = pin?.track_type ? pin?.track_type + trackSubtype : '';
  const displayName = pin?.display_name
    ? pin?.display_name
    : trackName
    ? trackName
    : pin?.name
    ? pin?.name
    : '';

  return 'Pin ' + displayName;
};
