import $api from './apiAxios';

export default class UserService {
  static async addUser(data) {
    return $api.post('/user-permissions?command=add&type=user', data);
  }
  static async updateUser(data) {
    return $api.post('/user-permissions?command=update&type=user', data);
  }
  static async deleteUser(id) {
    return $api.delete(`/user-permissions?type=user&id=${id}`);
  }
}
