import classNames from 'classnames';
import React from 'react';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import ButtonAction from '@shared/UI/Buttons/ButtonAction/ButtonAction';
import { connectionTypes } from '@widgets/ServerSettings/lib/ServerSettingsDictionary';
import './Connections.scss';
import { useTranslation } from 'react-i18next';
import { inputCheckToNumber } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';

const Connections = ({
  activeSettingsNavItem,
  dataState,
  connects,
  setConnects,
  extraInterfaces,
}) => {
  const { t } = useTranslation();
  // console.log('connects', connects);
  // console.log('State', dataState);

  const addConnect = () => {
    const newId = connects.length === 0 ? 1 : connects[connects.length - 1].id + 1;
    const updateData = [
      ...connects,
      {
        id: newId,
        interface: dataState.available_interfaces ? dataState.available_interfaces[0] : '',
        port: '',
        type: 'RestAPI',
      },
    ];
    setConnects(updateData);
  };

  const deleteConnect = (id) => {
    const updateData = connects.filter((item) => item.id !== id);
    setConnects(updateData);
  };

  const setType = (e, id) => {
    const valueType = e.target.value;
    const updateData = connects.map((item) => ({
      ...item,
      type: item.id === id ? valueType : item.type,
    }));
    setConnects(updateData);
  };

  const setPort = (e, id) => {
    const valuePort = +e.target.value;
    const updateData = connects.map((item) => ({
      ...item,
      port: item.id === id ? valuePort : item.port,
    }));
    setConnects(updateData);
  };

  const setAddress = (e, id) => {
    const valueAddress = e.target.value;
    // console.log('valueAddress', valueAddress);
    const updateData = connects.map((item) => ({
      ...item,
      interface: item.id === id ? valueAddress : item.interface,
    }));
    setConnects(updateData);
  };

  return !connects ? (
    <Loader />
  ) : (
    <div
      className={classNames(
        'settings-form-general mt-10',
        activeSettingsNavItem === 0 ? 'setting-show' : '',
      )}
    >
      <div className="connections">
        <div className="table-header mb10">
          <p className="table-name">{t('connections')}</p>
          <ButtonAction
            label={t('add')}
            showFunc={addConnect}
            img={'#add'}
            classes={'pic pic--green'}
          />
        </div>
        <div className="connections-header">
          <div className="connections-header__item">{t('ip_address')}</div>
          <div className="connections-header__item">{t('port')}</div>
          <div className="connections-header__item">{t('type')}</div>
          <div className="connections-header__item">{t('action')}</div>
        </div>
        <div className="connections-table">
          {connects.map((item, i) => (
            <div className="connections-table__item" key={item.type + i}>
              <select
                defaultValue={item.interface}
                className="severity-task-data-list"
                onChange={(e) => setAddress(e, item.id)}
              >
                {dataState.available_interfaces.map((elem, i) => {
                  return (
                    <option value={elem} key={`el-${i}-${elem}`}>
                      {elem}
                    </option>
                  );
                })}
                {extraInterfaces.map((item, i) => (
                  <option key={'extraInterfaces' + item + '' + i} value={item}>
                    {item}
                  </option>
                ))}
              </select>

              <input
                type="number"
                className="item-input input-22 num"
                defaultValue={item.port}
                max={65535}
                min={1}
                onBlur={(e) => setPort(e, item.id)}
                onKeyPress={inputCheckToNumber}
              />

              <select
                defaultValue={item.type}
                className="connections-list"
                onChange={(e) => setType(e, item.id)}
              >
                {connectionTypes(t)}
              </select>
              <ButtonAction
                label={''}
                showFunc={() => deleteConnect(item.id)}
                img={'#trash'}
                classes={'pic pic--red'}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Connections;
