import React from 'react';
import Groups from '@widgets/SecuritySettings/Groups/Groups';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import Permissions from '@widgets/SecuritySettings/Permissions/Permissions';
import { CSSTransition } from 'react-transition-group';
import Security from '@services/SecuritySettings';
import GroupPermissions from '@services/GroupSettings';
import SecurityError from '@shared/UI/Errors/SecurityError/SecurityError';
import './SecuritySettings.scss';

const SecuritySettings = ({ state }) => {
  const [groupsData, setGroupsData] = React.useState([]);
  const [usersData, setUsersData] = React.useState([]);
  const [showError, setShowError] = React.useState(false);
  const [selectGroup, setSelectGroup] = React.useState('0');
  // console.log('state', state);

  const getSecureData = async () => {
    await Security.getData().then((response) => {
      setGroupsData(response.data.groups);
      setUsersData(response.data.users);
    });
  };

  const updateTryLock = () => {
    GroupPermissions.refreshSession().then((res) => {
      if (res.isAxiosError) {
        if (res.response.status === 409) {
          setShowError(true);
        }
      }
    });
  };
  const unlockSession = () => {
    GroupPermissions.unlock();
  };

  React.useEffect(() => {
    getSecureData();
    updateTryLock();
    const timer = setInterval(updateTryLock, 45000);
    return () => {
      unlockSession();
      clearInterval(timer);
    };
  }, []);

  return !groupsData.length ? (
    <Loader />
  ) : (
    <div className="security">
      <CSSTransition in={showError} timeout={500} unmountOnExit classNames="alert">
        <SecurityError setShowError={setShowError} />
      </CSSTransition>
      <div className="security-container">
        <Groups
          getSecureData={getSecureData}
          state={state}
          groupsData={groupsData}
          selectGroup={selectGroup}
          setSelectGroup={setSelectGroup}
          blockAction={showError}
        />
        {groupsData.length !== 0 && (
          <Permissions
            getSecureData={getSecureData}
            groupsData={groupsData}
            usersData={usersData}
            selectGroup={selectGroup}
            setSelectGroup={setSelectGroup}
            blockAction={showError}
          />
        )}
      </div>
    </div>
  );
};

export default SecuritySettings;
