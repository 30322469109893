import classNames from 'classnames';
import React from 'react';
import { logLevels } from '@widgets/ServerSettings/lib/ServerSettingsDictionary';
import { useFormContext } from 'react-hook-form';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import { useDispatch } from 'react-redux';
import { loggingActions } from '@store/LoggingSettings/actions';
import { loggingLabels } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import { useTranslation } from 'react-i18next';

const Logging = ({ activeSettingsNavItem, dataState }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register } = useFormContext();

  // console.log('dataState', dataState);

  const [applyData, setApplyData] = React.useState([]);

  // console.log('dataState', Object.entries(dataState.log_config));

  const logApply = () => {
    const objData = dataState.log_config;
    // console.log('objData', objData);
    const { dispatcher, ...newData } = objData;
    const arrData = Object.entries(newData).map((elem) => ({
      name: elem[0],
      level: elem[1][0]?.sinks?.[0].level,
    }));
    setApplyData(arrData);
    dispatch(loggingActions.setLoggingSettings(arrData));
  };

  React.useEffect(() => {
    logApply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatcherData = dataState.log_config.dispatcher.filter(
    (item) => item.type !== 'StackTrace',
  );

  return applyData.length === 0 ? (
    <Loader />
  ) : (
    <div
      className={classNames(
        'settings-form-general',
        activeSettingsNavItem === 2 ? 'setting-show' : '',
      )}
    >
      <h2 className="settings-form-title">{t('logging')}</h2>
      <div className="settings-form__item">
        <label className="settings-form__item-label" htmlFor="name">
          {t('save_logs_to')}
        </label>
        <input
          className="settings-form__input-file"
          defaultValue={dataState.options.LogDirectory}
          {...register('logDirectory')}
          id="logDirectory"
          name="logDirectory"
          type="text"
        />
      </div>
      {dispatcherData.map((elem, i) =>
        elem?.sinks?.map((item) => (
          <div className="settings-form__item" key={elem.type + i}>
            <label className="settings-form__item-label">
              {loggingLabels(t).find((el) => el.type === elem.type)?.label
                ? loggingLabels(t).find((el) => el.type === elem.type)?.label
                : elem.type}
            </label>
            <select
              defaultValue={item.level === 'off' ? 'not set' : item.level}
              className="settings-form__input-list settings-form__log-list"
              name={item.file_name ? item.file_name : elem.type}
              id={item.file_name ? item.file_name : elem.type}
              {...register(item.file_name ? item.file_name : elem.type)}
            >
              {logLevels}
            </select>
          </div>
        )),
      )}
    </div>
  );
};

export default Logging;
