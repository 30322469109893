import React from 'react';
import s from './ButtonClose.module.scss';
import classNames from 'classnames';
import { ReactComponent as SvgCloseRounded } from '@images/close.svg';

const ButtonClose = ({ onClick, className }) => {
  return (
    <button onClick={onClick} className={classNames(s['close-button'], className ? className : '')}>
      <SvgCloseRounded className={s['close-svg']} />
    </button>
  );
};

export default ButtonClose;
