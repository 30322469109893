import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Rights from './Rights/Rights';
import Users from './Users/Users';

const Permissions = ({ groupsData, usersData, selectGroup, getSecureData, blockAction }) => {
  const { t } = useTranslation();
  const [activePermission, setActivePermission] = React.useState(0);
  const activeMenu = [{ name: t('permissions') }, { name: t('users') }];
  const groupUsers = usersData.filter((elem) => elem.group_id === selectGroup);
  const currentGroup = groupsData.find((elem) => elem.id === selectGroup);

  return (
    <div className="home-box__permissions">
      <div className="snmp-nav">
        {activeMenu.map((item, i) => (
          <div
            key={item.name + i}
            className={classNames(
              'snmp-nav__item',
              activePermission === i ? 'snmp-nav-active' : '',
            )}
            onClick={() => setActivePermission(i)}>
            {item.name}
          </div>
        ))}
      </div>
      {activePermission === 0 ? (
        <Rights
          selectGroup={selectGroup}
          currentGroup={currentGroup}
          getSecureData={getSecureData}
          blockAction={blockAction}
        />
      ) : (
        <Users
          selectGroup={selectGroup}
          getSecureData={getSecureData}
          usersData={groupUsers}
          blockAction={blockAction}
        />
      )}
    </div>
  );
};

export default Permissions;
