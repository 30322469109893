import React from 'react';
import sprite from '@shared/Images/sprite.svg';
import './SvgPic.scss';

const SvgPic = ({ img, classes }) => {
  return (
    <svg className={classes}>
      <use href={sprite + img}></use>
    </svg>
  );
};

export default SvgPic;
