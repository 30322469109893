import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import s from './ServicesSource.module.scss';
import classNames from 'classnames';
import Collapse from '@shared/UI/Collapse/Collapse';
import {
  apiRefreshAllProgs,
  apiTurnConnectorPins,
  getTransformConnectorList,
} from '@services/CreateTaskService';
import ButtonBase from '@shared/UI/Buttons/ButtonBase/ButtonBase';
import { ReactComponent as SvgRefresh } from '@shared/Images/refresh.svg';
import { updatePinsOnly } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import { SERVICES } from '@shared/Lib/Utils/Constants/SourceTemplate';

const ServicesSource = ({
  selectItem,
  setSelectItem,
  isShowMiniLoader,
  setIsShowMiniLoader,
  setErrorMessage,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const [checkedPrograms, setCheckedPrograms] = useState([]);

  const setToggleProg = (e, programNumber) => {
    e.stopPropagation();
    // console.log('programNum', programNum);
    const newPrograms = selectItem?.groups?.map((item) => {
      return {
        ...item,
        checked: item?.program === programNumber ? !item.checked : item.checked,
        pins: item?.pins?.map((pin) => ({
          ...pin,
          is_used: item?.program === programNumber ? !item.checked : pin.is_used,
        })),
      };
    });

    // console.log('updateRenderProgs', updateRenderProgs);

    setCheckedPrograms((prevCheckedProprams) => {
      let newUpdatedProgram = newPrograms.find((item) => item?.program === programNumber);
      let progIndex = prevCheckedProprams.findIndex((item) => item?.program === programNumber);
      if (progIndex === -1) {
        return [...prevCheckedProprams, newUpdatedProgram];
      } else {
        return prevCheckedProprams.map((program, index) =>
          index === progIndex ? newUpdatedProgram : program,
        );
      }
    });

    setSelectItem((selectItem) => {
      return { ...selectItem, groups: newPrograms };
    });
  };

  const setTogglePin = (pinIndex, programNumber) => {
    const newPrograms = selectItem?.groups?.map((item) => {
      let updatedPins =
        item.program === programNumber
          ? item?.pins?.map((pin) => ({
              ...pin,
              is_used: pin.index === pinIndex ? !pin.is_used : pin.is_used,
            }))
          : item.pins;

      return {
        ...item,
        checked: updatedPins.some((pin) => pin.is_used),
        pins: updatedPins,
      };
    });

    setCheckedPrograms((prevCheckedPrograms) => {
      let newUpdatedProgram = newPrograms.find((program) => program?.program === programNumber);
      // console.log('newUpdatedProgram', newUpdatedProgram);

      let progIndex = prevCheckedPrograms.findIndex(
        (program) => program?.program === programNumber,
      );
      if (progIndex === -1) {
        return [...prevCheckedPrograms, newUpdatedProgram];
      } else {
        return prevCheckedPrograms.map((program, index) =>
          index === progIndex ? newUpdatedProgram : program,
        );
      }
    });

    setSelectItem((selectItem) => {
      return { ...selectItem, groups: newPrograms };
    });
  };

  const handleApply = (e) => {
    e.preventDefault();
    if (checkedPrograms?.length) {
      sendPins();
    }
  };

  const sendPins = async () => {
    setIsShowMiniLoader(true);
    const turnConnectorPins = [];
    checkedPrograms?.forEach((program) => {
      program?.pins?.forEach(({ is_used, index }) => {
        turnConnectorPins.push({
          command: 'turn',
          section_id: selectItem.id,
          pin_index: index,
          choice: is_used ? 'on' : 'off',
        });
      });
    });
    if (turnConnectorPins.length) {
      try {
        await apiTurnConnectorPins(turnConnectorPins).catch((errors) => {
          if (Array.isArray(errors)) {
            const allErrors = errors?.reduce(
              (acc, current) => (current?.message ? acc + current?.message + '\n' : acc),
              '',
            );
            // dispatch(createTaskActions.setErrorFromRequest({ message: allErrors }));
            setErrorMessage({ message: allErrors });
            console.dir(allErrors);
            //не надо выполнять uncheck pin, т к необходимо оставить возможность выбора подходящего кодека в трансформ секции
          } else {
            console.dir(errors);
          }
        });
        setIsShowMiniLoader(true);
      } catch (error) {
        console.dir(error);
      } finally {
        setIsShowMiniLoader(false);
        setCheckedPrograms([]);
      }
    }
  };

  const handleRefresh = async () => {
    setIsShowMiniLoader(true);
    try {
      setCheckedPrograms([]);
      await apiRefreshAllProgs();
      await updatePinsOnly();
      await getTransformConnectorList().then((res) => {
        const selectSection = res?.data?.data?.find((item) => item.section_id === selectItem?.id);
        const newSelectItem = {
          ...selectItem,
          groups: selectSection?.groups,
        };
        setSelectItem(newSelectItem);
      });
      setActiveTab(SERVICES);
    } catch (error) {
    } finally {
      setIsShowMiniLoader(false);
    }
  };

  return (
    <div className={s['services-source']}>
      <div className={s['services-source__header']}>
        <div className={s['services-source__header__title']}>{t('source_items')}</div>
        <div className={classNames(s['services-source__header__button'])}>
          <ButtonBase color={'grey'} isIcon onClick={handleRefresh}>
            <SvgRefresh />
            <p className={classNames(s['services-source__header__button__text'])}>{t('refresh')}</p>
          </ButtonBase>
        </div>
      </div>
      <div className={s['services-source__body']}>
        {selectItem?.groups &&
          selectItem?.groups?.map((item, index) => (
            <div key={'services-source__collapse-item_' + index}>
              <Collapse
                classNameControl={s['services-source__collapse__text']}
                classNameButton={classNames(
                  'svg-button_dark',
                  s['services-source__collapse__button'],
                )}
                text={
                  item.program_name
                    ? `Prog#${item.program} (${item.program_name})`
                    : `Prog#${item.program}`
                }
                ComponentBeforeText={
                  <input
                    onChange={(e) => setToggleProg(e, item.program)}
                    type="checkbox"
                    checked={item.checked || item?.pins?.some((pin) => pin.is_used)}
                    disabled={isShowMiniLoader}
                  />
                }
              >
                {item?.pins?.map((pin, index) => {
                  return (
                    <div
                      key={'services-source__collapse__children_' + index}
                      className={s['services-source__collapse__children']}
                    >
                      <input
                        id={pin?.name + '_item_' + index}
                        type="checkbox"
                        onChange={(e) => setTogglePin(pin.index, item.program)}
                        checked={pin.is_used}
                        disabled={isShowMiniLoader}
                      />
                      <label htmlFor={pin?.name + '_item_' + index}> {pin?.name}</label>
                    </div>
                  );
                })}
              </Collapse>
            </div>
          ))}
      </div>
      <div className={s['services-source__button-add']}>
        <ButtonBase
          color={'green'}
          type="submit"
          onClick={handleApply}
          disabled={isShowMiniLoader || !checkedPrograms.length}
        >
          {t('apply')}
        </ButtonBase>
      </div>
    </div>
  );
};

export default ServicesSource;
