import classNames from 'classnames';
import { GetValueWithMeaning } from './lib/ParseValue';
import s from './TaskParamRow.module.scss';

const TaskParamRow = ({ param, index, link, readOnly, onNameClick }) => {
  if (!param) {
    return null;
  }

  const hasNewLines = param.value?.toString().includes('\n') || isNaN(param.value);

  return (
    <div
      className={classNames(
        s['sections-filters-params__item'],
        hasNewLines ? s['sections-filters-params__item-status'] : '',
      )}
      key={param.name + index}
    >
      {readOnly ? (
        <div>{param.name}:</div>
      ) : link ? (
        <div
          className={classNames(
            s['params__item-name'],
            param.error ? s['params__item-name--error'] : '',
          )}
        >
          {param.name}
        </div>
      ) : (
        <div
          className={classNames(
            s['params__item-name'],
            param.error ? s['params__item-name--error'] : '',
          )}
          onClick={onNameClick}
        >
          {param.name}:
        </div>
      )}
      {hasNewLines ? (
        <pre data-type={param.type} className={s['params__item-status']}>
          {param.value?.toString().replace(/&#x0A;/g, '\n').trim()}
        </pre>
      ) : (
        <div
          className={classNames(
            s['params__item-value'],
            isNaN(param.value) ? s['params__item-value--statistics'] : '',
            param.error ? s['params__item-value--error'] : '',
          )}
          data-type={param.type}
        >
          {param.type === 'VT_R8' || param.type === 'VT_R4'
            ? parseFloat(param.value?.toString().replace(',', '.')).toFixed(2)
            : GetValueWithMeaning(param)}
        </div>
      )}
    </div>
  );
};
export default TaskParamRow;
