import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { apiGetAllOutputsList, apiSendParams } from '@services/CreateTaskService';
import { createTaskActions } from '@store/CreateTask';
import { parameterCheckMeaning } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import InputStretchable from '@shared/UI/InputStretchable/InputStretchable';
import classnames from 'classnames';
import TextFieldWrapper from '@shared/UI/TextFieldWrapper/TextFieldWrapper';

const OutputParameters = ({ parameters }) => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const handleChangeParamApi = (e) => {
    const { value, name } = e.target;
    let changedValue = { [name]: value };

    apiSendParams(changedValue).then(() => {
      apiGetAllOutputsList().then((items) => {
        const outputData = items?.data?.data;
        const arrSections = outputData ? outputData.map((elem) => elem.section) : [];
        dispatch(createTaskActions.setExchangeOutput(arrSections));
      });
    });
  };

  return (
    <>
      {parameters.map(
        (param) =>
          param.visible &&
          !param.readonly && (
            <TextFieldWrapper key={param.id} label={param.name} dataType={param.type}>
              {param.valid_options ? (
                <select
                  {...register(`${param.id}`, {
                    onChange: handleChangeParamApi,
                  })}
                  defaultValue={param.value?.toString()}
                  className="params__item-output params__output-value--select"
                >
                  {param.valid_options.map((elem, index) => (
                    <option key={elem.meaning + index} value={elem.value.toString()}>
                      {parameterCheckMeaning(elem)}
                    </option>
                  ))}
                </select>
              ) : (
                <InputStretchable
                  param={param}
                  className={classnames(
                    'params__item-output ',
                    errors[`${param.id}`] ? 'border-error' : '',
                  )}
                  handleChangeParamApi={handleChangeParamApi}
                />
              )}
            </TextFieldWrapper>
          ),
      )}
    </>
  );
};

export default OutputParameters;
