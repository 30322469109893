import React from 'react';
import ButtonAction from '@shared/UI/Buttons/ButtonAction/ButtonAction';
import { useState } from 'react';
import { apiGetAllOutputsList } from '@services/CreateTaskService';
import { useDispatch } from 'react-redux';
import { createTaskActions } from '@store/CreateTask';
import { useEffect } from 'react';
import {
  apiCreateExtension,
  apiGetAvailableParams,
  apiSendMethod,
} from '@widgets/TaskConfigurator/OutputSection/api/apiExtensions';
import { CSSTransition } from 'react-transition-group';
import Error from '@shared/UI/Errors/Error/Error';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';

const ExtensionAddButtons = ({
  extension,
  dependencies,
  actionAfterAdd,
  className,
  buttonLabel,
}) => {
  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState('');

  const dispatch = useDispatch();
  const [availableExtensionParamsToAdd, setAvailableExtensionParamsToAdd] = useState([]);
  const [availableExtensionMethods, setAvailableExtensionMethods] = useState([]);

  useEffect(() => {
    return () => {
      setAvailableExtensionParamsToAdd([]);
    };
  }, []);

  useEffect(() => {
    if (extension.id) {
      apiGetAvailableParams(extension.id)
        .then((res) => {
          setAvailableExtensionParamsToAdd(res?.data?.available_types);
          setAvailableExtensionMethods(res?.data?.methods);
        })
        .catch((error) => {
          console.dir(error.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  const handlerClickAddButton = (guid) => {
    apiCreateExtension(extension.id, guid)
      .then(() => {
        apiGetAllOutputsList().then((items) => {
          const outputData = items?.data?.data;
          const arrSections = outputData ? outputData.map((elem) => elem.section) : [];
          dispatch(createTaskActions.setExchangeOutput(arrSections));
          actionAfterAdd && actionAfterAdd();
        });
      })
      .catch((error) => {
        setMessageError(error.message);
        setShowError(true);
      });
  };

  const handlerClickMethod = (e, name) => {
    apiSendMethod(extension.id, name)
      .then(() => {
        apiGetAllOutputsList().then((items) => {
          const outputData = items?.data?.data;
          const arrSections = outputData ? outputData.map((elem) => elem.section) : [];
          dispatch(createTaskActions.setExchangeOutput(arrSections));
          actionAfterAdd && actionAfterAdd();
        });
      })
      .catch((error) => {
        setMessageError(error.message);
        setShowError(true);
      });
    e.preventDefault();
  };

  return (
    <>
      <div className={`${className ? className : ''}`}>
        {!!availableExtensionParamsToAdd?.length &&
          availableExtensionParamsToAdd?.map(
            (item) =>
              item.can_be_added && (
                <ButtonAction
                  key={item.guid}
                  label={`${buttonLabel ? buttonLabel : item.name}`}
                  showFunc={() => handlerClickAddButton(item.guid)}
                  img={'#add'}
                  classes={'button-add'}
                />
              ),
          )}
        {!!availableExtensionMethods?.length &&
          availableExtensionMethods?.map((name, index) => (
            <ButtonAction
              key={name + index}
              label={name}
              showFunc={(e) => handlerClickMethod(e, name)}
              img={'#sync_alt'}
              classes={'button-sync'}
            />
          ))}
      </div>
      <ModalPortal showModal={showError}>
        <CSSTransition in={showError} timeout={500} unmountOnExit classNames="alert">
          <Error
            setShowError={setShowError}
            message={messageError}
            setMessageError={setMessageError}
          />
        </CSSTransition>
      </ModalPortal>
    </>
  );
};

export default ExtensionAddButtons;
