import React from 'react';
import ExtensionNodeTaskParams from '@widgets/Task/ExtensionNodeTaskParams/ExtensionNodeTaskParams';
import OutputParametersTaskParams from '@widgets/Task/OutputParametersTaskParams/OutputParametersTaskParams';

const Extensions = ({ extensions }) => {
  return (
    <>
      <OutputParametersTaskParams filter={extensions} />
      {extensions?.extensions &&
        extensions?.extensions.map((extensionNode) => (
          <div key={extensionNode.id} className="extension extension-node w-100p">
            <ExtensionNodeTaskParams node={extensionNode} />
          </div>
        ))}
    </>
  );
};

export default Extensions;
