import { useFormContext } from 'react-hook-form';
import './SelectBasic.scss';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

const SelectBasic = ({
  labelName,
  inputName,
  defaultValue,
  valid_options,
  handleChangeCallback,
  disabled,
  wrapperClassName,
  inputClassName,
  useFormObjectName,
  isUpdateValue,
}) => {
  const registerName = useFormObjectName ? useFormObjectName + inputName : inputName;
  const [selectValue, setSelectValue] = useState(defaultValue);
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue(`${registerName}`, defaultValue);
    setSelectValue(defaultValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateValue, defaultValue]);

  return (
    <div
      className={classNames('input-wrapper--small-gap ', wrapperClassName ? wrapperClassName : '')}
    >
      {labelName && (
        <label htmlFor={inputName} className="input-wrapper__label" disabled={disabled}>
          {labelName}
        </label>
      )}
      <div disabled={disabled}>
        <select
          id={inputName}
          {...register(registerName)}
          defaultValue={selectValue}
          className={classNames('select-basic ', inputClassName ? inputClassName : '')}
          onChange={(e) => {
            const updateValue = e.target.value;
            register(`${registerName}`).onChange(e);
            setSelectValue(updateValue);
            handleChangeCallback && handleChangeCallback(e);
          }}
        >
          {!defaultValue && <option value=""></option>}
          {valid_options?.map((elem, index) => (
            <option key={elem + '' + index} value={elem.toString()}>
              {elem}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectBasic;
