import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import sprite from '@shared/Images/sprite.svg';
import './SourceBlock.scss';
import { createTaskActions } from '@store/CreateTask';
import { apiAddSection, apiDeleteSection } from '@services/CreateTaskService';
import { onDeleteSource } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';

const SourceBlock = ({ number, titleName, sectionId }) => {
  // console.log(titleName);
  // console.log('titleName', titleName);
  const dispatch = useDispatch();
  const activeBlockTab = useSelector((state) => state.createTask.activeSourceBlock);
  const activeInputTab = useSelector((state) => state.createTask.activeInputTab);
  const arraySources = useSelector((state) => state.createTask.arraySources);
  const currentSource = arraySources.find((elem) => elem.sectionNumber === number);
  // console.log('currentSource', currentSource);
  const currentAddress = currentSource?.parameters
    ?.filter((item) => item.identifier)
    ?.map((elem) => elem.value);
  // console.log('currentAddress', currentAddress);!currentAddress &&

  const showSettings = () => {
    if (activeInputTab === 'Services') {
      dispatch(createTaskActions.setInputTabs('Settings'));
    }
  };

  const onDeleteBlock = (event, number) => {
    const updateArraySources = arraySources.filter((elem) => elem.sectionNumber !== number);
    // console.log('updateArraySources', updateArraySources);
    // console.log('sectionId', sectionId);
    // console.log('arraySources', arraySources);
    event.stopPropagation();

    sectionId
      ? apiDeleteSection(sectionId)
          .then((res) => {
            // console.log('res', res);
            dispatch(createTaskActions.setCleanOutput());
            onDeleteSource(arraySources, number, dispatch);
            dispatch(
              createTaskActions.setActiveSourceBlock(
                updateArraySources ? updateArraySources[0]?.sectionNumber : 0,
              ),
            );
          })
          .catch((err) => {
            console.dir(err);
            dispatch(createTaskActions.setCleanOutput());
            onDeleteSource(arraySources, number, dispatch);
            dispatch(
              createTaskActions.setActiveSourceBlock(
                updateArraySources ? updateArraySources[0]?.sectionNumber : 0,
              ),
            );
          })
      : onDeleteSource(arraySources, number, dispatch);
  };

  const allProgsInSource = currentSource.programs;

  // console.log('allProgsInSource', allProgsInSource);

  const numbersCheckStreams = allProgsInSource
    ?.filter((item) => item.checked)
    .map((elem) => elem.pins.filter((pin) => pin.is_used))
    .flat().length;
  // console.log('numbersCheckStreams', numbersCheckStreams);

  const numbersForInputSources = allProgsInSource?.map((prog) => prog.pins).flat().length;
  // console.log('numbersForInputSources', numbersForInputSources);

  const cloneBlock = (e) => {
    let currentNumber = +e.target?.dataset?.number;

    const currentSource = arraySources.find((source) => source.sectionNumber === currentNumber);
    if (currentSource) {
      let maxSourceNumber = Math.max(...arraySources.map((source) => source.sectionNumber));

      // console.log('currentSource', currentSource);
      apiAddSection(currentSource.selectSource.template, currentSource.selectSource.section).then(
        (res) => {
          let newSectionData = res?.data?.data?.section;

          if (newSectionData) {
            let sourceFilters = newSectionData?.filters;
            let newSectionNumber = maxSourceNumber + 1;
            const newSource = {
              id: newSectionData.id,
              selectSource: currentSource.selectSource,
              sectionNumber: newSectionNumber,
              programs: [],
              sourceFilters: sourceFilters,
              copied: true,
            };
            // console.log('newSource', newSource);
            dispatch(createTaskActions.setCloneSourceBlock(newSource));
            dispatch(createTaskActions.setInputsNumberBlock(newSectionNumber));
            dispatch(createTaskActions.setActiveSourceBlock(newSectionNumber));
            dispatch(createTaskActions.setInputTabs('Settings'));
          }
        },
      );
    }
  };

  return (
    <div
      className={classNames(
        'source-block mb10',
        activeBlockTab === number ? 'source-block--active' : '',
      )}
      onClick={(e) => {
        if (e.target.dataset.target !== 'clone') {
          dispatch(createTaskActions.setActiveSourceBlock(number));
          showSettings();
        }
      }}
    >
      {numbersForInputSources !== 0 && (
        <div
          className="source-block-copy"
          data-number={number}
          data-target="clone"
          onClick={(e) => cloneBlock(e)}
        >
          <svg className="source-block-copy__img" data-target="clone" data-number={number}>
            <use
              className="transform-properties-program__item-pluser"
              href={sprite + '#copy-sign2'}
              data-target="clone"
              data-number={number}
            ></use>
          </svg>
        </div>
      )}
      <div className="source-block-close" onClick={(e) => onDeleteBlock(e, number)}>
        ×
      </div>
      {/* ЗАГОЛОВОК IP-1 */}
      <div className="source-block-title">
        <div className="source-block-title__text"> {`${titleName}-${number}`}</div>
        {currentSource.isReserve && (
          <svg className="source-block-title__img">
            <use
              className="transform-properties-program__item-pluser"
              href={sprite + '#reserve'}
            ></use>
          </svg>
        )}
      </div>

      {/* Количество выбранных стримов */}
      {numbersCheckStreams !== 0 && numbersCheckStreams ? (
        <div className="source-block-outputService">{numbersCheckStreams}</div>
      ) : null}
      {/* Количество полученных стримов */}
      {numbersForInputSources !== 0 && numbersForInputSources ? (
        <div className={`source-block-inputService color-${number}`}>{numbersForInputSources}</div>
      ) : null}

      <div className="source-block-address">
        {currentAddress && currentAddress.length === 1
          ? currentAddress.map((elem) =>
              elem.length > 37 ? `${elem.slice(0, 15)}...${elem.slice(-15)}` : elem,
            )
          : currentAddress && currentAddress.length === 2
          ? `${currentAddress[0]}:${currentAddress[1]}`
          : ''}
      </div>
    </div>
  );
};

export default SourceBlock;
