export const createTaskActionTypes = {
  SET_IS_DELETE_SECTIONS: 'CREATE_TASK.SET_IS_DELETE_SECTIONS',
  // SOURCES //

  SET_ERROR_FROM_REQUEST: 'CREATE_TASK.SET_ERROR_FROM_REQUEST',
  SET_CLEAR_ERROR_REQUEST: 'CREATE_TASK.SET_CLEAR_ERROR_REQUEST',

  SET_ADD_SOURCE: 'CREATE_TASK.SET_ADD_SOURCE',

  SET_DELETE_SOURCE: 'CREATE_TASK.SET_DELETE_SOURCE',

  SET_UPDATE_TRANSFORM_PROGRAM: 'CREATE_TASK.SET_UPDATE_TRANSFORM_PROGRAM',
  SET_UPDATE_RENDER_PINS: 'CREATE_TASK.SET_UPDATE_RENDER_PINS',
  SET_TRANSFORM_APPLY: 'CREATE_TASK.SET_TRANSFORM_APPLY',
  SET_ENCODER_DATA_TRANSFORM: 'CREATE_TASK.SET_ENCODER_DATA_TRANSFORM',
  SET_DELETE_SOURCE_FROM_TRANSFORM: 'CREATE_TASK.SET_DELETE_SOURCE_FROM_TRANSFORM',

  SET_ADD_SOURCE_BLOCK: 'CREATE_TASK.SET_ADD_SOURCE_BLOCK',
  SET_CLONE_SOURCE_BLOCK: 'CREATE_TASK.SET_CLONE_SOURCE_BLOCK',
  SET_UPDATE_SOURCE_BLOCK: 'CREATE_TASK.SET_UPDATE_SOURCE_BLOCK',

  SET_ACTIVE_SOURCE_BLOCK: 'CREATE_TASK.SET_ACTIVE_SOURCE_BLOCK',
  SET_ACTIVE_TRANSFORM_TAB: 'CREATE_TASK.SET_ACTIVE_TRANSFORM_TAB',

  SET_ADD_PROGRAMS_SOURCE_ITEMS: 'CREATE_TASK.SET_ADD_PROGRAMS_SOURCE_ITEMS',
  SET_UPDATE_PROGRAMS_SOURCE_ITEMS: 'CREATE_TASK.SET_UPDATE_PROGRAMS_SOURCE_ITEMS',
  SET_UPDATE_ALL_PROGRAMS_SOURCE_ITEMS: 'CREATE_TASK.SET_UPDATE_ALL_PROGRAMS_SOURCE_ITEMS',
  SET_DELETE_ALL_SOURCE_PROGRAMS: 'CREATE_TASK.SET_DELETE_ALL_SOURCE_PROGRAMS',

  SET_DELETE_PROGRAMS_SOURCE_ITEMS: 'CREATE_TASK.SET_DELETE_PROGRAMS_SOURCE_ITEMS',

  SET_SOURCE_LIST_TO_PIN: 'CREATE_TASK.SET_SOURCE_LIST_TO_PIN',

  SET_INPUTS_NUMBER_BLOCK: 'CREATE_TASK.SET_INPUTS_NUMBER_BLOCK',
  SET_OUTPUTS_NUMBER_BLOCK: 'CREATE_TASK.SET_OUTPUTS_NUMBER_BLOCK',

  SET_MODAL_OPEN_TRANSFORM_SECTION: 'CREATE_TASK.SET_MODAL_OPEN_TRANSFORM_SECTION',

  SET_ADD_OUTPUT: 'CREATE_TASK.SET_ADD_OUTPUT',
  SET_DELETE_OUTPUT: 'CREATE_TASK.SET_DELETE_OUTPUT',
  SET_UPDATE_OUTPUT: 'CREATE_TASK.SET_UPDATE_OUTPUT',
  SET_EXCHANGE_OUTPUT: 'CREATE_TASK.SET_EXCHANGE_OUTPUT',
  SET_CLEAN_OUTPUT: 'CREATE_TASK.SET_CLEAN_OUTPUT',

  SET_UPDATE_SECTION: 'CREATE_TASK.SET_UPDATE_SECTION',

  SET_ADD_OUTPUT_BLOCK: 'CREATE_TASK. SET_ADD_OUTPUT_BLOCK',
  SET_DELETE_OUTPUT_BLOCK: 'CREATE_TASK.SET_DELETE_OUTPUT_BLOCK',

  SET_ADD_OUTPUT_SECTION: 'CREATE_TASK.SET_ADD_OUTPUT_SECTION',
  SET_DELETE_OUTPUT_SECTION: 'CREATE_TASK.SET_DELETE_OUTPUT_SECTION',

  SET_ADD_OUTPUT_ADDRESS: 'CREATE_TASK.SET_ADD_OUTPUT_ADDRESS',
  SET_ADD_COMPONENT_OUTPUT_ADDRESS: 'CREATE_TASK.SET_ADD_COMPONENT_OUTPUT_ADDRESS',

  SET_ADD_AVAILABLE_ITEMS: 'CREATE_TASK.SET_ADD_AVAILABLE_ITEMS',
  SET_ADD_UNIQE_STREAMS: 'CREATE_TASK.SET_ADD_UNIQE_STREAMS',
  SET_ADD_OUTPUT_PROGRAMS_ITEMS: 'CREATE_TASK.SET_ADD_OUTPUT_PROGRAMS_ITEMS',

  SET_ADD_COMPONENT_OUTPUT_ITEMS: 'CREATE_TASK.SET_ADD_COMPONENT_OUTPUT_ITEMS',

  SET_CLEAR_CREATE_COMPONENT: 'CREATE_TASK.SET_CLEAR_CREATE_COMPONENT',

  SET_SELECTED_STREAMS_NUMBER: 'CREATE_TASK.SET_SELECTED_STREAMS_NUMBER',

  SET_OUT_TABS: 'CREATE_TASK.SET_OUT_TABS',
  SET_INPUT_TABS: 'CREATE_TASK.SET_INPUT_TABS',
  SET_ADD_ID_WITH_ERROR: 'CREATE_TASK.SET_ADD_ID_WITH_ERROR',
  SET_DELETE_ID_WITH_ERROR: 'CREATE_TASK.SET_DELETE_ID_WITH_ERROR',
};

export const createTaskActions = {
  setIsDeleteSections: (payload) => ({
    type: createTaskActionTypes.SET_IS_DELETE_SECTIONS,
    payload,
  }),

  setErrorFromRequest: (payload) => ({
    type: createTaskActionTypes.SET_ERROR_FROM_REQUEST,
    payload,
  }),

  setClearErrorRequest: (payload) => ({
    type: createTaskActionTypes.SET_CLEAR_ERROR_REQUEST,
    payload,
  }),

  // SOURCE //

  setAddSource: (payload) => ({
    type: createTaskActionTypes.SET_ADD_SOURCE,
    payload,
  }),

  setDeleteSource: (payload) => ({
    type: createTaskActionTypes.SET_DELETE_SOURCE,
    payload,
  }),

  // ENDSOURCE //

  // SOURCE BLOCKS //

  setAddSourceBlock: (selectSource, number, sourceFilters) => ({
    type: createTaskActionTypes.SET_ADD_SOURCE_BLOCK,
    selectSource,
    number,
    sourceFilters,
  }),

  setUpdateSourceBlock: (id, number, sourceFilters) => ({
    type: createTaskActionTypes.SET_UPDATE_SOURCE_BLOCK,
    id,
    number,
    sourceFilters,
  }),

  setCloneSourceBlock: (payload) => ({
    type: createTaskActionTypes.SET_CLONE_SOURCE_BLOCK,
    payload,
  }),

  // END SOURCE BLOCKS //

  // TRANSFORM //

  setTransformApply: (payload) => ({
    type: createTaskActionTypes.SET_TRANSFORM_APPLY,
    payload,
  }),

  setUpdateTransformProgram: (sectionId, programNum, pinsData) => ({
    type: createTaskActionTypes.SET_UPDATE_TRANSFORM_PROGRAM,
    sectionId,
    programNum,
    pinsData,
  }),

  setUpdateRenderPins: (payload) => ({
    type: createTaskActionTypes.SET_UPDATE_RENDER_PINS,
    payload,
  }),

  setEncoderDataTransform: (index, isUsed) => ({
    type: createTaskActionTypes.SET_ENCODER_DATA_TRANSFORM,
    index,
    isUsed,
  }),

  setDeleteSourceFromTransform: () => ({
    type: createTaskActionTypes.SET_DELETE_SOURCE_FROM_TRANSFORM,
  }),

  // END TRANSFORM //

  setAddProgramsSourceItems: (programs, id) => ({
    type: createTaskActionTypes.SET_ADD_PROGRAMS_SOURCE_ITEMS,
    programs,
    id,
  }),

  setUpdateProgramsSourceItems: (programs, progNum, id) => ({
    type: createTaskActionTypes.SET_UPDATE_PROGRAMS_SOURCE_ITEMS,
    programs,
    progNum,
    id,
  }),

  setUpdateAllProgramsSourceItems: (programs) => ({
    type: createTaskActionTypes.SET_UPDATE_ALL_PROGRAMS_SOURCE_ITEMS,
    programs,
  }),

  setDeleteAllSourceProgs: () => ({
    type: createTaskActionTypes.SET_DELETE_ALL_SOURCE_PROGRAMS,
  }),

  setDeleteProgramsSourceItems: (payload) => ({
    type: createTaskActionTypes.SET_DELETE_PROGRAMS_SOURCE_ITEMS,
    payload,
  }),

  setSourceListToPin: (payload) => ({
    type: createTaskActionTypes.SET_SOURCE_LIST_TO_PIN,
    payload,
  }),

  setActiveSourceBlock: (payload) => ({
    type: createTaskActionTypes.SET_ACTIVE_SOURCE_BLOCK,
    payload,
  }),

  setActiveTransformTab: (payload) => ({
    type: createTaskActionTypes.SET_ACTIVE_TRANSFORM_TAB,
    payload,
  }),

  setInputsNumberBlock: (payload) => ({
    type: createTaskActionTypes.SET_INPUTS_NUMBER_BLOCK,
    payload,
  }),

  setOutputsNumberBlock: (payload) => ({
    type: createTaskActionTypes.SET_OUTPUTS_NUMBER_BLOCK,
    payload,
  }),

  setModalOpenTransformSection: (payload) => ({
    type: createTaskActionTypes.SET_MODAL_OPEN_TRANSFORM_SECTION,
    payload,
  }),
  // <OUTPUT> //
  setAddOutput: (payload) => ({
    type: createTaskActionTypes.SET_ADD_OUTPUT,
    payload,
  }),

  setUpdateOutput: (addData, deleteData) => ({
    type: createTaskActionTypes.SET_UPDATE_OUTPUT,
    addData,
    deleteData,
  }),

  setExchangeOutput: (payload) => ({
    type: createTaskActionTypes.SET_EXCHANGE_OUTPUT,
    payload,
  }),

  setCleanOutput: (payload) => ({
    type: createTaskActionTypes.SET_CLEAN_OUTPUT,
    payload,
  }),

  setDeleteOutput: (payload) => ({
    type: createTaskActionTypes.SET_DELETE_OUTPUT,
    payload,
  }),
  // </OUTPUT> //
  setUpdateSection: (addMuxProgs, addPins, arrChangedParams) => ({
    type: createTaskActionTypes.SET_UPDATE_SECTION,
    addMuxProgs,
    addPins,
    arrChangedParams,
  }),

  setSelectedStreamsNumber: (payload, id) => ({
    type: createTaskActionTypes.SET_SELECTED_STREAMS_NUMBER,
    payload,
    id,
  }),

  setAddOutputBlock: (payload) => ({
    type: createTaskActionTypes.SET_ADD_OUTPUT_BLOCK,
    payload,
  }),

  setDeleteOutputBlock: (payload) => ({
    type: createTaskActionTypes.SET_DELETE_OUTPUT_BLOCK,
    payload,
  }),

  setAddOutputSection: (payload) => ({
    type: createTaskActionTypes.SET_ADD_OUTPUT_SECTION,
    payload,
  }),

  setDeleteOutputSection: (payload) => ({
    type: createTaskActionTypes.SET_DELETE_OUTPUT_SECTION,
    payload,
  }),

  setAddOutputAddress: (payload, number) => ({
    type: createTaskActionTypes.SET_ADD_OUTPUT_ADDRESS,
    payload,
    number,
  }),

  setAddComponentOutputAddress: (payload, number) => ({
    type: createTaskActionTypes.SET_ADD_COMPONENT_OUTPUT_ADDRESS,
    payload,
    number,
  }),

  setAddAvailableItems: (payload) => ({
    type: createTaskActionTypes.SET_ADD_AVAILABLE_ITEMS,
    payload,
  }),

  setAddUniqeStreams: (payload, number) => ({
    type: createTaskActionTypes.SET_ADD_UNIQE_STREAMS,
    payload,
    number,
  }),

  setAddOutputProgramsItems: (payload, number) => ({
    type: createTaskActionTypes.SET_ADD_OUTPUT_PROGRAMS_ITEMS,
    payload,
    number,
  }),

  setAddComponentOutputItems: (payload) => ({
    type: createTaskActionTypes.SET_ADD_COMPONENT_OUTPUT_ITEMS,
    payload,
  }),

  setClearCreateComponent: (payload) => ({
    type: createTaskActionTypes.SET_CLEAR_CREATE_COMPONENT,
    payload,
  }),

  setAddIdWithError: (payload) => ({
    type: createTaskActionTypes.SET_ADD_ID_WITH_ERROR,
    payload,
  }),

  setDeleteIdWithError: (payload) => ({
    type: createTaskActionTypes.SET_DELETE_ID_WITH_ERROR,
    payload,
  }),

  setOutTabs: (payload) => ({
    type: createTaskActionTypes.SET_OUT_TABS,
    payload,
  }),
  setInputTabs: (payload) => ({
    type: createTaskActionTypes.SET_INPUT_TABS,
    payload,
  }),
};
