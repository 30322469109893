import classNames from 'classnames';
import React from 'react';
import TransformPin from './TransformPin/TransformPin';

const TransformProgram = ({ program, active }) => {
  return (
    <div
      className={classNames(
        'transform-properties-program',
        active && 'transform-properties-program--active',
      )}
    >
      <form className="transform-properties-form">
        {program?.pins?.map(
          (pin, i) =>
            pin?.is_used && (
              <TransformPin
                key={'transform-pin' + pin.name + pin.index}
                pin={pin}
                sectionId={program.section_id}
                pinRenderIndex={i}
                programNum={program.program}
              />
            ),
        )}
      </form>
    </div>
  );
};

export default TransformProgram;
