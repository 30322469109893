import React from 'react';
import { useDispatch } from 'react-redux';
import { authSettingsActions } from '@store/Authorization';
import { apiLogin } from './api/AuthService';
import s from './Login.module.scss';
import { useTranslation } from 'react-i18next';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";
import { ReactComponent as SvgOpenEyesIcon } from "@images/open-eye.svg";
import { ReactComponent as SvgClosedEyesIcon } from "@images/close-eye.svg";
import { ReactComponent as SvgLoginIcon } from "@images/login.svg";
import { useHistory } from 'react-router';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = React.useState({ mail: '', pass: '' });
  const [error, showError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [hidePass, setHidePass] = React.useState(false);
  const mainForm = React.useRef();
  const history = useHistory()

  const handleChange = (e) => {
    showError(false);
    const { name, value } = e.target;
    setData({ ...data, [name]: value })
  };

  const toggleShowPass = () => {
    setHidePass(!hidePass);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    apiLogin({ username: data.mail, password: data.pass })
      .then((response) => {
        // console.log(response);
        if (response.data.name) {
          const updateAuth = { auth: true, ...response.data };
          const localDataAuth = JSON.stringify(updateAuth);
          dispatch(authSettingsActions.setAuthSettingsLogin(updateAuth));
          localStorage.setItem('authSettings', localDataAuth);
          history.goBack()
        }
      })
      .catch((error) => {
        console.dir(error);
        setErrorMessage(error.message);
        showError(true);
      });
  };

  return (
    <div className={s["login"]}>
      <SvgLoginIcon className={s["login__icon"]} />
      <h2 className={s["login__title"]}>{t('sign_in')}</h2>

      <form ref={mainForm} onSubmit={onSubmit} className={s["login__form"]}>
        <label className={s["login__label"]}>
          <p>{t('username')}</p>
          <div className={s["login__input-wrapper"]}>
            <input
              name="mail"
              className={s["login__input"]}
              type="text"
              onChange={handleChange}
              autoComplete="username"
              required
            />
          </div>
        </label>
        <label className={s["login__label"]}>
          <p>{t('password')}</p>
          <div
            className={s["login__input-wrapper"]}
          >
            <input
              name="pass"
              className={s["login__input"]}
              type={hidePass ? 'text' : 'password'}
              onChange={handleChange}
              autoComplete="current-password"
              required
            />
            {hidePass ? (
              <SvgOpenEyesIcon
                className={s["login__password-eye"]}
                onClick={toggleShowPass}
                data-target="pass"
              />
            ) : (
              <SvgClosedEyesIcon
                className={s["login__password-eye"]}
                onClick={toggleShowPass}
                data-target="pass"
              />
            )}
          </div>
        </label>

        {error && <div className="login__error">{errorMessage}</div>}
        <ButtonBase color={'blue'} type={'submit'} size={'big'}>
          {t('SIGN_IN')}
        </ButtonBase>
      </form>
    </div>
  );
};

export default Login;
