import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TransformProgram from './TransformProgram/TransformProgram';
import './TransformSection.scss';
import TransformTabs from './TransformTabs/TransformTabs';

const TransformSection = ({ arrSources }) => {
  const { t } = useTranslation();
  const activeSourceBlock = useSelector((state) => state.createTask.activeSourceBlock);
  const activeTransformTab = useSelector((state) => state.createTask.activeTransformTab);

  const tabsList = arrSources?.filter((elem) => !elem?.isReserve);

  const isShowTransform =
    arrSources
      ?.filter((elem) => !elem?.isReserve)
      ?.map((elem) => elem?.programs?.map((program) => program?.pins))
      ?.flat(2)
      ?.filter((pin) => pin?.is_used)?.length !== 0;
  // console.log('isShowTransform', isShowTransform);

  return (
    <>
      {isShowTransform && (
        <div className="transform-properties" hidden={activeSourceBlock !== null}>
          <h2 className="transform-properties-title">{t('transform')}</h2>
          <TransformTabs tabsList={tabsList} />
          <div className="transform-properties-block">
            {tabsList.map((elem, idx) =>
              elem?.programs?.map((program, index) => {
                const tab = `tab-${elem.id}-${idx}-${index}`;

                return (
                  program?.checked && (
                    <TransformProgram
                      key={program.program + 'program' + elem.id}
                      program={program}
                      active={activeTransformTab === tab}
                    />
                  )
                );
              }),
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TransformSection;
