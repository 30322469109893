import React from 'react';
import './ForbiddenRow.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ForbiddenRow = () => {
  const { t } = useTranslation();

  return (
    <div className="forbidden-row">
      <p>
        <span className="fw-600">{t('forbidden-row_status')}</span> {t('forbidden-row_text')}
      </p>

      <Link to="/login">
        <span className="forbidden-row__link">{t('forbidden-row_link')}</span>
      </Link>
    </div>
  );
};

export default ForbiddenRow;
