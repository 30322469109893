import classNames from 'classnames';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import GroupPermissions from '@services/GroupSettings';
import Information from '@shared/UI/Warnings/Information/Information';
import './Groups.scss';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';
import { useTranslation } from 'react-i18next';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";

const Groups = ({ groupsData, selectGroup, setSelectGroup, getSecureData, blockAction }) => {
  const { t } = useTranslation();
  const [showAddGroup, setShowAddGroup] = React.useState(false);
  const [showWarnDelete, setShowWarnDelete] = React.useState(false);

  const refDataTable = React.useRef();
  // console.log('selectGroup', selectGroup);

  const showModalAddGroup = () => {
    setShowAddGroup(true);
  };

  const closeModal = () => {
    setShowAddGroup(false);
  };

  const groupName = React.useRef();

  const submitAddGroup = (event) => {
    event.preventDefault();
    const nameGroup = groupName.current.value;
    GroupPermissions.addGroup(nameGroup).then((response) => {
      // console.log(response);
      closeModal();
      getSecureData().then(() => setSelectGroup(response.data.id));
    });
    GroupPermissions.applyUpdate().then((response) => {
      // console.log('apply', response);
    });
    GroupPermissions.refreshSession();
  };

  const deleteGroups = () => {
    GroupPermissions.deleteGroup(selectGroup).then((response) => {
      // console.log(response);
      setSelectGroup('0');
      getSecureData();
    });
    GroupPermissions.applyUpdate().then((response) => {
      // console.log('apply', response);
    });
    GroupPermissions.refreshSession();
  };

  return (
    <div className="home-box__groups">
      {showAddGroup ? (
        <div className="add-group-header">
          <div className="add-group-title">{t('add_group')}</div>
          <form className="add-group-box" onSubmit={submitAddGroup}>
            <input className="add-group-box__input" type="text" required ref={groupName} />
            <div className="groups__buttons">
              <ButtonBase type={'submit'} color={'green'}>
                {t('apply')}
              </ButtonBase>
              <ButtonBase color={'grey'} onClick={closeModal}>
                {t('cancel')}
              </ButtonBase>
            </div>
          </form>
        </div>
      ) : (
        <div className="home-box__groups-header">
          <h2 className="subheader-text">{t('groups')}</h2>
          <div className="groups__buttons">
            <ButtonBase
              color={'green'}
              disabled={blockAction ? true : false}
              onClick={showModalAddGroup}
            >
              {t('add_group')}
            </ButtonBase>
            <ButtonBase
              color={'red'}
              disabled={blockAction ? true : selectGroup === '0' ? true : false}
              onClick={() => setShowWarnDelete(true)}
            >
              {t('delete_group')}
            </ButtonBase>
          </div>
        </div>
      )}

      <div className="table-box">
        {groupsData.length === 0 ? (
          <table className="table">
            <thead className="t-head">
              <tr className="table-row-head ">
                <th className="table-row__head table-row-checkbox"> {t('#')}</th>
                <th className="table-row__head table-row-data">{t('name')}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-row">
                <td className="table-row__body">
                  <p className="table__text">{t('no_groups')}</p>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table className="table">
            <thead className="t-head">
              <tr className="table-row-head ">
                <th className="table-row__head table-row-check">
                  <div className="group-box jc-c">{t('#')}</div>
                </th>
                <th className="table-row__head table-row-data">
                  <div className="group-box">{t('name')}</div>
                </th>
              </tr>
            </thead>
            <tbody ref={refDataTable}>
              {groupsData.map((item, i) => (
                <tr
                  className={classNames(
                    'table-row',
                    selectGroup === item.id ? 'table-row-active' : '',
                  )}
                  key={item.id}
                  data-key={item.id}
                >
                  <td className="table-row__body">
                    <div className="group-box jc-c">{i + 1}</div>
                  </td>
                  <td className="table-row__body">
                    <div className="group-box cur" onClick={() => setSelectGroup(item.id)}>
                      <p
                        className={classNames(
                          'table__text',
                          selectGroup === item.id ? 'select-text' : '',
                        )}
                      >
                        {item.name}
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <ModalPortal showModal={showWarnDelete}>
        <CSSTransition in={showWarnDelete} timeout={500} unmountOnExit classNames="alert">
          <Information
            setShowInform={setShowWarnDelete}
            message={t('delete_selected_group')}
            withFunc={true}
            func={deleteGroups}
          />
        </CSSTransition>
      </ModalPortal>
    </div>
  );
};

export default Groups;
