import $api from '@services/apiAxios';

export const apiRenameSchema = async (sid, schema, newName) => {
  try {
    const res = await $api.post(
      `/encoder/schema/rename?storage_id=${sid}&schema=${schema}&new_name=${newName}`,
    );
    console.dir(res);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data ? res.response.data : res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};
