import classNames from 'classnames';
import React from 'react';
import { apiGetAnnounceList } from '@services/CreateTaskService';
import './Announces.scss';
import { useTranslation } from 'react-i18next';
import { showScroll } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import { useDispatch, useSelector } from 'react-redux';
import { createTaskActions } from '@store/CreateTask';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";
import { ReactComponent as SvgTransformIcon } from "@images/transform.svg";

const Announces = ({
  setShowAnnounce,
  renderSourceFilters,
  setRenderSourceFilters,
  sectionDataId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeSourceBlock = useSelector((state) => state.createTask.activeSourceBlock);
  const [activeAnnounce, setActiveAnnounce] = React.useState(null);
  const [updateData, setUpdateData] = React.useState(null);
  const [renderList, setRenderList] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);

  const getAnnounceData = () => {
    setShowLoader(true);
    apiGetAnnounceList().then((res) => {
      setShowLoader(false);
      // console.log('res', res);
      const announceList = res?.data?.announces;
      setRenderList(announceList ? announceList : []);
      // console.log('announceList', announceList);
    });
  };

  React.useEffect(() => {
    getAnnounceData();
  }, []);

  // console.log('renderList', renderList);
  const selectName = (e) => {
    const idx = e.target.closest('.TRow').dataset.address;
    setActiveAnnounce(+idx);
    const data = renderList.find((elem, i) => i === +idx);
    const clearData = renderSourceFilters?.map((filter) => {
      if (filter.name === 'NWSource' || filter.name === 'NDI Source') {
        return {
          ...filter,
          parameters: filter?.parameters?.map((parameter, index) => {
            let newValue =
              index === 0 ? '' : index === 1 ? '' : index === 3 ? '' : parameter?.value;

            return { ...parameter, value: newValue };
          }),
        };
      } else {
        return filter;
      }
    });

    clearData && setRenderSourceFilters(clearData);
    dispatch(createTaskActions.setUpdateSourceBlock(sectionDataId, activeSourceBlock, clearData));

    const newUpdateData = renderSourceFilters?.map((filter) => {
      if (filter.name === 'NWSource' || filter.name === 'NDI Source') {
        return {
          ...filter,
          parameters: filter?.parameters?.map((parameter, index) => {
            let newValue =
              index === 0
                ? data.McastAddress
                : index === 1
                ? data.Mcast_port
                : index === 3
                ? data.Interface
                : parameter?.value;

            return { ...parameter, value: newValue };
          }),
        };
      } else {
        return filter;
      }
    });
    setUpdateData(newUpdateData);
  };

  const onSubmit = (e) => {
    updateData && setRenderSourceFilters(updateData);
    dispatch(createTaskActions.setUpdateSourceBlock(sectionDataId, activeSourceBlock, updateData));
    showScroll();
    setShowAnnounce(false);
  };

  const handleUpdate = () => {
    getAnnounceData();
  };

  return (
    <div className="overlay">
      <div className="container-announce">
        <div className="container-announce-title">
          {t('announces_list')}
          <div
            className="container-announce-close"
            onClick={() => {
              setShowAnnounce(false);
              showScroll();
            }}
          >
            ×
          </div>
        </div>
        <div className="container-announce-table">
          {renderList.length === 0 ? (
            <table className="table">
              <thead className="thead">
                <tr>
                  <th className="ta-l-15-p">{t('session_name')}</th>
                  <th className="ta-l-15-p">{t('description')}</th>
                  <th className="ta-l-15-p">{t('information')}</th>
                  <th className="ta-r">{t('multicast_group')}</th>
                  <th className="ta-r">
                    <p className="pl-10">{t('port')}</p>
                  </th>
                  <th className="ta-r">{t('server_address')}</th>
                  <th className="ta-r">{t('interface')}</th>
                </tr>
              </thead>
              <tbody className="tbody">
                <tr className={classNames(`TRow cur`)}>
                  <td></td>
                  <td>
                    <div className="clusters-params clusters-params__text">
                      {showLoader ? `${t('loading')}...` : `${t('no_found_announces')}`}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table className="table">
              <thead className="thead">
                <tr>
                  <th className="ta-l-15-p">{t('session_name')}</th>
                  <th className="ta-l-15-p">{t('description')}</th>
                  <th className="ta-l-15-p">{t('information')}</th>
                  <th className="ta-r">{t('multicast_group')}</th>
                  <th className="ta-r">
                    <p className="pl-10">{t('port')}</p>
                  </th>
                  <th className="ta-r">{t('server_address')}</th>
                  <th className="ta-r">{t('interface')}</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {renderList.map((elem, i) => (
                  <tr
                    className={classNames(
                      `TRow cur`,
                      activeAnnounce === i ? 'announce-active' : '',
                    )}
                    key={elem.McastAddress + 'a' + i}
                    data-address={i}
                    onClick={(e) => selectName(e)}
                    onDoubleClick={(e) => {
                      selectName(e);
                      onSubmit();
                    }}
                  >
                    <td>
                      <div className="clusters-params clusters-params__text">
                        {elem.SessionName}
                      </div>
                    </td>
                    <td>
                      <div className="clusters-params clusters-params__text">
                        {elem.Description}
                      </div>
                    </td>
                    <td>
                      <div className="clusters-params clusters-params__text">
                        {elem.SessionInformation}
                      </div>
                    </td>
                    <td>
                      <div className="clusters-params clusters-params--right">
                        {elem.McastAddress}
                      </div>
                    </td>
                    <td>
                      <div className="clusters-params clusters-params--right">
                        {elem.Mcast_port}
                      </div>
                    </td>
                    <td>
                      <div className="clusters-params clusters-params--right">
                        {elem.ServerAddress}
                      </div>
                    </td>
                    <td>
                      <div className="clusters-params clusters-params--right">{elem.Interface}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="announces__actions">
          <ButtonBase
            color={'grey'}
            onClick={handleUpdate}
          >
            <SvgTransformIcon className="announces__update-icon" />
            <p className="announces__update-text">{t('refresh')}</p>
          </ButtonBase>
          <div className="announces__buttons-group">
            <ButtonBase
              color={'green'}
              onClick={onSubmit}
            >
              <span className='announces__button-text'>{t('ok')}</span>
            </ButtonBase>
            <ButtonBase
              color={'grey'}
              onClick={() => {
                setShowAnnounce(false);
                showScroll();
              }}
            >
              <span className='announces__button-text'>{t('cancel')}</span>
            </ButtonBase>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announces;
