import { HasProperty } from '@services/apiService';

function BooleanToYesNo(value) {
  return value ? 'Yes' : 'No';
}

function IsBooleanFalse(param) {
  return (
    param.value === false ||
    (param.value === 0 &&
      param.valid_options?.length > 0 &&
      (param.valid_options[0].meaning.toUpperCase() === 'NO' ||
        param.valid_options[0].meaning.toUpperCase() === 'OFF' ||
        param.valid_options[0].meaning.toUpperCase() === 'FALSE' ||
        param.valid_options[0].meaning.toUpperCase() === 'DISABLE' ||
        param.valid_options[0].meaning.toUpperCase() === 'DISABLED' ||
        param.valid_options[0].meaning.toUpperCase() === 'NONE'))
  );
}

function ValidOptionsAre(param, option1, option2) {
  return (
    param.valid_options?.length === 2 &&
    ((option1.toUpperCase() === param.valid_options[0].meaning.toUpperCase() &&
      option2.toUpperCase() === param.valid_options[1].meaning.toUpperCase()) ||
      (option1.toUpperCase() === param.valid_options[1].meaning.toUpperCase() &&
        option2.toUpperCase() === param.valid_options[0].meaning.toUpperCase()))
  );
}

function IsParamBoolean(param) {
  return (
    typeof param.value == 'boolean' ||
    ValidOptionsAre(param, 'yes', 'no') ||
    ValidOptionsAre(param, 'on', 'off') ||
    ValidOptionsAre(param, 'true', 'false') ||
    ValidOptionsAre(param, 'enabled', 'disabled')
  );
}

export function GetValueWithMeaning(param) {
  let result =
    typeof param.value == 'boolean' ? BooleanToYesNo(param.value) : param.value?.toString();
  if (
    !HasProperty(param, 'valid_options') ||
    !Array.isArray(param.valid_options) ||
    param.valid_options.length === 0
  ) {
    return result;
  }
  param.valid_options.forEach((item) => {
    if (item.value === param.value) {
      result =
        IsParamBoolean(param) ||
        IsBooleanFalse(param) ||
        param.value?.toString() === item.meaning.toString()
          ? item.meaning.toString()
          : param.value?.toString() + ' (' + item.meaning.toString() + ')';
      return;
    }
  });
  return result;
}
