import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createTaskActions } from '@store/CreateTask';
import sprite from '@shared/Images/sprite.svg';
import classNames from 'classnames';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import Announces from './Announces/Announces';
import { apiAddSection, apiRefreshAllProgs, apiSendParams } from '@services/CreateTaskService';
import ViewSourceLink from '@shared/UI/ViewSourceLink/ViewSourceLink';
import {
  hideScroll,
  updatePins,
  updatePinsAfterDelete,
} from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import AnnouncesNDI from './Announces/AnnouncesNDI/AnnouncesNDI';
import { useTranslation } from 'react-i18next';
import { apiGetAllOutputsListForEdit } from '@pages/TaskConfigurator/api/apiTaskConfigurator';
import InputParameters from '@widgets/TaskConfigurator/InputSection/InputParameters/InputParameters';
import Collapse from '@shared/UI/Collapse/Collapse';
import s from './InputAddressSection.module.scss';
import { CSSTransition } from 'react-transition-group';
import MiniInfo from '@shared/UI/Warnings/MiniInfo/MiniInfo';
import { refreshPins } from '@shared/Lib/Utils/TaskConfigurator/apiGeneralTaskConfigurator';
import { generatePinName } from '@shared/Lib/Utils/TaskConfigurator/generalFunctions';
import { SOURCE } from '@shared/Lib/Utils/Constants/Constants';

const InputAddressSection = React.memo(
  ({
    hiddenInputAddressSection,
    number,
    selectSource,
    showLoaderSourceItems,
    setShowLoaderSourceItems,
    isEditTask,
    isShowTabMenu,
  }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const arraySources = useSelector((state) => state.createTask.arraySources);

    const [renderSourceFilters, setRenderSourceFilters] = React.useState([]);

    const [sectionData, setSectionData] = React.useState(null);

    const [showTabMenu, setShowTabMenu] = React.useState(isShowTabMenu ? isShowTabMenu : false);
    const [showError, setShowError] = React.useState(false);
    const [showAnnounce, setShowAnnounce] = React.useState(false);
    const [showAnnounceNDI, setShowAnnounceNDI] = React.useState(false);
    const activeSourceBlock = useSelector((state) => state.createTask.activeSourceBlock);
    const activeInputTab = useSelector((state) => state.createTask.activeInputTab);
    const isDeleteSections = useSelector((state) => state.createTask.isDeleteSections);
    const [showSettingLoader, setShowSettingLoader] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = React.useState(false);

    const getSourceParameters = (template, section) => {
      // console.log('NEWSOURCE');
      if (template && section) {
        if (isEditTask) {
          let newSectionData = arraySources.find((source) => source.sectionNumber === number);

          if (newSectionData?.copy) {
            setShowTabMenu(false);
          }
          let sourceFilters = newSectionData?.sourceFilters;
          setRenderSourceFilters(sourceFilters);
          dispatch(
            createTaskActions.setUpdateSourceBlock(newSectionData.id, number, sourceFilters),
          );
          setSectionData(newSectionData);
        } else {
          setShowSettingLoader(true);

          apiAddSection(template, section)
            .then((res) => {
              let newSectionData = res?.data?.data?.section;
              let sourceFilters = newSectionData?.filters;
              setRenderSourceFilters(sourceFilters);
              dispatch(
                createTaskActions.setUpdateSourceBlock(newSectionData.id, number, sourceFilters),
              );
              setSectionData(newSectionData);
            })
            .finally(() => {
              setShowSettingLoader(false);
            });
        }
      }
    };
    React.useEffect(() => {
      getSourceParameters(selectSource.template, selectSource.section);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
      // console.log('isDeleteSections', isDeleteSections);
      // console.log('activeBlockTab', activeBlockTab);
      // console.log('number', number);
      if (isDeleteSections && activeSourceBlock === number) {
        // console.log('<<<<REFRESH>>>>');
        dispatch(createTaskActions.setIsDeleteSections(false));
        dispatch(createTaskActions.setDeleteAllSourceProgs());
        apiRefreshAllProgs().then((res) => {
          // console.log('res', res);
          updatePinsAfterDelete(dispatch, sectionData, setShowLoaderSourceItems);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleteSections]);

    const methods = useForm({
      mode: 'onBlur',
    });

    const { handleSubmit, resetField } = methods;

    // console.log(`errors`, errors);
    const onSubmit = async (data) => {
      setShowError(false);
      dispatch(createTaskActions.setInputTabs('Services'));
      setShowLoaderSourceItems(true);
      setShowTabMenu(true);

      // param?.guid т к необходим autocomplete для Multicast Group or Unicast Address и Port
      const finishData = Object.keys(data).reduce((obj, key) => {
        if (isNaN(key)) {
          let newKey = renderSourceFilters
            ?.map((filter) => filter?.parameters)
            ?.flat()
            ?.find((parameter) => parameter?.guid === key)?.id;

          return Object.assign(obj, { [newKey]: data[key] });
        } else {
          return Object.assign(obj, { [key]: data[key] });
        }
      }, {});
      apiSendParams(finishData)
        .then((res) => {
          updatePins(dispatch, sectionData, setShowLoaderSourceItems)
            .then((res) => {
              if (res) {
                refreshPins(res, dispatch, number)
                  .then(() => {
                    setShowSuccessModal(true);
                    updateSettingsSection();
                  })
                  .finally(() => {
                    setShowLoaderSourceItems(false);
                  });
              }
            })
            .finally(() => {
              if (showLoaderSourceItems) {
                setShowLoaderSourceItems(false);
              }
            });
        })
        .finally(() => {
          Object.keys(data)?.forEach((key) => {
            // param?.guid т к необходим autocomplete для Multicast Group or Unicast Address и Port
            if (!isNaN(key)) {
              resetField(key);
            }
          });
        });

      if (showLoaderSourceItems) {
        setShowLoaderSourceItems(false);
      }
    };

    // console.log('renderSourceFilters', renderSourceFilters);

    useEffect(() => {
      const currentSource = arraySources.find(
        (source) => source.sectionNumber === activeSourceBlock,
      );

      setRenderSourceFilters(currentSource?.sourceFilters ? currentSource?.sourceFilters : []);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSourceBlock]);

    const updateSettingsSection = () => {
      setShowSettingLoader(true);
      apiGetAllOutputsListForEdit()
        .then((res) => {
          let oldSectionData = arraySources.find(
            (source) => source.sectionNumber === activeSourceBlock,
          );

          let newSectionData = res?.data?.data?.find((source) => {
            return source?.section?.id === oldSectionData?.id;
          })?.section;

          let currentSourceFilters = newSectionData?.filters;
          setRenderSourceFilters(currentSourceFilters);
          dispatch(
            createTaskActions.setUpdateSourceBlock(
              newSectionData?.id,
              number,
              currentSourceFilters,
            ),
          );
          setSectionData(newSectionData);
        })
        .finally(() => {
          setShowSettingLoader(false);
        });
    };

    return (
      <>
        <div className="input-addressSection" hidden={activeSourceBlock === number ? false : true}>
          <div className="output-sections-tabs" hidden={showTabMenu ? false : true}>
            <div
              className={classNames(
                `output-sections-tabs__item`,
                activeInputTab === `Services` ? 'output-sections-tabs__item--active' : '',
              )}
              onClick={() => dispatch(createTaskActions.setInputTabs('Services'))}
            >
              {t('services')}
            </div>
            <div
              className={classNames(
                `output-sections-tabs__item`,
                activeInputTab === `Settings` ? 'output-sections-tabs__item--active' : '',
              )}
              onClick={() => {
                dispatch(createTaskActions.setInputTabs('Settings'));
                // когда будет готово https://redmine.elecard.net.ru/issues/11841
                // надо будет получать адрес source для отображения в source block
                // и надо будет запрашивать apiGetAllOutputsListForEdit() после нажатия на Apply в Source секции

                //  вызываем запрос параметров "Settings" т к необходимо обновить ID параметров для Source секции после reset
                //  https://redmine.elecard.net.ru/issues/11711
                updateSettingsSection();
              }}
            >
              {t('settings')}
            </div>
          </div>

          {showError && (
            <div className="input-properties-error">{t('no_input_streams_detected')}</div>
          )}
          {showLoaderSourceItems && activeInputTab === `Services` && <Loader center={true} />}
          {showSettingLoader && activeInputTab === `Settings` ? (
            <Loader center={true} />
          ) : (
            <FormProvider {...methods}>
              <form
                hidden={
                  hiddenInputAddressSection ? true : activeInputTab === `Services` ? true : false
                }
                className="input-properties input-properties-main"
                onSubmit={handleSubmit(onSubmit)}
              >
                <ViewSourceLink
                  uri={`${window.location.origin}/api/configurator?sections=source`}
                />
                <h2 className="input-properties-title">
                  {t('source')} - {selectSource?.section}
                </h2>

                {renderSourceFilters?.length !== 0 &&
                  renderSourceFilters?.map((filter, index) => (
                    <React.Fragment key={filter.name + 'source filter' + index}>
                      <p>{filter.name}</p>
                      {/*когда будет готово надо будет переделать отображение анонсов  https://redmine.elecard.net.ru/issues/11841 */}
                      {(filter.name === 'NWSource' || filter.name === 'NDI Source') && (
                        <div className="input-properties-announces">
                          <svg
                            className="input-properties-announces__img"
                            onClick={() => {
                              if (filter.name === 'NWSource') {
                                setShowAnnounce(true);
                              }

                              if (filter.name === 'NDI Source') {
                                setShowAnnounceNDI(true);
                              }
                              hideScroll();
                            }}
                          >
                            <use href={sprite + `#list`}></use>
                          </svg>
                        </div>
                      )}

                      {filter?.parameters && (
                        <InputParameters parameters={filter?.parameters} isUseGuid={true} />
                      )}

                      {filter?.pins && (
                        <Collapse
                          text={'Pins'}
                          classNameControl={'output-filters-item ' + s['collapse__text']}
                          classNameButton={'svg-button_dark'}
                        >
                          {filter?.pins?.map((pin, index) => (
                            <React.Fragment key={pin.name + 'pin' + index}>
                              <Collapse
                                text={generatePinName(pin, SOURCE)}
                                classNameControl={'output-filters-item ' + s['collapse__text']}
                                classNameButton={'svg-button_dark'}
                                classNameCollapse={s['collapse_with-padding']}
                              >
                                <div className="output-filters-item-params">
                                  {pin?.parameters && (
                                    <InputParameters parameters={pin.parameters} />
                                  )}
                                </div>
                              </Collapse>
                            </React.Fragment>
                          ))}
                        </Collapse>
                      )}
                    </React.Fragment>
                  ))}

                <div className="input-properties-button">
                  <button type="submit" className="button input-properties-cancel btn-110px m0">
                    {t('apply')}
                  </button>
                </div>
                {/*когда будет готово надо будет переделать отображение анонсов  https://redmine.elecard.net.ru/issues/11841 */}
                {showAnnounce && (
                  <Announces
                    key={`AnnouncesList`}
                    setShowAnnounce={setShowAnnounce}
                    renderSourceFilters={renderSourceFilters}
                    setRenderSourceFilters={setRenderSourceFilters}
                    sectionDataId={sectionData?.id}
                  />
                )}
                {showAnnounceNDI && (
                  <AnnouncesNDI
                    key={`AnnouncesListNDI`}
                    setShowAnnounceNDI={setShowAnnounceNDI}
                    renderSourceFilters={renderSourceFilters}
                    setRenderSourceFilters={setRenderSourceFilters}
                    sectionDataId={sectionData?.id}
                  />
                )}
              </form>
            </FormProvider>
          )}
        </div>
        <CSSTransition in={showSuccessModal} timeout={500} unmountOnExit classNames="alert">
          <MiniInfo setShowInform={setShowSuccessModal} message={'Schema was successfully made'} />
        </CSSTransition>
      </>
    );
  },
);
export default InputAddressSection;
