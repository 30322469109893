import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  consoleStatesName,
  severityLevels,
} from '@widgets/ServerSettings/lib/ServerSettingsDictionary';
import './TaskStates.scss';

const TaskStates = ({ activeSeverityItem, dataState }) => {
  const { t } = useTranslation();
  const { register, watch, setValue } = useFormContext();
  const state = dataState.snmp.console_states;

  React.useEffect(() => {
    state.map((item) => setValue(`enabled-${item.state_type}`, item.enabled));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames('severity-data', activeSeverityItem === 0 ? 'severity-task-show' : '')}
    >
      <div className="severity-task-header hla">
        <div className="severity-task-header__item"> {t('trap_name')}</div>
        <div className="severity-task-header__item"> {t('active_level')}</div>
        <div className="severity-task-header__item"> {t('cleared_level')}</div>
      </div>
      <div className="severity-task-data">
        {state.map((item, i) => (
          <div className="severity-task-data__item" key={item.state_type + i}>
            <div className="taskLog-labelBox">
              <input
                defaultValue={item.enabled}
                className="input-check"
                {...register(`enabled-${item.state_type}`)}
                id={`enabled-${item.state_type}`}
                type="checkbox"
              />
              <label className="redundancy-label" htmlFor={`enabled-${item.state_type}`}>
                {consoleStatesName[item.state_type]}
              </label>
            </div>

            <select
              defaultValue={item.active_level}
              id={`activeLevel-${item.state_type}`}
              className="severity-task-data-list"
              {...register(`activeLevel-${item.state_type}`)}
              disabled={!watch(`enabled-${item.state_type}`)}
            >
              {severityLevels}
            </select>

            <select
              defaultValue={item.cleared_level}
              id={`clearedLevel-${item.state_type}`}
              className="severity-task-data-list"
              {...register(`clearedLevel-${item.state_type}`)}
              disabled={!watch(`enabled-${item.state_type}`)}
            >
              {severityLevels}
            </select>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskStates;
