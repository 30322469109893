import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { apiGetLogData } from '@services/LogService';

const useLogData = (activeTab) => {
  const location = useLocation();
  // console.log('location', location);
  const { storage_id, schema } = useParams();
  // console.log('storage_id', storage_id);
  // console.log('schema', schema);

  const path = location.pathname.includes('/encoder/schema/')
    ? `/encoder/schema/log`
    : location.pathname;

  const isServerLogs = path === '/server/log';

  const queryParams = isServerLogs ? '' : `?storage_id=${storage_id}&schema=${schema}`;
  const showTabs = isServerLogs;

  const [showArchive, setShowArchive] = React.useState(false);
  const [errorLog, setErrorLog] = useState();
  const [logData, setLogData] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    apiGetLogData(path, queryParams, activeTab)
      .then((res) => {
        setLogData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setErrorLog(`${activeTab}'s Log not found`);
        setIsLoading(false);

        console.dir(error);
      });

    return () => {
      setErrorLog(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return {
    location,
    showTabs,
    showArchive,
    path,
    queryParams,
    setIsLoading,
    setErrorLog,
    setShowArchive,
    isLoading,
    errorLog,
    setLogData,
    logData,
  };
};

export default useLogData;
