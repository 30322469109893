import React from 'react';

const useTaskStaticsData = (storage_id, schema) => {
  // State

  // TASKS ACTIVE
  const host = window.location.host;
  const ws = React.useRef(null);
  const refStats = React.useRef();
  const [wsData, setWsData] = React.useState(null);
  const templateSort = ['Source', 'Decode', 'Transform', 'Render'];
  // console.log('wsA.current', wsA.current);
  // console.log('wsData', wsData);
  // console.log('updateActiveData', updateActiveData);
  // console.log('updateInactiveData', updateInactiveData);
  // console.log('ws', ws);

  React.useEffect(() => {
    return () => {
      // console.log('CLOSED SOCKET TASKS STATS');
      ws.current.cwFinal = true;
      ws.current.close();
    };
  }, []);

  React.useEffect(() => {
    // console.log('ws.current', ws.current);
    // readyState===0 - CONNECTING
    // readyState===1 - OPEN
    // readyState===2 - CLOSING
    // readyState===3 - CLOSED
    if (ws.current === null || ws.current.readyState === 2 || ws.current.readyState === 3) {
      startWS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws.current]);

  React.useEffect(() => {
    // console.log('ws', ws.current);
    ws.current?.readyState === 1 && ws.current.close();
    ws.current?.readyState > 1 && startWS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema]);

  const startWS = () => {
    ws.current = new WebSocket(
      `ws://${host}/api/encoder/schema/statistics?storage_id=${storage_id}&schema=${schema}`,
    );
    ws.current.onopen = () => {
      ws.current.send(`/api/encoder/schema/statistics?storage_id=${storage_id}&schema=${schema}`);
      // console.log('Соединение открыто');
    };
    ws.current.onclose = () => {
      if (!ws.current.cwFinal) {
        setTimeout(startWS, 3000);
      }
      // console.log('Соединение закрыто');
    };
    ws.current.onerror = (event) => {
      console.dir(event);
    };
    gettingData();
  };

  const gettingData = () => {
    ws.current.onmessage = (e) => {
      if (e.data === 'OK') {
        return;
      }
      const message = JSON.parse(e.data);
      setWsData(message?.data?.data?.sections ? message.data.data?.sections : []);
    };
  };

  const updateStatsData = templateSort.map((el) => ({
    name: el,
    data: wsData?.filter((item) => item.type === el),
  }));
  // console.log('updateStatsData', updateStatsData);

  return {
    wsData,
    updateStatsData,
    refStats,
  };
};

export default useTaskStaticsData;
