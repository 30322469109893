import React, { useEffect } from 'react';
import { parameterCheckMeaning } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

const SelectBase = ({ name, value, valid_options, className, disabled }) => {
  const [paramValue, setParamValue] = useState(value);
  const handleChange = (e) => {
    setParamValue(e.target.value);
  };
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setParamValue(value);
    setValue(`${name}`, value.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <select
      {...register(`${name}`)}
      value={paramValue}
      onChange={handleChange}
      className={className ? className : ''}
      disabled={disabled}
    >
      {valid_options?.map((elem, index) => (
        <option key={elem.meaning + index} value={elem.value.toString()}>
          {parameterCheckMeaning(elem)}
        </option>
      ))}
    </select>
  );
};

export default SelectBase;
