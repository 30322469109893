import React from 'react';
import {
  apiPrepareTasks,
  apiRestartTasks,
  apiStartTasks,
  apiStopTasks,
} from '@services/TaskService';

const useServerData = ({
  setShowMiniLoader,
  setManyMessageError,
  setShowManyError,
  setMessage,
  setShowInform,
}) => {
  // State

  // TASKS ACTIVE

  const host = window.location.host;
  const wsA = React.useRef(null);
  const [wsData, setWsData] = React.useState(null);
  const [activeTasksData, setActiveTasksData] = React.useState(null);
  const [updateActiveData, setUpdateActiveData] = React.useState(null);
  const [updateInactiveData, setUpdateInactiveData] = React.useState(null);
  // console.log('wsA.current', wsA.current);
  // console.log('wsData', wsData);
  // console.log('updateActiveData', updateActiveData);
  // console.log('updateInactiveData', updateInactiveData);
  // console.log('ws', ws);

  React.useEffect(() => {
    if (wsData) {
      setUpdateActiveData(
        wsData
          .filter((el) => el.graph.state === 2)
          .map((elem) => ({
            ...elem,
            checked: updateActiveData?.find((item) => item.graph.name === elem.graph.name)?.checked
              ? updateActiveData?.find((item) => item.graph.name === elem.graph.name)?.checked
              : false,
          })),
      );
      setUpdateInactiveData(
        wsData
          .filter((el) => el.graph.state !== 2)
          .map((elem) => ({
            ...elem,
            checked: updateInactiveData?.find((item) => item.graph.name === elem.graph.name)
              ?.checked
              ? updateInactiveData?.find((item) => item.graph.name === elem.graph.name)?.checked
              : false,
          })),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsData]);

  React.useEffect(() => {
    return () => {
      // console.log('CLOSED SOCKET TASKS DATA');
      wsA.current.cwFinal = true;
      wsA.current.close();
    };
  }, []);

  React.useEffect(() => {
    // console.log('wsA.current', wsA.current);
    // readyState===0 - CONNECTING
    // readyState===1 - OPEN
    // readyState===2 - CLOSING
    // readyState===3 - CLOSED
    if (wsA.current === null || wsA.current.readyState === 2 || wsA.current.readyState === 3) {
      startWS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsA.current]);

  const startWS = () => {
    wsA.current = new WebSocket(`ws://${host}/api/encoder/schema/list`);
    wsA.current.onopen = () => {
      wsA.current.send('/api/encoder/schema/list');
      // console.log('Соединение открыто');
    };
    wsA.current.onclose = () => {
      if (!wsA.current.cwFinal) {
        setTimeout(startWS, 3000);
      }
      // console.log('Соединение закрыто');
    };
    wsA.current.onerror = (event) => {
      console.dir(event);
    };
    gettingData();
  };

  const gettingData = () => {
    wsA.current.onmessage = (e) => {
      if (e.data === 'OK') {
        return;
      }
      const message = JSON.parse(e.data);
      setWsData(message?.data?.data?.data_tasks ? message.data.data.data_tasks : []);
      setActiveTasksData(message?.data?.data?.task_usage ? message.data.data.task_usage : []);
    };
  };

  // State ACTIVE checkboxes

  const [mainCheckActive, setMainCheckActive] = React.useState(false);

  const checkedActive = updateActiveData
    ? updateActiveData.filter((el) => el.checked === true)
    : [];

  // Выделение общего чекбокса
  const setAllCheckedActive = () => {
    const updateCheckedItems = updateActiveData.map((item) => ({
      ...item,
      checked: !mainCheckActive,
    }));
    setMainCheckActive(!mainCheckActive);
    setUpdateActiveData(updateCheckedItems);
  };

  // Выделение индивидуального чекбокса
  const updateCheckboxActive = (id) => {
    const updateCheckedItems = updateActiveData.map((item) => ({
      ...item,
      checked: item.id === id ? !item.checked : item.checked,
    }));
    const numChecked = updateCheckedItems.filter((el) => el.checked === true).length;
    setMainCheckActive(numChecked === updateCheckedItems.length);
    setUpdateActiveData(updateCheckedItems);
  };

  // State INACTIVE checkboxes

  const [mainCheckInactive, setMainCheckInactive] = React.useState(false);

  const checkedInactive = updateInactiveData
    ? updateInactiveData.filter((el) => el.checked === true)
    : [];

  // Выделение общего чекбокса
  const setAllCheckedInactive = () => {
    const updateCheckedItems = updateInactiveData.map((item) => ({
      ...item,
      checked: !mainCheckInactive,
    }));
    setMainCheckInactive(!mainCheckInactive);
    setUpdateInactiveData(updateCheckedItems);
  };

  // Выделение индивидуального чекбокса
  const updateCheckboxInactive = (name) => {
    const updateCheckedItems = updateInactiveData.map((item) => ({
      ...item,
      checked: item.graph.name === name ? !item.checked : item.checked,
    }));
    const numChecked = updateCheckedItems.filter((el) => el.checked === true).length;
    setMainCheckInactive(numChecked === updateCheckedItems.length);
    setUpdateInactiveData(updateCheckedItems);
  };

  // Отмена выделения
  const clearCheckState = () => {
    setUpdateActiveData(updateActiveData.map((el) => ({ ...el, checked: false })));
    setUpdateInactiveData(updateInactiveData.map((el) => ({ ...el, checked: false })));
    setMainCheckActive(false);
    setMainCheckInactive(false);
  };

  // Действия над задачами в Row

  // Старт задачи
  const TaskStart = (id, name, environment) => {
    // console.log('name', name);
    setShowMiniLoader(true);
    apiStartTasks(id, name, environment)
      .then(function (res) {
        // console.dir(res);
        if (res?.status === 200) {
          setShowMiniLoader(false);
          setMessage('Success');
          setShowInform(true);
        }
      })
      .catch((err) => {
        setManyMessageError([{ name: name, errorMessage: err.message }]);
        setShowManyError(true);
        setShowMiniLoader(false);
      });
  };

  // Стоп задачи
  const TaskStop = (name, id) => {
    // console.log('links', links);
    // console.log('elem', elem);
    setShowMiniLoader(true);
    apiStopTasks(id, name)
      .then(function (res) {
        // console.dir(res);
        if (res?.status === 200) {
          setShowMiniLoader(false);
          setMessage('Success');
          setShowInform(true);
        }
      })
      .catch((err) => {
        setManyMessageError([{ name: name, errorMessage: err.message }]);
        setShowManyError(true);
        setShowMiniLoader(false);
      });
  };

  // Рестарт задачи
  const TaskRestart = (elem) => {
    // console.log('id', elem.id);
    // console.log('elem', elem);
    setShowMiniLoader(true);
    apiRestartTasks(elem.id)
      .then(() => {
        // console.log(res);
        setShowMiniLoader(false);
        setMessage('Success');
        setShowInform(true);
      })
      .catch((err) => {
        setManyMessageError([{ name: elem.graph.name, errorMessage: err.message }]);
        setShowManyError(true);
        setShowMiniLoader(false);
      });
  };

  // Подготовка задачи
  const TaskPrepare = (id, name) => {
    // console.log('restart');
    setShowMiniLoader(true);
    apiPrepareTasks(id, name)
      .then(() => {
        // console.log(res);
        setShowMiniLoader(false);
        setMessage('Success');
        setShowInform(true);
      })
      .catch((err) => {
        setManyMessageError([{ name: name, errorMessage: err.message }]);
        setShowManyError(true);
        setShowMiniLoader(false);
      });
  };

  return {
    updateActiveData,
    mainCheckActive,
    checkedActive,
    setAllCheckedActive,
    updateCheckboxActive,

    updateInactiveData,
    mainCheckInactive,
    checkedInactive,
    setAllCheckedInactive,
    updateCheckboxInactive,
    clearCheckState,

    TaskStart,
    TaskPrepare,
    TaskRestart,
    TaskStop,

    activeTasksData,
  };
};

export default useServerData;
