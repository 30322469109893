import React from 'react';
import { useTranslation } from 'react-i18next';
import ServerListData from '@widgets/ServerList/ServerListData/ServerListData';
import './ServerList.scss';

const ServerList = () => {
  const { t } = useTranslation();
  return (
    <div className="server-list">
      <h2 className="server-list-title">{t('web_servers')}</h2>
      <ServerListData />
    </div>
  );
};

export default ServerList;
