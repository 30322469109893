import React, { useState, useEffect } from 'react';
import s from './HeaderSaveName.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@shared/UI/Buttons/ButtonBase/ButtonBase';
import { FORBIDDEN_SYMBOLS_REGEX, FILE_NAME_REGEX } from '@shared/Lib/Utils/Constants/Constants';
import { useForm } from 'react-hook-form';
import TextTooltip from '@shared/UI/TextTooltip/TextTooltip';

const HeaderSaveName = ({
  titleName,
  inputLabel,
  inputPlaceHolder,
  handleClickButton,
  isValidOutside,
  className,
  disabled,
}) => {
  const { t } = useTranslation();
  const taskNameField = 'task-name';

  const methods = useForm();
  const { handleSubmit, register, setValue } = methods;
  const [isValid, setIsValid] = useState({ status: true, error: '' });

  const handleChangeName = (e) => {
    const {
      validationMessage,
      validity: { valid },
      value,
      name,
    } = e.target;
    setValue(name, value.replace(/^\s+/, ''));
    if (valid) {
      if (FORBIDDEN_SYMBOLS_REGEX.test(value)) {
        setIsValid({ status: false, error: t('forbidden_symbols_error_message') });
      } else if (!FILE_NAME_REGEX.test(value)) {
        setIsValid({ status: false, error: t('one_character_error_message') });
      } else {
        setIsValid({ status: true, error: '' });
      }
    } else {
      setIsValid({ status: false, error: validationMessage });
    }
  };

  const onSubmitNewTask = async (data) => {
    const schemaName = data[taskNameField].trim();
    handleClickButton(schemaName);
  };

  useEffect(() => {
    setIsValid(isValidOutside);
  }, [isValidOutside]);

  return (
    <div className={classNames(s['header-save-name'], className)}>
      <div className={s['header-save-name__title']}>{titleName}</div>
      <form>
        <div className={s['header-save-name__name-block']}>
          <label className={s['header-save-name__name-block__title']}>{inputLabel}</label>
          <input
            className={s['header-save-name__name-block__input']}
            defaultValue={'default'}
            {...register(taskNameField)}
            placeholder={inputPlaceHolder}
            type="text"
            required
            onChange={handleChangeName}
          />
          <TextTooltip
            isOpen={isValid?.error?.length > 0}
            text={isValid?.error}
            classNamePosition={s['header-save-name__name-block__text-tooltip']}
          />
          <ButtonBase
            color={'green'}
            disabled={!!isValid?.error || !!isValidOutside?.error || disabled}
            onClick={handleSubmit(onSubmitNewTask)}
          >
            {t('save_exit')}
          </ButtonBase>
        </div>
      </form>
    </div>
  );
};

export default HeaderSaveName;
