import classNames from 'classnames';
import TaskParamRow from './TaskParamRow/TaskParamRow';
import React from 'react';
import s from './TaskSection.module.scss';
import Collapse from '@shared/UI/Collapse/Collapse';

const TaskSection = ({ section }) => {
  //console.log('section', section);
  let error = section?.filters?.some((filter) =>
    filter?.parameters?.some((parameter) => parameter.error),
  );
  return (
    <>
      <ul className={classNames(s['sections-names'], error && s['sections-names_error'])}>
        <Collapse
          isShowFirst='true'
          text={section.name}
          classNameCollapse={classNames(s['sections-names__info'])}
          classNameButton={classNames(s['sections-names__item-btn'])}
          classNameControl={classNames(s['sections-names__item'])}
          classNameText={classNames(s['sections-names__item-title'], error && s['sections-names__item-title_error'])}
        >
          <li
            className={classNames(
              s['sections-filters'],
              section.type === 'Transform' ? s['sections-filters--transform'] : '',
            )}
          >
            {section?.filters?.map((filter) => (
              <ul
                key={filter.name + filter.id}
                className={classNames(
                  s['sections-filters-block'],
                  section.type === 'Transform' ? s['sections-filters-block--transform'] : '',
                )}
              >
                <div className={classNames(s['sections-filters__name'], 'fw-500')}>{filter.name}</div>
                <li className={s['sections-filters-params']}>
                  {filter.parameters?.map((param, i) => (
                    <TaskParamRow key={param.guid} param={param} index={i} />
                  ))}

                  {filter?.pins?.map((pin, i) => (
                    <div className={classNames(s['sections-filters-params'], 'pl-5')} key={pin + i}>
                      <p className={s['sections-filters__name']}>{pin.name}</p>
                      {pin?.parameters?.map((param, i) => (
                        <TaskParamRow key={param.guid} param={param} index={i} />
                      ))}
                    </div>
                  ))}
                </li>
              </ul>
            ))}
          </li>
        </Collapse>
      </ul>
    </>
  );
};
export default TaskSection;
