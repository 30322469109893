import React, { useState } from 'react';
import s from './Collapse.module.scss';
import ArrowDown from '@shared/UI/ArrowDown/ArrowDown';
import classNames from 'classnames';

const Collapse = ({
  children,
  isShowFirst,
  text,
  classNameButton,
  classNameControl,
  classNameText,
  classNameCollapse,
  isFullWidth,
  ComponentBeforeText,
}) => {
  const [isRotateToggle, setIsRotateToggle] = useState(isShowFirst);
  const toggleCollapseInfo = () => {
    setIsRotateToggle(!isRotateToggle);
  };

  return (
    <div className={classNames(s['collapse'], classNameCollapse)} data-testid="collapse">
      <div
        className={classNames(
          s['collapse__control'],
          isFullWidth && s['collapse__control_full-width'],
          classNameControl,
        )}
        onClick={toggleCollapseInfo}
        data-testid="collapse-control"
      >
        {ComponentBeforeText}
        <p
          className={classNames(s['collapse__text'], 'overflow-text_dots', classNameText)}
          data-testid="collapse-text"
        >
          {text}
        </p>
        {children && <ArrowDown isRotate={isRotateToggle} classNameButton={classNameButton} />}
      </div>

      <div
        className={classNames(
          s['collapse__parameters'],
          !isRotateToggle && s['collapse__parameters_hidden'],
        )}
        data-testid="collapse-children"
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
