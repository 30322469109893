import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { createTaskActions } from '@store/CreateTask';
import './OutputBlock.scss';
import Cookies from 'js-cookie';

const OutputBlock = ({ number, titleName, sectionData }) => {
  const dispatch = useDispatch();
  const [numberInputItems, setNumberInputItems] = useState(0);

  const activeBlockTab = useSelector((state) => state.createTask.activeSourceBlock);
  const errorCreateTaskId = useSelector((state) => state.createTask.errorCreateTaskId);
  const addrInfo = sectionData.filters.find((filter) => filter.parameters).parameters;
  const sectionId = sectionData.id;
  // console.log('number', number);

  const transformPrograms = useSelector((state) => state.createTask.arrayOutputs).filter(
    (item) => item.id === sectionId,
  );
  // console.log('transformPrograms', transformPrograms);
  // console.log('sectionData', sectionData);

  useEffect(() => {
    let modifier = Cookies.get('CW_CONFIGURATOR');
    let isMuxer = transformPrograms
      ?.map((item) => item.filters)
      ?.flat()
      ?.find((elem) => elem.is_special === 'muxer');
    const numberInputItems =
      transformPrograms.length > 0
        ? isMuxer && modifier === 'special'
          ? transformPrograms
              .map((item) => item.filters)
              .flat()
              .find((elem) => elem.is_special === 'muxer')
              ?.extensions?.extensions?.find((elem) => elem.name === 'MuxPrograms')
              ?.extensions?.reduce((allPins, currentPins) => {
                return currentPins?.pins ? [...allPins, ...currentPins?.pins] : [...allPins];
              }, []).length
          : transformPrograms
              .map((item) => item.filters)
              .flat()
              .map((prog) => (prog.pins ? prog.pins : []))
              .flat().length
        : 0;
    setNumberInputItems(!numberInputItems ? 0 : numberInputItems);
  }, [transformPrograms]);

  return (
    <div
      className={classNames(
        'output-block mb10',
        activeBlockTab === number ? 'output-block--active' : '',
        errorCreateTaskId.some((elem) => elem === number) ? 'output-block--error' : '',
      )}
      onClick={() => {
        dispatch(createTaskActions.setActiveSourceBlock(number));
      }}
    >
      <div className="output-block-title">{titleName}</div>
      {numberInputItems !== 0 ? (
        <div className="source-block-inputService">{numberInputItems}</div>
      ) : null}
      {numberInputItems !== 0 ? (
        <div className="source-block-outputService">{numberInputItems}</div>
      ) : null}
      <div className="output-block-address">
        {addrInfo && addrInfo.length === 1
          ? addrInfo.map((elem) =>
              elem.value.length > 37
                ? `${elem.value.slice(0, 15)}...${elem.value.slice(-15)}`
                : elem.value,
            )
          : addrInfo && addrInfo.length === 2
          ? `${addrInfo[0].value}:${addrInfo[1].value}`
          : ''}
      </div>
    </div>
  );
};

export default OutputBlock;
