import React from 'react';
import { useEffect, useState } from 'react';
import { apiGetArchiveList, apiGetArchiveLog, apiGetLogData } from '@services/LogService';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import ErrorText from '@shared/UI/Errors/ErrorText/ErrorText';
import s from './LogArchive.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from "classnames";

const LogArchive = ({ setIsLoadingData, path, setErrorLogData, queryParams, activeTab, setArchiveLogData }) => {
  const [archiveList, setArchiveList] = useState([]);
  const [activeArchiveTab, setActiveArchiveTab] = useState('current');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);

    apiGetArchiveList(path, activeTab, queryParams)
      .then((res) => {
        let archiveList = res?.data?.data;
        archiveList.sort((a, b) => (a.time > b.time ? -1 : 1));
        setArchiveList(archiveList);
        archiveList.length === 0 && setError(`${activeTab}'s Archive List not found`);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(`${activeTab}'s Archive List not found`);
        setIsLoading(false);
        console.dir(error);
      });
    return () => {
      setError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const changeLog = (elem) => {
    setActiveArchiveTab(elem.name)
    setIsLoadingData(true);
    setErrorLogData(null);

    apiGetArchiveLog(path, activeTab, queryParams, elem?.name)
      .then((res) => {
        setArchiveLogData(res.data);
        setIsLoadingData(false);
      })
      .catch((error) => {
        setErrorLogData(`"${elem?.time}:" Archive Log not found`);
        setIsLoadingData(false);
        console.dir(error);
      });
    // console.log(elem);
  };

  const changeLogCurrent = () => {
    setActiveArchiveTab('current');
    setIsLoadingData(true);
    setErrorLogData(null);

    apiGetLogData(path, queryParams, activeTab)
      .then((res) => {
        setArchiveLogData(res.data);
        setIsLoadingData(false);
        //console.log(res.data);
      })
      .catch((error) => {
        setErrorLogData(`${activeTab}'s Log not found`);
        setIsLoadingData(false);
        console.dir(error);
      });
  };

  return (
    <div className={s['log-archive']}>
      <h4 className={s['log-archive__title']}>{t('archive')}</h4>
      {isLoading ? (
        <Loader top={true} />
      ) : error ? (
        <ErrorText message={error} />
      ) : (
        <>
          <div
            className={classNames(s['log-archive__item'], activeArchiveTab === 'current' && s['log-archive__item_active'])}
            onClick={() => changeLogCurrent()}
          >
            {t('current_log')}
          </div>
          {archiveList.map((elem) => (
            <div
              className={classNames(s['log-archive__item'], activeArchiveTab === elem.name && s['log-archive__item_active'])}
              key={elem.name}
              onClick={(e) => changeLog(elem)}
            >
              <span>{elem.time}</span>
              <span>{(elem.size / 1000).toFixed(1)}{t('kB')}</span>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default LogArchive;
