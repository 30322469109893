import $api from '@services/apiAxios';

export const getConsoleById = async (id) => {
  try {
    const res = await $api.get(`/server/config?just_console=${id}`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const setConsoleById = async (id, data) => {
  try {
    const res = await $api.put(`/server/config?just_console=${id}`, data);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const setEnviromentInTaskSettings = async (storage_id, schema, enviroment) => {
  try {
    const res = await $api.post(
      `/e_mode?storage_id=${storage_id}&schema=${schema}&enviroment=${enviroment}`,
    );
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const getSchemaReservingData = async (storage_id, schema) => {
  try {
    const res = await $api.get(
      `/encoder/schema/schema_reserving?storage_id=${storage_id}&schema=${schema}`,
    );
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const setSchemaReservingData = async (storage_id, schema, data) => {
  try {
    const res = await $api.post(
      `/encoder/schema/schema_reserving?storage_id=${storage_id}&schema=${schema}`,
      data,
    );
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res?.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};
