import $api from '@services/apiAxios';

export const getLogin = async () => {
  try {
    const res = await $api.get('/login');
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiLogin = async ({ username, password }) => {
  try {
    const res = await $api.post('/login', { username, password });
    if (res.isAxiosError) {
      // console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};
export const apiLogout = async () => {
  try {
    const res = await $api.delete('/login');
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    // console.log('res', res);
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};
