import HeaderSaveName from '@widgets/ConfiguratorRemux/HeaderSaveName/HeaderSaveName';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import s from './SourceTemplate.module.scss';
import {
  apiAddDraftForTemplate,
  apiAddSection,
  apiCreateSourceTemplate,
  apiDeleteDraft,
  apiGetSourceList,
} from '@services/CreateTaskService';
import { useHistory } from 'react-router';
import SelectSource from '@widgets/SourceTemplate/SelectSource/SelectSource';
import Cookies from 'js-cookie';
import { CW_DRAFT_ID } from '@shared/Lib/Utils/Constants/Constants';
import SettingsSource from '@widgets/SourceTemplate/SettingsSource/SettingsSource';
import ServicesSource from '@widgets/SourceTemplate/ServicesSource/ServicesSource';
import TabsBase from '@shared/UI/Tabs/TabsBase/TabsBase';
import { ReactComponent as SvgPlusSquare } from '@shared/Images/plus-square.svg';
import ErrorAlert from '@shared/UI/Errors/ErrorAlert/ErrorAlert';
import { CSSTransition } from 'react-transition-group';
import MiniLoader from '@shared/UI/Loaders/MiniLoader/MiniLoader';
import { SERVICES, SETTINGS } from '@shared/Lib/Utils/Constants/SourceTemplate';

const SourceTemplate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [templateData, setTemplateData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [draft, setDraft] = useState();
  const [isShowMiniLoader, setIsShowMiniLoader] = useState(true);

  useEffect(() => {
    setDraftId();

    return () => removeDraft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDraftId = async () => {
    try {
      setIsShowMiniLoader(true);

      const draftName = Cookies.get(CW_DRAFT_ID);
      draftName && (await removeDraft());
      await apiAddDraftForTemplate().then((res) => {
        const draftId = res.data;
        Cookies.set(CW_DRAFT_ID, draftId);
        setDraft(draftId);
      });

      apiGetSourceList().then((res) => {
        setTemplateData(res?.data);
      });
    } catch (error) {
      setErrorMessage({ message: error.message, status: error.status });
    } finally {
      setIsShowMiniLoader(false);
    }
  };

  const removeDraft = async () => {
    await apiDeleteDraft().then(() => {
      Cookies.remove(CW_DRAFT_ID);
      setDraft('');
    });
  };

  const [isShowSelectSource, setIsShowSelectSource] = useState();
  const handleClickButton = (name) => {
    apiCreateSourceTemplate(name, '')
      .then(() => {
        history.goBack();
      })
      .catch((error) => {
        console.dir(error);
      });
  };

  const handlerClick = () => {
    setIsShowSelectSource(true);
  };

  const [selectItem, setSelectItem] = useState();
  const addSection = () => {
    const { category, name } = selectItem;
    apiAddSection(category, name)
      .then((res) => {
        setIsShowSettings(true);
        const newSection = res?.data?.data?.section;
        setSelectItem((selectItem) => ({ ...newSection, ...selectItem, id: newSection?.id }));
        setActiveTab(SETTINGS);
      })
      .finally(() => {
        setIsShowSelectSource(false);
      });
  };

  const [isShowSettings, setIsShowSettings] = useState();
  const [isShowServices, setIsShowServices] = useState();

  useEffect(() => {
    if (!!selectItem?.groups?.length) {
      setIsShowServices(true);
    }
  }, [selectItem?.groups]);

  const tabNames = [SERVICES, SETTINGS];
  // console.log('selectItem', selectItem);

  const [activeTab, setActiveTab] = useState();

  return (
    <div className="home">
      {errorMessage?.message && (
        <ErrorAlert
          message={errorMessage?.message}
          status={errorMessage?.status}
          onClose={() => setErrorMessage(null)}
        />
      )}
      <HeaderSaveName
        titleName={t('create_source_template')}
        inputLabel={t('template_name')}
        inputPlaceHolder={t('enter_source_template')}
        handleClickButton={handleClickButton}
        className={'mb-10'}
        disabled={!selectItem?.groups?.length}
      />
      <div className={s['source-template']}>
        <div className={s['source-template__item']}>
          <div className={s['source-template__item-buttons']}>
            <p className={s['source-template__item-title']}>{t('source')}</p>
            <SvgPlusSquare
              className={s['source-template__item__button-add']}
              onClick={() => handlerClick()}
              disabled={!draft || isShowSelectSource || selectItem}
            />
          </div>
        </div>

        {isShowSelectSource && (
          <SelectSource
            setIsShowSelectSource={setIsShowSelectSource}
            templateData={templateData}
            addSection={() => addSection()}
            selectItem={selectItem}
            setSelectItem={setSelectItem}
            isLoading={isShowMiniLoader}
          />
        )}

        {(isShowSettings || isShowServices) && (
          <div className={s['source-template__tabs-wrapper']}>
            <TabsBase
              activeTab={activeTab}
              isShowTabNames={!!isShowServices}
              tabNames={tabNames}
              tabPanelComponents={[
                <>
                  {isShowServices && (
                    <ServicesSource
                      selectItem={selectItem}
                      setSelectItem={setSelectItem}
                      isShowMiniLoader={isShowMiniLoader}
                      setIsShowMiniLoader={setIsShowMiniLoader}
                      setErrorMessage={setErrorMessage}
                      setActiveTab={setActiveTab}
                    />
                  )}
                </>,
                <>
                  {isShowSettings && (
                    <SettingsSource
                      selectItem={selectItem}
                      setSelectItem={setSelectItem}
                      isShowMiniLoader={isShowMiniLoader}
                      setIsShowMiniLoader={setIsShowMiniLoader}
                      setActiveTab={setActiveTab}
                    />
                  )}
                </>,
              ]}
            />
          </div>
        )}
      </div>
      <CSSTransition in={isShowMiniLoader} timeout={500} unmountOnExit classNames="alert">
        <MiniLoader />
      </CSSTransition>
    </div>
  );
};

export default SourceTemplate;
