import { getLogin } from '@pages/Login/api/AuthService';
import {
  apiGetAllOutputsList,
  getTransformConnectorList,
  apiUpdateSourcePins,
} from '@services/CreateTaskService';
import { createTaskActions } from '@store/CreateTask';

// Выделение чекбокса изменение стэйта
export const handleToggle = (
  id,
  schema,
  state,
  checked,
  setChecked,
  refDataTable,
  mainCheckbox,
  classname,
  elemId,
) => {
  const selectData = { schema: schema, id: id, state: state, elemId: elemId };
  // console.log('checked', checked);
  // console.log('selectData', selectData);
  const arrCurrentsSchemes = checked.filter((elem) => elem.id === id);
  // console.log('arrCurrentsSchemes', arrCurrentsSchemes);
  const newChecked = [...checked];
  const allCheckboxes = Array.from(refDataTable.current.querySelectorAll(`.${classname}`));
  if (arrCurrentsSchemes.length === 0) {
    newChecked.push(selectData);
    setChecked(newChecked);
  } else {
    const deleteIndex = newChecked.findIndex((elem) => elem.id === id);
    newChecked.splice(deleteIndex, 1);
    setChecked(newChecked);
  }
  // Выделение главного чекбокса если все остальные выделены
  if (newChecked.length === allCheckboxes.length) {
    mainCheckbox.current.checked = checked;
  } else {
    mainCheckbox.current.checked = false;
  }
};

// Выделение чекбокса изменение стэйта в схемах
export const handleToggleSchemas = (
  id,
  schema,
  state,
  checked,
  setChecked,
  refDataTable,
  mainCheckbox,
  classname,
) => {
  const selectData = { schema: schema, id: id, state: state };
  // console.log('selectData', selectData);
  // console.log('checked', checked);
  const arrCurrentsSchemes = checked.filter(
    (elem) => `${elem.id}+${elem.schema}` === `${id}+${schema}`,
  );
  // console.log('arrCurrentsSchemes', arrCurrentsSchemes);
  const newChecked = [...checked];
  // console.log('newChecked', newChecked);
  const allCheckboxes = Array.from(refDataTable.current.querySelectorAll(`.${classname}`));
  // console.log('allCheckboxes', allCheckboxes);
  if (arrCurrentsSchemes.length === 0) {
    newChecked.push(selectData);
    setChecked(newChecked);
  } else {
    const deleteIndex = newChecked.findIndex(
      (elem) => `${elem.id}+${elem.schema}` === `${id}+${schema}`,
    );
    newChecked.splice(deleteIndex, 1);
    setChecked(newChecked);
  }
  // Выделение главного чекбокса если все остальные выделены
  if (newChecked.length === allCheckboxes.length) {
    mainCheckbox.current.checked = checked;
  } else {
    mainCheckbox.current.checked = false;
  }
};

// Действия при нажатии на главный чекбокс
export const handleMainCheckbox = (refDataTable, mainCheckbox, setChecked, classname) => {
  const allCheckboxes = Array.from(refDataTable.current.querySelectorAll(`.${classname}`));
  // console.log('allCheckboxes', allCheckboxes);
  if (mainCheckbox.current.checked === true) {
    allCheckboxes.forEach((elem) => (elem.checked = true));
    const newChecked = [];
    allCheckboxes.forEach((elem) =>
      newChecked.push({ ...elem.dataset, state: +elem.dataset.state, id: elem.dataset.id }),
    );

    setChecked(newChecked);
  } else {
    allCheckboxes.forEach((elem) => (elem.checked = false));
    setChecked([]);
  }
};

export const unchekedAllCheckboxes = (classname, mainCheckbox) => {
  const checkboxes = document.querySelectorAll(`.${classname}`);
  checkboxes.forEach((element) => (element.checked = false));
  // console.log('mainCheckbox', mainCheckbox);
  mainCheckbox.current !== null &&
    mainCheckbox.current !== undefined &&
    (mainCheckbox.current.checked = false);
};

// Функция создания уникальных пидов
export const getUniqeNumber = (busyNums, dispatch) => {
  // console.log('busyNums', busyNums);
  const arrPids = Array.from({ length: 8086 }, (v, k) => k + 100);
  const freeNums = arrPids.filter((pid) => !busyNums.includes(pid));
  // console.log('freeNums', freeNums);
  const uniqeNumber = freeNums[0];
  // console.log('uniqeNumber', uniqeNumber);
  const updateBusyNums = [...busyNums, uniqeNumber];
  // console.log('updateBusyNums', updateBusyNums);
  dispatch(createTaskActions.setArrayPmtPids(updateBusyNums));
  return uniqeNumber;
};

// Действия при нажатии на главный чекбокс
export const handleMainCheckbox1 = (e, refDataTable, mainCheckbox, setChecked) => {
  const classname = e.target.className;
  const allCheckboxes = Array.from(refDataTable.current.querySelectorAll(`.${classname}`));

  // console.log(allCheckboxes);
  // console.log(mainCheckbox.current.checked);
  if (mainCheckbox.current.checked === true) {
    allCheckboxes.forEach((elem) => (elem.checked = true));
    const newChecked = [];
    allCheckboxes.forEach((elem) => newChecked.push({ ...elem.dataset, id: +elem.dataset.id }));
    // console.log(newChecked);
    setChecked(newChecked);
  } else {
    allCheckboxes.forEach((elem) => (elem.checked = false));
    setChecked([]);
  }
};

// Выделение чекбокса изменение стэйта
export const handleToggle1 = (
  e,
  id,
  name,
  checked,
  setChecked,
  refDataTable,
  mainCheckbox,
  lastSelected,
  setLastSelected,
) => {
  // console.log('checked', checked);
  // console.log('pressShift', pressShift);
  const classname = e.target.className;
  const pressShift = e.nativeEvent.shiftKey;
  // console.log('refDataTable', refDataTable.current);
  const selectData = { id: id, name: name };
  // console.log('selectData', selectData);
  setLastSelected(selectData);
  const arrCurrentsPlayers = checked.filter((elem) => elem.id === id);
  // console.log('arrCurrentsPlayers', arrCurrentsPlayers);
  const newChecked = [...checked];
  // console.log('newChecked', newChecked);
  // console.log('classname', classname);
  const allCheckboxes = Array.from(refDataTable.current.querySelectorAll(`.${classname}`));
  // console.log('allCheckboxes', allCheckboxes);
  const allItems = allCheckboxes.map((elem) => ({ id: +elem.dataset.id, name: elem.dataset.name }));
  // console.log('allItems', allItems);

  if (arrCurrentsPlayers.length === 0) {
    newChecked.push(selectData);
    setChecked(newChecked);
  } else {
    const deleteIndex = newChecked.findIndex((elem) => elem.id === id);
    newChecked.splice(deleteIndex, 1);
    setChecked(newChecked);
  }
  if (pressShift) {
    const firstItem = lastSelected
      ? lastSelected
      : checked[checked.length - 1]
      ? checked[checked.length - 1]
      : selectData;
    // console.log('firstItem', firstItem);
    const lastItem = selectData;
    // console.log('lastItem', lastItem);

    const firstItemIdx = allItems.findIndex((elem) => elem.id === firstItem.id);
    // console.log('firstItemIdx', firstItemIdx);

    const lastItemIdx = allItems.findIndex((elem) => elem.id === lastItem.id);
    // console.log('lastItemIdx', lastItemIdx);

    // проверяем bool значение последнего item(обратное)
    const isLastCheck = allCheckboxes.find((elem, i) => i === lastItemIdx).checked;
    // console.log('isLastCheck', isLastCheck);

    const firstItemIdxMin = Math.min(firstItemIdx, lastItemIdx);
    // console.log('firstItemIdxMin', firstItemIdxMin);
    const lastItemIdxMax = Math.max(firstItemIdx, lastItemIdx);
    // console.log('lastItemIdxMax', lastItemIdxMax);

    const newItems = allItems.slice(firstItemIdxMin, lastItemIdxMax + 1);
    // console.log('newItems', newItems);

    if (isLastCheck === true) {
      // условие на прибавку
      const updateChecked = newItems.filter((item) => !checked.some((elem) => item.id === elem.id));
      // console.log('updateChecked', updateChecked);
      const newChecked = [...checked, ...updateChecked];
      setChecked(newChecked);
      allCheckboxes
        .slice(firstItemIdxMin, lastItemIdxMax + 1)
        .forEach((elem) => (elem.checked = true));
    } else {
      const newChecked = checked.filter((item) => !newItems.some((elem) => item.id === elem.id));
      // console.log('newChecked', newChecked);
      setChecked(newChecked);
      allCheckboxes
        .slice(firstItemIdxMin, lastItemIdxMax + 1)
        .forEach((elem) => (elem.checked = false));
    }
  }
  // Выделение главного чекбокса если все остальные выделены
  if (allCheckboxes.filter((elem) => elem.checked).length === allCheckboxes.length) {
    mainCheckbox.current.checked = checked;
  } else {
    mainCheckbox.current.checked = false;
  }
};

// Удаление сорца

export const onDeleteSource = (arraySources, number, dispatch) => {
  const index = arraySources.findIndex((n) => n.sectionNumber === number);
  const updateArraySources = arraySources.filter((elem) => elem.sectionNumber !== number);
  updateArraySources.length !== 0 && dispatch(createTaskActions.setIsDeleteSections(true));
  if (index !== -1) {
    dispatch(createTaskActions.setDeleteSource(index));
  }
};

// Проверка куки логина

export const doesHttpOnlyCookieExist = (cookiename) => {
  const d = new Date();
  d.setTime(d.getTime() + 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cookiename + '=new_value;path=/;' + expires;
  // console.log(document.cookie);
  // console.log(document.cookie.indexOf(cookiename + '='));
  return document.cookie.indexOf(cookiename + '=') === -1;
};

export const serviceRightsName = {
  1: 'Service control (activate/deactivate/restart)',
  2: 'Service settings',
  3: 'Redundancy settings',
  4: 'Security settings',
  5: 'View service info (Log, Diagnostics)',
  10: 'Schema control (start/stop/create/edit)',
};

export const tasksRightsName = {
  6: 'Task control (activate/deactivate/restart)',
  7: 'Task settings',
  8: 'View Task Info (Log, Diagnostics, Schema)',
  9: 'Color bar generator control',
  10: 'Schema control (start/stop/create/edit)',
};

export const allTaskParams = (currentGroup) => ({
  actions: currentGroup.access_tables[1]?.actions,
  owner_id: 'all',
});

export const serviceAccessName = (
  <>
    <option value="1">Allow</option>
    <option value="2">Deny</option>
  </>
);

export const tasksOption = (dataState) => {
  // console.log(Object.entries(dataState.log_config));
  // for (let key in dataState.log_config) {
  //   key !== 'dispatcher' &&
  //     // <option value={key}>{key}</option>
  //     console.log(key);
  //     // <option value={key}>{dataState.log_config[key][0].sinks[0].level}</option>
  // }
};

export const serviceTasksName = (arr) => {
  const tasksData = (
    <>
      {arr.map((elem) => (
        <option value={elem.owner_id} key={elem.owner_id}>
          {elem.owner_id === 'all' ? 'All tasks' : `Task ${elem.owner_id}`}
        </option>
      ))}
    </>
  );
  return tasksData;
};

export const updateOutputs = (dispatch, res) => {
  const arrDeleteSections = res.data?.last_deleted_sections;
  const arrAddSections = res.data?.last_added_sections?.map((item) => item.section);
  // console.log('res', res);
  // console.log('arrAddSections', arrAddSections);
  // console.log('arrDeleteSections', arrDeleteSections);

  (arrDeleteSections || arrAddSections) &&
    dispatch(createTaskActions.setUpdateOutput(arrAddSections, arrDeleteSections));
};

export const toggleCollapse = (target) => {
  let parent = target.closest('.output-filters-item');
  let buttonArrowDown = parent.querySelector('.output-filters-item__collapse');
  buttonArrowDown.classList.toggle('rotate');
  parent.nextElementSibling.classList.toggle('sections-filters-params__item--hidden');
};

export const updatePinsOnly = async () => {
  let found_all = false;

  const checkUpdateSourcePinsRecursion = async (n) => {
    if (n !== 0) {
      const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await apiUpdateSourcePins()
        .then(async (pins) => {
          found_all = pins?.data?.data?.found_all;

          if (!found_all) {
            await timeout(1000);
            await checkUpdateSourcePinsRecursion(n - 1);
          }

          if (found_all || n === 1 || !n) {
            await checkUpdateSourcePinsRecursion(0);
          }
        })
        .catch((error) => {
          console.dir(error);
        });
    }

    return found_all;
  };

  return checkUpdateSourcePinsRecursion(10);
};

// AddressSection
export const updatePins = async (dispatch, sectionData, setShowLoader, setRenderPrograms) => {
  let found_all = false;
  let updatePrograms = false;
  dispatch(createTaskActions.setCleanOutput());
  const checkUpdateSourcePinsRecursion = async (n) => {
    if (n === 0) {
      setShowLoader && setShowLoader(false);
      return;
    } else {
      const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await apiUpdateSourcePins()
        .then(async (pins) => {
          found_all = pins?.data?.data?.found_all;
          if (!found_all) {
            await timeout(1000);
            await checkUpdateSourcePinsRecursion(n - 1);
          }

          if (found_all || n === 1) {
            await getTransformConnectorList()
              .then(async (items) => {
                // console.log('items', items);
                setShowLoader && setShowLoader(false);
                const sourcePrograms = items
                  ? items.data.data.filter((item) => item.section_id === sectionData.id)[0]?.groups
                  : [];
                updatePrograms = sourcePrograms
                  ? sourcePrograms.map((prog) => ({
                      ...prog,
                      section_id: sectionData.id,
                      checked: prog.pins.find((pin) => pin.is_used) ? true : false,
                      pins: prog.pins
                        .map((pin) => ({
                          ...pin,
                          order:
                            pin.track_type === 'video'
                              ? 1
                              : pin.track_type === 'audio'
                              ? 2
                              : pin.track_type === 'subpicture'
                              ? 3
                              : 4,
                        }))
                        .sort((a, b) => a.order - b.order),
                    }))
                  : [];
                // console.log(`updatePrograms`, updatePrograms);
                const isReserveProgram = updatePrograms.some((elem) =>
                  elem.pins.find((pin) => pin.can_show_next_section === false),
                );
                // console.log('isReserveProgram', isReserveProgram);
                setRenderPrograms && setRenderPrograms(updatePrograms);
                dispatch(
                  createTaskActions.setAddProgramsSourceItems(
                    updatePrograms.length === 0 ? null : updatePrograms,
                    sectionData.id,
                  ),
                );

                if (updatePrograms.length !== 0) {
                  // console.log('<<<<UPDATE SOURCES>>>>');
                  dispatch(createTaskActions.setModalOpenTransformSection(true));
                  isReserveProgram &&
                    (await apiGetAllOutputsList()
                      .then((items) => {
                        const outputData = items?.data?.data;
                        // console.log('outputData', outputData);
                        // console.log('items', items);
                        const arrSections = outputData
                          ? outputData.map((elem) => elem.section)
                          : [];
                        // console.log('sectionData', outputData);
                        dispatch(createTaskActions.setExchangeOutput(arrSections));
                      })
                      .catch((err) => console.dir('error', err)));
                }
              })
              .catch((err) => {
                console.dir(err);
                dispatch(createTaskActions.setAddProgramsSourceItems(null, sectionData.id));
              });
            await checkUpdateSourcePinsRecursion(0);
          }
        })
        .catch((error) => {
          console.dir(error);
        });
    }
  };

  await checkUpdateSourcePinsRecursion(10);

  return updatePrograms;
};

export const updatePinsAfterDelete = (dispatch, sectionData, setShowLoader) => {
  // console.log('sectionData', sectionData);
  let sec = 0;
  if (sec === 0) {
    dispatch(createTaskActions.setCleanOutput());
  }
  let stop = false;
  const findConnectors = setInterval(
    stop
      ? null
      : () => {
          apiUpdateSourcePins()
            .then((pins) => {
              // console.log('pins', pins);
              const getAllData = pins.data.data.found_all;
              if (getAllData) {
                clearInterval(findConnectors);
                stop = true;
              }
              sec += 1;
              if (getAllData || sec === 10) {
                getTransformConnectorList().then((items) => {
                  // console.log('GETALLPROGS');
                  // console.log('items', items);
                  // console.log('sec', sec);
                  // setShowLoader(false);

                  const updateSections = items ? items.data.data : [];
                  // console.log('updateSections', updateSections);
                  // console.log('sectionData', sectionData.id);
                  const updatePrograms = updateSections?.map((prog) => ({
                    id: prog.section_id,
                    programs: prog.groups.map((elem) => ({
                      pins: elem.pins
                        .map((pin) => ({
                          ...pin,
                          order:
                            pin.track_type === 'video'
                              ? 1
                              : pin.track_type === 'audio'
                              ? 2
                              : pin.track_type === 'subpicture'
                              ? 3
                              : 4,
                        }))
                        .sort((a, b) => a.order - b.order),
                      program: elem.program,
                      program_name: elem.program_name,
                      section_id: prog.section_id,
                      checked: elem.pins.find((pin) => pin.is_used) ? true : false,
                    })),
                  }));

                  // console.log(`updatePrograms`, updatePrograms);
                  dispatch(
                    createTaskActions.setUpdateAllProgramsSourceItems(
                      updatePrograms.length === 0 ? null : updatePrograms,
                      sectionData?.id,
                    ),
                  );
                  dispatch(createTaskActions.setActiveTransformTab('tab-0-0'));
                  if (updatePrograms.length !== 0) {
                    // console.log('<<<<UPDATE SOURCES>>>>');
                    dispatch(createTaskActions.setModalOpenTransformSection(true));
                  }
                });
              }
            })
            .catch((err) => {
              sec += 1;
              console.dir(err);
            });
          if (sec >= 10) {
            setShowLoader(false);
            clearInterval(findConnectors);
          }
        },
    1000,
  );
};

export const strategyDeactivate = (t) => {
  const data = [
    { name: t('deactivate_server'), value: 'deactivate' },
    { name: t('become_a_redundant_server'), value: 'mark' },
  ];
  return data;
};

export const loggingLabels = (t) => {
  const data = [
    { type: 'Default', label: t('logging_level_for_dispatcher') },
    { type: 'Snmp', label: t('logging_level_for_SNMP') },
    { type: 'Access', label: t('logging_level_for_access') },
    { type: 'WebServer', label: t('web_server_log') },
    { type: 'StackTrace', label: t('stack_trace_log') },
  ];
  return data;
};

export const convertMbToGb = (mb, isShowMeasure, t) => {
  if (isNaN(mb)) {
    return '';
  }

  return `${(mb / 1024).toFixed(1)}${isShowMeasure ? ` ${t('GB')}` : ''}`;
};

export const convertKbToBytes = (kb) => {
  if (isNaN(kb)) {
    return '';
  }

  return +kb * 1024;
};

export const convertKbToHumanReadable = (kb, t) => {
  let isWrongValues = checkToWrongValues(kb, t('B'));

  if (!isWrongValues) {
    if (+kb > 1024 * 1024 * 1024) {
      return (+kb / (1024 * 1024 * 1024)).toFixed(2) + ' ' + t('TB');
    }

    if (+kb > 1024 * 1024) {
      return (+kb / (1024 * 1024)).toFixed(2) + ' ' + t('GB');
    }

    if (+kb > 1024) {
      return (+kb / 1024).toFixed(1) + ' ' + t('MB');
    }

    return (+kb).toFixed(0) + ' ' + t('KB');
  } else {
    return isWrongValues;
  }
};

export const convertKBitToBits = (kbit) => {
  if (isNaN(kbit)) {
    return '';
  }

  return +kbit * 1000;
};

export const convertBitToHumanReadable = (bits, t) => {
  let isWrongValues = checkToWrongValues(bits, t('bit/s'));

  if (!isWrongValues) {
    return (+bits / (1000 * 1000)).toFixed(2) + ' ' + t('Mbit/s');
  } else {
    return isWrongValues;
  }
};

export const convertKBitToHumanReadable = (kbit, t) => {
  let isWrongValues = checkToWrongValues(kbit, t('bit/s'));

  if (!isWrongValues) {
    //if need to convert to Gbit
    if (+kbit >= 1000 * 1000) {
      return (+kbit / (1000 * 1000)).toFixed(2) + ' ' + t('Gbit/s');
    }

    //if need to convert to Mbit
    if (+kbit >= 1000) {
      return (+kbit / 1000).toFixed(1) + ' ' + t('Mbit/s');
    }

    return (+kbit).toFixed(0) + ' ' + t('kbit/s');
  } else {
    return isWrongValues;
  }
};

export const checkToWrongValues = (value, nullMeasure) => {
  if (isNaN(value)) {
    return value;
  }

  if (+value === 0) {
    return nullMeasure ? `${+value} ${nullMeasure}` : value;
  }

  return false;
};

export const checkErrorType = (res) => {
  return Array.isArray(res?.response?.data)
    ? res.response.data[0].message
    : res?.response?.data
    ? res.response.data.message
    : res.message;
};

export const hideScroll = () => {
  const offset = window.innerWidth - document.documentElement.clientWidth;
  if (offset > 0) {
    document.documentElement.style.marginRight = `${offset}px`;
    document.documentElement.style.overflow = 'hidden';
  }
};

export const showScroll = () => {
  document.documentElement.style.overflow = '';
  document.documentElement.style.marginRight = '';
};

export const calculatePercent = (part, total) => {
  return part && total ? Math.ceil((part / total) * 100) : '';
};

export const addPercentSignToValue = (value) => {
  return value || value === 0 ? value + '%' : '';
};

export const inputCheckToNumber = (e) => !/[0-9]/.test(e.key) && e.preventDefault();

export const parameterCheckMeaning = (parameter) =>
  `${parameter.value.toString()} ${parameter.meaning ? `(${parameter.meaning})` : ''}`;

export const saveDirtyFields = (
  item,
  dirtyFields,
  useFormObjectName,
  useFormObjectNameWithDot,
  watch,
) => {
  // console.log('item', item);
  // console.log('dirtyFields', dirtyFields);
  return {
    ...item,
    dropper: {
      ...item.dropper,
      enabled: dirtyFields?.[useFormObjectName]?.['enable-record']
        ? watch(useFormObjectNameWithDot + 'enable-record')
        : item.dropper.enabled,
      chunk_size: dirtyFields?.[useFormObjectName]?.['chunk-size']
        ? +watch(useFormObjectNameWithDot + 'chunk-size')
        : +item.dropper.chunk_size,
      life_time: dirtyFields?.[useFormObjectName]?.['record-life-time']
        ? +watch(useFormObjectNameWithDot + 'record-life-time')
        : +item.dropper.life_time,
    },
    log_config: item.log_config.map((log) => {
      if (log.type === 'Default') {
        return {
          ...log,
          sinks: log?.sinks?.map((sink, index) => {
            if (index === 0) {
              return {
                ...sink,
                level: dirtyFields?.[useFormObjectName]?.['level']
                  ? watch(useFormObjectNameWithDot + 'level')
                  : sink?.level,
              };
            } else {
              return sink;
            }
          }),
        };
      } else {
        return log;
      }
    }),
    restart_condition: {
      ...item.restart_condition,
      by_cpu: {
        ...item.restart_condition.by_cpu,
        duration: dirtyFields?.[useFormObjectName]?.['restart-cpu-duration']
          ? +watch(useFormObjectNameWithDot + 'restart-cpu-duration')
          : +item.restart_condition.by_cpu?.duration,
        enabled: dirtyFields?.[useFormObjectName]?.['enable-restart-cpu']
          ? watch(useFormObjectNameWithDot + 'enable-restart-cpu')
          : item.restart_condition.by_cpu?.enabled,
        low_level: dirtyFields?.[useFormObjectName]?.['restart-cpu-value']
          ? +watch(useFormObjectNameWithDot + 'restart-cpu-value')
          : +item.restart_condition.by_cpu?.low_level,
      },
      by_ram: {
        ...item.restart_condition.by_ram,
        duration: dirtyFields?.[useFormObjectName]?.['restart-ram-duration']
          ? +watch(useFormObjectNameWithDot + 'restart-ram-duration')
          : +item.restart_condition.by_ram?.duration,
        enabled: dirtyFields?.[useFormObjectName]?.['enable-restart-ram']
          ? watch(useFormObjectNameWithDot + 'enable-restart-ram')
          : item.restart_condition.by_ram?.enabled,
        max_value: dirtyFields?.[useFormObjectName]?.['restart-ram-value']
          ? +watch(useFormObjectNameWithDot + 'restart-ram-value')
          : +item.restart_condition.by_ram?.max_value,
      },
      by_time: {
        ...item.restart_condition.by_time,
        enabled: dirtyFields?.[useFormObjectName]?.['enable-restart']
          ? watch(useFormObjectNameWithDot + 'enable-restart')
          : item.restart_condition.by_time?.enabled,
        period: dirtyFields?.[useFormObjectName]?.['restart-duration']
          ? +watch(useFormObjectNameWithDot + 'restart-duration')
          : +item.restart_condition.by_time?.period,
      },
    },
    safe_exit: dirtyFields?.[useFormObjectName]?.['safe_exit']
      ? watch(useFormObjectNameWithDot + 'safe_exit')
      : item?.safe_exit,
    stat_log_time: dirtyFields?.[useFormObjectName]?.['log-duration']
      ? +watch(useFormObjectNameWithDot + 'log-duration')
      : +item?.stat_log_time,
  };
};

export const checkToAuth = () => {
  if (localStorage.getItem('authSettings')) {
    getLogin()
      .then((res) => {
        if (!res?.data?.user) {
          localStorage.removeItem('authSettings');
          window.history.go('/login');
        }
      })
      .catch((error) => {
        console.dir(error);
      });
  }
};

export const trimNumber = (num) => {
  const updateNum = +num.replace(',', '.').replace(' ', '');
  return num ? updateNum.toFixed(2) : null;
};

export const formErrorObject = (res) => {
  let message = res?.response?.data?.error
    ? res?.response?.data?.error
    : res?.response?.data?.message
    ? res?.response?.data?.message
    : res?.message
    ? res?.message
    : '';
  let status = res?.response?.status ? res?.response?.status : res?.status ? res?.status : '';

  return { message, status };
};

export const formatTimestamp = (timestamp) => {
  if (isNaN(timestamp)) {
    return '';
  }

  const date = new Date(+timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const findAndNumberDuplicates = (array) => {
  let duplicates = {};
  let numberedDuplicates = {};

  for (let i = 0; i < array.length; i++) {
    let current = array[i];

    if (duplicates[current] === undefined) {
      duplicates[current] = 1;
    } else {
      duplicates[current]++;
    }

    if (duplicates[current] > 1) {
      if (numberedDuplicates[current] === undefined) {
        numberedDuplicates[current] = 2;
      } else {
        numberedDuplicates[current]++;
      }
    }
  }

  return numberedDuplicates;
};
