import classNames from 'classnames';
import React from 'react';
import TransformPinParams from './TransformPinParams/TransformPinParams';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  apiGetAllOutputsList,
  getRenderConnectorList,
  apiSetConnectorPin,
  apiTransformUpdatePins,
} from '@services/CreateTaskService';
import { createTaskActions } from '@store/CreateTask';

const TransformPin = ({ pin, sectionId, pinRenderIndex, programNum }) => {
  // console.log('pin', pin);
  const dispatch = useDispatch();
  const [selectValue, setSelectValue] = React.useState(pin?.next_section);

  useEffect(() => {
    setSelectValue(pin?.next_section);
  }, [pin]);

  const [isError, setIsError] = React.useState();

  const setConnectorPin = async (value) => {
    setSelectValue(value);
    const selectId = pin?.connectorList?.findIndex((item) => item.name === value);
    await apiSetConnectorPin(sectionId, pin.index, selectId + 1)
      .then(async (res) => {
        await apiTransformUpdatePins();
        getRenderConnectorList()
          .then((list) => {
            const renderPinsData = list?.data?.data;
            // console.log('allRenderPins', list);
            dispatch(
              createTaskActions.setUpdateTransformProgram(
                sectionId,
                programNum,
                renderPinsData ? renderPinsData : [],
              ),
            );
          })
          .catch((error) => {
            dispatch(createTaskActions.setUpdateTransformProgram(sectionId, programNum, []));
          })
          .finally(() => {
            updateOutput();
          });
      })
      .catch((err) => {
        console.dir(err);
        setIsError(true);
        dispatch(createTaskActions.setErrorFromRequest(err));
      });
  };

  const updateOutput = () => {
    dispatch(createTaskActions.setClearErrorRequest());
    setIsError(false);
    apiGetAllOutputsList()
      .then((items) => {
        const outputData = items?.data?.data;
        const arrSections = outputData ? outputData.map((elem) => elem.section) : [];
        dispatch(createTaskActions.setExchangeOutput(arrSections));
      })
      .catch((error) => {
        dispatch(createTaskActions.setErrorFromRequest(error));
        setIsError(true);
      });
  };

  return (
    <div
      className={classNames(
        'transform-properties__item',
        pin.track_type === 'video'
          ? 'transform-properties__item--color1'
          : pin.track_type === 'audio'
          ? 'transform-properties__item--color2'
          : pin.track_type === 'subpicture'
          ? 'transform-properties__item--color3'
          : 'transform-properties__item--color4',
      )}
    >
      <div className="transform-properties-decoder">
        <div className="transform-properties__item-blockLabel">{pin.name}</div>
        <select
          value={selectValue}
          className={classNames(
            'transform-properties__item-select',
            !selectValue || isError ? 'border-error' : '',
          )}
          onChange={(e) => setConnectorPin(e.target.value)}
        >
          <option value={''}>{'none'}</option>
          {pin?.connectorList?.length !== 0 &&
            pin?.connectorList?.map((coder, index) => (
              <option key={coder.name + index} value={coder.name}>
                {coder.name}
              </option>
            ))}
          {pin?.connectorList?.length === 0 && selectValue && (
            <option value={selectValue}>{selectValue}</option>
          )}
        </select>
      </div>
      {!isError && (
        <div className="transform-properties-encoder">
          {pin.renderPins
            ? pin.renderPins.map((encoder, i) => (
                <TransformPinParams
                  key={encoder.name + encoder.index}
                  encoderDef={encoder}
                  sectionCoderId={pin.section_id}
                  pinIndex={pinRenderIndex}
                  outputPinIndex={i}
                  sectionId={sectionId}
                  programNum={programNum}
                />
              ))
            : null}
        </div>
      )}
    </div>
  );
};

export default TransformPin;
