import React from 'react';
import DraggableModal from '@shared/UI/DraggableModal/DraggableModal';
import { useTranslation } from 'react-i18next';
import { convertKBitToHumanReadable } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import Collapse from '@shared/UI/Collapse/Collapse';
import './NetworkStatisticModal.scss';

const NetworkStatisticModal = ({
  setShowModal,
  interfacesInfo,
  showLoader,
  serverName,
  classNameDraggableModal,
}) => {
  const { t } = useTranslation();

  return (
    <DraggableModal
      showLoader={showLoader}
      setShowModal={setShowModal}
      title={t('network_usage')}
      className={classNameDraggableModal}
    >
      {interfacesInfo && (
        <>
          {serverName && (
            <p className="ta-c fw-500">
              {t('server_name')}: {serverName}
            </p>
          )}

          <div className="network-statistic__table">
            <div className="network-statistic__table--scroll">
              <table className="table">
                <thead className="thead">
                  <tr className="network-statistic__table__row">
                    <th className="ta-l w-40 pl-10">{t('interface')}</th>
                    <th className="ta-l w-10">RX</th>
                    <th className="ta-l w-10">TX</th>
                  </tr>
                </thead>
                <tbody>
                  {interfacesInfo?.map((item, index) => (
                    <tr
                      className="network-statistic__table__row"
                      key={`${item.friendly_name} ${index}`}
                    >
                      <td className="pl-10 ta-l">
                        <Collapse
                          text={`${
                            item?.IPv4?.[0]
                              ? item?.IPv4?.[0]
                              : item?.IPv6?.[0]
                              ? item?.IPv6?.[0]
                              : ''
                          }  ${item.friendly_name ? `(${item.friendly_name})` : ''}`}
                        >
                          {item?.IPv4 && (
                            <pre className="collapse__pre">
                              <b>IPv4: </b>
                              {item?.IPv4?.length > 1 ? '\n' : ''}
                              {item?.IPv4?.join(',\n')}
                            </pre>
                          )}
                          {item?.IPv6 && (
                            <pre className="collapse__pre">
                              <b>IPv6: </b>
                              {item?.IPv6?.length > 1 ? '\n' : ''}
                              {item?.IPv6?.join(',\n')}
                            </pre>
                          )}
                          {item?.speed && (
                            <pre className="collapse__pre">
                              <b>{t('speed')}: </b>
                              {item.speed}
                            </pre>
                          )}
                          {item?.adapter_name && (
                            <pre className="collapse__pre">
                              <b>{t('adapter_name')}: </b>
                              {item.adapter_name}
                            </pre>
                          )}
                        </Collapse>
                      </td>
                      <td className="ta-l ff-roboto">{convertKBitToHumanReadable(item?.rx, t)}</td>
                      <td className="ta-l ff-roboto">{convertKBitToHumanReadable(item?.tx, t)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </DraggableModal>
  );
};

export default NetworkStatisticModal;
