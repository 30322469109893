import React from 'react';
import { ReactComponent as SvgArrowDown } from '@shared/Images/arrow-down-sign-to-navigate.svg';
import s from './ArrowDown.module.scss';
import classNames from 'classnames';

const ArrowDown = ({ classNameButton, isRotate }) => {
  return (
    <SvgArrowDown
      data-testid="arrow-down"
      className={classNames(s['arrow-down'], classNameButton, isRotate && s['arrow-down_rotate'])}
    />
  );
};

export default ArrowDown;
