import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const InputCheckbox = ({
  labelName,
  inputName,
  defaultValue,
  useFormObjectName,
  wrapperClassName,
  inputClassName,
  labelClassName,
  isUpdateValue,
}) => {
  const [checkboxValue, setCheckboxValue] = useState(defaultValue);
  const { register, setValue } = useFormContext();
  const registerName = useFormObjectName + inputName;

  useEffect(() => {
    setValue(`${registerName}`, defaultValue);
    setCheckboxValue(defaultValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateValue]);

  return (
    <div className={wrapperClassName ? wrapperClassName : ''}>
      <input
        id={inputName}
        checked={checkboxValue}
        {...register(registerName)}
        className={inputClassName ? inputClassName : ''}
        type="checkbox"
        onChange={(e) => {
          const updateValue = e.target.checked;
          register(`${registerName}`).onChange(e);
          setCheckboxValue(updateValue);
        }}
      />
      <label className={labelClassName ? labelClassName : ''} htmlFor={inputName}>
        {labelName}
      </label>
    </div>
  );
};

export default InputCheckbox;
