import { saveDirtyFields } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import $api from '@services/apiAxios';

export const getEnviroment = async () => {
  try {
    const res = await $api.get('/enviroment');
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const setEnviroment = async (thresholds, dirtyFields, watch, currentMode) => {
  try {
    const useFormObjectName = 'Enviroment';
    const useFormObjectNameWithDot = useFormObjectName + '.';

    let newData = await {
      ...thresholds,
      env_modes: thresholds?.env_modes?.map((item, index) => {
        if (index === currentMode) {
          //сохранить все dirtyfields при перелистывании в объекте
          return saveDirtyFields(
            item,
            dirtyFields,
            useFormObjectName,
            useFormObjectNameWithDot,
            watch,
          );
        } else {
          return item;
        }
      }),
    };
    const res = await $api.post('/enviroment', newData);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};
