import {
  apiGetAllOutputsList,
  getRenderConnectorList,
  apiTransformCoderList,
  apiTransformUpdatePins,
} from '@services/CreateTaskService';
import { createTaskActions } from '@store/CreateTask';

export const refreshPins = async (programs, dispatch, number) => {
  // console.log('programs', programs);
  return await Promise.all(
    programs?.map((program) => program?.checked && getTransformData(program, dispatch, number)),
  ).then(async () => {
    let isNotReserveProgram = programs?.find((program) =>
      program?.pins?.some((pin) => pin?.can_show_next_section),
    );
    // console.log('isReserveProgram', isReserveProgram);
    if (isNotReserveProgram) {
      await apiTransformUpdatePins();

      await Promise.all(
        programs?.map(async (program) => {
          let isReserveProgram = program?.pins?.some((pin) => !pin?.can_show_next_section);
          if (!isReserveProgram && program?.checked) {
            try {
              const list = await getRenderConnectorList();
              const renderPinsData = list?.data?.data;
              // console.log(`allRenderPins${programNum}`, list);
              renderPinsData &&
                dispatch(
                  createTaskActions.setUpdateTransformProgram(
                    program.section_id,
                    program.program,
                    renderPinsData ? renderPinsData : [],
                  ),
                );
            } catch (error) {
              dispatch(
                createTaskActions.setUpdateTransformProgram(
                  program.section_id,
                  program.program,
                  [],
                ),
              );
            }
          }
        }),
      );

      return await updateOutput(dispatch).catch((error) => {
        console.dir(error);
      });
    }
  });
};

export const getTransformData = async (program, dispatch, number) => {
  let isReserveProgram = program?.pins?.some((pin) => !pin?.can_show_next_section);
  if (!isReserveProgram) {
    await Promise.all(
      program?.pins?.map(
        (pin) =>
          //получаем список для верхнего выпадающего списка и выбранный select
          pin?.is_used &&
          apiTransformCoderList(program.section_id, pin.index).then((res) => {
            // console.log('coderLIst', res);
            let connectorList = res?.data?.data?.connectors;
            // console.log('connectorList', connectorList);
            let newPinList = { index: pin.index, connectorList: connectorList };
            dispatch(
              createTaskActions.setSourceListToPin({
                sectionNumber: number,
                programNum: program.program,
                newPinList: newPinList,
              }),
            );
          }),
      ),
    );
  }
};

export const updateOutput = async (dispatch) => {
  return await apiGetAllOutputsList().then((items) => {
    const outputData = items?.data?.data;
    const arrSections = outputData ? outputData.map((elem) => elem.section) : [];
    dispatch(createTaskActions.setExchangeOutput(arrSections));
  });
};


