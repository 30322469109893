import classNames from 'classnames';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ServiceRights from './ServiceRights/ServiceRights';
import TasksRights from './TasksRights/TasksRights';
import './Rights.scss';
import { useTranslation } from 'react-i18next';

const Rights = ({ currentGroup, selectGroup, getSecureData, blockAction }) => {
  const { t } = useTranslation();
  const [activeMenu, setActiveMenu] = React.useState(0);
  const arrActiveMenu = [{ name: t('service') }, { name: t('tasks') }];

  const selectOwnerId = currentGroup?.access_tables[0]?.owner_id;
  // console.log('currentGroup', currentGroup);

  const methods = useForm();
  const onSubmitTransform = (data) => {
    // console.log('data', data);
  };

  const { handleSubmit } = methods;

  return (
    <div className="permissions-item permissions-item-show">
      <FormProvider {...methods}>
        <form className="permissions" onSubmit={handleSubmit(onSubmitTransform)}>
          <div className="snmp-nav">
            {arrActiveMenu.map((item, i) => (
              <div
                key={item.name + i}
                className={classNames('snmp-nav__item', activeMenu === i ? 'snmp-nav-active' : '')}
                onClick={() => setActiveMenu(i)}
              >
                {item.name}
              </div>
            ))}
          </div>

          {activeMenu === 0 ? (
            <ServiceRights
              activeMenu={activeMenu}
              currentGroup={currentGroup}
              selectGroup={selectGroup}
              getSecureData={getSecureData}
              selectOwnerId={selectOwnerId}
              blockAction={blockAction}
            />
          ) : (
            <TasksRights
              currentGroup={currentGroup}
              activeMenu={activeMenu}
              selectGroup={selectGroup}
              getSecureData={getSecureData}
              blockAction={blockAction}
            />
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default Rights;
