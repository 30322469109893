import React from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  apiAllowColorBar,
  apiDeleteTasks,
  apiDenyColorBar,
  apiResetAst,
  apiResetRefPids,
  apiRestartTasks,
  apiRunColorbar,
  apiStartTasks,
  apiStopColorbar,
  apiStopTasks,
  apiTaskRebuild,
} from '@services/TaskService';
import Information from '@shared/UI/Warnings/Information/Information';
import WarningDelete from '@shared/UI/Warnings/WarningDelete/WarningDelete';
import s from './TaskActionPanel.module.scss';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';
import { useTranslation } from 'react-i18next';
import useAuth from '@shared/Lib/Utils/Auth/useAuth';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";

const TaskActionPanel = ({
  checked,
  setTypeModal,
  setShowManyError,
  setManyMessageError,
  setShowMiniLoader,
  clearCheckState,
  setMessage,
  setShowInform,
}) => {
  const { t } = useTranslation();
  const [showWarn, setShowWarn] = React.useState(false);
  const [showQuestion, setShowQuestion] = React.useState(false);
  const { disableButtonForGuest } = useAuth();

  // Старт задач
  const tasksStart = () => {
    applyForAll(apiStartTasks);
  };
  // Стоп задач
  const tasksStop = () => {
    applyForAll(apiStopTasks);
  };

  // Перезапуск задач
  const tasksRestart = () => {
    applyForAll(apiRestartTasks, true);
  };

  // Ребилд задач
  const tasksRebuild = () => {
    applyForAll(apiTaskRebuild, false, 'rebuild');
  };

  //   ColorBar
  const tasksApplyColorBar = () => {
    applyForAll(apiAllowColorBar, true, 'applyColor');
  };

  const tasksDenyColorBar = () => {
    applyForAll(apiDenyColorBar, true, 'denyColor');
  };

  const tasksRunColorBar = () => {
    applyForAll(apiRunColorbar, true, 'runColor');
  };

  const tasksStopColorBar = () => {
    applyForAll(apiStopColorbar, true, 'stopColor');
  };

  const resetAst = () => {
    applyForAll(apiResetAst);
  };

  const resetRefPids = () => {
    applyForAll(apiResetRefPids);
  };

  // Удаление задач
  const tasksDelete = () => {
    setShowMiniLoader(true);
    let arrErrors = [];
    Promise.all(
      checked.map(async (elem) =>
        elem.graph.state === 2
          ? await apiStopTasks(elem.graph.storage_id, elem.graph.name)
              .then((res) => {
                // console.log('стопнули');
                // console.log(res);
                if (res?.status === 200) {
                  apiDeleteTasks(elem.graph.storage_id, elem.graph.name)
                    .then((res) => {
                      // console.log(res);
                    })
                    .catch((err) => {
                      console.dir(err);
                      arrErrors.push({
                        name: elem.graph.name,
                        errorMessage: err.message,
                        type: 'error',
                      });
                    });
                }
              })
              .catch((err) => {
                arrErrors.push({
                  name: elem.graph.name,
                  errorMessage: err.message,
                  type: 'error',
                });
              })
          : await apiDeleteTasks(elem.graph.storage_id, elem.graph.name)
              .then((res) => {
                if (res?.status === 200) {
                }
              })
              .catch((error) => {
                arrErrors.push({
                  name: elem.graph.name,
                  errorMessage: error.message,
                  type: 'error',
                });
              }),
      ),
    ).then(() => {
      setShowMiniLoader(false);
      setMessage('Success');
      setShowInform(true);
      if (arrErrors.length !== 0) {
        // console.log(arrErrors);
        const typeModal = () => {
          const typeArr = arrErrors.map((elem) => elem.type);
          if (typeArr.includes('inform') && typeArr.includes('error')) {
            return 'warning';
          }
          if (typeArr.includes('inform') && !typeArr.includes('error')) {
            return 'inform';
          }
          if (!typeArr.includes('inform') && typeArr.includes('error')) {
            return 'error';
          }
        };
        // console.log(typeModal());
        if (typeModal() === 'inform') {
          setShowInform(true);
          setMessage(t('Schema_has_been_successfully_rebuilt'));
        } else {
          setTypeModal(typeModal());
          setShowManyError(true);
          setManyMessageError(arrErrors);
        }
        // console.log('arrErrors', arrErrors);
      }
    });

    clearCheckState();
  };

  // console.log('checked', checked);

  const applyForAll = (apiRequest, otherWay, commandName) => {
    let arrErrors = [];
    setShowMiniLoader(true);
    Promise.all(
      checked.map((elem, i) => {
        if (otherWay) {
          return apiRequest(elem.id)
            .then((res) => {
              // console.log(res);
              console.dir(res);
              if (res) {
                if (res.status === 200) {
                  if (commandName) {
                    if (commandName === 'rebuild') {
                      arrErrors.push({
                        name: elem.graph.name,
                        errorMessage: t('Schema_has_been_successfully_rebuilt'),
                        type: 'inform',
                      });
                    }
                    if (commandName === 'applyColor') {
                      arrErrors.push({
                        name: elem.graph.name,
                        errorMessage: t('color_bar_generator_is_allowed'),
                        type: 'inform',
                      });
                    }
                    if (commandName === 'denyColor') {
                      arrErrors.push({
                        name: elem.graph.name,
                        errorMessage: t('color_bar_generator_is_denied'),
                        type: 'inform',
                      });
                    }
                  }
                }
              }
            })
            .catch((err) => {
              arrErrors.push({
                name: elem.graph.name,
                errorMessage: err.message,
                type: 'error',
              });
            });
        } else {
          if (apiRequest.name === 'apiResetAst') {
            if (elem?.dash_ast_reset?.possible) {
              return apiRequest(elem.graph.storage_id, elem.graph.name)
                .then((res) => {
                  // console.log(res);
                  console.dir(res);
                })
                .catch((err) => {
                  arrErrors.push({
                    name: elem.graph.name,
                    errorMessage: err.message,
                    type: 'error',
                  });
                });
            } else {
              return null;
            }
          } else if (apiRequest.name === 'apiResetRefPids') {
            if (elem?.active) {
              return apiRequest(elem.graph.storage_id, elem.graph.name)
                .then((res) => {
                  // console.log(res);
                  console.dir(res);
                })
                .catch((err) => {
                  arrErrors.push({
                    name: elem.graph.name,
                    errorMessage: err.message,
                    type: 'error',
                  });
                });
            } else {
              return null;
            }
          } else {
            return apiRequest(elem.graph.storage_id, elem.graph.name, elem.environment)
              .then((res) => {
                // console.log(res);
                console.dir(res);
              })
              .catch((err) => {
                arrErrors.push({
                  name: elem.graph.name,
                  errorMessage: err.message,
                  type: 'error',
                });
              });
          }
        }
      }),
    ).then(() => {
      setShowMiniLoader(false);
      setMessage('Success');
      setShowInform(true);
      if (arrErrors.length !== 0) {
        // console.log(arrErrors);
        const typeModal = () => {
          const typeArr = arrErrors.map((elem) => elem.type);
          if (typeArr.includes('inform') && typeArr.includes('error')) {
            return 'warning';
          }
          if (typeArr.includes('inform') && !typeArr.includes('error')) {
            return 'inform';
          }
          if (!typeArr.includes('inform') && typeArr.includes('error')) {
            return 'error';
          }
        };
        // console.log(typeModal());
        if (typeModal() === 'inform') {
          setShowInform(true);
          commandName === 'rebuild' && setMessage(t('Schema_has_been_successfully_rebuilt'));
          commandName === 'applyColor' && setMessage(t('color_bar_generator_is_allowed'));
          commandName === 'denyColor' && setMessage(t('color_bar_generator_is_denied'));
        } else {
          setTypeModal(typeModal());
          setShowManyError(true);
          setManyMessageError(arrErrors);
        }
        // console.log('arrErrors', arrErrors);
      }
    });
    clearCheckState();
  };

  return (
    <div className={s['task-action-panel']}>
      <div className={s['task-action-panel__block']}>
        <p className={s['task-action-panel__text']}>
          {t('tasks_selected')} :&nbsp;
          {checked.length}
        </p>
        <div className={s['task-action-panel__action-buttons']}>
          <ButtonBase
            color={'green'}
            onClick={tasksStart}
            disabled={disableButtonForGuest}
          >
            {t('start')}
          </ButtonBase>
          <ButtonBase
            color={'grey'}
            onClick={tasksStop}
            disabled={disableButtonForGuest}
          >
            {t('stop')}
          </ButtonBase>
          <ButtonBase
            color={'blue'}
            onClick={tasksRestart}
            disabled={disableButtonForGuest}
          >
            {t('restart')}
          </ButtonBase>
          <ButtonBase
            color={'grey'}
            onClick={tasksRebuild}
            disabled={disableButtonForGuest}
          >
            {t('rebuild')}
          </ButtonBase>
          {checked?.some((item) => item?.dash_ast_reset?.possible) && (
            <ButtonBase
              color={'grey'}
              onClick={resetAst}
              disabled={disableButtonForGuest}
            >
              {t('reset_ast')}
            </ButtonBase>
          )}
          {checked?.some((item) => item?.active) && (
            <ButtonBase
              color={'grey'}
              onClick={resetRefPids}
              disabled={disableButtonForGuest}
            >
              {t('reset_ref_pids')}
            </ButtonBase>
          )}
          <ButtonBase
            color={'red'}
            onClick={() => setShowWarn(true)}
            disabled={disableButtonForGuest}
          >
            {t('delete')}
          </ButtonBase>
        </div>
      </div>
      <div className={s['task-action-panel__block']}>
        <p className={s['task-action-panel__text']}>{t('color_bar_generator')}</p>
        <div className={s['task-action-panel__actions']}>
          <div className={s['task-action-panel__action']}>
            <span className={s['task-action-panel__action-type']}>{t('auto_mode')}:</span>
            <div className={s['task-action-panel__action-buttons']}>
              <ButtonBase
                color={'green'}
                onClick={tasksApplyColorBar}
                disabled={disableButtonForGuest}
              >
                {t('allow')}
              </ButtonBase>
              <ButtonBase
                color={'red'}
                onClick={tasksDenyColorBar}
                disabled={disableButtonForGuest}
              >
                {t('deny')}
              </ButtonBase>
            </div>
          </div>
          <div className={s['task-action-panel__action']}>
            <span className={s['task-action-panel__action-type']}>{t('manual_mode')}:</span>
            <div className={s['task-action-panel__action-buttons']}>
              <ButtonBase
                color={'green'}
                onClick={() => setShowQuestion(true)}
                disabled={disableButtonForGuest}
              >
                {t('run')}
              </ButtonBase>
              <ButtonBase
                color={'red'}
                onClick={tasksStopColorBar}
                disabled={disableButtonForGuest}
              >
                {t('stopColor')}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
      <ModalPortal showModal={showWarn}>
        <CSSTransition in={showWarn} timeout={500} unmountOnExit classNames="alert">
          <WarningDelete
            deleteTask={tasksDelete}
            setShowError={setShowWarn}
            checkedActive={checked}
            checkedInactive={[]}
          />
        </CSSTransition>
      </ModalPortal>
      <ModalPortal showModal={showQuestion}>
        <CSSTransition in={showQuestion} timeout={500} unmountOnExit classNames="alert">
          <Information
            setShowInform={setShowQuestion}
            message={t('run_color_bar_generator')}
            withFunc={true}
            func={tasksRunColorBar}
          />
        </CSSTransition>
      </ModalPortal>
    </div>
  );
};

export default TaskActionPanel;
