import { checkErrorType } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import $api from '@services/apiAxios';

export const apiGetData = async () => {
  try {
    const res = await $api.get('/server-redundancy');
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

/* export const requestStartRedundancy = async () => {
  return await $api.post(`/server-redundancy/start?strategy=mediator`);
}; */
export const apiStartRedundancy = async () => {
  try {
    const res = await $api.post(`/server-redundancy/start?strategy=mediator`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiStopRedundancy = async (strategy) => {
  try {
    const res = await $api.post(`/server-redundancy/close?strategy=${strategy}`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiHeartBeatRedundancy = async () => {
  try {
    const res = await $api.post(`/server-redundancy/heartbeat`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiRedundancyGroups = async () => {
  try {
    const res = await $api.get(`/server-redundancy/group`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiRedundancyHosts = async () => {
  try {
    const res = await $api.get(`/server-redundancy/host`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const apiRedundancyPeer = async () => {
  try {
    const res = await $api.get('/server-redundancy/peer');
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const apiAddHosts = async (data) => {
  try {
    const res = await $api.post('/server-redundancy/host', data);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const apiAddGroups = async (data) => {
  try {
    const res = await $api.post('/server-redundancy/group', data);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const apiDeleteHosts = async (data) => {
  try {
    const res = await $api.delete(`/server-redundancy/host?nodes=[${data}]`);
  
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const apiDeleteGroups = async (data) => {
  try {
    const res = await $api.delete(`/server-redundancy/group?nodes=[${data}]`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

// SETTINGS //

export const apiRedundancySettings = async () => {
  try {
    const res = await $api.get('/server-redundancy/options');
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiUpdateSettings = async (data) => {
  try {
    const res = await $api.post('/server-redundancy/options', data);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiRedundancySettingsDeactivate = async () => {
  try {
    const res = await $api.get('/server-redundancy/deactivate-settings');
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiUpdateSettingsDeactivate = async (data) => {
  try {
    const res = await $api.post('/server-redundancy/deactivate-settings', data);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiUpdateTasks = async () => {
  try {
    const res = await $api.post('/server-redundancy/update-schemas');
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiChangeRedundancy = async (reserv, main) => {
  try {
    const res = await $api.post(`/server-redundancy/relocate?reserv=${reserv}&main=${main}`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(checkErrorType(res));
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
