import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import {
  apiGetAllOutputsList,
  getRenderConnectorOnePin,
  apiSetRenderConnectorParam,
  apiTurnRenderConnector,
} from '@services/CreateTaskService';
import { createTaskActions } from '@store/CreateTask';
import Error from '@shared/UI/Errors/Error/Error';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const TransformPinParams = React.memo(
  ({ encoderDef, sectionCoderId, outputPinIndex, pinIndex, sectionId, programNum }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const renderPins = useSelector((state) => state.createTask.transformRenderPins);
    const [encoderListData, setEncodersListData] = React.useState(undefined);
    const [showEncoderListData, setShowEncodersListData] = useState(true);
    const [showError, setShowError] = React.useState(false);
    const [messageError, setMessageError] = React.useState('');
    const [isError, setIsError] = React.useState(false);
    const [isChecked, setIsChecked] = useState(encoderDef?.is_used);
    // console.log('encoderDef', encoderDef);
    // console.log('renderPins', renderPins);
    // console.log(`encoderListData${programNum}-${encoderDef.name}`, encoderListData);
    const applyData = useSelector((state) => state.createTask.transformApplyData);
    // console.log('applyData', applyData);
    useEffect(() => {
      if (applyData) {
        if (
          sectionId === applyData.sectionId &&
          programNum === applyData.programNum &&
          encoderDef.index !== applyData.encoderId
        ) {
          if (encoderListData) {
            const choiseId =
              encoderListData.connectors.findIndex((item) => item.name === applyData.value) + 1;
            updateRenderConnectionParam(choiseId, applyData.value);
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyData]);

    useEffect(() => {
      if (renderPins) {
        if (sectionId === renderPins.sectionId && programNum === renderPins.programNum) {
          if (encoderDef.is_used) {
            getOneRenderPin();
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderPins]);

    const getOneRenderPin = async (isClearError, choise) => {
      !isClearError && dispatch(createTaskActions.setClearErrorRequest());
      //получаем список коннекторов и выбранный кодек внизу
      await getRenderConnectorOnePin(sectionCoderId, encoderDef.index)
        .then((pin) => {
          // console.log('pinON', pin);
          if (pin) {
            const updateEncodersList = {
              ...pin.data.data,
              connectors: pin.data.data.connectors.filter((item) => item !== null),
            };
            // console.log('updateEncodersList', updateEncodersList);
            setEncodersListData(updateEncodersList);
            dispatch(
              createTaskActions.setEncoderDataTransform(
                encoderDef.index,
                choise === 'off' ? false : encoderDef.is_used,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch(createTaskActions.setErrorFromRequest(error));
        });
    };

    React.useEffect(() => {
      setIsChecked(encoderDef?.is_used);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [encoderDef?.is_used]);

    useEffect(() => {
      getEncoders(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEncoders = async (updateIsUsed, isUpdatedOutput) => {
      // console.log('updateIsUsed', updateIsUsed);
      if (updateIsUsed) {
        await getRenderConnectorOnePin(sectionCoderId, encoderDef.index).then((pin) => {
          // console.log('pinON', pin);
          if (pin) {
            const updateEncodersList = {
              ...pin?.data?.data,
              connectors: pin?.data?.data?.connectors?.filter((item) => item !== null),
            };
            setEncodersListData(updateEncodersList);
            dispatch(createTaskActions.setEncoderDataTransform(encoderDef.index, updateIsUsed));
            setShowEncodersListData(true);
          }
        });
      } else {
        dispatch(createTaskActions.setEncoderDataTransform(encoderDef.index, updateIsUsed));
      }

      if (isUpdatedOutput) {
        updateOutput();
      }
    };

    const setTogglePin = (updateIsUsed) => {
      // console.log('updateIsUsed', updateIsUsed);
      const choise = updateIsUsed ? 'on' : 'off';
      apiTurnRenderConnector(sectionCoderId, encoderDef.index, choise)
        .then((res) => {
          // console.log('res', res);
          getEncoders(updateIsUsed, true);
        })
        .catch((error) => {
          setShowError(true);
          setMessageError(error.message);
          // console.dir(error);
        })
        .finally(() => {
          if (!updateIsUsed) {
            getOneRenderPin(true, choise);
          }
        });
    };

    const changePinParam = (e) => {
      const value = e.target.value;
      // console.log('value', value);
      const selectId = encoderListData.connectors.findIndex((item) => item.name === value);
      updateRenderConnectionParam(selectId + 1, value);
    };

    const updateRenderConnectionParam = (selectId, value) => {
      setEncodersListData({
        ...encoderListData,
        current: { index: selectId, section: value },
      });

      dispatch(createTaskActions.setClearErrorRequest());
      setIsError(false);
      apiSetRenderConnectorParam(sectionCoderId, encoderDef.index, selectId)
        .then((res) => {
          updateOutput();

          dispatch(
            createTaskActions.setEncoderDataTransform(encoderDef.index, value ? true : false),
          );
        })
        .catch((error) => {
          dispatch(createTaskActions.setErrorFromRequest(error));
          getOneRenderPin(true);
        });
    };

    const selectOutputAll = () => {
      const needData = {
        encoderId: encoderDef.index,
        value: encoderListData.current.section,
        sectionId: sectionId,
        programNum: programNum,
        choise: encoderListData.current.index,
      };
      dispatch(createTaskActions.setTransformApply(needData));
      // console.log('needData', needData);
    };

    const updateOutput = () => {
      dispatch(createTaskActions.setClearErrorRequest());
      setIsError(false);
      apiGetAllOutputsList()
        .then((items) => {
          const outputData = items?.data?.data;
          const arrSections = outputData ? outputData.map((elem) => elem.section) : [];
          dispatch(createTaskActions.setExchangeOutput(arrSections));
        })
        .catch((error) => {
          dispatch(createTaskActions.setErrorFromRequest(error));
          setIsError(true);
        });
    };

    return (
      <div className="transform-properties-encoder__item">
        <div className="transform-properties-encoder__item-blockLabel">
          <input
            className="source-properties-check"
            onChange={() => setTogglePin(!isChecked)}
            id={`${encoderDef.name}*-check-${encoderDef.index}`}
            type="checkbox"
            checked={isChecked}
          />
          <label
            className="transform-properties-encoder__item-label"
            htmlFor={`${encoderDef.name}*-check-${encoderDef.index}`}
          >
            {encoderDef.name}
          </label>
        </div>

        {showEncoderListData &&
          encoderListData &&
          (pinIndex === 0 && outputPinIndex === 0 ? (
            <div className="transform-properties-encoder__item-blockLabel">
              <select
                value={encoderListData?.current?.section ? encoderListData?.current?.section : ''}
                className={classNames(
                  'transform-properties-encoder__item-select',
                  isError ? 'border-error' : '',
                )}
                onChange={changePinParam}
              >
                <option value={''}>{'none'}</option>
                {encoderListData?.connectors?.map((coder, index) => (
                  <option key={coder.name + index} value={coder.name}>
                    {coder.name}
                  </option>
                ))}
              </select>
              <button
                type="button"
                className="button button-all button-normalText"
                onClick={selectOutputAll}
              >
                {t('apply_to_all')}
              </button>
            </div>
          ) : (
            <select
              value={encoderListData?.current?.section ? encoderListData?.current?.section : ''}
              className={classNames(
                'transform-properties-encoder__item-select',
                isError ? 'border-error' : '',
              )}
              onChange={changePinParam}
            >
              <option value={''}>{'none'}</option>
              {encoderListData?.connectors?.map((coder, index) => (
                <option key={coder.name + index} value={coder.name}>
                  {coder.name}
                </option>
              ))}
            </select>
          ))}
        <ModalPortal showModal={showError}>
          <CSSTransition in={showError} timeout={500} unmountOnExit classNames="alert">
            <Error
              message={messageError}
              setMessageError={setMessageError}
              setShowError={setShowError}
              typeModal={'error'}
              objName={'Pin'}
            />
          </CSSTransition>
        </ModalPortal>
      </div>
    );
  },
);

export default TransformPinParams;
