import React from 'react';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { ReactComponent as SvgMinusSquare } from '@shared/Images/minus-square.svg';
import { ReactComponent as SvgPlusSquare } from '@shared/Images/plus-square.svg';
import s from './TreeViewBase.module.scss';

const TreeViewBase = ({ treeData, setSelectedTreeItemId }) => {
  const handleClick = (node) => {
    node?.id && setSelectedTreeItemId(node.id);
    console.log('treeValue', node.id);
  };

  const renderTree = (node) => (
    <TreeItem
      className={s['tree-item']}
      key={`tree-item ${node.id}`}
      nodeId={`${node.id}`}
      label={node.name}
      collapseIcon={<SvgMinusSquare />}
      expandIcon={<SvgPlusSquare />}
      onClick={() => handleClick(node)}
    >
      {Array.isArray(node.children) ? node.children.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  return <TreeView defaultExpanded={['root']}>{renderTree(treeData)}</TreeView>;
};

export default TreeViewBase;
