export const createTaskActionTypes = {
  SET_SOURCE_PARAMS: 'SERVER.SET_SOURCE_PARAMS',
  SET_LINK_TO_TRANSFORM: 'SERVER.SET_LINK_TO_TRANSFORM',
};

export const serverCreateTaskActions = {
  setSourceParams: (payload) => ({
    type: createTaskActionTypes.SET_SOURCE_PARAMS,
    payload,
  }),
  setLinkToTransform: (payload) => ({
    type: createTaskActionTypes.SET_LINK_TO_TRANSFORM,
    payload,
  }),
};
