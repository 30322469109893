import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const InputBase = ({ name, value, className, disabled }) => {
  const [paramValue, setParamValue] = useState(value);

  const handleChange = (e) => {
    let newValue = e.target.value;
    setParamValue(newValue);
    setValue(`${name}`, newValue.toString());
  };
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setParamValue(value);
    setValue(`${name}`, value.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <input
      key={'input-base_' + name}
      {...register(`${name}`)}
      type="text"
      defaultValue={paramValue}
      onChange={handleChange}
      className={className ? className : ''}
      disabled={disabled}
    />
  );
};

export default InputBase;
