import React, { useState } from 'react';
import {
  apiStartTasks,
  apiStopTasks,
  apiUpdateTaskParameters,
  getTaskParams,
} from '@services/TaskService';
import { useDispatch, useSelector } from 'react-redux';
import { taskParamsActions } from '@store/TaskParams';

const useParamsData = ({ storage_id, schema, setError, dirtyFields, environment }) => {
  const dispatch = useDispatch();
  const [showLoading, setShowloading] = useState(false);
  const [paramsData, setParamsData] = React.useState([]);
  const [arrayTypeName, setArrayTypeName] = React.useState([]);
  const templateSort = ['Source', 'Decode', 'Transform', 'Render'];
  const [showMiniLoader, setShowMiniLoader] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const taskState = useSelector((state) => state.taskParams.taskState);

  React.useEffect(() => {
    setShowloading(true);
    getTaskParams(storage_id, schema)
      .then((res) => {
        // console.log('schema', schema);
        // console.log(res?.data?.data?.graph?.name);
        dispatch(taskParamsActions.setEditTaskName(res?.data?.data?.graph?.name));
        const data = res?.data?.data?.sections;
        setParamsData(
          data.sort((a, b) => templateSort.indexOf(a.type) - templateSort.indexOf(b.type)),
        );
        // Получения названия секций
        const newArr = [];
        data.filter(function (item) {
          let i = newArr.findIndex((x) => x.type === item.type);
          if (i <= -1) {
            newArr.push(item);
          }
          return null;
        });
        const resArray = newArr.map((elem) => (elem.type === 'Render' ? 'Output' : elem.type));
        setArrayTypeName(resArray);
      })
      .catch((error) => {
        // console.log(error);
        dispatch(taskParamsActions.setErrorFromRequest(error));
      })
      .finally(() => {
        setShowloading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema]);

  //если не запускается с первого раза запускаем еще n раз https://redmine.elecard.net.ru/issues/10980
  const startTaskRecursively = (n) => {
    if (n === 0) {
      return;
    }
    apiStartTasks(storage_id, schema, environment).catch((error) => {
      console.dir(error);
      setTimeout(() => startTaskRecursively(n - 1), 1000);
      if (n === 1) {
        setShowError(true);
        setMessageError(error.message);
      }
    });
  };

  const applyData = (command, finishData) => {
    apiUpdateTaskParameters(storage_id, schema, finishData)
      .then((res) => {
        if (command === 'start') {
          // console.log('startted');
          startTaskRecursively(10);
        }
        // console.log('res', res);
        setShowSuccessModal(true);
        setShowMiniLoader(false);
      })
      .catch((err) => {
        console.dir(err);
        setShowMiniLoader(false);
        err.map((el) => setError(el.id, { message: el.message }));
      });
  };

  const onSubmit = (data) => {
    // console.log('data', data);
    // console.log('paramsData', paramsData);
    // console.log('dirtyFields', dirtyFields);
    // let oldData = new Map();
    // console.log('sortData', sortData);
    const changedId = Object.keys(dirtyFields);
    // console.log('changedId', changedId);
    const changedFields = changedId.map((el) => ({
      id: el,
      value: data[el],
    }));
    // console.log('changedFields', changedFields);

    const finishData = changedFields.reduce(
      (obj, item) => Object.assign(obj, { [item.id]: item.value }),
      {},
    );
    // console.log('finishData', finishData);

    if (changedId.length !== 0) {
      //if task status: started
      if (taskState === 2) {
        setShowMiniLoader(true);
        apiStopTasks(storage_id, schema).then(function (res) {
          // console.log(res);
          if (res?.status === 200) {
            applyData('start', finishData);
          } else {
            // console.log(res);
            setShowError(true);
            setMessageError(res.data);
            setShowMiniLoader(false);
          }
        });
      } else {
        // console.log('withoutstart');
        setShowMiniLoader(true);
        applyData('no', finishData);
      }
    }
  };

  return {
    paramsData,
    arrayTypeName,
    onSubmit,
    taskState,
    showMiniLoader,
    showError,
    setShowError,
    messageError,
    setMessageError,
    showSuccessModal,
    setShowSuccessModal,
    showLoading,
  };
};

export default useParamsData;
