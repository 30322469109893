import axios from 'axios';
import { client } from './apiService.jsx';
import { checkToAuth } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs.js';

export const API_URL = client.bookmarkUri;
// console.log('API_URL', API_URL);
require('dotenv').config();

const headers = {
  'Content-Type': 'application/json',
};
const $api = axios.create({
  // withCredentials: true,
  baseURL: API_URL,
  headers: headers,
});

$api.interceptors.request.use((config) => {
  // console.log('config', config);
  return config;
});

$api.interceptors.response.use(
  (config) => {
    if (config.data.error?.code === 401) {
      localStorage.removeItem('authSettings');
      window.history.go('/login');
    } else {
      return config;
    }
  },
  (error) => {
    console.dir(error);
    if (error?.response?.status === 403) {
      checkToAuth();
    }
    // if (error?.response?.status === 401) {
    //   localStorage.removeItem('authSettings');
    //   window.history.go('/login');
    // }
    // window.history.go('/error');
    return error;
  },
);

export default $api;
