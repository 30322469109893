import React from 'react';
import ViewSourceLink from '@shared/UI/ViewSourceLink/ViewSourceLink';
import './OutputSection.scss';
import { useSelector } from 'react-redux';
import OutputParameters from '@widgets/TaskConfigurator/OutputSection/OutputParameters/OutputParameters';
import OutputFiltersItem from './OutputFiltersItem/OutputFiltersItem';
import OutputTabs from './OutputTabs/OutputTabs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Extensions from './Extensions/Extensions';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePinName } from '@shared/Lib/Utils/TaskConfigurator/generalFunctions';

const OutputSection = ({ number, sectionData }) => {
  const { t } = useTranslation();
  const activeBlockTab = useSelector((state) => state.createTask.activeSourceBlock);
  const [filterTab, setFilterTab] = useState(0);
  const methods = useForm();

  return (
    <div className="output-address-section" hidden={activeBlockTab === number ? false : true}>
      <ViewSourceLink uri={`${window.location.origin}/api/configurator?sections=render&type=all`} />
      <FormProvider {...methods}>
        <form className="input-properties input-properties-main">
          <h2 className="input-properties-title">
            {t('output')} - {sectionData.name}
          </h2>
          <OutputTabs
            className="pb-6"
            tabNames={sectionData.filters}
            activeTab={filterTab}
            setActiveTab={setFilterTab}
          />
          <div className="output-filters-item-params">
            {sectionData.filters[filterTab].parameters && (
              <OutputParameters parameters={sectionData.filters[filterTab].parameters} />
            )}

            {sectionData.filters[filterTab].mux_programs &&
              sectionData.filters[filterTab].mux_programs.map((prog) => (
                <div className="output-filters-progs" key={`Mux-${prog.id}`}>
                  <div className="output-filters-name">{`Prog#${prog.id}`}</div>
                  {prog.parameters && <OutputParameters parameters={prog.parameters} />}
                </div>
              ))}

            {sectionData.filters[filterTab].pins &&
              sectionData.filters[filterTab].pins.map((pin) => (
                <div className="output-filters-progs" key={`Pin-${pin.id}`}>
                  <OutputFiltersItem item={{ ...pin, name: generatePinName(pin) }}>
                    {pin?.parameters && <OutputParameters parameters={pin.parameters} />}
                  </OutputFiltersItem>
                </div>
              ))}

            {sectionData.filters[filterTab].extensions && (
              <Extensions
                extensions={sectionData.filters[filterTab].extensions}
                multiplexer_id={sectionData.filters[filterTab].id}
              />
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default OutputSection;
