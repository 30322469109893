import Tooltip from '@mui/material/Tooltip';
import './Users.scss'
import classNames from 'classnames';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import GroupPermissions from '@services/GroupSettings';
import UserService from '@services/UserService';
import {
  handleMainCheckbox1,
  handleToggle1,
  showScroll,
} from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import Fade from '@mui/material/Fade';
import Information from '@shared/UI/Warnings/Information/Information';
import AddUser from './AddUser/AddUser';
import sprite from '@shared/Images/sprite.svg';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';
import { useTranslation } from 'react-i18next';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";

const Users = ({ usersData, getSecureData, selectGroup, blockAction }) => {
  // console.log('usersdata', usersData);
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState([]);
  // console.log('checked', checked);
  const [editData, setEditData] = React.useState(null);
  const [lastSelected, setLastSelected] = React.useState(null);
  const [showAddUser, setShowAddUser] = React.useState(false);
  const [showEditUser, setShowEditUser] = React.useState(false);
  const [showWarn, setShowWarn] = React.useState(false);
  const mainCheckbox = React.useRef();
  const refDataTable = React.useRef();
  // Изменение основных чекбоксов

  const handleToggleChecboxes = (id, name, e) => {
    handleToggle1(
      e,
      id,
      name,
      checked,
      setChecked,
      refDataTable,
      mainCheckbox,
      lastSelected,
      setLastSelected,
    );
  };

  React.useEffect(() => {
    setChecked([]);
  }, [selectGroup]);

  // Изменение главного чекбокса
  const handleToggleMainCheckbox = (e) => {
    handleMainCheckbox1(e, refDataTable, mainCheckbox, setChecked);
  };

  const hideModal = () => {
    setShowAddUser(false);
    showScroll();
  };

  const showAddUserModal = () => {
    setShowAddUser(true);
  };

  const showModalWarnMessage = () => {
    setShowWarn(true);
  };

  const deleteUsers = async () => {
    // console.log('checked', checked);
    const asyncRes = await Promise.all(
      checked.map(
        async (elem) =>
          await UserService.deleteUser(elem.id)
            .then((response) => {
              // console.log(mainCheckbox);
              getSecureData();
              const updateUser = checked.filter((id) => id === elem.id);
              setChecked(updateUser);
              mainCheckbox.current.checked = false;
            })
            .catch((error) => {
              console.dir(error);
            }),
      ),
    );
    await asyncRes;
    GroupPermissions.applyUpdate().then((response) => {
      // console.log('apply', response);
    });
    GroupPermissions.refreshSession();
  };

  // Показ модального с изменением юзера
  const showModalEditUser = (e) => {
    const data = { ...e.target.dataset };
    // console.log(data);
    setEditData(data);
    setShowEditUser(true);
  };

  return (
    <div className="permissions-item permissions-item-show">
      <div className="permissions">
        {showAddUser && (
          <>
            <AddUser
              selectGroup={selectGroup}
              getSecureData={getSecureData}
              setShowAddUser={setShowAddUser}
              usersData={usersData}
            />
            <div className="overlay" onClick={hideModal}></div>
          </>
        )}
        {showEditUser && (
          <>
            <AddUser
              selectGroup={selectGroup}
              getSecureData={getSecureData}
              setShowAddUser={setShowEditUser}
              usersData={usersData}
              edit={true}
              editData={editData}
            />
            <div className="overlay" onClick={hideModal}></div>
          </>
        )}
        <div className="home-box__users-header">
          <h2 className="subheader-text">{t('users')}</h2>
          <div className="users__buttons">
            <ButtonBase
              color={'green'}
              disabled={blockAction ? true : checked.length > 0 ? true : false}
              onClick={showAddUserModal}
            >
              {t('add_user')}
            </ButtonBase>
            <ButtonBase
              color={'red'}
              disabled={checked.length === 0 ? true : false}
              onClick={showModalWarnMessage}
            >
              {t('delete_user')}
            </ButtonBase>
          </div>
        </div>

        {usersData.length === 0 ? (
          <table className="table">
            <thead className="t-head">
            <tr className="table-row-head ">
              <th className="table-row__head table-row-check">
                <div className="table-box-check">
                  <input type="checkbox" disabled />
                </div>
              </th>
              <th className="table-row__head table-row-check">
                <div className="group-box">{t('#')}</div>
              </th>
              <th className="table-row__head table-row-playlist">
                  <div className="group-box">{t('name')}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-row">
                <td className="table-row__body"></td>
                <td className="table-row__body"></td>
                <td className="table-row__body">
                  <p className="table__text">{t('no_users')}</p>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table className="table">
            <thead className="t-head">
              <tr className="table-row-head ">
                <th className="table-row__head table-row-check">
                  <div className="table-box-check">
                    <input
                      disabled={blockAction ? true : false}
                      className="checkbox"
                      type="checkbox"
                      name="elem"
                      id="checkall"
                      onChange={(e) => handleToggleMainCheckbox(e)}
                      ref={mainCheckbox}
                    />
                  </div>
                </th>
                <th className="table-row__head table-row-check">
                  <div className="group-box">{t('#')}</div>
                </th>
                <th className="table-row__head table-row-playlist">
                  <div className="group-box">{t('name')}</div>
                </th>
                <th className="table-row__head table-row-action2">
                  <div className="group-box jc-c">{t('control')}</div>
                </th>
              </tr>
            </thead>
            <tbody ref={refDataTable}>
              {usersData.map((item, i) => (
                <tr className="table-row" key={item.id} data-key={item.id}>
                  <td className="table-row__body">
                    <div className="table-box-check">
                      <input
                        disabled={blockAction ? true : false}
                        className="checkbox"
                        type="checkbox"
                        data-id={item.id}
                        onChange={(e) => {
                          handleToggleChecboxes(item.id, item.name, e);
                        }}
                      />
                    </div>
                  </td>
                  <td className="table-row__body">
                    <div className="group-box">{i + 1}</div>
                  </td>
                  <td className="table-row__body">
                    <div className="group-box">
                      <p className="table__text">{item.name}</p>
                    </div>
                  </td>
                  <td className="table-row__body">
                    <div className="table-action-center">
                      <Tooltip
                        enterNextDelay={500}
                        enterDelay={500}
                        disableInteractive
                        placement={'top'}
                        title="Edit user"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <svg
                          className={classNames(
                            `table-action-btns__img`,
                            blockAction ? 'pic-disabled' : '',
                          )}
                          data-id={item.id}
                          data-uname={item.name}
                          onClick={blockAction ? () => true : showModalEditUser}
                        >
                          <use
                            href={sprite + '#edit'}
                            data-id={item.id}
                            data-uname={item.name}
                          ></use>
                        </svg>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <ModalPortal showModal={showWarn}>
          <CSSTransition in={showWarn} timeout={500} unmountOnExit classNames="alert">
            <Information
              setShowInform={setShowWarn}
              message={t('delete_selected_users')}
              withFunc={true}
              func={deleteUsers}
            />
          </CSSTransition>
        </ModalPortal>
      </div>
    </div>
  );
};

export default Users;
