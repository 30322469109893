import React from 'react';
import { Link } from 'react-router-dom';
import './Logo.scss';

const Logo = () => {
  const companyName = process.env.REACT_APP_COMPANY_NAME;
  const productName = process.env.REACT_APP_PRODUCT_NAME;

  return (
    <h1 className="logo">
      <Link className="logo__link" to="/" aria-label="logo">
        {companyName === 'Elecard' ? (
          <>
            <img className="logo__img" src="/logo.svg" alt="logo" />
            <p className="logo__text">CODEC</p>
            <p className="logo__text--blue">WORKS</p>
          </>
        ) : companyName === 'UniqCast' ? (
          <img className="logo-u" src="/uniqcast-logo.svg" alt="logo" />
        ) : companyName === 'NGN' ? (
          <>
            <img className="logo-u" src="/logo192.png" alt="logo" />
            <p className="logo__text pl-5">{productName}</p>
          </>
        ) : null}
      </Link>
    </h1>
  );
};

export default Logo;
