import classNames from 'classnames';
import React from 'react';
import s from './TabItem.module.scss';

const TabItem = ({ onClick, children, tabText, active }) => {
  return (
    <li
      onClick={onClick}
      className={classNames(s['tab-item'], active && s['tab-item_active'])}
      data-testid="tab-item"
    >
      <div>{tabText}</div>
      {children}
    </li>
  );
};

export default TabItem;
