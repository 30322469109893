import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import InputBase from '@shared/UI/InputBase/InputBase';
import SelectBase from '@shared/UI/SelectBase/SelectBase';
import s from './InputParameters.module.scss';
import InputError from '@shared/UI/InputError/InputError';
import TextFieldWrapper from '@shared/UI/TextFieldWrapper/TextFieldWrapper';

const InputParameters = ({ parameters, isUseGuid, disabled }) => {
  const {
    formState: { errors },
  } = useFormContext();

  // param?.guid т к необходим autocomplete для Multicast Group or Unicast Address и Port
  const paramName = (param) => (isUseGuid ? param?.guid : param?.id);

  return (
    <>
      {parameters?.map(
        (param, i) =>
          param.visible &&
          !param.readonly && (
            <div key={param.name + param.id + 'parameter' + i}>
              {errors[paramName(param)] && <InputError text={errors[paramName(param)]?.message} />}
              <TextFieldWrapper label={param.name}>
                <div
                  className={classNames(s['params__item-value'], s['params__item-value--adaptive'])}
                  data-type={param.type}
                >
                  {param.valid_options ? (
                    <SelectBase
                      className={classNames(
                        s['source-input__param'],
                        s['source-input__param--adaptive'],
                      )}
                      name={paramName(param)}
                      value={param.value?.toString()}
                      valid_options={param?.valid_options}
                      disabled={disabled}
                    />
                  ) : (
                    <InputBase
                      className={classNames(
                        s['source-input__param'],
                        s['source-input__param--adaptive'],
                      )}
                      name={paramName(param)}
                      value={param?.value}
                      disabled={disabled}
                    />
                  )}
                </div>
              </TextFieldWrapper>
            </div>
          ),
      )}
    </>
  );
};

export default InputParameters;
