import s from './TextTooltip.module.scss';
import classNames from 'classnames';

// classNamePosition - обязательный пропс-класс с параметрами позиционирования absolut на месте монтирования
const TextTooltip = ({ isOpen, text, classNamePosition }) => {
    return (
        <span className={classNames(s['text-tooltip'], isOpen ? s['text-tooltip_visible'] : '', classNamePosition ? classNamePosition : '')}>
            {text}
        </span>
    )
}

export default TextTooltip;