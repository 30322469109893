import React from 'react';
import s from './ResizableBlock.module.scss';
import classNames from 'classnames';
import ButtonBase from '@shared/UI/Buttons/ButtonBase/ButtonBase';
import ModalHeader from '@shared/UI/ModalHeader/ModalHeader';

const ResizableBlock = ({ title, classNameResizableBlock, children, handleClick, disabled }) => {
  return (
    <div className={classNames(s['resizable-block'], classNameResizableBlock)}>
      <ModalHeader
        classNameTitle={s['resizable-block__title']}
        title={title}
        buttonComponent={
          <div className={s['resizable-block__button']}>
            <ButtonBase color={'green'} onClick={() => handleClick()} disabled={disabled}>
              Add
            </ButtonBase>
          </div>
        }
      />
      <div>{children}</div>
    </div>
  );
};

export default ResizableBlock;
