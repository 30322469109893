import React from 'react';
import { GetResourceTypes, GetSocket, ResourceTypes } from '@services/apiService';
import { navMenuIndex } from '@store/NavMenu';
import NavMenu from '@widgets/NavMenu/NavMenu';
import Redundancy from '@pages/Redundancy/Redundancy';
import { ResourceProvider, WebSocketProvider } from '@components/ResourceProvider/ResourceProvider';
import SecuritySettings from '@pages/SecuritySettings/SecuritySettings';
import Server from '@pages/Server/Server';
import TaskInfo from '@widgets/Task/TaskInfo/TaskInfo';
import ComponentView from '@components/ViewSourceLink/ComponentView';
import PageTitle from '@entities/PageTitle/PageTitle';
import ErrorBoundary from '@components/Router/ErrorBoundary/ErrorBoundary';
import ServerTasksActive from '@widgets/Server/ServerData/ServerTasksActive/ServerTasksActive';
import ServerList from '@pages/ServerList/ServerList';

const RouterComponent = ({ state, error }) => {
  const components = [];
  // const history = useHistory();
  const types = GetResourceTypes(state);
  // console.log('authSettings', authSettings);
  // console.log('components', components);
  // console.log('types', types);
  // console.log('state', state);

  if (error) {
    return (
      <>
        <PageTitle />
        <ResourceProvider Components={NavMenu} key="NavMenu" state="/" />
        <ComponentView state={state} error={error} />
      </>
    );
  }

  let menuIndex = navMenuIndex.UNKNOWN;
  // console.log('types', types);

  if (types.includes(ResourceTypes.RESOURCE_SERVER)) {
    components.push(<Server key="ServerItem" state={state} />);
    menuIndex = navMenuIndex.SERVER;
  }

  if (types.includes(ResourceTypes.RESOURCE_LOGS)) {
    menuIndex = navMenuIndex.LOG;
  }

  if (types.includes(ResourceTypes.SERVER_LIST)) {
    components.push(<ServerList key="ServerList" state={state} />);
  }

  if (types.includes(ResourceTypes.SECURITY_SETTINGS)) {
    components.push(<SecuritySettings key="SecuritySettings" state={state} />);
  }

  if (types.includes(ResourceTypes.RESOURCE_REDUNDANCY)) {
    components.push(<Redundancy key="Redundancy" state={state} />);
  }

  if (components.length === 0) {
    if (state.links.has('cw:channel')) {
      menuIndex = navMenuIndex.SERVER;
      components.push(
        <WebSocketProvider
          key="ServerTasks"
          Components={ServerTasksActive}
          socket={GetSocket('ServerTasks')}
          state={state}
        />,
      );
    }
    /*   if (state.links.has('cw:events')) {
      menuIndex = navMenuIndex.SERVER;
      components.push(<ServerSettings key="ServerSettings" state={state} />);
    } */
    if (state.links.has('cw:job')) {
      menuIndex = navMenuIndex.TASK;
      components.push(
        <WebSocketProvider
          key="TaskInfo"
          Components={TaskInfo}
          socket={GetSocket('TaskInfo')}
          state={state}
        />,
      );
    }

    components.push(<PageTitle key="PageTitle" index={menuIndex} />);
  }

  return (
    <>
      <ResourceProvider Components={NavMenu} key="NavMenu" state="/" menuIndex={menuIndex} />
      {components.length > 0 ? (
        <ErrorBoundary>{components}</ErrorBoundary>
      ) : (
        <ComponentView state={state} error={'No data'} />
      )}
    </>
  );
};

export default RouterComponent;
