import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiGetAllOutputsList } from '@services/CreateTaskService';
import { createTaskActions } from '@store/CreateTask';
import { apiDeleteExtension } from '@widgets/TaskConfigurator/OutputSection/api/apiExtensions';
import { CSSTransition } from 'react-transition-group';
import Error from '@shared/UI/Errors/Error/Error';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';
import ButtonClose from '@shared/UI/Buttons/ButtonClose/ButtonClose';
import Collapse from '@shared/UI/Collapse/Collapse';
import s from './OutputFiltersItem.module.scss';

const OutputFiltersItem = ({ item, showCloseButton, children }) => {
  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const dispatch = useDispatch();

  const handlerClickCloseButton = (e, item) => {
    e.stopPropagation();
    apiDeleteExtension(item.id)
      .then(() => {
        apiGetAllOutputsList().then((items) => {
          const outputData = items?.data?.data;
          const arrSections = outputData ? outputData.map((elem) => elem.section) : [];
          dispatch(createTaskActions.setExchangeOutput(arrSections));
        });
      })
      .catch((error) => {
        setMessageError(error.message);
        setShowError(true);
      });
    e.preventDefault();
  };
  return (
    <>
      <div className={'po-r'}>
        <Collapse
          classNameControl={s['output-filters-item']}
          text={item.name}
          isShowFirst={true}
          classNameButton="svg-button_dark"
        >
          <div className={s['output-filters-item-params']}>{children}</div>
        </Collapse>
        {showCloseButton && <ButtonClose onClick={(e) => handlerClickCloseButton(e, item)} />}
      </div>
      <ModalPortal showModal={showError}>
        <CSSTransition in={showError} timeout={500} unmountOnExit classNames="alert">
          <Error
            setShowError={setShowError}
            message={messageError}
            setMessageError={setMessageError}
          />
        </CSSTransition>
      </ModalPortal>
    </>
  );
};

export default OutputFiltersItem;
