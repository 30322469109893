import $api from './apiAxios';

export default class GroupPermissions {
  static async applyUpdate() {
    return $api.post('/user-permissions?command=apply');
  }
  static async unlock() {
    return $api.post('/user-permissions?command=unlock');
  }
  static async refreshSession() {
    return $api.post('/user-permissions?command=try_lock');
  }
  static async resetSession() {
    return $api.post('/user-permissions?command=reset');
  }
  static async addGroup(groupname) {
    return $api.post('/user-permissions?command=add&type=group', groupname);
  }
  static async deleteGroup(id) {
    return $api.delete(`/user-permissions?type=group&id=${id}`);
  }
}
