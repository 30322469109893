import React from 'react';
import DraggableModal from '@shared/UI/DraggableModal/DraggableModal';
import CPUCoreTable from '@widgets/Server/CPUCoreTable/CPUCoreTable';
import { useTranslation } from 'react-i18next';

const CPUInfoModal = ({
  setShowModal,
  cpuInfo,
  showLoader,
  serverName,
  classNameDraggableModal,
}) => {
  const { t } = useTranslation();

  return (
    <DraggableModal
      showLoader={showLoader}
      setShowModal={setShowModal}
      title={t('cpu_core_utilization')}
      subtitle={serverName}
      className={classNameDraggableModal}
    >
      {cpuInfo && (
        <>
          {serverName && (
            <p className="ta-c fw-500">
              {t('server_name')}: {serverName}
            </p>
          )}
          <p className="ta-c">{cpuInfo?.name}</p>
          {cpuInfo?.data && (
            <>
              <p className="ta-c">
                {t('logical_processors')}: {cpuInfo?.data?.length}
              </p>
              <CPUCoreTable cpuDetails={cpuInfo?.data} />
            </>
          )}
        </>
      )}
    </DraggableModal>
  );
};

export default CPUInfoModal;
