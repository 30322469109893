import React, { useState } from 'react';
import ExtensionNode from '@widgets/TaskConfigurator/OutputSection/ExtensionNode/ExtensionNode';
import { useEffect } from 'react';
import { apiGetSimulCryptData, apiSetSimulCryptData } from '@widgets/TaskConfigurator/OutputSection/api/apiExtensions';
import { useDispatch } from 'react-redux';
import s from './SimulCrypt.module.scss';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import MiniInfo from '@shared/UI/Warnings/MiniInfo/MiniInfo';
import { useForm, useFormState } from 'react-hook-form';
import ErrorParam from '@shared/UI/Errors/ErrorParam/ErrorParam';
import classNames from 'classnames';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import { updateOutputs } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';

const SimulCrypt = ({ extensions }) => {
  const dispatch = useDispatch();
  const methods = useForm({ mode: 'onBlur' });
  const {
    register,
    control,
    setValue,
    resetField,
    setError,
    formState: { errors },
  } = methods;
  const { dirtyFields } = useFormState({ control });
  // console.log('dirtyFields', dirtyFields);
  // console.log('errors', errors);

  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [jsonText, setJsonText] = useState();

  useEffect(() => {
    if (toggle) {
      resetField(`${extensions?.id}`);
      apiGetSimulCryptData(extensions?.id)
        .then((res) => {
          // console.log('res', res);
          let newJsonText = res?.data?.data;
          setJsonText(newJsonText ? JSON.stringify(newJsonText, null, 4) : '');
        })
        .catch((error) => {
          console.dir(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  // console.log('jsonText', jsonText);

  const handleChange = (e) => {
    let newValue = e.target.value;
    setJsonText(newValue);
    setValue(`${extensions?.id}`, newValue, { shouldDirty: true });
  };

  const handleBlur = async () => {
    if (!dirtyFields?.[`${extensions?.id}`]) {
      return;
    }

    let newData = null;
    try {
      let res = JSON.parse(jsonText);
      newData = res;
    } catch (error) {
      console.dir(error);
      setError(`${extensions?.id}`, { message: error?.message });
    }

    if (newData) {
      setShowLoader(true);
      await apiSetSimulCryptData(extensions?.id, newData)
        .then(async () => {
          resetField(`${extensions?.id}`);
          return await updateOutputs(dispatch)
            .then(() => {
              setShowSuccessModal(true);
            })
            .finally(() => {
              setShowLoader(false);
            });
        })
        .catch((error) => {
          console.dir(error);
        });

      setShowLoader(false);
    }
  };

  return (
    <>
      <div>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={toggle} onChange={() => setToggle((toggle) => !toggle)} />}
            label={'Edit JSON'}
          />
        </FormGroup>
      </div>

      {toggle ? (
        <>
          <textarea
            {...register(`${extensions?.id}`)}
            className={classNames(s['text-input'], errors?.[`${extensions?.id}`] && 'border-error')}
            value={jsonText}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors?.[`${extensions?.id}`] && (
            <ErrorParam messageError={errors?.[`${extensions?.id}`]?.message} />
          )}
        </>
      ) : showLoader ? (
        <Loader top={true} />
      ) : (
        <ExtensionNode node={extensions} />
      )}
      <CSSTransition in={showSuccessModal} timeout={500} unmountOnExit classNames="alert">
        <MiniInfo setShowInform={setShowSuccessModal} message={'Changes made successfully'} />
      </CSSTransition>
    </>
  );
};

export default SimulCrypt;
