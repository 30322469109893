import React from 'react';
import DraggableModal from '@shared/UI/DraggableModal/DraggableModal';
import { useTranslation } from 'react-i18next';
import Collapse from '@shared/UI/Collapse/Collapse';
import {
  addPercentSignToValue,
  convertKbToHumanReadable,
} from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gpuInfoActions } from '@store/GpuInfo/actions';

const GPUInfoModal = ({ setShowModal, gpuInfo, classNameDraggableModal }) => {
  const dispatch = useDispatch();
  const checkedIds = useSelector((state) => state.gpuInfo.checkedIds);

  const { t } = useTranslation();

  const [isCheckAll, setIsCheckAll] = useState(false);

  const checkedAll = () => {
    setIsCheckAll(!isCheckAll);

    if (isCheckAll) {
      dispatch(gpuInfoActions.setNewArray([]));
    } else {
      let newArr = [];
      gpuInfo?.forEach((item) => {
        newArr.push(item.id);
      });

      dispatch(gpuInfoActions.setNewArray(newArr));
    }
  };

  const checkedOne = (e) => {
    const { id, checked } = e.target;
    dispatch(gpuInfoActions.setCheckedId({ id, checked }));
  };

  useEffect(() => {
    if (checkedIds.length === 0) {
      setIsCheckAll(false);
    }

    if (checkedIds.length === gpuInfo?.length) {
      setIsCheckAll(true);
    }
  }, [gpuInfo?.length, checkedIds.length]);

  return (
    <DraggableModal
      title={t('gpu_utilization')}
      setShowModal={setShowModal}
      className={classNameDraggableModal}
    >
      <div className="network-statistic__table">
        <div className="network-statistic__table--scroll">
          <table className="table tl-a">
            <thead className="thead">
              <tr className="network-statistic__table__row">
                <th className="w-5 pl-10">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={isCheckAll}
                    onChange={checkedAll}
                  />
                </th>
                <th className="w-5">{t('#')}</th>
                <th className="ta-l-15-p w-60 pl-10">{t('name')}</th>
                <th className="ta-l-15-p w-30 pl-10">{t('memory')}</th>
                <th className="ta-l-15-p w-10 pl-10">{t('usage')}</th>
              </tr>
            </thead>
            <tbody>
              {gpuInfo?.map((item) => (
                <tr key={`${item.name}_${item.id}`} className="network-statistic__table__row">
                  <td className="pl-10">
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={checkedIds.includes(item?.id)}
                      onChange={checkedOne}
                      id={item?.id}
                    />
                  </td>
                  <td className="ff-roboto">{item?.id + 1}</td>
                  <td className="pl-10 ta-l-15-p w-200">
                    <Collapse text={item.name}>
                      <pre className="collapse__pre">
                        <b>{t('driver_version')}: </b>
                        {item.driver_ver}
                      </pre>
                    </Collapse>
                  </td>
                  <td className="ta-l-15-p ff-roboto pl-10">
                    {`${convertKbToHumanReadable(item.used_mem, t)} / ${convertKbToHumanReadable(
                      item.total_mem,
                      t,
                    )}`}
                  </td>
                  <td className="ta-l-15-p ff-roboto pl-10">{addPercentSignToValue(item.util)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DraggableModal>
  );
};

export default GPUInfoModal;
