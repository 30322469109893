import { formErrorObject } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import $api from '@services/apiAxios';

export const apiGetDraftForEditTask = async (storage_id, schema) => {
  try {
    const res = await $api.post(
      `/configurator/reconfigure?storage_id=${storage_id}&schema=${schema}`,
    );
    // console.log(res);
    if (res.isAxiosError) {
      console.dir(res);
      let newError = formErrorObject(res);
      return Promise.reject(newError);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiGetAllOutputsListForEdit = async () => {
  try {
    //'CW_CONFIGURATOR' переключение между режимом разработчика (dev) и режимом для пользователя (special)
    //let modifier = Cookies.get('CW_CONFIGURATOR');
    const res = await $api.get(
      `/configurator/sections/source?type=all`,
      /* ${
          modifier ? `&modifier=${modifier}` : '&modifier=special'
        }, */
    );
    if (res.isAxiosError) {
      console.dir(res);
      if (res.response?.status !== 400) {
        throw new Error(res.message);
      }
    } else {
      return res;
    }
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};
