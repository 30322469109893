import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import './InputMeasureable.scss';
import classNames from 'classnames';

const InputMeasureable = ({
  disabled,
  labelName,
  inputName,
  defaultValue,
  inputMeasure,
  onKeyPress,
  min,
  max,
  inputClassName,
  wrapperClassName,
  useFormObjectName,
  isUpdateValue,
}) => {
  const { register, setValue } = useFormContext();
  const registerName = useFormObjectName ? useFormObjectName + inputName : inputName;

  useEffect(() => {
    if (!isNaN(defaultValue)) {
      setValue(`${registerName}`, +defaultValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateValue, defaultValue]);

  return (
    <div
      className={classNames('input-wrapper--small-gap ', wrapperClassName ? wrapperClassName : '')}
    >
      {labelName && (
        <label htmlFor={inputName} className="input-wrapper__label" disabled={disabled}>
          {labelName}
        </label>
      )}
      <div className="input--measureable" disabled={disabled}>
        <input
          id={inputName}
          {...register(registerName)}
          className={classNames(
            'input--measureable__input num ',
            inputClassName ? inputClassName : '',
          )}
          type="number"
          min={min}
          max={max}
          onKeyPress={onKeyPress}
          autoComplete="off"
          disabled={disabled}
          defaultValue={defaultValue}
          onChange={(e) => {
            const updateValue = e.target.value;
            register(`${registerName}`).onChange(e);
            setValue(`${registerName}`, +updateValue);
          }}
        />
        <div className="input--measureable__measure">{inputMeasure}</div>
      </div>
    </div>
  );
};

export default InputMeasureable;
