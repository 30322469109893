import $api from './apiAxios';

const tabsCurrent = {
  dispatcher: 'current',
  SNMP: 'snmp/current',
  web_server: 'web-server/current',
};

export const apiGetLogData = async (path, queryParams, activeTab) => {
  const res = await $api.get(`${path}/${tabsCurrent[activeTab]}${queryParams ? queryParams : ''}`);
  // console.log(res);
  if (res.isAxiosError) {
    //console.dir(res);
    return Promise.reject(res);
  }
  return res;
};

const tabsArchive = {
  dispatcher: 'archive',
  SNMP: 'snmp/archive',
  web_server: 'web-server/archive',
};
export const apiGetArchiveList = async (path, activeTab, queryParams) => {
  // console.log('path', path);
  const res = await $api.get(`${path}/${tabsArchive[activeTab]}${queryParams ? queryParams : ''}`);
  // console.log(res);
  if (res.isAxiosError) {
    //console.dir(res);
    return Promise.reject(res);
  }
  return res;
};

export const apiGetArchiveLog = async (path, activeTab, queryParams, name) => {
  const res = await $api.get(
    `${path}/${tabsArchive[activeTab]}/${name}${queryParams ? queryParams : ''}`,
  );
  // console.log(res);
  if (res.isAxiosError) {
    //console.dir(res);
    return Promise.reject(res);
  }
  return res;
};
