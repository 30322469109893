import { useState } from 'react';
import './Extensions.scss';
import React from 'react';
import OutputTabs from '@widgets/TaskConfigurator/OutputSection/OutputTabs/OutputTabs';
import ExtensionNode from '@widgets/TaskConfigurator/OutputSection/ExtensionNode/ExtensionNode';
import MuxPrograms from '@widgets/TaskConfigurator/OutputSection/MuxPrograms/MuxPrograms';
import DVBTables from '@widgets/TaskConfigurator/OutputSection/DVBTables/DVBTables';
import ExtensionAddButtons from '@widgets/TaskConfigurator/OutputSection/ExtensionAddButtons/ExtensionAddButtons';
import SimulCrypt from '@widgets/TaskConfigurator/OutputSection/SimulCrypt/SimulCrypt';

const Extensions = ({ extensions, multiplexer_id }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <OutputTabs
        className="pb-6"
        tabNames={extensions?.extensions}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        showCloseButton={true}
      >
        <ExtensionAddButtons
          className={'button-wrapper output-tabs__button-add'}
          extension={extensions}
          dependencies={[extensions, activeTab]}
          actionAfterAdd={() =>
            setActiveTab(extensions && extensions?.extensions ? extensions?.extensions.length : 0)
          }
        />
      </OutputTabs>

      {extensions?.extensions && extensions?.extensions[activeTab] && (
        <div className="extensions-data">
          {extensions?.extensions[activeTab]?.name === 'MuxPrograms' ? (
            <MuxPrograms
              muxPrograms={extensions?.extensions[activeTab]}
              multiplexer_id={multiplexer_id}
            />
          ) : extensions?.extensions[activeTab]?.name === 'DVB Tables' ? (
            <DVBTables dvbTables={extensions?.extensions[activeTab]} />
          ) : extensions?.extensions[activeTab]?.name === 'SimulCrypt' ? (
            <SimulCrypt extensions={extensions?.extensions[activeTab]} />
          ) : (
            <ExtensionNode node={extensions?.extensions[activeTab]} />
          )}
        </div>
      )}
    </>
  );
};

export default Extensions;
