import React from 'react';
import s from './ModalHeader.module.scss';
import classNames from 'classnames';

const ModalHeader = ({ title, buttonComponent, classNameTitle }) => {
  return (
    <div className={s['modal-header']}>
      <div className={classNames(classNameTitle)}>{title}</div>
      {buttonComponent}
    </div>
  );
};

export default ModalHeader;
