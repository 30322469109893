import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import ErrorParam from '@shared/UI/Errors/ErrorParam/ErrorParam';
import InputStretchable from '@shared/UI/InputStretchable/InputStretchable';
import classNames from 'classnames';
import s from './OutputParametersTaskParams.module.scss';
import TextFieldWrapper from '@shared/UI/TextFieldWrapper/TextFieldWrapper';
import Collapse from '@shared/UI/Collapse/Collapse';
import { generatePinName } from '@shared/Lib/Utils/TaskConfigurator/generalFunctions';

const OutputParametersTaskParams = ({ filter, type }) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const { dirtyFields } = useFormState({
    control,
  });

  return (
    <div className={s["output-parameters-task-params"]}>
      {filter?.parameters?.map((param, i) => (
        <React.Fragment key={param.name + param.id}>
          <TextFieldWrapper label={param.name} dataType={param.type}>
            {param.valid_options ? (
              <select
                defaultValue={param.value?.toString()}
                className={classNames(
                  s['params__item-input'],
                  s['params__item-input--select'],
                  dirtyFields[param.id] ? s['params__item-input--changed'] : '',
                )}
                {...register(`${param.id}`)}
              >
                {param?.valid_options?.map((elem, index) => (
                  <option key={elem.meaning + index + param.id} value={elem.value.toString()}>
                    {elem.value.toString()} ({elem.meaning})
                  </option>
                ))}
              </select>
            ) : (
              <InputStretchable
                param={param}
                className={classNames(
                  s['params__item-input'],
                  dirtyFields[param.id] ? s['params__item-input--changed'] : '',
                  errors[param.id] ? s['params__item-input--error'] : '',
                )}
              />
            )}
          </TextFieldWrapper>

          {errors[param.id] && <ErrorParam messageError={errors[param.id]?.message} />}
        </React.Fragment>
      ))}
      {filter?.mux_programs
        ? filter?.mux_programs?.map((program, i) => (
            <ul key={'mux-program' + program.id} className="sections-filters-block pl-10 width-100">
              <Collapse
                text={`Mux program-${program.id}`}
                classNameText={s['sections-filters__name']}
                isFullWidth={true}
              >
                {program?.parameters?.map((param, i) => (
                  <li key={param.name + program.id + i + param.id}>
                    <TextFieldWrapper label={param.name} dataType={param.type}>
                      {param.valid_options ? (
                        <select
                          defaultValue={param.value?.toString()}
                          className={classNames(
                            s['params__item-input'],
                            s['params__item-input--select'],
                            dirtyFields[param.id] ? s['params__item-input--changed'] : '',
                          )}
                          {...register(`${param.id}`)}
                        >
                          {param?.valid_options?.map((elem, index) => (
                            <option key={elem.meaning + index} value={elem.value.toString()}>
                              {elem.value.toString()} ({elem.meaning})
                            </option>
                          ))}
                        </select>
                      ) : (
                        <InputStretchable
                          param={param}
                          className={classNames(
                            s['params__item-input'],
                            dirtyFields[param.id] ? s['params__item-input--changed'] : '',
                            errors[param.id] ? s['params__item-input--error'] : '',
                          )}
                        />
                      )}
                    </TextFieldWrapper>
                    {errors[param.id] && <ErrorParam messageError={errors[param.id]?.message} />}
                  </li>
                ))}
              </Collapse>
            </ul>
          ))
        : null}
      {filter?.pins
        ? filter?.pins?.map((pin, idx) => (
            <ul
              key={'Pin' + pin.name + pin.id}
              className={classNames(s['sections-filters-block'], 'pl-10 width-100')}
            >
              <Collapse
                classNameText={s['sections-filters__name']}
                text={generatePinName(pin, type)}
                isShowFirst={false}
                isFullWidth={true}
              >
                {pin?.parameters?.map((param, i) => (
                  <li key={param.name + param.id}>
                    <TextFieldWrapper label={param.name} dataType={param.type}>
                      {param.valid_options ? (
                        <select
                          defaultValue={param.value?.toString()}
                          className={classNames(
                            s['params__item-input'],
                            s['params__item-input--select'],
                            dirtyFields[param.id] ? s['params__item-input--changed'] : '',
                          )}
                          {...register(`${param.id}`)}
                        >
                          {param?.valid_options?.map((elem, index) => (
                            <option key={elem.meaning + index} value={elem.value.toString()}>
                              {elem.value.toString()} ({elem.meaning})
                            </option>
                          ))}
                        </select>
                      ) : (
                        <InputStretchable
                          param={param}
                          className={classNames(
                            s['params__item-input'],
                            dirtyFields[param.id] ? s['params__item-input--changed'] : '',
                            errors[param.id] ? s['params__item-input--error'] : '',
                          )}
                        />
                      )}
                    </TextFieldWrapper>

                    {errors[param.id] && <ErrorParam messageError={errors[param.id]?.message} />}
                  </li>
                ))}
              </Collapse>
            </ul>
          ))
        : null}
    </div>
  );
};

export default OutputParametersTaskParams;
