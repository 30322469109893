import React, { useEffect, useState } from 'react';
import './Thresholds.scss';
import InputMeasureable from '@shared/UI/InputMeasureable/InputMeasureable';
import { useTranslation } from 'react-i18next';
import { formErrorObject, inputCheckToNumber, saveDirtyFields } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import { useForm, useFormContext, useFormState } from 'react-hook-form';
import classNames from 'classnames';
import SelectBasic from '@shared/UI/SelectBasic/SelectBasic';
import { logLevelsList } from '@widgets/ServerSettings/lib/ServerSettingsDictionary';
import InputCheckbox from '@shared/UI/InputCheckbox/InputCheckbox';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';
import Modal from '@shared/UI/Modal/Modal';
import { setEnviroment } from './api/apiThresholds';
import { FILE_NAME_REGEX, FORBIDDEN_SYMBOLS_REGEX } from '@shared/Lib/Utils/Constants/Constants';
import ButtonAction from '@shared/UI/Buttons/ButtonAction/ButtonAction';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";

const Thresholds = ({
  activeSettingsNavItem,
  thresholds,
  setThresholds,
  currentMode,
  setCurrentMode,
  setShowError,
  setMessage,
}) => {
  const [enviromentValidOptions, setEnviromentValidOptions] = useState();
  const [showModal, setShowModal] = useState({type: '', isOpen: false});
  const [isValid, setIsValid] = useState({status: false, error: ''});

  const { t } = useTranslation();
  const useFormObjectName = 'Enviroment';
  const useFormObjectNameWithDot = useFormObjectName + '.';
  const { register, handleSubmit, reset, setValue } = useForm();

  const { watch, control } = useFormContext();
  const { dirtyFields } = useFormState({
    control,
  });

  // console.log('dirtyFields', dirtyFields);
  useEffect(() => {
    thresholds?.env_modes &&
      setEnviromentValidOptions(thresholds?.env_modes.map((item) => item.mode_name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholds?.env_modes?.length]);

  // console.log('thresholds', thresholds);

  const handleChangeSelect = (e) => {
    setThresholds((thresholds) => {
      return {
        ...thresholds,
        env_modes: thresholds?.env_modes?.map((item, index) => {
          if (index === currentMode) {
            //сохранить все dirtyfields при перелистывании в объекте
            return saveDirtyFields(
              item,
              dirtyFields,
              useFormObjectName,
              useFormObjectNameWithDot,
              watch,
            );
          } else {
            return item;
          }
        }),
      };
    });
    setCurrentMode(() => e.target.selectedIndex);
  };

  const closeModal = () => {
    setShowModal({isOpen: false, type: ''});
    reset();
    setIsValid({status: false, error: ''})
  }

  const handleAddNewThresholds = async (data) => {
    const newName = data.mode_name.replace(/ +/g, ' ').trim();
    try {
      const newConfig = thresholds.env_modes.reduce((acc, current) => {
        if (current.mode_name === 'Common') {
          acc = {...current, mode_name: newName};
        }
        return acc;
      }, {});
      const newThresholds = {
        ...thresholds,
        env_modes: [
          ...thresholds.env_modes,
          newConfig
        ].sort((a, b) => {
          if (a.mode_name > b.mode_name) {
            return 1;
          }
          if (a.mode_name < b.mode_name) {
            return -1;
          }
          return 0;
        })
      };
      const updatedCurrentMode = newThresholds.env_modes.findIndex((item) => item.mode_name ===  newName)
      await setEnviroment(newThresholds, dirtyFields, watch, updatedCurrentMode);
      setThresholds(newThresholds);
      setCurrentMode(updatedCurrentMode);
    } catch (err) {
      console.dir(err);
      const { message } = formErrorObject(err);
      setShowError(true);
      setMessage({ isRequestMessage: true, text: message });
    } finally {
      closeModal();
    }
  };

  const handleDeleteThresholds = async () => {
    const deletedElement = thresholds.env_modes[currentMode].mode_name;
    try {
      const newConfig = thresholds.env_modes.filter((item) => item.mode_name !== deletedElement);
      const newThresholds = {
        ...thresholds,
        env_modes: newConfig
      };
      const updatedCurrentMode = deletedElement !== 'Common' ? newThresholds.env_modes.findIndex((item) => item.mode_name === 'Common') : 0;
      await setEnviroment(newThresholds, dirtyFields, watch, updatedCurrentMode);
      setThresholds(newThresholds);
      setCurrentMode(updatedCurrentMode);
    } catch (err) {
      console.dir(err);
      const { message } = formErrorObject(err);
      setShowError(true);
      setMessage({ isRequestMessage: true, text: message });
    } finally {
      closeModal();
    }
  }

  const handleChangeNewModeName = (e) => {
    const { validationMessage, validity: {valid}, value, name } = e.target;
    setValue(name, value.replace(/^\s+/, ''));
    if (valid) {
      if (FORBIDDEN_SYMBOLS_REGEX.test(value)) {
        setIsValid({ status: false, error: t('forbidden_symbols_error_message') });
      } else if (!FILE_NAME_REGEX.test(value)) {
        setIsValid({ status: false, error: t('task_name_error_message') });
      } else if (enviromentValidOptions.includes(value)) {
        setIsValid({ status: false, error: 'Name already used' });
      } else {
        setIsValid({ status: true, error: '' });
      }
    } else {
      setIsValid({ status: false, error: validationMessage });
    }
  }

  return (
    <>
      <div
        className={classNames(
          'settings-form-general',
          activeSettingsNavItem === 5 ? 'setting-show' : '',
        )}
      >
        <h2 className="settings-form-title">{t('thresholds')}</h2>
        {enviromentValidOptions && (
          <div className="settings-form__container">
            <SelectBasic
              inputName={'enviroment'}
              labelName={t('enviroment')}
              defaultValue={thresholds?.env_modes?.[currentMode]?.mode_name}
              valid_options={enviromentValidOptions}
              wrapperClassName="settings-form__item "
              inputClassName="task-settings__select"
              useFormObjectName={useFormObjectNameWithDot}
              handleChangeCallback={handleChangeSelect}
            />
            <ButtonAction
              label={t('add')}
              showFunc={() => setShowModal({isOpen: true, type: 'add'})}
              img={'#add'}
              classes={'pic pic--green'}
            />
            <ButtonAction
                label={''}
                disabled={thresholds?.env_modes?.[currentMode]?.mode_name === 'Common'}
                showFunc={() => setShowModal({isOpen: true, type: 'delete'})}
                img={'#trash'}
                classes={'pic pic--red'}
              />
          </div>
        )}
        {thresholds?.env_modes && (
          <div className="m10">
            <div className="settings-form-subtitle hl">{t('restart_subtitle')}</div>
            <div className="settings-form__item">
              <InputCheckbox
                labelName={t('restart_every')}
                inputName={'enable-restart'}
                defaultValue={
                  thresholds?.env_modes?.[currentMode]?.restart_condition?.by_time?.enabled
                }
                useFormObjectName={useFormObjectNameWithDot}
                wrapperClassName="input-wrapper--small-gap"
                isUpdateValue={currentMode}
              />

              <InputMeasureable
                disabled={!watch(useFormObjectNameWithDot + 'enable-restart')}
                inputName={'restart-duration'}
                defaultValue={
                  thresholds?.env_modes?.[currentMode]?.restart_condition?.by_time?.period
                }
                inputMeasure={t('minutes')}
                onKeyPress={inputCheckToNumber}
                min={0}
                max={20160}
                useFormObjectName={useFormObjectNameWithDot}
                isUpdateValue={currentMode}
              />
            </div>

            <div className="settings-form__item">
              <div className="input-wrapper--small-gap">
                <InputCheckbox
                  labelName={t('restart_if_cpu_usage_drops_to_less_than')}
                  inputName={'enable-restart-cpu'}
                  defaultValue={
                    thresholds?.env_modes?.[currentMode]?.restart_condition?.by_cpu?.enabled
                  }
                  useFormObjectName={useFormObjectNameWithDot}
                  wrapperClassName="input-wrapper--small-gap"
                  labelClassName="input-wrapper--small-gap__label"
                  isUpdateValue={currentMode}
                />
                <InputMeasureable
                  disabled={!watch(useFormObjectNameWithDot + 'enable-restart-cpu')}
                  inputName={'restart-cpu-value'}
                  defaultValue={
                    thresholds?.env_modes?.[currentMode]?.restart_condition?.by_cpu?.low_level
                  }
                  inputMeasure={'%'}
                  onKeyPress={inputCheckToNumber}
                  min={1}
                  max={99}
                  wrapperClassName={'w-95'}
                  useFormObjectName={useFormObjectNameWithDot}
                  isUpdateValue={currentMode}
                />
              </div>

              <InputMeasureable
                disabled={!watch(useFormObjectNameWithDot + 'enable-restart-cpu')}
                labelName={t('for')}
                inputName={'restart-cpu-duration'}
                defaultValue={
                  thresholds?.env_modes?.[currentMode]?.restart_condition?.by_cpu?.duration
                }
                inputMeasure={t('seconds')}
                onKeyPress={inputCheckToNumber}
                min={0}
                max={600}
                useFormObjectName={useFormObjectNameWithDot}
                isUpdateValue={currentMode}
              />
            </div>

            <div className="settings-form__item">
              <div className="input-wrapper--small-gap">
                <InputCheckbox
                  labelName={t('restart_if_ram_usage_rises_higher_than')}
                  inputName={'enable-restart-ram'}
                  defaultValue={
                    thresholds?.env_modes?.[currentMode]?.restart_condition?.by_ram?.enabled
                  }
                  useFormObjectName={useFormObjectNameWithDot}
                  wrapperClassName="input-wrapper--small-gap"
                  labelClassName="input-wrapper--small-gap__label"
                  isUpdateValue={currentMode}
                />

                <InputMeasureable
                  disabled={!watch(useFormObjectNameWithDot + 'enable-restart-ram')}
                  inputName={'restart-ram-value'}
                  defaultValue={
                    thresholds?.env_modes?.[currentMode]?.restart_condition?.by_ram?.max_value
                  }
                  inputMeasure={t('MB')}
                  onKeyPress={inputCheckToNumber}
                  min={100}
                  max={100001}
                  wrapperClassName={'w-95'}
                  useFormObjectName={useFormObjectNameWithDot}
                  isUpdateValue={currentMode}
                />
              </div>

              <InputMeasureable
                disabled={!watch(useFormObjectNameWithDot + 'enable-restart-ram')}
                labelName={t('for')}
                inputName={'restart-ram-duration'}
                defaultValue={
                  thresholds?.env_modes?.[currentMode]?.restart_condition?.by_ram?.duration
                }
                inputMeasure={t('seconds')}
                onKeyPress={inputCheckToNumber}
                min={0}
                max={600}
                useFormObjectName={useFormObjectNameWithDot}
                isUpdateValue={currentMode}
              />
            </div>

            <div className="settings-form-subtitle hl">{t('log_statistics_subtitle')}</div>
            <InputMeasureable
              inputName={'log-duration'}
              labelName={t('log_statistics_every')}
              defaultValue={thresholds?.env_modes?.[currentMode]?.stat_log_time}
              inputMeasure={t('seconds')}
              onKeyPress={inputCheckToNumber}
              min={0}
              max={86400}
              wrapperClassName="settings-form__item"
              useFormObjectName={useFormObjectNameWithDot}
              isUpdateValue={currentMode}
            />

            <div className="settings-form-subtitle hl">{t('save_exit_subtitle')}</div>
            <InputCheckbox
              labelName={t('console_safe_exit')}
              inputName={'safe_exit'}
              defaultValue={thresholds?.env_modes?.[currentMode]?.safe_exit}
              useFormObjectName={useFormObjectNameWithDot}
              wrapperClassName="settings-form__item jc-fs"
              inputClassName="peerList-check"
              labelClassName="peerList-label"
              isUpdateValue={currentMode}
            />

            <div className="settings-form-subtitle hl">{t('consoles_log_subtitle')}</div>
            <SelectBasic
              inputName={'level'}
              labelName={t('level')}
              defaultValue={
                thresholds?.env_modes?.[currentMode]?.log_config
                  ?.filter((item) => item.type === 'Default')?.[0]
                  ?.sinks?.[0]?.level?.toString() === 'off'
                  ? 'not set'
                  : thresholds?.env_modes?.[currentMode]?.log_config
                      ?.filter((item) => item.type === 'Default')?.[0]
                      ?.sinks?.[0]?.level?.toString()
              }
              valid_options={logLevelsList}
              wrapperClassName="settings-form__item "
              inputClassName="task-settings__select"
              useFormObjectName={useFormObjectNameWithDot}
              isUpdateValue={currentMode}
            />

            <div className="settings-form-subtitle hl">{t('input_record')}</div>
            <InputCheckbox
              labelName={t('enable_record')}
              inputName={'enable-record'}
              defaultValue={thresholds?.env_modes?.[currentMode]?.dropper.enabled}
              useFormObjectName={useFormObjectNameWithDot}
              wrapperClassName="settings-form__item jc-fs"
              inputClassName="peerList-check"
              labelClassName="peerList-label"
              isUpdateValue={currentMode}
            />

            <InputMeasureable
              disabled={!watch(useFormObjectNameWithDot + 'enable-record')}
              inputName={'chunk-size'}
              labelName={t('chunk_size')}
              defaultValue={thresholds?.env_modes?.[currentMode]?.dropper.chunk_size}
              inputMeasure={t('KB')}
              onKeyPress={inputCheckToNumber}
              min={0}
              wrapperClassName="settings-form__item"
              useFormObjectName={useFormObjectNameWithDot}
              isUpdateValue={currentMode}
            />

            <InputMeasureable
              disabled={!watch(useFormObjectNameWithDot + 'enable-record')}
              inputName={'record-life-time'}
              labelName={t('record_life_time')}
              defaultValue={thresholds?.env_modes?.[currentMode]?.dropper.life_time}
              inputMeasure={t('seconds')}
              onKeyPress={inputCheckToNumber}
              min={0}
              wrapperClassName="settings-form__item"
              useFormObjectName={useFormObjectNameWithDot}
              isUpdateValue={currentMode}
            />
          </div>
        )}
      </div>
      {showModal.isOpen && (
        <ModalPortal showModal={showModal}>
          <Modal title={t(showModal.type === 'add' ? 'add_configuration': 'delete_configuration')} onClose={closeModal}>
            {showModal.type === 'add'
              ? <form className='settings-form__modal-form' onSubmit={handleSubmit(handleAddNewThresholds)}>
                  <div className="settings-form__modal-field">
                    <label className="settings-form__modal-label">
                      {t('name')}
                      <input
                        className={`settings-form__modal-input ${isValid.error ? 'settings-form__modal-input_type_error' : ''}`}
                        placeholder={t('enter_name')}
                        id="mode_name"
                        {...register('mode_name')}
                        type="text"
                        required
                        onChange={handleChangeNewModeName}
                      />
                    </label>
                    <span className='settings-form__modal-input-error'>{isValid.error}</span>
                  </div>
                <ButtonBase
                  color={'green'}
                  type={'submit'}
                  disabled={!isValid.status}
                >
                  <span className="settings-form__modal-button-text">{t('ok')}</span>
                </ButtonBase>
                </form>
              : <div className='settings-form__modal-info'>
                  <div className='settings-form__modal-text'>
                    {`${t('name')}: `}
                    <span>{thresholds?.env_modes?.[currentMode]?.mode_name}</span>
                  </div>
                <ButtonBase
                  color={'green'}
                  onClick={handleDeleteThresholds}
                >
                  <span className="settings-form__modal-button-text">{t('ok')}</span>
                </ButtonBase>
              </div>

            }
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

export default Thresholds;
