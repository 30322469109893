import { FormControl, InputLabel, OutlinedInput, TextField } from '@mui/material';
import React from 'react';
import s from './SettingsParameters.module.scss';
import ButtonBase from '@shared/UI/Buttons/ButtonBase/ButtonBase';
import { apiSendParams } from '@services/CreateTaskService';
import { useForm } from 'react-hook-form';

const SettingsParameters = ({ parameters }) => {
  //отправлять параетры когда был потерян фокус

  const handleClickSendParameters = (data) => {
    // используем guid т к необходим autocomplete для Multicast Group or Unicast Address и Port
    const finishData = Object.keys(data).reduce((obj, key) => {
      if (isNaN(key)) {
        let newKey = parameters.find((parameter) => parameter?.guid === key)?.id;

        return Object.assign(obj, { [newKey]: data[key] });
      } else {
        return Object.assign(obj, { [key]: data[key] });
      }
    }, {});

    apiSendParams(finishData);

    //пока не реализовано получение параметров по ид и типу секции хранить параметры в стейте
    //после будет реализовано добавление программ как в services после update_pins и connector_list
  };

  const methods = useForm();
  const { register, handleSubmit } = methods;

  return (
    <div className={s['settings-parameters']}>
      {parameters?.map((parameter) => {
        //name value id
        return !parameter?.valid_options ? (
          <FormControl size="small">
            <InputLabel htmlFor={parameter?.id?.toString()}>{parameter?.name}</InputLabel>
            <OutlinedInput
              label={parameter?.name}
              {...register(parameter?.guid?.toString())}
              size="small"
            />
          </FormControl>
        ) : (
          <TextField
            select
            label={parameter?.name}
            SelectProps={{
              native: true,
            }}
            {...register(parameter?.id?.toString())}
            size="small"
          >
            {parameter?.valid_options?.map((item) => (
              <option key={item.meaning} value={item.value.toString()}>
                {item.value.toString()} ({item.meaning})
              </option>
            ))}
          </TextField>
        );
      })}
      <div className={'m-a '}>
        <ButtonBase color={'green'} onClick={handleSubmit(handleClickSendParameters)}>
          Apply
        </ButtonBase>
      </div>
    </div>
  );
};

export default SettingsParameters;
