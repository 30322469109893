import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import GroupPermissions from '@services/GroupSettings';
import Security from '@services/SecuritySettings';
import {
  allTaskParams,
  serviceAccessName,
  //serviceTasksName,
  tasksRightsName,
} from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import './TasksRights.scss';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";

const TasksRights = ({ selectGroup, getSecureData, currentGroup, blockAction }) => {
  const { t } = useTranslation();
  // const [selectTask, setSelectTask] = React.useState('all');
  const selectTask = 'all';
  const [tasksRights, setTasksRights] = React.useState([]);
  const taskPermitions = tasksRights.find((elem) => elem.owner_id === selectTask)?.actions;
  const [allApplyAccess, setAllApplyAccess] = React.useState('1');
  const { register } = useFormContext();
  React.useEffect(() => {
    const updateAccessTables = [allTaskParams(currentGroup), ...currentGroup?.access_tables];
    setTasksRights(updateAccessTables.filter((item) => item.owner_id !== '0'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectGroup]);

  // console.log('selectGroup', selectGroup);
  // console.log('tasksRights', tasksRights);
  // console.log('taskPermitions', taskPermitions);

  const handleChange = async (updateItem, value) => {
    // console.log('updateItem', updateItem);
    // console.log('value', value);

    const itemIdx = taskPermitions.findIndex((elem) => elem.id === updateItem);
    // console.log('itemIdx', itemIdx);

    const updateRightsOneTask =
      updateItem === 'all'
        ? taskPermitions.map((elem) => ({ access: value, id: elem.id }))
        : [
            ...taskPermitions.slice(0, itemIdx),
            { access: value, id: updateItem },
            ...taskPermitions.slice(itemIdx + 1),
          ];
    // console.log('updateRightsOneTask', updateRightsOneTask);
    const indexSelectTask = tasksRights.findIndex((elem) => elem.owner_id === selectTask);
    // console.log('indexSelectTask', indexSelectTask);

    const updateAllRights =
      selectTask === 'all'
        ? tasksRights.map((elem) => ({ actions: updateRightsOneTask, owner_id: elem.owner_id }))
        : selectTask === '1'
        ? [
            { actions: updateRightsOneTask, owner_id: 'all' },
            { actions: updateRightsOneTask, owner_id: selectTask },
            ...tasksRights.slice(indexSelectTask + 1),
          ]
        : [
            ...tasksRights.slice(0, indexSelectTask),
            { actions: updateRightsOneTask, owner_id: selectTask },
            ...tasksRights.slice(indexSelectTask + 1),
          ];
    // console.log('updateAllRights', updateAllRights);
    setTasksRights(updateAllRights);

    const updateDataItemOne = [
      { group_id: +selectGroup, owner_id: +selectTask, action: +updateItem, access: +value },
    ];
    // console.log('updateDataItemOne', updateDataItemOne);
    if (selectTask === 'all') {
      const originalRights = updateAllRights.filter((item) => item.owner_id !== 'all');
      // console.log('originalRights', originalRights);
      if (updateItem === 'all') {
        const asyncRes = await Promise.all(
          originalRights.map(async (item) => {
            const updateDataItemAll = item.actions.map((elem) => ({
              group_id: +selectGroup,
              owner_id: +item.owner_id,
              action: +elem.id,
              access: +value,
            }));
            // console.log('updateDataItemAll', updateDataItemAll);
            await Security.updatePermission(updateDataItemAll).then((response) => {
              // console.log(response);
            });
          }),
        );
        await asyncRes;
        getSecureData();
        GroupPermissions.applyUpdate().then((response) => {
          // console.log('apply', response);
        });
      } else {
        const updateDataItemAll = originalRights.map((elem) => ({
          group_id: +selectGroup,
          owner_id: +elem.owner_id,
          action: +updateItem,
          access: +value,
        }));
        // console.log('updateDataItemAll', updateDataItemAll);
        await Security.updatePermission(updateDataItemAll).then((response) => {
          // console.log(response);
        });
        getSecureData();
        GroupPermissions.applyUpdate().then((response) => {
          // console.log('apply', response);
        });
      }
    } else {
      if (updateItem === 'all') {
        const updateDataItemAll = updateRightsOneTask.map((elem) => ({
          group_id: +selectGroup,
          owner_id: +selectTask,
          action: +elem.id,
          access: +elem.access,
        }));
        // console.log('updateDataItemAll', updateDataItemAll);
        Security.updatePermission(updateDataItemAll).then((response) => {
          // console.log(response);
          getSecureData();
          GroupPermissions.applyUpdate().then((response) => {
            // console.log('apply', response);
          });
        });
      } else {
        Security.updatePermission(updateDataItemOne).then((response) => {
          // console.log(response);
          getSecureData();
          GroupPermissions.applyUpdate().then((response) => {
            // console.log('apply', response);
          });
        });
      }
    }
  };

  return (
    <div className="service-rights">
      <div className="permissions-item-show">
        {/* закоментирован функционал в связи с https://redmine.elecard.net.ru/issues/11473 */}
        {/*   <div className="service-rights-data__item mb-10">
          <div className="service-rights-data__itemname">{t('permissions_for')}: </div>
          <select
            disabled={blockAction ? true : selectGroup === '0' ? true : false}
            className="severity-task-data-list"
            value={selectTask}
            {...register(`selectTask`)}
            onChange={(e) => {
              register(`selectTask`).onChange(e);
              setSelectTask(e.target.value);
            }}>
            {serviceTasksName(tasksRights)}
          </select>
        </div> */}

        <div className="permissions-item-header hla">
          <div className="severity-task-header__item">{t('action')}</div>
          <div className="severity-task-header__item">{t('access')}</div>
        </div>
        <div className="service-rights-data hla">
          {taskPermitions?.map((item) => (
            <div className="service-rights-data__item" key={item.id} name={item.id}>
              <div className="service-rights-data__item-name">{tasksRightsName[item.id]}</div>
              <select
                disabled={blockAction ? true : selectGroup === '0' ? true : false}
                name={item.id}
                className="severity-task-data-list"
                value={item.access}
                {...register(`task-${item.id}`)}
                onChange={(e) => {
                  register(`task-${item.id}`).onChange(e);
                  handleChange(item.id, e.target.value);
                }}
              >
                {serviceAccessName}
              </select>
            </div>
          ))}
        </div>
        <div className="service-rights-data">
          <div className="service-rights-data-all">
            <div className="severity-events-data__name">{t('common_access')}</div>
            <div className="service-rights-data-all-btns">
              <select
                defaultValue={allApplyAccess}
                disabled={blockAction ? true : selectGroup === '0' ? true : false}
                className="severity-task-data-list severity-task-data-list-short"
                {...register(`accessAll`)}
                onChange={(e) => {
                  register(`accessAll`).onChange(e);
                  setAllApplyAccess(e.target.value);
                }}
              >
                {serviceAccessName}
              </select>
              <ButtonBase
                color={'green'}
                disabled={blockAction ? true : selectGroup === '0' ? true : false}
                onClick={() => handleChange('all', allApplyAccess)}
              >
                {t('apply_for_all')}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TasksRights;
