import classNames from 'classnames';
import i18next from 'i18next';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import sprite from '@shared/Images/sprite.svg';

const DocsMenu = () => {
  const { t } = useTranslation();
  const menuRef = useRef();
  const [showDocsList, setShowDocsList] = React.useState(false);
  const currentPath = window.location.origin;
  //   console.log('link', window.location);
  const currentLanguage = i18next.language;

  React.useEffect(() => {
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setShowDocsList(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const toggleShowUserMenu = () => {
    setShowDocsList(!showDocsList);
  };

  return (
    <div className={classNames(`user`, showDocsList ? 'user-show' : '')} ref={menuRef}>
      <div className="user-box" onClick={toggleShowUserMenu}>
        <p className={classNames(`docs-text`, showDocsList ? 'docs-text--black' : '')}>
          {t('documentation')}
        </p>
        <svg className={classNames(`docs-arrow`, showDocsList ? 'docs-arrow--black' : '')}>
          <use href={sprite + '#arrow-down'}></use>
        </svg>
      </div>

      <div
        className={classNames(
          `dropdown-menu dropdown-menu--left`,
          showDocsList ? 'dropdown-menu--show' : '',
        )}
      >
        <div className="dropdown-menu__userinfo">
          <a
            href={`${currentPath}/doc/user-guide${
              currentLanguage === 'ru' ? '/ru/QuickStart.html' : '/en/QuickStart.html'
            } `}
            target="_blank"
            rel="noreferrer"
          >
            <div className="dropdown-menu__userinfo-text" onClick={toggleShowUserMenu}>
              <svg className="user-img user-img--black">
                <use href={sprite + '#guide'}></use>
              </svg>
              {t('quick_start_guide')}
            </div>
          </a>
          <div className="divider-thin"></div>
          <a
            href={`${currentPath}/doc/user-guide${
              currentLanguage === 'ru' ? '/ru/Guide.html' : '/en/Guide.html'
            } `}
            target="_blank"
            rel="noreferrer"
          >
            <div className="dropdown-menu__userinfo-text" onClick={toggleShowUserMenu}>
              <svg className="user-img user-img--black">
                <use href={sprite + '#guide'}></use>
              </svg>
              {t('user_guide')}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DocsMenu;
