import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import './ServerListData.scss';
import { addPercentSignToValue, calculatePercent } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import CPUInfoModal from '@widgets/Server/CPUInfoModal/CPUInfoModal';
import NetworkStatisticModal from '@widgets/Server/NetworkStatisticModal/NetworkStatisticModal';

const ServerListData = () => {
  // const [showModal, setShowModal] = useState(false);
  const [showModalNetwork, setShowModalNetwork] = useState();
  const [showModalCore, setShowModalCore] = useState();

  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const host = window.location.host;
  const ws = useRef(null);

  useEffect(() => {
    return () => {
      // console.log('CLOSED SOCKET LIST DATA');
      ws.current.cwFinal = true;
      ws.current.close();
    };
  }, []);

  React.useEffect(() => {
    // console.log('ServerListWS', ws.current);
    // readyState===0 - CONNECTING
    // readyState===1 - OPEN
    // readyState===2 - CLOSING
    // readyState===3 - CLOSED
    if (ws.current === null || ws.current.readyState === 2 || ws.current.readyState === 3) {
      startWS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws.current]);

  const startWS = () => {
    ws.current = new WebSocket(`ws://${host}/api/server-list/peer`);
    ws.current.onopen = () => {
      ws.current.send('/api/server-list/peer');
      // console.log('Соединение открыто');
    };
    ws.current.onclose = () => {
      if (!ws.current.cwFinal) {
        setTimeout(startWS, 3000);
      }
      // console.log('Соединение закрыто');
    };
    ws.current.onerror = (event) => {
      console.dir(event);
    };
    gettingData();
  };

  const gettingData = () => {
    ws.current.onmessage = (e) => {
      if (e.data === 'OK') {
        return;
      }
      const message = JSON.parse(e.data);
      setData(message?.data ? message.data : []);
    };
  };

  if (!data) {
    return <Loader top={true} />;
  }

  return (
    <>
      <div className="server-list-data-table">
        <table className="table">
          <thead className="thead">
            <tr>
              <th scope="col" className="t-server-list-num w-2p">
                {t('#')}
              </th>
              <th scope="col" className="t-server-list-name ta-l">
                {t('name')}
              </th>
              <th scope="col" className="t-server-list-name ta-l">
                {t('address')}
              </th>
              <th scope="col" className="t-server-list-name ta-l">
                {t('cpu')}
              </th>
              <th scope="col" className="t-server-list-name ta-l">
                {t('ram')}
              </th>
              <th scope="col" className="t-server-list-name ta-l">
                {t('gpu')}
              </th>
              <th scope="col" className="t-server-list-name ta-l">
                HDD
              </th>
              <th scope="col" className="t-server-list-name ta-l">
                {t('os')}
              </th>
            </tr>
          </thead>
          <tbody className="tbody">
            {data && data.length === 0 ? (
              <tr className="server-list-data-table__row">
                <td></td>
                <td className="ta-l server-list-params__text">{t('no_servers_available')}</td>
                <td className="ta-l"></td>
              </tr>
            ) : (
              data.map((server, i) => (
                <tr className="server-list-data-table__row" key={server.name + i}>
                  <td>{i + 1}</td>
                  <td className="ta-l">
                    <div className="d-flex fdc">
                      <span className="server-list-name ">{server.name}</span>
                      {server?.system_info?.cpu?.data ? (
                        <button
                          className="button--transparent"
                          onClick={() => {
                            setShowModalNetwork((showModalNetwork) => {
                              return { ...showModalNetwork, [server?.name]: true };
                            });
                          }}
                          disabled={showModalNetwork?.[server?.name]}
                        >
                          {t('network_statistic')}
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                    {showModalNetwork?.[server?.name] && server?.system_info?.network && (
                      <NetworkStatisticModal
                        setShowModal={() => {
                          setShowModalNetwork((showModalNetwork) => {
                            return { ...showModalNetwork, [server?.name]: false };
                          });
                        }}
                        interfacesInfo={server?.system_info?.network}
                        serverName={server?.name}
                      />
                    )}
                  </td>
                  <td className="ta-l">
                    <div>
                      <ul>
                        {server.connections &&
                          server.connections.map((connection, index) => (
                            <li key={server.name + connection.interface + index}>
                              <a
                                className="server-list-params__link"
                                href={`http://${connection.interface}:${connection.port}`}
                              >
                                {connection.interface}:{connection.port}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </td>

                  <td className="ta-l ff-roboto">
                    <div className="d-flex fdc">
                      {addPercentSignToValue(server?.system_info?.cpu?.total_usage)}
                      {server?.system_info?.cpu?.data ? (
                        <button
                          className="button--transparent"
                          onClick={() => {
                            setShowModalCore((showModalCore) => {
                              return { ...showModalCore, [server?.name]: true };
                            });
                          }}
                          disabled={showModalCore?.[server?.name]}
                        >
                          {t('core_utilization')}
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                    {showModalCore?.[server?.name] && server?.system_info?.cpu && (
                      <CPUInfoModal
                        setShowModal={() => {
                          setShowModalCore((showModalCore) => {
                            return { ...showModalCore, [server?.name]: false };
                          });
                        }}
                        cpuInfo={server?.system_info?.cpu}
                        serverName={server?.name}
                      />
                    )}
                  </td>
                  <td className="ta-l ff-roboto">
                    {addPercentSignToValue(
                      calculatePercent(
                        server?.system_info?.ram?.used,
                        server?.system_info?.ram?.size,
                      ),
                    )}
                  </td>
                  <td className="ta-l ff-roboto">
                    {addPercentSignToValue(server?.system_info?.gpu?.total_usage)}
                  </td>
                  <td className="ta-l ff-roboto">
                    {addPercentSignToValue(
                      calculatePercent(
                        server?.system_info?.disk?.used,
                        server?.system_info?.disk?.size,
                      ),
                    )}
                  </td>
                  <td className="ta-l">{server?.system_info?.os_name}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ServerListData;
