import React from 'react';
import ServerTasksInactive from './ServerTasksInactive/ServerTasksInactive';
import { CSSTransition } from 'react-transition-group';
import WarningDelete from '@shared/UI/Warnings/WarningDelete/WarningDelete';
import Error from '@shared/UI/Errors/Error/Error';
import MiniInfo from '@shared/UI/Warnings/MiniInfo/MiniInfo';
import MiniLoader from '@shared/UI/Loaders/MiniLoader/MiniLoader';
import { Link } from 'react-router-dom';
import ServerTasksActive from './ServerTasksActive/ServerTasksActive';
import TaskActionPanel from '@features/Server/ServerData/TaskActionPanel/TaskActionPanel';
import { useTranslation } from 'react-i18next';
import useAuth from '@shared/Lib/Utils/Auth/useAuth';
// import { CONFIGURATOR_REMUX_LINK } from '@shared/Lib/Utils/Constants/RouteLinks';
import ButtonBase from '@shared/UI/Buttons/ButtonBase/ButtonBase';
import useServerData from '@widgets/Server/ServerData/model/useServerData';

const ServerData = ({ isWindowsOS }) => {
  const { t } = useTranslation();
  // console.log('ACTIVE RESERVE', isReserving);
  const [showWarn, setShowWarn] = React.useState(false);
  const [showInform, setShowInform] = React.useState(false);
  const [showMiniLoader, setShowMiniLoader] = React.useState(false);
  const [showManyError, setShowManyError] = React.useState(false);
  const [manyMessageError, setManyMessageError] = React.useState([]);
  const [typeModal, setTypeModal] = React.useState('error');
  const [message, setMessage] = React.useState(null);
  const { disableButtonForGuest } = useAuth();

  const {
    updateActiveData,
    mainCheckActive,
    checkedActive,
    setAllCheckedActive,
    updateCheckboxActive,

    updateInactiveData,
    mainCheckInactive,
    checkedInactive,
    setAllCheckedInactive,
    updateCheckboxInactive,
    clearCheckState,

    TaskStart,
    TaskPrepare,
    TaskRestart,
    TaskStop,

    activeTasksData,
  } = useServerData({
    setShowMiniLoader,
    setManyMessageError,
    setShowManyError,
    setShowInform,
    setMessage,
  });
  const isReserving = updateActiveData?.some((item) => item.reserving === true);
  const checked = React.useRef([]);
  // console.log('checked', checked.current);
  checked.current = [...checkedActive, ...checkedInactive];

  return (
    <>
      <div className="server-title-box">
        <div className="server-title-box__text">
          {t('active_tasks')}
          {activeTasksData && ` (${activeTasksData.tasks}/${activeTasksData.tasks_total})`}
          {isReserving && <span className="server-info__text">{t('redundancy')}</span>}
        </div>
        <div className="buttons-group-wrapper ">
          <Link to="/configurator">
            <ButtonBase color={'green'} disabled={disableButtonForGuest}>
              {t('create_task')}
            </ButtonBase>
          </Link>
          {/*    <Link to={CONFIGURATOR_REMUX_LINK}>
            <ButtonBase color={'green'} disabled={disableButtonForGuest}>
              {t('create_task_remux')}
            </ButtonBase>
          </Link> */}
        </div>
      </div>
      <ServerTasksActive
        updateActiveData={updateActiveData}
        checked={checkedActive}
        mainCheckActive={mainCheckActive}
        setAllCheckedActive={setAllCheckedActive}
        updateCheckboxActive={updateCheckboxActive}
        isWindowsOS={isWindowsOS}
        TaskRestart={TaskRestart}
        TaskStop={TaskStop}
      />

      {!isReserving && (
        <>
          <div className="clusters-title"> {t('inactive_tasks')}</div>
          <ServerTasksInactive
            updateInactiveData={updateInactiveData}
            checkedInactive={checkedInactive}
            mainCheckInactive={mainCheckInactive}
            setAllCheckedInactive={setAllCheckedInactive}
            updateCheckboxInactive={updateCheckboxInactive}
            TaskStart={TaskStart}
            TaskRestart={TaskRestart}
            TaskPrepare={TaskPrepare}
          />
        </>
      )}

      <CSSTransition in={showWarn} timeout={500} unmountOnExit classNames="alert">
        <WarningDelete setShowError={setShowWarn} />
      </CSSTransition>
      <CSSTransition in={showManyError} timeout={500} unmountOnExit classNames="alert">
        <Error
          many={true}
          manyMessageError={manyMessageError}
          setManyMessageError={setManyMessageError}
          setShowManyError={setShowManyError}
          typeModal={typeModal}
          objName={'Task'}
        />
      </CSSTransition>
      <CSSTransition in={showInform} timeout={500} unmountOnExit classNames="alert">
        <MiniInfo setShowInform={setShowInform} message={message} />
      </CSSTransition>
      <CSSTransition in={showMiniLoader} timeout={500} unmountOnExit classNames="alert">
        <MiniLoader />
      </CSSTransition>
      {checked.current?.length > 0 && (
        <TaskActionPanel
          checked={checked.current}
          clearCheckState={clearCheckState}
          setShowMiniLoader={setShowMiniLoader}
          setShowManyError={setShowManyError}
          setTypeModal={setTypeModal}
          setManyMessageError={setManyMessageError}
          setMessage={setMessage}
          setShowInform={setShowInform}
        />
      )}
    </>
  );
};

export default ServerData;
