import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiGetSourceList } from '@services/CreateTaskService';
import { createTaskActions } from '@store/CreateTask';
import { onDeleteSource } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import arrowDownBlack from '@shared/Images/arrow-down-black.svg';
import InputAddressSection from '@widgets/TaskConfigurator/InputAddressSection/InputAddressSection';
import classNames from 'classnames';
import './InputSection.scss';
import { useTranslation } from 'react-i18next';
import { SOURCE_TEMPLATE_LINK } from '@shared/Lib/Utils/Constants/RouteLinks';
import { Link } from 'react-router-dom';
import ButtonBase from '@shared/UI/Buttons/ButtonBase/ButtonBase';

const InputSection = ({
  number,
  copied,
  template,
  showLoaderSourceItems,
  setShowLoaderSourceItems,
  isEditTask,
  isShowTabMenu,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // console.log('copied', copied);
  const [sourceRenderData, setSourceRenderData] = React.useState([]);
  const [selectSource, setSelectSource] = React.useState({
    template: copied ? template.template : '',
    section: copied ? template.section : '',
  });
  const [hiddenInputSection, setHiddenInputSection] = React.useState(false);
  // console.log('hiddenInputSection', hiddenInputSection);
  const [hiddenInputAddressSection, setHiddenInputAddressSection] = React.useState(true);
  // console.log(`number`, number);
  const selectSourceRef = React.useRef();
  const arraySources = useSelector((state) => state.createTask.arraySources);

  const getSourceData = async () => {
    await apiGetSourceList().then((res) => {
      // console.log('res', res);
      const resData = res?.data?.categories;
      // console.log('resData', resData);
      if (!resData) {
        setSourceRenderData(null);
        return;
      }
      const updateData = Object.entries(resData).map(([category, type]) => ({
        category,
        type,
      }));
      // console.log('updateData', updateData);
      const updatedata2 = updateData
        .map((elem) => ({
          ...elem,
          type: elem.type.map((item) => {
            return Object.entries(item).map(([subCategory, name]) => ({
              subCategory,
              name,
            }));
          }),
        }))
        .map((cat) => ({
          ...cat,
          type: cat.type.map((iter) => iter[0]),
        }));
      // console.log('updatedata2', updatedata2);

      const updateData3 = updatedata2.filter((data) =>
        data.type.some((item) => item !== undefined),
      );
      // console.log('updateData3', updateData3);

      const finishUpdateData = updateData3.map((data) => ({
        ...data,
        type: data.type.filter((item) => item !== undefined),
      }));
      // console.log('finishUpdateData', finishUpdateData);

      const haveNetwork = Boolean(finishUpdateData.find((elem) => elem.category === 'Network'));
      // console.log('haveNetwork', haveNetwork);

      const updateDefTemplate = haveNetwork
        ? finishUpdateData.find((elem) => elem.category === 'Network').type[0].subCategory
        : finishUpdateData[0].type[0].subCategory;
      // console.log('updateDefTemplate', updateDefTemplate);

      const updateDefSection = haveNetwork
        ? finishUpdateData.find((elem) => elem.category === 'Network').type[0].name[0]
        : finishUpdateData[0].type[0].name[0];
      // console.log('updateDefSection', updateDefSection);

      setSelectSource({ template: updateDefTemplate, section: updateDefSection });
      setSourceRenderData(finishUpdateData);
    });
    selectSourceRef.current && selectSourceRef.current.focus();
  };

  const changeSource = (template, section) => {
    setSelectSource({ template: template, section: section });
  };

  React.useEffect(() => {
    if (copied) {
      setHiddenInputSection(true);
      setHiddenInputAddressSection(false);
      // console.log('SET_HIDDEN_INPUT_ADDRESS_SECTION');
    } else {
      getSourceData();
    }
  }, [copied]);

  const toggleCollapse = (target) => {
    target.classList.toggle('source-properties-program-collapse--rotate');
    const blockWithStreams = target.closest('.select-source-group__label').nextElementSibling;
    blockWithStreams.classList.toggle('source-properties-streams--hidden');
  };

  const onSubmitInput = () => {
    setHiddenInputSection(true);
    setHiddenInputAddressSection(false);
    // console.log('title', title);
    dispatch(createTaskActions.setAddSourceBlock(selectSource, number));
  };

  return sourceRenderData?.length === 0 && !copied ? (
    <Loader top={true} />
  ) : sourceRenderData === null ? (
    <>
      <div className="input-properties">{t('no_templates_available')}</div>
      <button
        type="button"
        onClick={() => {
          onDeleteSource(arraySources, number, dispatch);
        }}
        className="button input-properties-cancel btn-w60px-center"
      >
        {t('ok')}
      </button>
    </>
  ) : (
    <>
      <div hidden={hiddenInputSection ? true : false} className="input-properties">
        <h2 className="input-properties-title">{t('select_source')}</h2>
        <div
          className="input-properties-close"
          data-number={number}
          onClick={() => {
            onDeleteSource(arraySources, number, dispatch);
          }}
        >
          ×
        </div>
        <Link to={SOURCE_TEMPLATE_LINK}>
          <div className="input-properties__button">
            <ButtonBase color={'green'}>{t('create_source_template')}</ButtonBase>
          </div>
        </Link>
        <div className="select-source">
          {sourceRenderData.map((elem, i) => (
            <div className="select-source-group" key={elem.category + number}>
              <div className="select-source-group__label">
                <label className="select-source-group__label-text">{elem.category}</label>
                <img
                  className={classNames(
                    `source-properties-program-collapse`,
                    elem.category !== 'Capture device'
                      ? 'source-properties-program-collapse--rotate'
                      : '',
                  )}
                  src={arrowDownBlack}
                  alt="arrowdown"
                  onClick={(e) => {
                    const target = e.target;
                    toggleCollapse(target);
                  }}
                />
              </div>
              <div
                className={classNames(
                  `select-source-box`,
                  elem.category !== 'Capture device' ? '' : 'source-properties-streams--hidden',
                )}
              >
                {elem.type.map((item, index) => (
                  <React.Fragment key={item.subCategory + index}>
                    {item.name.map((cat) => (
                      <div
                        onDoubleClick={onSubmitInput}
                        onClick={() => changeSource(item.subCategory, cat)}
                        className={classNames(
                          `select-source-cat`,
                          cat === selectSource.section ? 'select-source-cat--active' : '',
                        )}
                        key={cat}
                      >
                        <label className="select-source-cat__label">{cat}</label>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="input-properties-button">
          <button
            type="button"
            onClick={onSubmitInput}
            className="button input-properties-cancel btn-w80px"
          >
            {t('add')}
          </button>
        </div>
      </div>
      {hiddenInputSection && (
        <InputAddressSection
          number={number}
          hiddenInputAddressSection={hiddenInputAddressSection}
          selectSource={selectSource}
          showLoaderSourceItems={showLoaderSourceItems}
          setShowLoaderSourceItems={setShowLoaderSourceItems}
          isEditTask={isEditTask}
          isShowTabMenu={isShowTabMenu}
        />
      )}
    </>
  );
};

export default InputSection;
