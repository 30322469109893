import React from 'react';
import { apiRedundancyPeer } from '@widgets/Redundancy/api/apiRedundancyService';
import { addGroups, addHosts } from '@widgets/Redundancy/RedundancyRequests/RedundancyRequests';
import './AddServer.scss';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { inputCheckToNumber } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";

const AddServer = ({ title, closeMenu, item, setReservItems }) => {
  const { t } = useTranslation();
  const [dataPeer, setDataPeer] = React.useState(null);
  const [customData, setCustomData] = React.useState(null);
  const [inputData, setInputData] = React.useState('');
  const [portData, setPortData] = React.useState('1535');
  const [showError, setShowError] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  // console.log('dataPeer', dataPeer);
  const getPeer = async () => {
    await apiRedundancyPeer()
      .then((response) => {
        // console.log('response', response);
        const items = response?.data?.data?.map((el) => ({
          name: el.name,
          subname: el.connections
            ? el?.connections?.[0]?.port === 1535
              ? el?.connections?.[0]?.interface
              : el?.connections?.[0]?.interface + ':' + el?.connections?.[0]?.port
            : '',
          check: false,
        }));
        setDataPeer(items);
      })
      .catch((error) => {
        setShowError(true);
        setMessageError(error.message);
      });
  };
  React.useEffect(() => {
    getPeer();
  }, []);

  const schemaIP = yup.object().shape({
    ipAddress: yup.string().ipv4().ipv4ExeptAllZeros().required('IP Address is required field'),
    port: yup
      .number()
      .typeError('Port must be a number')
      .min(1, 'Port min number 1')
      .max(65535, 'Port max number 65535')
      .required('Port is required field'),
  });

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schemaIP),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const onSubmitSettings = (data) => {
    const updateData = { name: `${inputData}:${portData}`, check: true };
    const hasAddress = customData?.some((elem) => elem.name === updateData.name);
    // console.log('hasAddress', hasAddress);
    !hasAddress && setCustomData(customData ? [...customData, updateData] : [updateData]);
    setInputData('');
    setPortData('');
  };

  // console.log('customData', customData);

  const addServerItem = () => {
    const selectServers = dataPeer
      ? dataPeer.filter((el) => el.check).map((item) => item.name)
      : [];
    // console.log('selectServers', selectServers);
    const selectCustoms = customData
      ? customData.filter((el) => el.check).map((item) => item.name)
      : [];
    // console.log('selectCustoms', selectCustoms);
    const allData = [...selectServers, ...selectCustoms];
    // console.log('allData', allData);
    item === 'host'
      ? addHosts(allData, setShowError, setMessageError, setReservItems, closeMenu)
      : addGroups(allData, setShowError, setMessageError, setReservItems, closeMenu);
  };

  if (!dataPeer) {
    return (
      <div className="addServer">
        <Loader center={true} />
      </div>
    );
  }

  return (
    <div className="addServer">
      <div className="addServer-title">{title}</div>
      <div className="modal-close" onClick={closeMenu}>
        ×
      </div>
      {errors.ipAddress && (
        <div className="settings-form__errors mb10">{errors.ipAddress.message}</div>
      )}
      {errors.port && <div className="settings-form__errors mb10">{errors.port.message}</div>}
      <div className="box-container box-container--add">
        <div className="addServer-box">
          <div className="peerList">
            {dataPeer.map((el) => (
              <div className="peerList__item" key={el.name}>
                <input
                  id={`${el.name}`}
                  className="peerList-check"
                  type="checkbox"
                  checked={el.check}
                  onChange={() => {
                    const idxItem = dataPeer.findIndex((item) => item.name === el.name);
                    const selecttem = dataPeer.find((item) => item.name === el.name);
                    const updateSelectItem = { ...selecttem, check: !el.check };
                    const updatePeer = [
                      ...dataPeer.slice(0, idxItem),
                      updateSelectItem,
                      ...dataPeer.slice(idxItem + 1),
                    ];
                    setDataPeer(updatePeer);
                    setShowError(false);
                  }}
                />
                <label className="peerList-label" htmlFor={`${el.name}`}>
                  {el.name}
                </label>
                <span className="peerList-ip">&nbsp;({el.subname})</span>
              </div>
            ))}
          </div>
          <div className="peerBox">
            {customData ? (
              <div className="peerList peerList--custom">
                {customData.map((item) => (
                  <div className="peerList__item" key={item.name}>
                    <input
                      id={`${item.name}`}
                      className="peerList-check"
                      type="checkbox"
                      checked={item.check}
                      onChange={() => {
                        const idxItem = customData.findIndex((elem) => elem.name === item.name);
                        const selecttem = customData.find((elem) => elem.name === item.name);
                        const updateSelectItem = { ...selecttem, check: !item.check };
                        const updatePeer = [
                          ...customData.slice(0, idxItem),
                          updateSelectItem,
                          ...customData.slice(idxItem + 1),
                        ];
                        setCustomData(updatePeer);
                        setShowError(false);
                      }}
                    />
                    <label className="peerList-label" htmlFor={`${item.name}`}>
                      {item.name} {item.subname}
                    </label>
                  </div>
                ))}
              </div>
            ) : null}
            <form onSubmit={handleSubmit(onSubmitSettings)} className="customList">
              <div className="customList__item">
                <label className="addServer-item__label" htmlFor="ipAddress">
                  {t('ip_address')}
                </label>
                <input
                  id="ipAddress"
                  placeholder={'xxx.xxx.xxx.xxx'}
                  {...register('ipAddress')}
                  maxLength="15"
                  className="customList-input"
                  type="text"
                  value={inputData}
                  onChange={(e) => {
                    register('ipAddress').onChange(e);
                    const value = e.target.value;
                    const updateValue = value.replace(/[a-zA-Zа-яА-Я]/, '');
                    setInputData(updateValue);
                    setShowError(false);
                  }}
                />
                :
                <input
                  className="customList-port num"
                  type="number"
                  value={portData}
                  {...register('port')}
                  onChange={(e) => {
                    setPortData(e.target.value);
                    setShowError(false);
                  }}
                  min={1}
                  max={65535}
                  onKeyPress={inputCheckToNumber}
                />
                <ButtonBase type={'submit'} color={'green'} disabled={inputData === ''}>
                  {t('add')}
                </ButtonBase>
              </div>
            </form>
          </div>
        </div>
        <div className="add-server__actions">
          <ButtonBase color={'green'} onClick={addServerItem}>
            {t('apply')}
          </ButtonBase>
          <ButtonBase color={'grey'} onClick={closeMenu}>
            {t('cancel')}
          </ButtonBase>
        </div>
      </div>
      {showError && <div className="login-error">{messageError}</div>}
    </div>
  );
};

export default AddServer;
