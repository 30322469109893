import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputItem from '@shared/UI/InputItem/InputItem';
import { inputCheckToNumber } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';

const SourceRedundancy = ({ activeSettingsNavItem, dataState }) => {
  const { t } = useTranslation();
  const { register, watch, setValue } = useFormContext();
  const config = dataState?.source_redundancy;
  //console.log('config', config);
  const videoCheck = watch('videoSignal');
  const audioCheck = watch('audioSignal');
  const ccErrorsCheck = watch('ccErrors');

  React.useEffect(() => {
    setValue('videoSignal', config?.no_video.enabled);
    setValue('audioSignal', config?.no_audio.enabled);
    setValue('ccErrors', config?.cc_errors.enabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames(
        'settings-form-redundancy',
        activeSettingsNavItem === 1 ? 'setting-show' : '',
      )}
    >
      <h2 className="settings-form-title">{t('source_redundancy')}</h2>
      <div className="settings-form-subtitle">{t('checking_sources')}:</div>
      <div className="settings-form-redundancy-criteria">
        <div className="settings-form-redundancy-criteria__item">
          <div className="redundancy-labelBox">
            <input
              className="input-check"
              defaultChecked={dataState.source_redundancy.no_video.enabled}
              {...register('videoSignal')}
              id="videoSignal"
              type="checkbox"
            />
            <label className="redundancy-label" htmlFor="videoSignal">
              {t('no_video_signal')}
            </label>
          </div>

          <div className="redundancy-hiddenBlock">
            <InputItem
              type="number"
              name={'videoSignalNumber'}
              classes={'input-center input-center-72 num'}
              value={dataState.source_redundancy.no_video.duration}
              disabled={videoCheck ? null : true}
              minValue={0}
              maxValue={100000}
              onKeyPress={inputCheckToNumber}
            />

            <label className={classNames('text-forinput', videoCheck ? '' : 'disabled')}>
              {t('seconds')}
            </label>
          </div>
        </div>
        <div className="settings-form-redundancy-criteria__item">
          <div className="redundancy-labelBox">
            <input
              className="input-check"
              defaultChecked={dataState.source_redundancy.no_audio.enabled}
              {...register('audioSignal')}
              id="audioSignal"
              name="audioSignal"
              type="checkbox"
            />
            <label className="redundancy-label" htmlFor="audioSignal">
              {t('no_audio_signal')}
            </label>
          </div>
          <div className="redundancy-hiddenBlock">
            <InputItem
              type="number"
              name={'audioSignalNumber'}
              classes={'input-center input-center-72 num'}
              value={dataState.source_redundancy.no_audio.duration}
              disabled={audioCheck ? null : true}
              minValue={0}
              maxValue={100000}
              onKeyPress={inputCheckToNumber}
            />
            <label className={classNames('text-forinput', audioCheck ? '' : 'disabled')}>
              {t('seconds')}
            </label>
          </div>
        </div>
        <div className="settings-form-redundancy-criteria__item">
          <div className="redundancy-labelBox">
            <input
              className="input-check"
              defaultChecked={dataState.source_redundancy.cc_errors.enabled}
              {...register('ccErrors')}
              id="ccErrors"
              type="checkbox"
            />
            <label className="redundancy-label" htmlFor="ccErrors">
              {t('cc_errors')}
            </label>
          </div>
          <div className="redundancy-hiddenBlock redundancy-hiddenBlock--freeSize ml-a">
            <InputItem
              type="number"
              name={'errorsNumber'}
              classes={'input-center input-center-72 num'}
              value={dataState.source_redundancy.cc_errors.errors}
              disabled={ccErrorsCheck ? null : true}
              minValue={0}
              maxValue={100000}
              onKeyPress={inputCheckToNumber}
            />
            <label className={classNames('text-forinput', ccErrorsCheck ? '' : 'disabled')}>
              {t('errors_per')}
            </label>

            <InputItem
              type="number"
              name={'errorsSec'}
              classes={'input-center input-center-72 num'}
              value={dataState.source_redundancy.cc_errors.duration}
              disabled={ccErrorsCheck ? null : true}
              minValue={0}
              maxValue={100000}
              onKeyPress={inputCheckToNumber}
            />
            <label className={classNames('text-forinput', ccErrorsCheck ? '' : 'disabled')}>
              {t('seconds')}
            </label>
          </div>
        </div>
      </div>
      <div className="settings-form-redundancy-switch">
        <div className="redundancy-switch">
          <div className="redundancy-switch-block">
            <input
              className="input-check"
              {...register('switchBack')}
              id="switchBack"
              name="switchBack"
              type="checkbox"
              defaultChecked={dataState.source_redundancy.back_to_main}
            />
            <label className="redundancy-label" htmlFor="switchBack">
              {t('switch_back_to primary')}
            </label>
          </div>
          <span className="tooltipText">{t('when_the_main_signal_lost')}</span>
          <span className="tooltipText">{t('if_the_switch_back')}</span>
        </div>
      </div>
    </div>
  );
};

export default SourceRedundancy;
