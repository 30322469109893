import classNames from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';
import sprite from '@shared/Images/sprite.svg';
import GroupPermissions from '@services/GroupSettings';
import UserService from '@services/UserService';
import './AddUser.scss';
import { useTranslation } from 'react-i18next';
import { showScroll } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";

const AddUser = ({ setShowAddUser, usersData, getSecureData, selectGroup, edit, editData }) => {
  const { register, handleSubmit } = useForm();
  const [showErrorSameName, setShowErrorSameName] = React.useState(false);
  const [showErrorMatchPass, setShowErrorMatchPass] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [hidePass, setHidePass] = React.useState(false);
  const { t } = useTranslation();
  // console.log(userData);
  // console.log('usersData', usersData);
  // console.log('editData', editData);

  const closeModal = () => {
    setShowAddUser(false);
    showScroll();
  };

  const toggleShowPass = () => {
    setHidePass(!hidePass);
  };

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitNewUser = (data) => {
    // console.log(data);
    if (data.password !== data.confPassword) {
      setShowErrorMatchPass(true);
    } else {
      if (edit) {
        const updateData = {
          target: 'password',
          user_id: editData.id,
          old_password: data.oldPassword,
          new_password: data.confPassword,
        };
        // console.log(updateData);
        UserService.updateUser(updateData)
          .then((response) => {
            // console.log(response);
            closeModal();
            getSecureData();
          })
          .catch((error) => {
            console.dir(error);
            setShowError(true);
            setErrorMessage(error.response.data);
          });
        GroupPermissions.applyUpdate().then((response) => {
          // console.log('apply', response);
        });
      } else {
        const updateData = {
          username: data.name,
          password: data.confPassword,
          group_id: selectGroup,
        };
        // console.log(updateData);
        UserService.addUser(updateData).then((response) => {
          // console.log(response);
          closeModal();
          getSecureData();
        });
        GroupPermissions.applyUpdate().then((response) => {
          // console.log('apply', response);
        });
      }
    }
  };

  const validateName = (e) => {
    const value = e.target.value;
    // console.log(value);
    // const sameUser = usersData.find((item) => item.name === value);
    // console.log(sameUser);
    if (usersData.some((item) => item.name === value)) {
      setShowErrorSameName(true);
    } else {
      setShowErrorSameName(false);
    }
  };

  return (
    <div className="add-modal">
      <div className="add-modal-close" onClick={closeModal}>
        ×
      </div>
      <form id="formElem" onSubmit={handleSubmit(onSubmitNewUser)}>
        <h3 className="add-modal-header">{edit ? t('update_password') : t('add_user')}</h3>
        {edit ? (
          <>
            <div className="form">
              <div className="form__item">
                <label className="form__item-label">{t('old_password')} *</label>
                <input
                  className="form__item-input"
                  {...register('oldPassword')}
                  onChange={(e) => {
                    register('oldPassword').onChange(e);
                    setShowError(false);
                  }}
                  type="password"
                  autoComplete="off"
                  required
                />
              </div>
              <div className="form__item">
                <label className="form__item-label">{t('new_password')} *</label>
                <input
                  className="form__item-input"
                  {...register('password')}
                  onChange={(e) => {
                    register('password').onChange(e);
                  }}
                  type="password"
                  autoComplete="off"
                  minLength={6}
                  required
                />
              </div>
              <div className="form__item">
                <label className="form__item-label" htmlFor="source">
                  {t('confirm_password')} *
                </label>
                <div className={classNames(`conf-password-block`)} data-target="pass">
                  <input
                    autoComplete="off"
                    className="conf-password-block__input"
                    {...register('confPassword')}
                    onChange={(e) => {
                      register('confPassword').onChange(e);
                    }}
                    type={hidePass ? 'text' : 'password'}
                    minLength={6}
                    data-target="pass"
                    required
                  />
                  {hidePass ? (
                    <svg
                      className="password-block__img"
                      onClick={toggleShowPass}
                      data-target="pass"
                    >
                      <use
                        className="transform-properties-program__item-pluser"
                        href={sprite + '#open-eye'}
                      ></use>
                    </svg>
                  ) : (
                    <svg
                      className="password-block__img"
                      onClick={toggleShowPass}
                      data-target="pass"
                    >
                      <use
                        className="transform-properties-program__item-pluser"
                        href={sprite + '#close-eye'}
                      ></use>
                    </svg>
                  )}
                </div>
              </div>
              {showErrorMatchPass && (
                <div className="name-error">{t('passwords_does_not_match')}</div>
              )}
              {showError && <div className="name-error">{errorMessage}</div>}
              <div className="add-user__buttons">
                <ButtonBase
                  color={'green'}
                  type="submit"
                  disabled={showErrorSameName}
                >
                  {t('apply')}
                </ButtonBase>
                <ButtonBase
                  color={'grey'}
                  onClick={closeModal}
                >
                  {t('cancel')}
                </ButtonBase>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="form">
              <div className="form__item">
                <label className="form__item-label">{t('username')} *</label>
                <input
                  className="form__item-input"
                  {...register('name')}
                  type="text"
                  required
                  onBlur={(e) => {
                    register('name').onBlur(e);
                    validateName(e);
                  }}
                />
              </div>
              {showErrorSameName && (
                <div className="name-error">{t('this_name_is_already_in_use')}</div>
              )}
              <div className="form__item">
                <label className="form__item-label">{t('password')} *</label>
                <input
                  className="form__item-input"
                  {...register('password')}
                  onChange={(e) => {
                    register('password').onChange(e);
                  }}
                  type="password"
                  autoComplete="off"
                  minLength={6}
                  required
                />
              </div>
              <div className="form__item">
                <label className="form__item-label" htmlFor="source">
                  {t('confirm_password')} *
                </label>
                <div className={classNames(`conf-password-block`)} data-target="pass">
                  <input
                    autoComplete="off"
                    className="conf-password-block__input"
                    {...register('confPassword')}
                    onChange={(e) => {
                      register('confPassword').onChange(e);
                    }}
                    type={hidePass ? 'text' : 'password'}
                    minLength={6}
                    data-target="pass"
                    required
                  />
                  {hidePass ? (
                    <svg
                      className="password-block__img"
                      onClick={toggleShowPass}
                      data-target="pass"
                    >
                      <use
                        className="transform-properties-program__item-pluser"
                        href={sprite + '#open-eye'}
                      ></use>
                    </svg>
                  ) : (
                    <svg
                      className="password-block__img"
                      onClick={toggleShowPass}
                      data-target="pass"
                    >
                      <use
                        className="transform-properties-program__item-pluser"
                        href={sprite + '#close-eye'}
                      ></use>
                    </svg>
                  )}
                </div>
              </div>
              {showErrorMatchPass && (
                <div className="name-error">{t('passwords_does_not_match')}</div>
              )}
              <div className="add-user__buttons">
                <ButtonBase
                  color={'green'}
                  type="submit"
                  disabled={showErrorSameName}
                >
                  {t('apply')}
                </ButtonBase>
                <ButtonBase
                  color={'grey'}
                  onClick={closeModal}
                >
                  {t('cancel')}
                </ButtonBase>
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default AddUser;
