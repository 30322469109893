import {
  applyReserve,
  deleteGroups,
  deleteHosts,
  getGroups,
  getHosts,
  getStart,
  stopReserve,
  updateReserve,
} from '@widgets/Redundancy/RedundancyRequests/RedundancyRequests';
import React, { useState } from 'react';
import MyTable from '@shared/UI/MyTable/MyTable';
import ButtonAction from '@shared/UI/Buttons/ButtonAction/ButtonAction';
import AddServer from '@widgets/Redundancy/AddServer/AddServer';
import './Redundancy.scss';
import ErrorRow from '@shared/UI/Errors/ErrorRow/ErrorRow';
import { useHistory } from 'react-router';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import RedundancySettings from '@widgets/Redundancy/RedundancySettings/RedundancySettings';
import { useTranslation } from 'react-i18next';
import { apiChangeRedundancy } from '@widgets/Redundancy/api/apiRedundancyService';
import ButtonBase from '@shared/UI/Buttons/ButtonBase/ButtonBase';
import { useEffect } from 'react';
import { ReactComponent as SvgArrowLeft } from '@shared/Images/arrow-left.svg';

const Redundancy = () => {
  const [reservServers, setReservServers] = React.useState([]);
  const [reservHosts, setReservHosts] = React.useState([]);
  const [showAdd, setShowAdd] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [showAddGroup, setShowAddGroup] = React.useState(false);
  const isSelectHosts = reservHosts.some((el) => el.checked);
  const isSelectGroups = reservServers.some((el) => el.checked);
  const [showError, setShowError] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [isLoad, setIsLoad] = React.useState(false);
  const [isDisabledSwap, setIsDisabledSwap] = useState(true);
  const isSaveRef = React.useRef(false);
  const history = useHistory();
  const { t } = useTranslation();

  // console.log('isSelectHosts', isSelectHosts);
  // console.log('reservServers', reservServers);
  // console.log('isSaveRef', isSaveRef);

  useEffect(() => {
    let allCheckedReserv = reservServers?.filter((item) => item.checked);
    let allCheckedMain = reservHosts?.filter((item) => item.checked);
    if (allCheckedReserv?.length === 1 && allCheckedMain?.length === 1) {
      setIsDisabledSwap(false);
    } else {
      setIsDisabledSwap(true);
    }
  }, [reservServers, reservHosts]);

  const getServerData = async () => {
    await getStart(setShowError, setMessageError);
    await getGroups(setReservServers, setShowError, setMessageError);
    await getHosts(setReservHosts, setShowError, setMessageError, setIsLoad);
  };

  const showToggleAddMenu = () => {
    setShowAdd(!showAdd);
  };

  const showToggleAddMenuGroup = () => {
    setShowAddGroup(!showAddGroup);
  };

  const showToggleSettings = () => {
    setShowSettings(!showSettings);
  };

  React.useEffect(() => {
    getServerData();
    let timerId = setInterval(() => updateReserve(setShowError, setMessageError), 1000);
    return () => {
      clearTimeout(timerId);
      // console.log('isSaveRef', isSaveRef);
      !isSaveRef.current && stopReserve(setShowError, setMessageError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteHostsFunc = () => {
    const allData = reservHosts.filter((el) => el.checked).map((item) => `"${item.name}"`);
    deleteHosts(allData, setShowError, setMessageError, setReservHosts);
  };

  const deleteGroupsFunc = () => {
    const allData = reservServers.filter((el) => el.checked).map((item) => `"${item.name}"`);
    deleteGroups(allData, setShowError, setMessageError, setReservServers);
  };

  const handleSaveClose = () => {
    applyReserve(setShowError, setMessageError);
    isSaveRef.current = true;
    history.push('/');
  };

  const handleSwap = () => {
    let allCheckedReserv = reservServers?.filter((item) => item.checked);
    // console.log('allCheckedReserv', allCheckedReserv);

    let allCheckedMain = reservHosts?.filter((item) => item.checked);
    // console.log('allCheckedMain', allCheckedMain);

    if (allCheckedReserv?.length === 1 && allCheckedMain?.length === 1) {
      let reserv = allCheckedReserv?.[0]?.name;
      let main = allCheckedMain?.[0]?.name;
      apiChangeRedundancy(reserv, main).then(() => {
        moveReservToMain(reserv, main);
      });
    }
  };

  const moveReservToMain = (reserv, main) => {
    let newReservServersList = reservServers?.filter((item) => item?.name !== reserv);
    let reservItem = reservServers?.find((item) => item?.name === reserv);

    let newMainList = reservHosts?.map((item) => {
      return item?.name === main ? reservItem : item;
    });

    setReservServers(newReservServersList);
    setReservHosts(newMainList);
  };

  return (
    <div className="home">
      {showError && <ErrorRow message={messageError} setShowError={setShowError} />}
      <div className="header-box">
        <div className="header-box-title">{t('redundancy')}</div>
        <div className="header-box-btns">
          <ButtonBase
            color={'grey'}
            onClick={showToggleSettings}
            disabled={reservHosts.length === 0 || reservServers.length === 0}
            >
            {t('settings')}
          </ButtonBase>
        </div>
      </div>

      {showSettings && (
        <RedundancySettings
          closeMenu={showToggleSettings}
          setShowError={setShowError}
          setMessageError={setMessageError}
        />
      )}

      {showAdd && (
        <>
          <AddServer
            closeMenu={showToggleAddMenu}
            title={t('add_main_server')}
            item={'host'}
            setReservItems={setReservHosts}
          />
          <div className="overlay"></div>
        </>
      )}

      {showAddGroup && (
        <>
          <AddServer
            closeMenu={showToggleAddMenuGroup}
            title={t('add_redundancy_server')}
            item={'group'}
            setReservItems={setReservServers}
          />
          <div className="overlay"></div>
        </>
      )}
      {!isLoad ? (
        <Loader top={true} />
      ) : (
        <div className="data-box">
          <div className="tables-block">
            <div className="tables-block-all">
              <div className="table-header">
                <p className="table-name">{t('main_servers')}</p>
                {isSelectHosts ? (
                  <ButtonAction
                    label={t('delete')}
                    showFunc={deleteHostsFunc}
                    img={'#trash'}
                    classes={'pic pic--red'}
                  />
                ) : (
                  <ButtonAction
                    label={t('add')}
                    showFunc={showToggleAddMenu}
                    img={'#add'}
                    classes={'pic pic--green'}
                  />
                )}
              </div>
              {reservHosts.length === 0 ? (
                <MyTable
                  empty={true}
                  items={[
                    { name: 'checkbox', className: 'tab-check' },
                    { name: t('#'), className: 'tab-num' },
                    { name: t('name'), className: 'tab-text' },
                  ]}
                  emptyText={t('no_servers')}
                  bodyItems={null}
                />
              ) : (
                <MyTable
                  items={[
                    { name: 'checkbox', className: 'tab-check' },
                    { name: t('#'), className: 'tab-num' },
                    { name: t('name'), className: 'tab-text' },
                  ]}
                  bodyItems={reservHosts}
                  setBodyItems={setReservHosts}
                />
              )}
            </div>

            <ButtonBase
              color={'green'}
              onClick={handleSwap}
              disabled={isDisabledSwap}
              isIcon
            >
              <SvgArrowLeft />
            </ButtonBase>

            <div className="tables-block-all">
              <div className="table-header">
                <p className="table-name"> {t('redundancy_servers')}</p>
                {isSelectGroups ? (
                  <ButtonAction
                    label={t('delete')}
                    showFunc={deleteGroupsFunc}
                    img={'#trash'}
                    classes={'pic pic--red'}
                  />
                ) : (
                  <ButtonAction
                    label={t('add')}
                    showFunc={showToggleAddMenuGroup}
                    img={'#add'}
                    classes={'pic pic--green'}
                  />
                )}
              </div>

              {reservServers.length === 0 ? (
                <MyTable
                  empty={true}
                  items={[
                    { name: 'checkbox', className: 'tab-check' },
                    { name: t('#'), className: 'tab-num' },
                    { name: t('name'), className: 'tab-text' },
                  ]}
                  emptyText={'No servers'}
                  bodyItems={null}
                />
              ) : (
                <MyTable
                  items={[
                    { name: 'checkbox', className: 'tab-check' },
                    { name: t('#'), className: 'tab-num' },
                    { name: t('name'), className: 'tab-text' },
                  ]}
                  bodyItems={reservServers}
                  setBodyItems={setReservServers}
                />
              )}
            </div>
          </div>
          <ButtonBase color={'green'} onClick={handleSaveClose}>
            {t('save_exit')}
          </ButtonBase>
        </div>
      )}
    </div>
  );
};

export default Redundancy;
