import OutputFiltersItem from '@widgets/TaskConfigurator/OutputSection/OutputFiltersItem/OutputFiltersItem';
import OutputParameters from '@widgets/TaskConfigurator/OutputSection/OutputParameters/OutputParameters';
import React from 'react';
import './ExtensionNode.scss';
import ExtensionAddButtons from '@widgets/TaskConfigurator/OutputSection/ExtensionAddButtons/ExtensionAddButtons';

const ExtensionNode = ({ node }) => {
  return (
    <div className="extension-node--removable">
      <OutputFiltersItem item={node} showCloseButton={true}>
        {node.parameters && <OutputParameters parameters={node.parameters} />}

        <ExtensionAddButtons
          className={'button-wrapper'}
          extension={node}
          dependencies={[node.extensions]}
        />

        {node?.extensions &&
          node?.extensions.map((extensionNode) => (
            <ExtensionNode key={extensionNode.id} node={extensionNode} />
          ))}
      </OutputFiltersItem>
    </div>
  );
};

export default ExtensionNode;
