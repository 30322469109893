import Cookies from 'js-cookie';
import $api from './apiAxios';
import { formErrorObject } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';

export const apiAddDraft = async () => {
  try {
    const res = await $api.post(`configurator/draft`);
    if (res?.isAxiosError) {
      let newError = formErrorObject(res);
      return Promise.reject(newError);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiDeleteDraft = async () => {
  try {
    const res = await $api.delete(`configurator/draft`);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiAddDraftForTemplate = async () => {
  try {
    const res = await $api.post(`configurator/draft?mode=ssc_creator`);
    if (res?.isAxiosError) {
      let newError = formErrorObject(res);
      return Promise.reject(newError);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiGetSourceList = async () => {
  try {
    const res = await $api.get(`/configurator/sections/source/templates`);
    if (res?.isAxiosError) {
      let newError = formErrorObject(res);
      return Promise.reject(newError);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiAddSection = async (category, name) => {
  try {
    const res = await $api.post(
      `/configurator/sections/source/insert?template=${category}&section=${name}`,
    );
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiDeleteSection = async (id) => {
  try {
    const res = await $api.delete(`/configurator/sections/source?id=${id}`);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiGetAnnounceList = async () => {
  try {
    const res = await $api.get(`/configurator/sections/source/network/announces`);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiGetAnnounceNDIList = async () => {
  try {
    const res = await $api.get(`/configurator/sections/source/network/announces/ndi`);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiSendParams = async (parameters) => {
  try {
    const res = await $api.post(`/configurator/parameters`, parameters);
    // console.dir(res);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiUpdateSourcePins = async () => {
  try {
    const res = await $api.post(`/configurator/sections/source/update_pins`);
    // console.log('update_pins', res.data.data.found_all);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiTransformUpdatePins = async () => {
  try {
    const res = await $api.post(`/configurator/sections/transform/update_pins`);
    // console.log('update_pins', res.data.data.found_all);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiGetTransform = async (parameters) => {
  try {
    const res = await $api.get(`/configurator/sections/transform`);
    console.dir(res);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const getTransformConnectorList = async () => {
  const res = await $api.get(`/configurator/sections/transform/connector_list`);
  if (res.isAxiosError) {
    console.dir(res);
    if (res.response?.status !== 400) {
      throw new Error(res.message);
    }
  } else {
    return res;
  }
};

export const apiTransformCoderList = async (section_id, pin_index) => {
  try {
    const res = await $api.get(
      `/configurator/sections/transform/connector?section_id=${section_id}&pin_index=${pin_index}`,
    );
    // console.log(res);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiRefreshAllProgs = async () => {
  try {
    const res = await $api.post(`/configurator/sections/source/reset`);
    // console.log(res);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiTurnConnectorPin = async (id, idx, setCheck) => {
  try {
    const res = await $api.post(
      `/configurator/sections/transform/connector?command=turn&section_id=${id}&pin_index=${idx}&choice=${setCheck}`,
    );
    // console.log('res', res);
    if (res.isAxiosError) {
      throw new Error(
        res?.response?.data?.error
          ? res?.response?.data?.error
          : res?.response?.data?.message
          ? res?.response?.data?.message
          : res?.message
          ? res?.message
          : '',
      );
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiTurnConnectorPins = async (parameters) => {
  try {
    const res = await $api.post(`/configurator/sections/transform/connector-all`, parameters);
    // console.log('res', res);
    if (res.isAxiosError) {
      return Promise.reject(
        res?.response?.data?.changes ? res?.response?.data?.changes : formErrorObject(res),
      );
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiSetConnectorPin = async (id, idx, valueId) => {
  try {
    const res = await $api.post(
      `/configurator/sections/transform/connector?command=set&section_id=${id}&pin_index=${idx}&choice=${valueId}`,
    );
    // console.log('res', res);
    if (res.isAxiosError) {
      console.dir(res);
      let newError = formErrorObject(res);
      return Promise.reject(newError);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

// RENDER //

export const getRenderConnectorOnePin = async (id, idx) => {
  try {
    const res = await $api.get(
      `/configurator/sections/render/connector?section_id=${id}&pin_index=${idx}`,
    );

    if (res.isAxiosError) {
      let newError = formErrorObject(res);
      return Promise.reject(newError);
    }
    if (res.response?.status === 404) {
      console.dir(res);
      return { data: [] };
    } else {
      // console.log(res);
      return res;
    }
  } catch (error) {
    console.dir(error);
    // return error.response;
    return Promise.reject(error);
  }
};

export const getRenderConnectorList = async () => {
  try {
    const res = await $api.get(`/configurator/sections/render/connector_list`);
    // console.log(res);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiTurnRenderConnector = async (id, idx, valueId) => {
  try {
    const res = await $api.post(
      `/configurator/sections/render/connector?command=turn&section_id=${id}&pin_index=${idx}&choice=${valueId}`,
    );
    // console.dir(res);
    if (res.isAxiosError) {
      throw new Error(res?.response?.data?.error);
    } else {
      return res;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiSetRenderConnectorParam = async (id, idx, valueId) => {
  try {
    const res = await $api.post(
      `/configurator/sections/render/connector?command=set&section_id=${id}&pin_index=${idx}&choice=${valueId}`,
    );
    // console.log('res', res);
    if (res.isAxiosError) {
      let newError = formErrorObject(res);
      return Promise.reject(newError);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiGetAllOutputsList = async () => {
  try {
    //'CW_CONFIGURATOR' переключение между режимом разработчика (dev) и режимом для пользователя (special)
    let modifier = Cookies.get('CW_CONFIGURATOR');
    const res = await $api.get(
      `/configurator/sections/render?type=all${
        modifier ? `&modifier=${modifier}` : '&modifier=special'
      }`,
    );
    if (res.isAxiosError) {
      console.dir(res);
      if (res.response?.status !== 404 && res.response?.status !== 400) {
        throw new Error(res.message);
      }
    }
    if (res.response?.status === 404 || res.response?.status === 400) {
      console.dir(res);
      return { data: [] };
    } else {
      // console.log(res);
      return res;
    }
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiCreateSchema = async (name, comment) => {
  try {
    const res = await $api.post(`/configurator/save?name=${name}&comment=${comment}`);
    // console.log(res);
    if (res.isAxiosError) {
      console.dir(res);
      // throw new Error(res.response.data);
      let newError = formErrorObject(res);
      return Promise.reject(newError);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};

export const apiCompareName = async (name) => {
  try {
    const res = await $api.get(`/configurator/file-exist?name=${name}`);
    // console.log(res);
    if (res.isAxiosError) {
      console.dir(res);
      // throw new Error(res.response.data);
      let newError = formErrorObject(res);
      return Promise.reject(newError);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiCreateSourceTemplate = async (name) => {
  try {
    const res = await $api.post(
      `/configurator/sections/source/create_source_template?name=${name}`,
    );
    // console.log(res);
    return res;
  } catch (error) {
    console.dir(error);
    return error.response;
  }
};
