import React from 'react';
import './ButtonBase.module.scss';
import classNames from 'classnames';
import s from './ButtonBase.module.scss';

// обязательные параметры: children, color;
// допустимые параметры color (строка): 'blue', 'green', 'red', 'grey';
// допустимые параметры size (строка): 'small';
// по умолчанию кнопка стандартного размера с текстовым содержимым;
const ButtonBase = (
  {
    onClick,
    type = 'button',
    children,
    color,
    size,
    disabled = false,
    isIcon = false,
  }) => {
  return (
    <button
      data-testid="button-base"
      className={
        classNames(
          s['button-base'],
          color && s[`button-base_type_color-${color}`],
          size && s[`button-base_type_size-${size}`],
          isIcon && s['button-base_type_icon'],
        )}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {children ? children : 'button'}
    </button>
  );
};

export default ButtonBase;