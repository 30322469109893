import classNames from 'classnames';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from '@shared/Images/sprite.svg';
import { apiLogout } from '@pages/Login/api/AuthService';
import { authSettingsActions } from '@store/Authorization';
import { useTranslation } from 'react-i18next';
// import EditProfile from './EditProfile/EditProfile';

const UserMenu = () => {
  const { t } = useTranslation();
  const menuRef = useRef();
  const dispatch = useDispatch();
  const [showSetting, setShowSetting] = React.useState(false);
  const authSettings = useSelector((state) => state.authSettings);
  // console.log('authSettings', authSettings);

  React.useEffect(() => {
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setShowSetting(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const toggleShowUserMenu = () => {
    setShowSetting(!showSetting);
  };

  const signOut = () => {
    if (authSettings.auth) {
      apiLogout();
      localStorage.removeItem('authSettings');
      const updateAuth = {
        auth: false,
        group_id: null,
        group_name: 'guest',
        id: null,
        name: 'Guest',
      };
      dispatch(authSettingsActions.setAuthSettingsLogout(updateAuth));
      toggleShowUserMenu();
    }
  };

  const hideMenu = () => {
    toggleShowUserMenu();
  };

  return (
    <div className={classNames(`user`, showSetting ? 'user-show' : '')} ref={menuRef}>
      <div className="user-box" onClick={toggleShowUserMenu}>
        <svg className={classNames(`user-img`, showSetting ? 'user-img--black' : '')}>
          <use href={sprite + '#user'}></use>
        </svg>
        <p className={classNames(`user-text`, showSetting ? 'user-text--black' : '')}>
          {authSettings.name}
        </p>
        <svg className={classNames(`user-arrow`, showSetting ? 'user-arrow--black' : '')}>
          <use href={sprite + '#arrow-down'}></use>
        </svg>
      </div>

      <div
        className={classNames(
          `dropdown-menu dropdown-menu--right`,
          showSetting ? 'dropdown-menu--show' : '',
        )}
      >
        <div className="dropdown-menu__userinfo">
          <div className="dropdown-menu__userinfo-name">
            <span className="dropdown-menu__userinfo-name__text">{authSettings.name}</span>
            <span className="dropdown-menu__userinfo-role">
              {t('group')}: {authSettings.group_name}
            </span>
          </div>
          <>
            <div className="divider-thin"></div>
            <div className="dropdown-menu__userinfo-text">
              <Link className="dropdown-menu-item__link" to="/user-permissions" onClick={hideMenu}>
                {t('security_settings')}
              </Link>
            </div>
            <div className="divider-thin"></div>
            <div className="dropdown-menu__userinfo-text">
              <Link className="dropdown-menu-item__link" to="/login" onClick={signOut}>
                {authSettings.auth ? (
                  <>
                    <svg className="signout-img">
                      <use href={sprite + '#sign-out'}></use>
                    </svg>
                    {t('sign_out')}
                  </>
                ) : (
                  <>
                    <svg className="signout-img">
                      <use href={sprite + '#login'}></use>
                    </svg>
                    {t('SIGN_IN')}
                  </>
                )}
              </Link>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
