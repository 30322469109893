import { createTaskActionTypes } from './actions';

const initialState = { data: {}, link: {} };

export const addSource = (state = initialState, action) => {
  switch (action.type) {
    case createTaskActionTypes.SET_SOURCE_PARAMS:
      return {
        ...state,
        data: action.payload,
      };
    case createTaskActionTypes.SET_LINK_TO_TRANSFORM:
      return {
        ...state,
        link: action.payload,
      };
    default:
      return state;
  }
};
