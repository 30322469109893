import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  consoleEventsName,
  dispatcherEventsName,
  severityLevels,
} from '@widgets/ServerSettings/lib/ServerSettingsDictionary';

const SnmpEvents = ({ activeSeverityItem, dataState }) => {
  const { register, watch, setValue } = useFormContext();
  const { t } = useTranslation();
  const stateEvents = dataState.snmp.console_events;
  // console.log('stateEvents', stateEvents);
  const stateDispatch = dataState.snmp.dispatcher_events;
  // console.log('stateDispatch', stateDispatch);

  React.useEffect(() => {
    stateEvents.map((item) => setValue(`eventsTaskEnabled-${item.event_type}`, item.enabled));
    stateDispatch.map((item) => setValue(`eventsDispatchEnabled-${item.event_type}`, item.enabled));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames('severity-data', activeSeverityItem === 1 ? 'severity-task-flex' : '')}
    >
      <div className="severity-events-block" onMouseDown={() => console.log('true')}>
        <p className="severity-events-block__text">{t('task_events')}</p>
        <div className="severity-task-header hlab">
          <div className="severity-task-header__item">{t('trap_name')}</div>
          <div className="severity-task-header__item">{t('level')}</div>
        </div>
        <div className="severity-task-data">
          {stateEvents.map((item, i) => (
            <div className="severity-task-data__item" key={item.event_type + i}>
              <div className="snmpEvents-labelBox">
                <input
                  defaultValue={item.enabled}
                  className="input-check"
                  {...register(`eventsTaskEnabled-${item.event_type}`)}
                  id={`eventsTaskEnabled-${item.event_type}`}
                  type="checkbox"
                />
                <label
                  className="redundancy-label"
                  htmlFor={`eventsTaskEnabled-${item.event_type}`}
                >
                  {consoleEventsName[item.event_type]}
                </label>
              </div>
              <select
                defaultValue={item.level}
                disabled={!watch(`eventsTaskEnabled-${item.event_type}`)}
                className="severity-events-data-list"
                id={`level-${item.event_type}`}
                {...register(`level-${item.event_type}`)}
              >
                {severityLevels}
              </select>
            </div>
          ))}
        </div>
      </div>
      <div className="severity-events-block">
        <p className="severity-events-block__text">{t('dispatcher_events')}</p>
        <div className="severity-task-header hlab">
          <div className="severity-task-header__item">{t('trap_name')}</div>
          <div className="severity-task-header__item">{t('level')}</div>
        </div>
        <div className="severity-task-data">
          {stateDispatch.map((item, i) => (
            <div className="severity-task-data__item" key={item.event_type + i}>
              <div className="snmpEvents-labelBox">
                <input
                  defaultValue={item.enabled}
                  className="input-check"
                  {...register(`eventsDispatchEnabled-${item.event_type}`)}
                  id={`eventsDispatchEnabled-${item.event_type}`}
                  type="checkbox"
                />
                <label
                  className="redundancy-label"
                  htmlFor={`eventsDispatchEnabled-${item.event_type}`}
                >
                  {dispatcherEventsName[item.event_type]}
                </label>
              </div>

              <select
                disabled={!watch(`eventsDispatchEnabled-${item.event_type}`)}
                defaultValue={item.level}
                className="severity-events-data-list"
                name={`dispatcher-level-${item.event_type}`}
                id={`dispatcher-level-${item.event_type}`}
                {...register(`dispatcher-level-${item.event_type}`)}
              >
                {severityLevels}
              </select>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SnmpEvents;
