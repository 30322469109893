import './OutputTabs.scss';
import React from 'react';
import { apiGetAllOutputsList } from '@services/CreateTaskService';
import { createTaskActions } from '@store/CreateTask';
import { useDispatch } from 'react-redux';
import { apiDeleteExtension } from '@widgets/TaskConfigurator/OutputSection/api/apiExtensions';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Error from '@shared/UI/Errors/Error/Error';
import ButtonClose from '@shared/UI/Buttons/ButtonClose/ButtonClose';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';

const OutputTabs = ({
  tabNames,
  activeTab,
  setActiveTab,
  children,
  showCloseButton,
  className,
}) => {
  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const dispatch = useDispatch();

  const handleTabClick = (e, i) => {
    setActiveTab(i);
    e.preventDefault();
  };

  const handlerClickCloseButton = (e, item) => {
    apiDeleteExtension(item.id)
      .then(() => {
        apiGetAllOutputsList().then((items) => {
          const outputData = items?.data?.data;
          const arrSections = outputData ? outputData.map((elem) => elem.section) : [];
          dispatch(createTaskActions.setExchangeOutput(arrSections));
        });
      })
      .catch((error) => {
        setMessageError(error.message);
        setShowError(true);
      });

    e.preventDefault();
  };

  return (
    <>
      <div className="output-tabs">
        <ul className={`output-tabs__list ${className ? className : ''}`}>
          {tabNames &&
            !!tabNames?.length &&
            tabNames.map((item, i) => (
              <li className="output-tabs__list__item" key={item.id}>
                <button
                  className={`output-tabs__button ${
                    activeTab === i ? 'output-tabs__button--active' : ''
                  }`}
                  onClick={(e) => handleTabClick(e, i)}
                >
                  {item.name}
                </button>
                {showCloseButton && (
                  <ButtonClose onClick={(e) => handlerClickCloseButton(e, item)} />
                )}
              </li>
            ))}
          {children}
        </ul>
      </div>
      <ModalPortal showModal={showError}>
        <CSSTransition in={showError} timeout={500} unmountOnExit classNames="alert">
          <Error
            setShowError={setShowError}
            message={messageError}
            setMessageError={setMessageError}
          />
        </CSSTransition>
      </ModalPortal>
    </>
  );
};

export default OutputTabs;
