import React, { useRef } from 'react';
import i18next from 'i18next';
import sprite from '@shared/Images/sprite.svg';
import classNames from 'classnames';
import s from './Flags.module.scss'

const Flags = ({ login }) => {
  const [showSetting, setShowSetting] = React.useState(false);
  const menuRef = useRef();
  const toggleShowMenu = () => {
    setShowSetting(!showSetting);
  };

  React.useEffect(() => {
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setShowSetting(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const changeLanguage = (e) => {
    const lang = e.target.dataset.lang;
    // console.log(lang);
    i18next.changeLanguage(lang, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
    });
  };

  return (
    <div
      className={classNames(s['flags'], showSetting ? 'user-show' : '')}
      onClick={toggleShowMenu}
      ref={menuRef}
    >
      <svg className={classNames(s['flags-img'], showSetting ? s['flags-img--black'] : '')}>
        <use href={sprite + '#lang'}></use>
      </svg>
      <div
        className={classNames(
          `dropdown-menu dropdown-menu-lang`,
          showSetting ? 'dropdown-menu--show' : '',
        )}
      >
        <div className="dropdown-menu__userinfo">
          <span
            className={classNames(
              'dropdown-menu__userinfo-text jc-c',
              i18next.language === 'en' ? s['text-active'] : '',
            )}
            data-lang="en"
            onClick={changeLanguage}
          >
            En
          </span>
          <div className="divider-thin" />
          <span
            className={classNames(
              'dropdown-menu__userinfo-text jc-c',
              i18next.language === 'ru' ? s['text-active'] : '',
            )}
            data-lang="ru"
            onClick={changeLanguage}
          >
            Ru
          </span>
        </div>
      </div>
    </div>
  );
};

export default Flags;
