import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { createTaskActions } from '@store/CreateTask';
import ViewSourceLink from '@shared/UI/ViewSourceLink/ViewSourceLink';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import TransformSection from '@widgets/TaskConfigurator/TransformSection/TransformSection';
import TransformBlock from '@widgets/TaskConfigurator/TransformSection/TransformBlock/TransformBlock';
import ErrorAlert from '@shared/UI/Errors/ErrorAlert/ErrorAlert';
import {
  apiAddDraft,
  apiCompareName,
  apiCreateSchema,
  apiDeleteDraft,
  getTransformConnectorList,
} from '@services/CreateTaskService';
import OutputBlock from '@widgets/TaskConfigurator/OutputSection/OutputBlock/OutputBlock';
import OutputSection from '@widgets/TaskConfigurator/OutputSection/OutputSection';
import ErrorsAlert from '@shared/UI/Errors/ErrorsAlert/ErrorsAlert';
import SourceBlock from '@widgets/TaskConfigurator/SourceItems/SourceBlock/SourceBlock';
import SourceItems from '@widgets/TaskConfigurator/SourceItems/SourceItems';
import InputSection from '@widgets/TaskConfigurator/InputSection/InputSection';
import { useTranslation } from 'react-i18next';
import './TaskConfigurator.scss';
import { useParams } from 'react-router';
import { apiGetAllOutputsListForEdit, apiGetDraftForEditTask } from './api/apiTaskConfigurator';
import { refreshPins } from '@shared/Lib/Utils/TaskConfigurator/apiGeneralTaskConfigurator';
import {
  CW_DRAFT_ID,
  FORBIDDEN_SYMBOLS_REGEX,
  FILE_NAME_REGEX,
} from '@shared/Lib/Utils/Constants/Constants';
import TextTooltip from '@shared/UI/TextTooltip/TextTooltip';

const TaskConfigurator = memo(({ configType }) => {
  const [showLoaderSourceItems, setShowLoaderSourceItems] = useState(false);
  const [draft, setDraft] = useState(false);
  const [isValid, setIsValid] = useState({ status: true, error: '' });

  const { storage_id, schema } = useParams();

  // console.log('state', state);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorRequest = useSelector((state) => state.createTask.errorRequest);
  const arraySources = useSelector((state) => state.createTask.arraySources);
  const openTransformSection = useSelector((state) => state.createTask.openTransformSection);
  const inputsNumberBlock = useSelector((state) => state.createTask.inputsNumberBlock);
  const errorCreate = useSelector((state) => state.createTask.errorCreateTaskId);
  const activeSourceBlock = useSelector((state) => state.createTask.activeSourceBlock);
  const activeInputTab = useSelector((state) => state.createTask.activeInputTab);
  const isCompleteSource =
    arraySources?.length === 0 ? false : arraySources.some((elem) => !elem?.programs?.length);
  const history = useHistory();
  const inputsNumber = arraySources
    .map((elem) => elem?.programs?.map((program) => program?.pins?.map((pin) => pin)))
    .flat(2);

  const methods = useForm();

  const {
    handleSubmit,
    register,
    setValue,
    // setError,
    formState: { errors },
  } = methods;

  const arrErrors = Object.entries(errors).map((e) => ({ ...e[1], name: e[0] }));
  // console.log('arrErrors', arrErrors);

  const setDraftId = () => {
    const draftName = Cookies.get(CW_DRAFT_ID);
    if (draftName) {
      apiDeleteDraft().then(() => {
        Cookies.remove(CW_DRAFT_ID);
        setDraft('');

        //Get draft
        fillOutsourceData();
      });
    }
    !draftName && fillOutsourceData();
  };

  const fillOutsourceData = () => {
    if (configType === 'copy_task' || configType === 'edit_task') {
      setShowLoaderSourceItems(true);
      apiGetDraftForEditTask(storage_id, schema)
        .then((res) => {
          const draftId = res.data;
          Cookies.set(CW_DRAFT_ID, draftId);
          setDraft(draftId);
          //get source
          apiGetAllOutputsListForEdit().then((result) => {
            let sections = result?.data?.data;
            let i = 1;
            sections?.forEach((item) => {
              let section = item?.section;

              let newNumberBlock = inputsNumberBlock + i;
              onAddInputSection(newNumberBlock);
              let sourceFilters = section?.filters;

              dispatch(
                createTaskActions.setAddSourceBlock(
                  //Попросить добавить IP
                  { template: 'IP', section: section.name },
                  newNumberBlock,
                  sourceFilters,
                ),
              );
              dispatch(createTaskActions.setInputTabs('Services'));

              dispatch(
                createTaskActions.setUpdateSourceBlock(section?.id, newNumberBlock, sourceFilters),
              );

              getTransformConnectorList()
                .then((items) => {
                  // console.log('GETALLPROGS');
                  // console.log('items', items);
                  // console.log('sec', sec);

                  const sourcePrograms = items
                    ? items.data.data.filter((item) => item.section_id === section.id)[0]?.groups
                    : [];
                  // console.log('sourcePrograms', sourcePrograms);
                  // console.log('sectionData', sectionData.id);
                  const updatePrograms = sourcePrograms
                    ? sourcePrograms.map((prog) => ({
                        ...prog,
                        section_id: section.id,
                        checked: prog.pins.find((pin) => pin.is_used) ? true : false,
                        pins: prog.pins
                          .map((pin) => ({
                            ...pin,
                            order:
                              pin.track_type === 'video'
                                ? 1
                                : pin.track_type === 'audio'
                                ? 2
                                : pin.track_type === 'subpicture'
                                ? 3
                                : 4,
                          }))
                          .sort((a, b) => a.order - b.order),
                      }))
                    : [];

                  dispatch(
                    createTaskActions.setAddProgramsSourceItems(
                      updatePrograms.length === 0 ? null : updatePrograms,
                      section.id,
                    ),
                  );

                  if (updatePrograms.length !== 0) {
                    // console.log('<<<<UPDATE SOURCES>>>>');
                    dispatch(createTaskActions.setModalOpenTransformSection(true));
                    refreshPins(updatePrograms, dispatch, newNumberBlock).finally(() => {
                      if (sections.length === i - 1) {
                        setShowLoaderSourceItems(false);
                      }
                    });
                  }
                })
                .catch((err) => {
                  console.dir(err);
                  dispatch(createTaskActions.setAddProgramsSourceItems(null, section.id));
                });
              ++i;
            });
          });
        })
        .catch((error) => {
          dispatch(createTaskActions.setErrorFromRequest(error));
        });
    }
    if (configType === 'create_task') {
      apiAddDraft().then((res) => {
        const draftId = res.data;
        Cookies.set(CW_DRAFT_ID, draftId);
        setDraft(draftId);
      });
    }
  };

  const removeDraftId = () => {
    const draftName = Cookies.get(CW_DRAFT_ID);
    if (draftName) {
      apiDeleteDraft().then(() => {
        Cookies.remove(CW_DRAFT_ID);
      });
    }
  };

  useEffect(() => {
    arraySources.length === 0 && dispatch(createTaskActions.setInputsNumberBlock(0));
    arraySources.length === 0 && dispatch(createTaskActions.setInputTabs('Settings'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arraySources]);

  useEffect(() => {
    setDraftId();
    if (configType === 'create_task') {
      dispatch(createTaskActions.setClearCreateComponent());
    }

    return () => {
      removeDraftId();
      dispatch(createTaskActions.setClearCreateComponent());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const arrayInputSection =
    arraySources.length === 0
      ? null
      : arraySources.map((elem) => {
          return (
            <InputSection
              key={`InputSection${elem.sectionNumber}edit`}
              number={elem?.sectionNumber}
              copied={elem?.selectSource && elem?.id ? true : false}
              template={elem?.selectSource}
              showLoaderSourceItems={showLoaderSourceItems}
              setShowLoaderSourceItems={setShowLoaderSourceItems}
              isEditTask={elem?.selectSource && elem?.id ? true : false}
              isShowTabMenu={
                configType === 'copy_task' || configType === 'edit_task' ? true : false
              }
            />
          );
        });

  const arrayBlocksSources =
    arraySources.length === 0
      ? null
      : arraySources.map((elem) => {
          return (
            elem?.selectSource?.section && (
              <SourceBlock
                key={`SourceBlock${elem.sectionNumber}`}
                number={elem.sectionNumber}
                titleName={elem?.selectSource?.section}
                sectionId={elem.id}
              />
            )
          );
        });

  const arrayProgramsSourceItems =
    arraySources?.length === 0
      ? null
      : arraySources.map((elem) =>
          elem.programs === null && !showLoaderSourceItems ? (
            <div
              key={`SourceItems${elem.sectionNumber}`}
              className="source mt-10"
              hidden={
                activeSourceBlock === elem.sectionNumber
                  ? activeInputTab === `Services`
                    ? false
                    : true
                  : true
              }
            >
              {t('input_streams_are_not_detected')}
            </div>
          ) : elem.programs?.length !== 0 ? (
            <SourceItems
              key={`SourceItems${elem.sectionNumber}`}
              sectionData={elem}
              number={elem.sectionNumber}
              sectionId={elem.id}
              arrPrograms={elem.programs}
              showLoaderSourceItems={showLoaderSourceItems}
              setShowLoaderSourceItems={setShowLoaderSourceItems}
            />
          ) : null,
        );

  // console.log('arrayProgramsSourceItems', arrayProgramsSourceItems);

  const onAddInputSection = (newNumberBlock) => {
    //const newCount = inputsNumberBlock + 1;
    dispatch(createTaskActions.setInputTabs('Settings'));
    dispatch(createTaskActions.setInputsNumberBlock(newNumberBlock));
    dispatch(createTaskActions.setAddSource(newNumberBlock));
    dispatch(createTaskActions.setActiveSourceBlock(newNumberBlock));
  };

  const arrayOutputs = useSelector((state) => state.createTask.arrayOutputs);
  // console.log('arrayOutputs', arrayOutputs);

  const arrayOutputItems =
    arrayOutputs.length === 0
      ? null
      : arrayOutputs.map((elem, i) => (
          <OutputSection
            key={`OutputBlock${elem.id}`}
            number={i + 100}
            arrErrors={arrErrors}
            sectionData={elem}
          />
        ));
  // console.log('arrayOutputItems', arrayOutputItems);
  // console.log('configType', configType);

  const onSubmitNewTask = async (data, apply) => {
    // console.log('data', data);
    // console.log('apply', apply);
    if (configType === 'copy_task' || configType === 'create_task') {
      try {
        const schemaName = data['task-name'].trim();
        const isExistName = await apiCompareName(schemaName).then((res) => res.data.name_is_used);
        if (isExistName) {
          // имя задачи существует
          dispatch(
            createTaskActions.setErrorFromRequest({
              message: `"${schemaName}" ${t('task_name_alredy_used')}`,
            }),
          );
          setIsValid({ status: false, error: '' });
          // setError(
          //   'task-name',
          //   { message: `"${schemaName}" ${t('task_name_alredy_used')}` },
          //   { shouldFocus: true },
          // );
        } else {
          // имя задачи новое
          if (arrErrors.length === 0 && apply !== true) {
            try {
              await apiCreateSchema(schemaName, '');
              history.push('/');
              // console.log('res', res);
            } catch (error) {
              dispatch(createTaskActions.setErrorFromRequest({ message: t('error_save_task') }));
            }
          }
        }
      } catch (error) {
        //вписать конкретный код ошибки
        // if (error.status === 400) {
        //   // имя задачи содержит спецсимволы
        //   setError(
        //     'task-name',
        //     { message: t('forbidden_symbols_error_message') },
        //     { shouldFocus: true },
        //   );
        //   return;
        // }
        dispatch(createTaskActions.setErrorFromRequest(error));
      }
    }

    if (configType === 'edit_task') {
      if (arrErrors.length === 0 && apply !== true) {
        try {
          await apiCreateSchema(schema, '');
          history.goBack();
          // console.log('res', res);
        } catch (error) {
          dispatch(createTaskActions.setErrorFromRequest(error));
        }
      }
    }
  };

  const handleChangeTaskName = (e) => {
    dispatch(createTaskActions.setClearErrorRequest());
    const {
      validationMessage,
      validity: { valid },
      value,
      name,
    } = e.target;
    setValue(name, value.replace(/^\s+/, ''));
    if (valid) {
      if (FORBIDDEN_SYMBOLS_REGEX.test(value)) {
        setIsValid({ status: false, error: t('forbidden_symbols_error_message') });
      } else if (!FILE_NAME_REGEX.test(value)) {
        setIsValid({ status: false, error: t('one_character_error_message') });
      } else {
        setIsValid({ status: true, error: '' });
      }
    } else {
      setIsValid({ status: false, error: validationMessage });
    }
  };

  const clearRequestError = () => {
    dispatch(createTaskActions.setClearErrorRequest());
  };
  return (
    <div className="home">
      {errorRequest?.message && (
        <ErrorAlert
          message={errorRequest?.message}
          status={errorRequest?.status}
          onClose={clearRequestError}
        />
      )}
      {arrErrors.length !== 0 && arrayOutputs.length > 0 && <ErrorsAlert arrMessages={arrErrors} />}
      <ViewSourceLink uri={'http://localhost:3001/api/configurator'} />
      <header className="create-task-header">
        <div className="header-box-title">{configType ? t(configType) : ''}</div>
        <form className="create-task-name">
          <div className="create-task-name-block">
            <label className="create-task-name__title">{t('task_name')}</label>
            <input
              className={`create-task-name__input ${
                arrErrors.some((item) => item.name === 'task-name')
                  ? 'create-task-name__input--error'
                  : ''
              } ${!isValid.status ? 'create-task-name__input--error' : ''}`}
              defaultValue={configType === 'create_task' ? 'default' : schema}
              {...register('task-name')}
              placeholder={t('enter_task_name')}
              type="text"
              disabled={configType === 'edit_task'}
              required
              onChange={handleChangeTaskName}
            />
            <TextTooltip
              isOpen={isValid.error.length > 0}
              text={isValid.error}
              classNamePosition={'text-tooltip'}
            />
            <div className="create-task-name-btns">
              <button
                type="submit"
                className={
                  errorCreate.length !== 0
                    ? 'button button-disabled btn-w170px button-normalText'
                    : 'button btn-w170px button-normalText'
                }
                disabled={arrayOutputs.length === 0 ? true : !isValid.status}
                onClick={handleSubmit(onSubmitNewTask)}
              >
                {t('save_exit')}
              </button>
            </div>
          </div>

          <div className="create-task-error" hidden={errorCreate.length !== 0 ? false : true}>
            {t('please_set_the_required_parameters')}
          </div>
        </form>
      </header>

      <div className="create-task">
        <div className="create-task-data">
          <div className="create-task-data-source">
            <div className="create-task-data__item">
              <div className="create-task-data__item-buttons">
                <p className="create-task-data__item-title">{t('source')}</p>
                <button
                  type="button"
                  disabled={isCompleteSource || !draft}
                  className="create-task-data__item-buttonAdd-plus"
                  onClick={() => {
                    onAddInputSection(inputsNumberBlock + 1);
                  }}
                ></button>
              </div>
            </div>
            {arrayBlocksSources}
          </div>
          <div className="create-task-data-transform">
            {arraySources.length === 0 || inputsNumber.length === 0 ? (
              <div className="create-task-data__item">
                <div className="create-task-data__item-buttons">
                  <p className="create-task-data__item-title">{t('transform')}</p>
                </div>
              </div>
            ) : (
              <>
                <div className="create-task-data__item">
                  <div className="create-task-data__item-buttons">
                    <p className="create-task-data__item-title">{t('transform')}</p>
                  </div>
                </div>
                {arraySources?.some((source) => source?.programs) && <TransformBlock />}
              </>
            )}
          </div>
          <div className="create-task-data-output">
            <div className="create-task-data__item">
              <div className="create-task-data__item-buttons">
                <p className="create-task-data__item-title">{t('output')}</p>
              </div>
            </div>
            {arrayOutputs.length === 0
              ? null
              : arrayOutputs.map((elem, i) => (
                  <OutputBlock
                    key={`OutputBlock${elem.id}`}
                    number={i + 100}
                    titleName={`${elem.name}-${i + 1}`}
                    sectionData={elem}
                  />
                ))}
          </div>
          {/* SETTINGS */}
          <div className="create-task-settingForms">
            <FormProvider {...methods}>
              <div className="input">
                <div className="input-sections">{arrayInputSection}</div>
                {arrayProgramsSourceItems}
              </div>
              {openTransformSection && <TransformSection arrSources={arraySources} />}
              <div className="output">{arrayOutputItems}</div>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
});

export default TaskConfigurator;
