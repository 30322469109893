import React from 'react';
import './CPUCoreTable.scss';
import { addPercentSignToValue } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';

const CPUCoreTable = ({ cpuDetails }) => {
  let sizeClassName;

  if (cpuDetails) {
    if (Math.floor(cpuDetails.length / 16) >= 8) {
      sizeClassName = '_size_l';
    }
    if (Math.floor(cpuDetails.length / 16) >= 12) {
      sizeClassName = '_size_xl';
    }
  }

  return (
    <>
      {cpuDetails && (
        <div
          className={`core-table-wrapper ${
            sizeClassName ? 'core-table-wrapper' + sizeClassName : ''
          }`}
        >
          {cpuDetails?.map((item, index) => (
            <div
              key={`${item}_${index}`}
              className={`core-table__block  ${
                sizeClassName ? 'core-table__block' + sizeClassName : ''
              } ${
                item > 35 && item < 70
                  ? 'core-table__block_orange'
                  : item >= 70
                  ? 'core-table__block_red'
                  : 'core-table__block_green'
              }`}
            >
              {addPercentSignToValue((+item)?.toFixed(2))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CPUCoreTable;
//
