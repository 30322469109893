import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import '@widgets/TaskConfigurator/SourceItems/SourceBlock/SourceBlock.scss';
import { createTaskActions } from '@store/CreateTask';
import sprite from '@shared/Images/sprite.svg';
import Cookies from 'js-cookie';

const TransformBlock = () => {
  const dispatch = useDispatch();
  const [transformProgs, setTransformProgs] = useState([]);
  const [inputsNumber, setInputsNumber] = useState([]);
  const arraySources = useSelector((state) => state.createTask.arraySources);
  const activeBlockTab = useSelector((state) => state.createTask.activeSourceBlock);
  const transformPrograms = useSelector((state) => state.createTask.arrayOutputs);
  // console.log('transformPrograms', transformPrograms);

  useEffect(() => {
    let modifier = Cookies.get('CW_CONFIGURATOR');

    const newPins =
      transformPrograms.length > 0
        ? transformPrograms
            ?.map((item) => item?.filters)
            ?.flat()
            ?.map((filter) => {
              let muxerPins =
                filter.is_special === 'muxer' && modifier === 'special'
                  ? filter?.extensions?.extensions
                      ?.find((elem) => elem.name === 'MuxPrograms')
                      ?.extensions?.reduce((allPins, currentPins) => {
                        return currentPins?.pins
                          ? [...allPins, ...currentPins?.pins]
                          : [...allPins];
                      }, [])
                  : [];

              return filter?.pins?.length ? filter.pins : muxerPins?.length ? muxerPins : [];
            })
            .flat()
        : [];
    setTransformProgs(newPins);
  }, [transformPrograms]);

  useEffect(() => {
    const inputsNumber = arraySources
      ?.filter((elem) => !elem?.isReserve)
      ?.map((elem) => elem?.programs?.map((program) => program?.pins))
      .flat(2);
    setInputsNumber(inputsNumber?.filter((pin) => pin?.is_used));
    // console.log('inputsNumber', inputsNumber);
  }, [arraySources]);

  return (
    <div
      className={classNames(
        'transform-block mb10',
        activeBlockTab === null ? 'source-block--active' : '',
      )}
      onClick={() => {
        dispatch(createTaskActions.setActiveSourceBlock(null));
      }}
    >
      {inputsNumber.length !== 0 ? (
        <div className="output-block-streams">{inputsNumber.length}</div>
      ) : null}
      <div className="transform-block-title">From source</div>
      <svg className="transform-block-img">
        <use
          className="create-task-data__item-buttonAdd-pluser"
          href={sprite + '#right-brokenarrow'}
        ></use>
      </svg>
      {transformProgs.length !== 0 ? (
        <>
          <div className="transform-block-title">To outputs</div>
          <div className="source-block-outputService">{transformProgs.length}</div>
        </>
      ) : (
        <div className="transform-block-title">To outputs</div>
      )}
    </div>
  );
};

export default TransformBlock;
