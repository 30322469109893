export const gpuInfoActionTypes = {
  SET_NEW_ARRAY: 'GPU_INFO.SET_NEW_ARRAY',
  SET_CHECKED_ID: 'GPU_INFO.SET_CHECKED_ID',
};

export const gpuInfoActions = {
  setNewArray: (payload) => ({
    type: gpuInfoActionTypes.SET_NEW_ARRAY,
    payload,
  }),
  setCheckedId: (payload) => ({
    type: gpuInfoActionTypes.SET_CHECKED_ID,
    payload,
  }),
};
