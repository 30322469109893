import $api from '@services/apiAxios';

export const apiCreateExtension = async (id, guid) => {
  try {
    const res = await $api.post(`/configurator/extensible_graph_element?id=${id}&guid=${guid}`);
    // console.log(res);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiDeleteExtension = async (id) => {
  try {
    const res = await $api.delete(`/configurator/extensible_graph_element?id=${id}`);
    // console.log(res);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiGetAvailableParams = async (id) => {
  try {
    const res = await $api.get(`/configurator/extensible_graph_element?id=${id}`);
    // console.log(res);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiSendMethod = async (id, name) => {
  try {
    const res = await $api.post(
      `/configurator/extensible_graph_element/method?id=${id}&name=${name}`,
    );
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiChangeMuxProgram = async (multiplexer_id, pin_id, new_program_id) => {
  try {
    const res = await $api.post(
      `/configurator/special/muxer/move-pin?id=${multiplexer_id}&pin_id=${pin_id}&extention_id=${new_program_id}`,
    );
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiGetSimulCryptData = async (id) => {
  try {
    const res = await $api.get(`/configurator/special/muxer/simulcrypt?element_id=${id}`);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};

export const apiSetSimulCryptData = async (id, data) => {
  try {
    const res = await $api.post(`/configurator/special/muxer/simulcrypt?element_id=${id}`, data);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.message);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};
