import reactDom from 'react-dom';
import { useEffect, useState } from 'react';
import { hideScroll, showScroll } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';

const ModalPortal = ({ children, showModal }) => {
  let modal = document.getElementById('modal');
  const [isShowModal, setIsShowModal] = useState(false);
  useEffect(() => {
    showModal && hideScroll();
    setIsShowModal(true);

    return () => {
      showScroll();
    };
  }, [showModal]);

  return (
    isShowModal &&
    reactDom.createPortal(children, modal)
  )
};

export default ModalPortal;
