import axios from 'axios';

require('dotenv').config();

const headers = {
  'Content-Type': 'application/json',
};
const $apiHome = axios.create({
  // withCredentials: true,
  baseURL: '/',
  headers: headers,
});

$apiHome.interceptors.request.use((config) => {
  // console.log('config', config);
  return config;
});

$apiHome.interceptors.response.use(
  (config) => {
    // console.log('configApi', config);
    if (config.data.error?.code === 401) {
      localStorage.removeItem('authSettings');
      window.history.go('/login');
    } else {
      return config;
    }
  },
  (error) => {
    // console.dir(error);
    if (error?.response?.status === 401) {
      localStorage.removeItem('authSettings');
      window.history.go('/login');
    }
    // window.history.go('/error');
    return error;
  },
);

export default $apiHome;
