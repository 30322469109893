import { useMemo, useState } from "react";

const useServerTasksData = (data) => {
  // Сортировка данных по имени
  const [sortConfig, setSortConfig] = useState(null);
  const [openedInfoBlocks, setOpenedInfoBlocks] = useState([]);

  const toggleParams = (blocksWithHiddenInfo) => {
    if (openedInfoBlocks.length === blocksWithHiddenInfo.length) {
      setOpenedInfoBlocks([]);
    } else {
      const allOpenedBlocks = blocksWithHiddenInfo.reduce((acc, current) => {
        return [...acc, current.graph.name];
      }, []);
      setOpenedInfoBlocks(allOpenedBlocks);
    }
  };

  const toggleParamInfo = (name) => {
    if (openedInfoBlocks.includes(name)) {
      return setOpenedInfoBlocks(openedInfoBlocks.filter(item => item !== name));
    }
    setOpenedInfoBlocks([...openedInfoBlocks, name]);
  };

  useMemo(() => {
    if (sortConfig !== null) {
      data.sort((a, b) => {
        if (a[sortConfig.key] !== undefined && b[sortConfig.key] !== undefined) {
          if (typeof (a[sortConfig.key][sortConfig.key2] && b[sortConfig.key][sortConfig.key2]) === "number") {
            if (a[sortConfig.key][sortConfig.key2] < b[sortConfig.key][sortConfig.key2]) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key][sortConfig.key2] > b[sortConfig.key][sortConfig.key2]) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          } else {
            if (
              a[sortConfig.key][sortConfig.key2].toString().toLowerCase() <
              b[sortConfig.key][sortConfig.key2].toString().toLowerCase()
            ) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (
              a[sortConfig.key][sortConfig.key2].toString().toLowerCase() >
              b[sortConfig.key][sortConfig.key2].toString().toLowerCase()
            ) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          }
        } else {
          return 1;
        }
      });
    }
    return data;
  }, [data, sortConfig]);

  const requestSort = (key, key2) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key2 === key2 && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, key2, direction });
  };

  return {
    requestSort,
    toggleParamInfo,
    toggleParams,
    openedInfoBlocks,
  };
};

export default useServerTasksData;
