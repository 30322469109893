import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputItem from '@shared/UI/InputItem/InputItem';
import { inputCheckToNumber } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';

const Rotation = ({ activeSettingsNavItem, dataState }) => {
  const { register, watch, setValue } = useFormContext();
  const { t } = useTranslation();
  const config = dataState?.rotation_config;
  // console.log('config', config);

  const logStoreCheck = watch('rotationCheckboxes.logStoreCheck');
  const logDeleteCheck = watch('rotationCheckboxes.logDeleteCheck');
  const crashStoreCheck = watch('rotationCheckboxes.crashStoreCheck');
  const crashDeleteCheck = watch('rotationCheckboxes.crashDeleteCheck');
  const redundantStoreCheck = watch('rotationCheckboxes.redundantStoreCheck');
  const redundantDeleteCheck = watch('rotationCheckboxes.redundantDeleteCheck');

  React.useEffect(() => {
    setValue('rotationCheckboxes.logStoreCheck', config?.DeleteLogs.days.enabled);
    setValue('rotationCheckboxes.logDeleteCheck', config?.DeleteLogs.size.enabled);
    setValue('rotationCheckboxes.crashStoreCheck', config?.DeleteDumps.days.enabled);
    setValue('rotationCheckboxes.crashDeleteCheck', config?.DeleteDumps.size.enabled);
    setValue('rotationCheckboxes.redundantStoreCheck', config?.DeleteBackups.days.enabled);
    setValue('rotationCheckboxes.redundantDeleteCheck', config?.DeleteBackups.size.enabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames(
        'settings-form-redundancy',
        activeSettingsNavItem === 3 ? 'setting-show' : '',
      )}
      data-testid={'rotation'}
    >
      <h2 className="settings-form-title">{t('rotation')}</h2>
      <div className="settings-form-subtitle">{t('log_files')}:</div>
      <div className="settings-form-redundancy-criteria">
        <div className="settings-form-redundancy-criteria__item" data-testid={'rotation__item'}>
          <div className="redundancy-labelBox">
            <input
              defaultChecked={config?.DeleteLogs.days.enabled}
              className="input-check"
              {...register('rotationCheckboxes.logStoreCheck')}
              id="storeFiles"
              type="checkbox"
              data-testid={'input-checkbox'}
            />
            <label className="redundancy-label" htmlFor="storeFiles">
              {t('store_files_for')}
            </label>
          </div>
          <div className="redundancy-hiddenBlock">
            <InputItem
              type="number"
              name={'rotation_config.DeleteLogs.days'}
              classes={'input-center input-center-72 num'}
              value={config?.DeleteLogs.days.value ? config?.DeleteLogs.days.value : 30}
              disabled={!logStoreCheck}
              minValue={1}
              maxValue={365}
              onKeyPress={inputCheckToNumber}
            />
            <label className={classNames('text-forinput', logStoreCheck ? '' : 'disabled')}>
              {t('days')}
            </label>
          </div>
        </div>
        <div className="settings-form-redundancy-criteria__item" data-testid={'rotation__item'}>
          <div className="redundancy-labelBox">
            <input
              className="input-check"
              defaultChecked={config?.DeleteLogs.size.enabled}
              {...register('rotationCheckboxes.logDeleteCheck')}
              id="deleteFiles"
              type="checkbox"
              data-testid={'input-checkbox'}
            />
            <label className="redundancy-label" htmlFor="deleteFiles">
              {t('delete_files_larger_than')}
            </label>
          </div>
          <div className="redundancy-hiddenBlock">
            <InputItem
              type="number"
              name={'rotation_config.DeleteLogs.size'}
              classes={'input-center input-center-72 num'}
              value={config?.DeleteLogs.size.value ? config?.DeleteLogs.size.value : 2000}
              disabled={!logDeleteCheck}
              minValue={2000}
              maxValue={500000}
              onKeyPress={inputCheckToNumber}
            />

            <label className={classNames('text-forinput', logDeleteCheck ? '' : 'disabled')}>
              {t('MB')}
            </label>
          </div>
        </div>
      </div>
      <div className="settings-form-subtitle hl">{t('crash_dumps')}</div>
      <div className="settings-form-redundancy-criteria">
        <div className="settings-form-redundancy-criteria__item">
          <div className="redundancy-labelBox">
            <input
              defaultChecked={config?.DeleteDumps.days.enabled}
              className="input-check"
              {...register('rotationCheckboxes.crashStoreCheck')}
              id="storeFilesCrash"
              type="checkbox"
            />
            <label className="redundancy-label" htmlFor="storeFilesCrash">
              {t('store_files_for')}
            </label>
          </div>
          <div className="redundancy-hiddenBlock">
            <InputItem
              type="number"
              name={'rotation_config.DeleteDumps.days'}
              classes={'input-center input-center-72 num'}
              value={config?.DeleteDumps.days.value ? config?.DeleteDumps.days.value : 30}
              disabled={!crashStoreCheck}
              minValue={1}
              maxValue={365}
              onKeyPress={inputCheckToNumber}
            />
            <label className={classNames('text-forinput', crashStoreCheck ? '' : 'disabled')}>
              {t('days')}
            </label>
          </div>
        </div>
        <div className="settings-form-redundancy-criteria__item">
          <div className="redundancy-labelBox">
            <input
              defaultChecked={config?.DeleteDumps.size.enabled}
              className="input-check"
              {...register('rotationCheckboxes.crashDeleteCheck')}
              id="deleteFilesCrash"
              type="checkbox"
            />
            <label className="redundancy-label" htmlFor="deleteFilesCrash">
              {t('delete_files_larger_than')}
            </label>
          </div>
          <div className="redundancy-hiddenBlock">
            <InputItem
              type="number"
              name={'rotation_config.DeleteDumps.size'}
              classes={'input-center input-center-72 num'}
              value={config?.DeleteDumps.size.value ? config?.DeleteDumps.size.value : 2000}
              disabled={!crashDeleteCheck}
              minValue={2000}
              maxValue={500000}
              onKeyPress={inputCheckToNumber}
            />
            <label className={classNames('text-forinput', crashDeleteCheck ? '' : 'disabled')}>
              {t('MB')}
            </label>
          </div>
        </div>
        <div className="settings-form__item">
          <label className="settings-form__item-label">{t('system_dump_folder')}</label>
          <input
            className="settings-form__input"
            defaultValue={config.outside_dumps_dir}
            {...register('rotation_config.outside_dumps_dir')}
            id="CrashDirectory"
            type="text"
          />
        </div>
      </div>
      <div className="settings-form-subtitle hl">{t('redundant_copies_of_schemas')}</div>
      <div className="settings-form-redundancy-criteria">
        <div className="settings-form-redundancy-criteria__item">
          <div className="redundancy-labelBox">
            <input
              className="input-check"
              defaultChecked={config?.DeleteBackups.days.enabled}
              {...register('rotationCheckboxes.redundantStoreCheck')}
              id="storeFilesCopy"
              type="checkbox"
            />
            <label className="redundancy-label" htmlFor="storeFilesCopy">
              {t('store_files_for')}
            </label>
          </div>
          <div className="redundancy-hiddenBlock">
            <InputItem
              type="number"
              name={'rotation_config.DeleteBackups.days'}
              classes={'input-center input-center-72 num'}
              value={config?.DeleteBackups.days.value ? config?.DeleteBackups.days.value : 30}
              disabled={!redundantStoreCheck}
              minValue={1}
              maxValue={365}
              onKeyPress={inputCheckToNumber}
            />
            <label className={classNames('text-forinput', redundantStoreCheck ? '' : 'disabled')}>
              {t('days')}
            </label>
          </div>
        </div>
        <div className="settings-form-redundancy-criteria__item">
          <div className="redundancy-labelBox">
            <input
              className="input-check"
              defaultChecked={config?.DeleteBackups.size.enabled}
              {...register('rotationCheckboxes.redundantDeleteCheck')}
              id="deleteFilesCopy"
              type="checkbox"
            />
            <label className="redundancy-label" htmlFor="deleteFilesCopy">
              {t('delete_files_larger_than')}
            </label>
          </div>
          <div className="redundancy-hiddenBlock">
            <InputItem
              type="number"
              name={'rotation_config.DeleteBackups.size'}
              classes={'input-center input-center-72 num'}
              value={config?.DeleteBackups.size.value ? config?.DeleteBackups.size.value : 2000}
              disabled={!redundantDeleteCheck}
              minValue={2000}
              maxValue={500000}
              onKeyPress={inputCheckToNumber}
            />
            <label className={classNames('text-forinput', redundantDeleteCheck ? '' : 'disabled')}>
              {t('MB')}
            </label>
          </div>
        </div>
      </div>
      <div className="settings-form-subtitle hl">{t('After_the_dispatcher_start')}</div>
      <div className="settings-form-redundancy-criteria">
        <div className="settings-form-redundancy-criteria__item">
          <div className="redundancy-labelBox">
            <label className="addServer-item__label">{t('by_file_storing_period_each')}</label>
          </div>
          <div className="redundancy-hiddenBlock">
            <InputItem
              type="number"
              name={'rotation_config.check_time_period'}
              classes={'input-center input-center-72 num'}
              value={config?.check_time_period || 0}
              disabled={redundantStoreCheck || crashStoreCheck || logStoreCheck ? false : true}
              maxValue={9999}
              minValue={0}
              onKeyPress={inputCheckToNumber}
            />
            <label
              className={classNames(
                'text-forinput',
                redundantStoreCheck || crashStoreCheck || logStoreCheck ? '' : 'disabled',
              )}
            >
              {t('minutes')}
            </label>
          </div>
        </div>
        <div className="settings-form-redundancy-criteria__item">
          <div className="redundancy-labelBox">
            <label className="addServer-item__label">{t('by_file_size_each')}</label>
          </div>
          <div className="redundancy-hiddenBlock">
            <InputItem
              type="number"
              name={'rotation_config.check_time_size'}
              classes={'input-center input-center-72 num'}
              value={config?.check_time_size || 0}
              disabled={redundantDeleteCheck || crashDeleteCheck || logDeleteCheck ? false : true}
              maxValue={9999}
              minValue={0}
              onKeyPress={inputCheckToNumber}
            />
            <label
              className={classNames(
                'text-forinput',
                redundantDeleteCheck || crashDeleteCheck || logDeleteCheck ? '' : 'disabled',
              )}
            >
              {t('minutes')}
            </label>
          </div>
        </div>
        <div className="settings-form__redundancy-text">
          {t('files_exceeding_thresholds_will_be_deleted')}
        </div>
      </div>
    </div>
  );
};

export default Rotation;
