import classNames from 'classnames';
import React from 'react';
import '../Announces.scss';
import { apiGetAnnounceNDIList } from '@services/CreateTaskService';
import { useTranslation } from 'react-i18next';
import { showScroll } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import { useDispatch, useSelector } from 'react-redux';
import { createTaskActions } from '@store/CreateTask';
import ButtonBase from "@shared/UI/Buttons/ButtonBase/ButtonBase";
import { ReactComponent as SvgTransformIcon } from "@images/transform.svg";

const AnnouncesNDI = ({
  setShowAnnounceNDI,
  renderSourceFilters,
  setRenderSourceFilters,
  sectionDataId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeSourceBlock = useSelector((state) => state.createTask.activeSourceBlock);
  const [activeAnnounce, setActiveAnnounce] = React.useState(null);
  const [updateData, setUpdateData] = React.useState(null);
  const [renderList, setRenderList] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);

  const getAnnounceData = () => {
    setShowLoader(true);
    apiGetAnnounceNDIList().then((res) => {
      setShowLoader(false);
      // console.log('res', res);
      const announceList = res.data['ndi-announces'];
      setRenderList(announceList ? announceList : []);
      // console.log('announceList', announceList);
    });
  };

  React.useEffect(() => {
    getAnnounceData();
  }, []);

  // console.log('renderList', renderList);
  const selectName = (e) => {
    const idx = e.target.closest('.TRow').dataset.address;
    setActiveAnnounce(+idx);
    const data = renderList.find((elem, i) => i === +idx);
    /*    // console.log('data', data);
    const updateData = [
      { ...renderParams[0] },
      { ...renderParams[1], value: `${data.ServerName} (${data.SendName})` },
    ];
    // console.log('updateData', updateData);
    setUpdateData(updateData); */

    const clearData = renderSourceFilters?.map((filter) => {
      if (filter.name === 'NWSource' || filter.name === 'NDI Source') {
        return {
          ...filter,
          parameters: filter?.parameters?.map((parameter, index) => {
            let newValue = index === 1 ? '' : parameter?.value;

            return { ...parameter, value: newValue };
          }),
        };
      } else {
        return filter;
      }
    });

    clearData && setRenderSourceFilters(clearData);
    dispatch(createTaskActions.setUpdateSourceBlock(sectionDataId, activeSourceBlock, clearData));

    const newUpdateData = renderSourceFilters?.map((filter) => {
      if (filter.name === 'NWSource' || filter.name === 'NDI Source') {
        return {
          ...filter,
          parameters: filter?.parameters?.map((parameter, index) => {
            let newValue = index === 1 ? `${data.ServerName} (${data.SendName})` : parameter?.value;

            return { ...parameter, value: newValue };
          }),
        };
      } else {
        return filter;
      }
    });
    setUpdateData(newUpdateData);
  };

  const onSubmit = () => {
    updateData && setRenderSourceFilters(updateData);
    dispatch(createTaskActions.setUpdateSourceBlock(sectionDataId, activeSourceBlock, updateData));
    showScroll();
    setShowAnnounceNDI(false);
  };

  const handleUpdate = () => {
    getAnnounceData();
  };

  return (
    <div className="overlay">
      <div className="container-announce">
        <div className="container-announce-title">
          {t('announces_list')}
          <div
            className="container-announce-close"
            onClick={() => {
              setShowAnnounceNDI(false);
              showScroll();
            }}
          >
            ×
          </div>
        </div>
        <div className="container-announce-table">
          <table className="table">
            <thead className="thead">
            <tr>
              <th className="ta-l-15-p">{t('server_name')}</th>
              <th className="ta-l-15-p">{t('send_name')}</th>
              <th className="ta-r">{t('server_address')}</th>
              <th className="ta-r">{t('port')}</th>
            </tr>
            </thead>
            {renderList.length === 0 ? (
              <tbody className="tbody">
              <tr className={classNames(`TRow cur`)}>
                <td></td>
                <td>
                  <div className="clusters-params clusters-params__text">
                    {showLoader ? 'Loading...' : 'no found announces'}
                  </div>
                </td>
                <td></td>
                <td></td>
              </tr>
              </tbody>
            ) : (
              <tbody className="tbody">
              {renderList.map((elem, i) => (
                <tr
                  className={classNames(
                    `TRow cur`,
                    activeAnnounce === i ? 'announce-active' : '',
                  )}
                  key={elem.ServerAddress + 'a' + i}
                  data-address={i}
                  onClick={(e) => selectName(e)}
                  onDoubleClick={(e) => {
                    selectName(e);
                    onSubmit();
                  }}
                >
                  <td>
                    <div className="clusters-params clusters-params__text">{elem.ServerName}</div>
                  </td>
                  <td>
                    <div className="clusters-params clusters-params__text">{elem.SendName}</div>
                  </td>
                  <td>
                    <div className="clusters-params clusters-params--right">
                      {elem.ServerAddress}
                    </div>
                  </td>
                  <td>
                    <div className="clusters-params clusters-params--right">
                      {elem.ServerName}
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            )}
          </table>
        </div>
        <div className="announces__actions">
          <ButtonBase
            color={'grey'}
            onClick={handleUpdate}
          >
            <SvgTransformIcon className="announces__update-icon" />
            <p className="announces__update-text">{t('refresh')}</p>
          </ButtonBase>
          <div className="announces__buttons-group">
            <ButtonBase
              color={'green'}
              onClick={onSubmit}
            >
              <span className='announces__button-text'>{t('ok')}</span>
            </ButtonBase>
            <ButtonBase
              color={'grey'}
              onClick={() => {
                setShowAnnounceNDI(false);
                showScroll();
              }}
            >
              <span className='announces__button-text'>{t('cancel')}</span>
            </ButtonBase>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncesNDI;
