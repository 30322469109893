import React, { useRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import s from './InputStretchable.module.scss';
import classNames from 'classnames';
import { useState } from 'react';

const InputStretchable = ({ param, className, handleChangeParamApi }) => {
  const { register, watch, resetField } = useFormContext();
  const [paramValue, setParamValue] = useState(param.value);

  const elementHeight = 28;

  const handleChange = (e) => {
    handleChangeParamApi && handleChangeParamApi(e);
    setParamValue(e.target.value);
  };

  const textareaRef = useRef(null);
  const { ref, ...rest } = register(`${param.id}`, {
    required: {
      value: param.required,
      message: `${param.name} field is required`,
    },
    onChange: handleChange,
  });

  useEffect(() => {
    textareaRef?.current && elementAutoresize(textareaRef?.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(param.id.toString())]);

  useEffect(() => {
    const resize = () => {
      textareaRef?.current && elementAutoresize(textareaRef?.current);
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
      resetField(param.id.toString());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const elementAutoresize = (element) => {
    element.style.height = elementHeight + 'px';
    element.style.resize = 'none';

    if (element.scrollHeight > elementHeight) {
      element.style.height = element.scrollHeight + 5 + 'px';
      element.style['max-height'] = element.scrollHeight + 5 + 'px';
      element.style.resize = 'vertical';
    }
  };

  return (
    <textarea
      {...rest}
      ref={(e) => {
        ref(e);
        textareaRef.current = e;
      }}
      value={paramValue}
      className={classNames(
        s['input-stretchable'],
        s['input-stretchable__textarea'],
        className ? className : '',
      )}
    />
  );
};

export default InputStretchable;
