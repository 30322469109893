import React from 'react';
import s from './SuccessRow.module.scss';
import {ReactComponent as CloseBtn} from '@images/close-lite.svg';

const SuccessRow = ({ message, onClose }) => (
  <div className={s['success-row']}>
    <CloseBtn className={s['success-row__close-btn']} onClick={onClose} />
    <p>{message}</p>
  </div>
);

export default SuccessRow;
