import React, { useEffect } from 'react';
import Loader from '@shared/UI/Loaders/Loader/Loader';
import { ReactComponent as SvgCloseButton } from '@images/close-lite.svg';
import s from './Modal.module.scss';
import ModalHeader from '../ModalHeader/ModalHeader';

const Modal = ({ children, className, showLoader, onClose, title }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    const handleEscClose = (e) => {
      if (e.key === 'Escape') onClose();
    };
    document.addEventListener('keydown', handleEscClose);

    return () => {
      document.removeEventListener('keydown', handleEscClose);
    };
  }, [onClose]);

  return (
    <div className={s['modal']} onClick={handleOverlayClick}>
      {showLoader ? (
        <Loader className={s['modal__loader']} />
      ) : (
        <div className={`${s['modal__container']} ${className ? className : ''}`}>
          <ModalHeader
            title={title}
            buttonComponent={<SvgCloseButton className={s['modal__close']} onClick={onClose} />}
          />
          <div className={s['modal__body']}>{children}</div>
        </div>
      )}
    </div>
  );
};

export default Modal;
