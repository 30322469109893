import React from 'react';
import ViewSourceLink from '@shared/UI/ViewSourceLink/ViewSourceLink';
import { createTaskActions } from '@store/CreateTask';
import { useDispatch, useSelector } from 'react-redux';
import arrowDownBlack from '@shared/Images/arrow-down-black.svg';
import classNames from 'classnames';
import sprite from '@shared/Images/sprite.svg';
import { apiRefreshAllProgs, apiTurnConnectorPins } from '@services/CreateTaskService';
import { CSSTransition } from 'react-transition-group';
import Error from '@shared/UI/Errors/Error/Error';
import { useRef } from 'react';
import './SourceItems.scss';
import { updatePins } from '@shared/Lib/Utils/CommonFuncs/CommonFuncs';
import ModalPortal from '@shared/UI/ModalPortal/ModalPortal';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import useHandleDebounce from '@shared/Lib/Utils/HandleDebounce/HandleDebounce';
import MiniLoader from '@shared/UI/Loaders/MiniLoader/MiniLoader';
import MiniInfo from '@shared/UI/Warnings/MiniInfo/MiniInfo';
import { refreshPins } from '@shared/Lib/Utils/TaskConfigurator/apiGeneralTaskConfigurator';

const SourceItems = ({
  number,
  sectionId,
  sectionData,
  arrPrograms,
  showLoaderSourceItems,
  setShowLoaderSourceItems,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [renderPrograms, setRenderPrograms] = React.useState();
  const [checkedPrograms, setCheckedPrograms] = React.useState([]);
  const [showManyError, setShowManyError] = React.useState(false);
  const [manyMessageError, setManyMessageError] = React.useState([]);
  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState('');

  const [showMiniLoader, setShowMiniLoader] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);

  const activeBlockTab = useSelector((state) => state.createTask.activeSourceBlock);
  const activeInputTab = useSelector((state) => state.createTask.activeInputTab);

  useEffect(() => {
    if (checkedPrograms?.length && !showMiniLoader) {
      sendPins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBlockTab]);

  // console.log('checkedPrograms', checkedPrograms);

  useEffect(() => {
    setRenderPrograms(arrPrograms);
  }, [arrPrograms]);

  const handleRefresh = () => {
    if (!showMiniLoader) {
      setShowMiniLoader(true);
      setShowLoaderSourceItems(true);
      setRenderPrograms([]);
      setCheckedPrograms([]);
      apiRefreshAllProgs()
        .then(() => {
          updatePins(dispatch, sectionData, setShowLoaderSourceItems, setRenderPrograms).then(
            (res) => {
              if (res) {
                refreshPins(res, dispatch, number)
                  .then(() => {
                    setShowSuccessModal(true);
                  })
                  .finally(() => {
                    setShowLoaderSourceItems(false);
                    setShowMiniLoader(false);
                  });
              }
            },
          );
        })
        .catch((error) => {
          setMessageError(error.message);
          setShowError(true);
        })
        .finally(() => {
          if (showLoaderSourceItems) {
            setShowLoaderSourceItems(false);
          }

          if (showMiniLoader) {
            setShowMiniLoader(false);
          }
        });
    }
  };
  // const handleDebounceRefresh = useHandleDebounce({ callback: handleRefresh, delay: 500 });

  const arrErrors = useRef([]);
  // console.log('arrErrors', arrErrors);

  const setToggleProg = (e) => {
    const programNum = +e.target.dataset.program;
    // console.log('programNum', programNum);
    const updateRenderProgs = renderPrograms?.map((prog) => {
      return {
        ...prog,
        checked: prog?.program === programNum ? !prog.checked : prog.checked,
        pins: prog?.pins?.map((pin) => ({
          ...pin,
          is_used: prog?.program === programNum ? !prog.checked : pin.is_used,
        })),
      };
    });

    // console.log('updateRenderProgs', updateRenderProgs);

    setCheckedPrograms((prevCheckedProprams) => {
      let newUpdatedProgram = updateRenderProgs.find((program) => program?.program === programNum);
      let progIndex = prevCheckedProprams.findIndex((program) => program?.program === programNum);
      if (progIndex === -1) {
        return [...prevCheckedProprams, newUpdatedProgram];
      } else {
        return prevCheckedProprams.map((program, index) =>
          index === progIndex ? newUpdatedProgram : program,
        );
      }
    });

    dispatch(
      createTaskActions.setUpdateProgramsSourceItems(updateRenderProgs, programNum, sectionId),
    );
  };
  // const handleDebounceToggleProg = useHandleDebounce({ callback: setToggleProg, delay: 1000 });

  const setTogglePin = (idx, programNum) => {
    // console.log('idx', idx);
    // console.log('renderPrograms', renderPrograms);
    // console.log('program', program);
    const updateRenderProgs = renderPrograms?.map((prog) => {
      let updatedPins =
        prog.program === programNum
          ? prog?.pins?.map((pin) => ({
              ...pin,
              is_used: pin.index === idx ? !pin.is_used : pin.is_used,
            }))
          : prog.pins;

      return {
        ...prog,
        checked: updatedPins.some((pin) => pin.is_used),
        pins: updatedPins,
      };
    });
    // console.log('updateRenderProgs', updateRenderProgs);

    setCheckedPrograms((prevCheckedProprams) => {
      let newUpdatedProgram = updateRenderProgs.find((program) => program?.program === programNum);
      // console.log('newUpdatedProgram', newUpdatedProgram);

      let progIndex = prevCheckedProprams.findIndex((program) => program?.program === programNum);
      if (progIndex === -1) {
        return [...prevCheckedProprams, newUpdatedProgram];
      } else {
        return prevCheckedProprams.map((program, index) =>
          index === progIndex ? newUpdatedProgram : program,
        );
      }
    });

    dispatch(
      createTaskActions.setUpdateProgramsSourceItems(updateRenderProgs, programNum, sectionId),
    );
  };

  const handleApply = (e) => {
    e.preventDefault();
    if (checkedPrograms?.length) {
      sendPins();
    }
  };

  const sendPins = async () => {
    dispatch(createTaskActions.setErrorFromRequest());

    setShowMiniLoader(true);
    const turnConnectorPins = [];
    checkedPrograms?.forEach((program) => {
      program?.pins?.forEach(({ is_used, index }) => {
        turnConnectorPins.push({
          command: 'turn',
          section_id: sectionId,
          pin_index: index,
          choice: is_used ? 'on' : 'off',
        });
      });
    });
    if (turnConnectorPins.length) {
      try {
        // let newChechPrograms;
        await apiTurnConnectorPins(turnConnectorPins).catch((errors) => {
          if (Array.isArray(errors)) {
            const allErrors = errors?.reduce(
              (acc, current) => (current?.message ? acc + current?.message + '\n' : acc),
              '',
            );
            dispatch(createTaskActions.setErrorFromRequest({ message: allErrors }));
            console.dir(allErrors);
            //не надо выполнять uncheck pin, т к необходимо оставить возможность выбора подходящего кодека в трансформ секции
          } else {
            console.dir(errors);
          }
        });
        await refreshPins(checkedPrograms, dispatch, number);
        setShowSuccessModal(true);
      } catch (error) {
        console.dir(error);
      } finally {
        setShowMiniLoader(false);
        setCheckedPrograms([]);
      }
    }
  };

  const toggleCollapse = (target) => {
    target.classList.toggle('source-properties-program-collapse--rotate');
    const blockWithStreams = target.closest(
      '.source-properties__item-blockLabel',
    ).nextElementSibling;
    blockWithStreams.classList.toggle('source-properties-streams--hidden');
  };

  return (
    <>
      <form
        hidden={activeBlockTab === number ? (activeInputTab === `Services` ? false : true) : true}
        className="source"
      >
        <div className="source-properties">
          <ViewSourceLink
            uri={`${window.location.origin}/api/configurator/sections/transform/connector_list`}
          />
          <ModalPortal showModal={showManyError}>
            <CSSTransition in={showManyError} timeout={500} unmountOnExit classNames="alert">
              <Error
                many={true}
                manyMessageError={manyMessageError}
                setManyMessageError={setManyMessageError}
                setShowManyError={setShowManyError}
                typeModal={'error'}
                objName={'Pin'}
                arrErrors={arrErrors}
              />
            </CSSTransition>
          </ModalPortal>
          <ModalPortal showModal={showError}>
            <CSSTransition in={showError} timeout={500} unmountOnExit classNames="alert">
              <Error
                setShowError={setShowError}
                message={messageError}
                setMessageError={setMessageError}
              />
            </CSSTransition>
          </ModalPortal>
          {!showLoaderSourceItems && (
            <>
              <div className="source-title">
                <div className="source-properties-title">{t('source_items')}</div>
                <button
                  type="button"
                  className="source-title-update button button-grey"
                  onClick={handleRefresh}
                  // onClick={() => handleDebounceRefresh.handleDebounce()}
                >
                  <svg className="source-title-update__img">
                    <use href={sprite + '#transform'}></use>
                  </svg>
                  <p className="source-title-update__text">{t('refresh')}</p>
                </button>
              </div>

              <div className="source-properties-block">
                {renderPrograms &&
                  renderPrograms?.map((elem, i) => (
                    <div className="source-properties__item" key={elem.program + number}>
                      <div className="source-properties__item-blockLabel">
                        <input
                          className="source-properties-mainCheck"
                          onChange={(e) => setToggleProg(e)}
                          // onChange={(e) => handleDebounceToggleProg.handleDebounce(e)}
                          id={`${elem.program}*-check-${number}`}
                          type="checkbox"
                          data-program={elem.program}
                          checked={elem.checked}
                          disabled={showMiniLoader}
                        />
                        <label
                          className="source-properties-subtitle"
                          htmlFor={`${elem.program}*-check-${number}`}
                        >
                          {elem.program_name
                            ? `Prog#${elem.program} (${elem.program_name})`
                            : `Prog#${elem.program}`}
                        </label>
                        <img
                          className={classNames(
                            `source-properties-program-collapse`,
                            renderPrograms.length === 1
                              ? 'source-properties-program-collapse--rotate'
                              : '',
                          )}
                          id={elem.name}
                          src={arrowDownBlack}
                          alt="arrowdown"
                          onClick={(e) => {
                            const target = e.target;
                            toggleCollapse(target);
                          }}
                        />
                      </div>
                      <div
                        className={classNames(
                          `source-properties-streams`,
                          renderPrograms.length === 1 ? '' : 'source-properties-streams--hidden',
                        )}
                      >
                        {elem.pins.map((stream, index) => (
                          <div className="source-properties-stream" key={stream.name + index}>
                            <input
                              className="source-properties-check"
                              id={`${stream.name}-check-${number}`}
                              type="checkbox"
                              onChange={() => setTogglePin(stream.index, elem.program)}
                              checked={stream.is_used}
                              disabled={showMiniLoader}
                            />
                            <label
                              className="source-properties-stream__subtitle"
                              htmlFor={`${stream.name}-check-${number}`}
                            >
                              {stream.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="input-properties-button">
                <button
                  type="submit"
                  className="button input-properties-cancel btn-110px m0"
                  onClick={handleApply}
                >
                  {t('apply')}
                </button>
              </div>
            </>
          )}
        </div>
      </form>
      <CSSTransition in={showMiniLoader} timeout={500} unmountOnExit classNames="alert">
        <MiniLoader />
      </CSSTransition>
      <CSSTransition in={showSuccessModal} timeout={500} unmountOnExit classNames="alert">
        <MiniInfo setShowInform={setShowSuccessModal} message={'Schema was successfully made'} />
      </CSSTransition>
    </>
  );
};

export default SourceItems;
