import sprite from '@images/sprite.svg';

import classNames from 'classnames';
import React from 'react';
import './ButtonShowArchive.scss';
import { useTranslation } from 'react-i18next';

const ButtonShowArchive = ({ showArchive, setShowArchive }) => {
  const { t } = useTranslation();
  return (
    <div className="button-show-archive" onClick={() => setShowArchive(!showArchive)}>
      <svg
        className={classNames(
          'button-show-archive-img',
          showArchive ? 'button-show-archive-img--rotate' : '',
        )}
      >
        <use href={sprite + '#arrow-left'}></use>
      </svg>
      {!showArchive ? (
        <p className="button-show-archive-text">{t('show_archive')}</p>
      ) : (
        <p className="button-show-archive-text">{t('hide_archive')}</p>
      )}
    </div>
  );
};

export default ButtonShowArchive;
