import $api from '@services/apiAxios';

export const apiRestartServer = async () => {
  try {
    const res = await $api.put(`/server/state`);
    // console.log('res', res);
    if (res.isAxiosError) {
      console.dir(res);
      throw new Error(res.response.data);
    }
    return res;
  } catch (error) {
    console.dir(error);
    return Promise.reject(error);
  }
};
