import React, { memo, useState, useEffect } from 'react';
import TabItem from '../TabItem/TabItem';
import s from './TabsBase.module.scss';
import { useTranslation } from 'react-i18next';

// допустимые параметры tabNames - массив строк. Пример const tabNames = ['Settings', 'Services'];
// допустимые параметры tabPanelComponents - массив компонентов. Пример const tabPanelComponents = [<>Components 1</>, <>Components 2</>]
// onClick отдает выбранный index и tabName
const TabsBase = memo(
  ({ isShowTabNames = true, tabNames, tabPanelComponents, onClick, activeTab }) => {
    const [currentTab, setCurrentTab] = useState();
    const { t } = useTranslation();

    const clickHandler = (index, tabName) => {
      setCurrentTab(tabName);
      onClick && onClick(index, tabName);
    };

    useEffect(() => {
      setCurrentTab(activeTab ? activeTab : tabNames?.[0]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    return (
      <div data-testid="tabs-base">
        {isShowTabNames && (
          <ul className={s['tabs-base__lists']}>
            {tabNames?.map((tabName, index) => (
              <TabItem
                tabText={t(tabName)}
                active={currentTab?.toLowerCase() === tabName?.toLowerCase()}
                onClick={() => clickHandler(index, tabName)}
                key={'tab-item_' + index}
              />
            ))}
          </ul>
        )}

        {tabPanelComponents?.map((item, index) => {
          return (
            currentTab?.toLowerCase() === tabNames?.[index]?.toLowerCase() && (
              <div key={'tabs-base__panel-item_' + index} data-testid="tabs-base__panel-item">
                {item}
              </div>
            )
          );
        })}
      </div>
    );
  },
);

export default TabsBase;
