import React, { useEffect, useState } from 'react';
import { apiTaskRebuild } from '@services/TaskService';
import { useDispatch } from 'react-redux';
import { taskParamsActions } from '@store/TaskParams';

const useTaskInfoData = ({ storage_id, schema }) => {
  const dispatch = useDispatch();
  const host = window.location.host;
  const ws = React.useRef(null);
  const schemaName = React.useRef(schema);
  const [wsData, setWsData] = React.useState(null);
  // console.log('schema', schema);
  const [isSettingsDisabled, setIsSettingsDisabled] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showInform, setShowInform] = React.useState(false);
  const [showRename, setShowRename] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const message = 'Schema has been successfully rebuilt.';
  const [showSettings, setShowSettings] = useState();
  const showToggleSettings = () => {
    setShowSettings(!showSettings);
  };

  // console.log('updateActiveData', updateActiveData);
  // console.log('updateInactiveData', updateInactiveData);
  // console.log('ws', ws.current);

  React.useEffect(() => {
    return () => {
      // console.log('CLOSED SOCKET TASK INFO');
      ws.current.cwFinal = true;
      ws.current.cwEdit = false;
      ws.current.close();
    };
  }, []);

  React.useEffect(() => {
    // console.log('WS', ws.current);
    // readyState===0 - CONNECTING
    // readyState===1 - OPEN
    // readyState===2 - CLOSING
    // readyState===3 - CLOSED
    if (ws.current === null || ws.current.readyState === 2 || ws.current.readyState === 3) {
      // console.log('restart');
      startWS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws.current]);

  React.useEffect(() => {
    schemaName.current = schema;
    ws?.current?.readyState === 1 && ws?.current?.close();
    ws.current.cwFinal = true;
    ws.current.cwEdit = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema]);

  const startWS = () => {
    ws.current = new WebSocket(
      `ws://${host}/api/encoder/schema/state?storage_id=${storage_id}&schema=${schemaName.current}`,
    );
    ws.current.onopen = () => {
      ws.current.send(
        `/api/encoder/schema/state?storage_id=${storage_id}&schema=${schemaName.current}`,
      );
      // console.log('Соединение открыто');
    };
    ws.current.onclose = () => {
      if (!ws.current.cwFinal) {
        setTimeout(() => startWS(), 3000);
      }
      if (ws.current.cwEdit) {
        setTimeout(() => startWS(), 3000);
      }
      // console.log('Соединение закрыто');
    };
    ws.current.onerror = (event) => {
      console.dir(event);
    };
    gettingData();
  };

  const gettingData = () => {
    ws.current.onmessage = (e) => {
      if (e.data === 'OK') {
        return;
      }
      const message = JSON.parse(e.data);
      // console.log('message', message);
      setWsData(message?.data?.data ? message.data.data : []);
    };
  };

  useEffect(() => {
    dispatch(taskParamsActions.setEditTaskState(wsData?.graph?.state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsData?.graph?.state]);

  const taskRebuild = () => {
    apiTaskRebuild(storage_id, schema)
      .then((response) => setShowInform(true))
      .catch((error) => {
        setMessageError(error?.response?.data);
        setShowError(true);
      });
  };

  return {
    wsData,
    taskRebuild,
    showRename,
    setShowRename,
    showError,
    setShowError,
    messageError,
    setMessageError,
    showInform,
    setShowInform,
    message,
    showToggleSettings,
    showSettings,
    isSettingsDisabled,
    setIsSettingsDisabled,
    showSuccessModal,
    setShowSuccessModal,
  };
};

export default useTaskInfoData;
